import React, { useEffect, useState, useRef } from 'react'
import { deleteScorecard, postScoreCard, sendScorecardMail } from '../../api/tournamentAPI';
import swal from 'sweetalert2';

const StrokePlayGross = ({
    playerList,
    tournamentDetails,
    tournamentRound,
    format,
    selectedPlayer,
    setScorecardStatus,
    scorecardStatus,
    gameDetails,
    closeDrawer,
    fetchTournamentLeaderboard
}) => {

    const [scores,              setScores]              = useState({});
    const [totalScores,         setTotalScores]         = useState([]);
    const [userTotal,           setUserTotal]           = useState(0);
    const [footerTotalScore,    setFooterTotalScore]    = useState('-');
    const [comment,             setComment]             = useState("");
    const inputRefs = useRef([]);

    useEffect(() => {
        const initialScores = {};
        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            const oldScore = gameDetails.Card.find(s => s.ScoreCardMasterID == item.ID);
            initialScores[index] = oldScore ? oldScore.Score : 0;
        });
        setScores(initialScores);
        calculateScores(initialScores);
    }, [gameDetails]);

    useEffect(() => {
        if (selectedPlayer) {
            setScorecardStatus(selectedPlayer.IsVerified == 'Y' ? "verify" : selectedPlayer.IsVerified == "WD" ? 'withdraw' : selectedPlayer.IsVerified == 'DQ' ? 'disqualify' : '')
        }
    }, [selectedPlayer])

    const calculateScores = (updatedScores) => {
        const updatedTotalScores = [];
        let cumulativeTotalScore = 0;
        let cumulativeUserTotal = 0;
        let allScoresZero = true;

        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            const score = parseInt(updatedScores[index], 10) || 0;
            const cal = score - item.Par;

            if (score !== 0) {
                allScoresZero = false;
                cumulativeTotalScore += cal;
            }

            updatedTotalScores[index] = {
                score: score == 0 ? '-' : (cal == 0 ? 'E' : (cal < 0 ? cal : "+" + cal)),
                totalScore: score == 0 ? '-' : (cumulativeTotalScore == 0 ? 'E' : (cumulativeTotalScore < 0 ? cumulativeTotalScore : "+" + cumulativeTotalScore)),
            };

            cumulativeUserTotal += score;
        });

        setTotalScores(updatedTotalScores);
        setUserTotal(cumulativeUserTotal);
        setFooterTotalScore(allScoresZero ? '-' : (cumulativeTotalScore == 0 ? 'E' : (cumulativeTotalScore < 0 ? cumulativeTotalScore : "+" + cumulativeTotalScore)));
    };

    const handleScoreChange = (index, value) => {
        // if (!(/^[0-9X]*$/).test(value))  return;  // If the value matches the regex, update the state

        const updatedScores = { ...scores, [index]: value };
        setScores(updatedScores);
        calculateScores(updatedScores);

        // Move focus to the next input field if it exists
        if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
            inputRefs.current[index + 1].select();
        }
    };

    const toggleStatus = (value) => {
        setScorecardStatus(prevStatus => (prevStatus == value ? '' : value));
    };

    const deleteScoreCard = async () => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await deleteScorecard(selectedPlayer.DrawID);
            fetchTournamentLeaderboard();
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : 'Scorecard Updated Successfully!',
                timer               : 1500,
                animation           : false,
                showConfirmButton   : false
            });
            closeDrawer();
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const saveScorecard = () => {
        let newScoreCard = [];
        let notComplete = false;

        let hole = 0;

        const rows = document.querySelectorAll('#table-scorecard > tbody > tr');
        rows.forEach((row, index) => {
            const masterID = row.getAttribute('data-masterid');

            const userControl = row.querySelector(`[id^=txtPlayer${index}Score]`);
            const userInputVal = userControl ? userControl.value : '0';

            
            const grossScore = parseInt(userInputVal, 10);

            const pointElement = row.querySelector('[id^=lblScore]');
            const extractedPoint = pointElement.getAttribute('data-score')
            const point = (extractedPoint == '-' || extractedPoint == 'E') ? 0 : parseInt(extractedPoint, 10);

            const totalPointElement = row.querySelector('[id^=lblTotalScore]');
            const extractedTotalPoint = totalPointElement.getAttribute('data-score')
            const totalPoint = (extractedTotalPoint == '-' || extractedTotalPoint == 'E') ? 0 : parseInt(extractedTotalPoint, 10);

            if ((isNaN(grossScore) || grossScore == 0) && scorecardStatus == 'verify') {
                notComplete = true;
                return;
            } else {
                newScoreCard.push({
                    ID                  : 0,
                    TournamentDrawID    : selectedPlayer.DrawID,
                    PlayerID            : selectedPlayer.PlayerID,
                    PlayerType          : selectedPlayer.Player1Type,
                    ScoreCardMasterID   : masterID,
                    Score               : grossScore,
                    HandicapScore       : grossScore,
                    DriveUserID         : 0,
                    Point               : point,
                    TotalPoint          : totalPoint,
                    Scratch             : "N"
                });

                if (grossScore !== 0) hole++;
            }
        });

        const result = {
            ID: 0,
            TournamentDrawID: selectedPlayer.DrawID,
            Hole: hole,
            Point: footerTotalScore == 'E' ? 0 : footerTotalScore,
            TotalPoint: footerTotalScore == 'E' ? 0 : footerTotalScore,
            IsVerified: scorecardStatus == 'verify' ? 'Y' : (scorecardStatus == 'disqualify' ? 'DQ' : (scorecardStatus == 'withdraw' ? 'WD' : 'N')),
            Comment: (scorecardStatus == 'disqualify' || scorecardStatus == 'withdraw') ? comment : ''
        };

        if (notComplete) {
            swal.fire({ 
                icon        : "warning", 
                titleText   : "Incomplete Scorecard", 
                text        : "Please complete the scorecard..!" 
            });
            return;
        }

        const dto = {
            tournamentId: tournamentDetails.ID,
            round: selectedPlayer.Round,
            userId: selectedPlayer.PlayerID,
            scoreCard: newScoreCard,
            result: result
        };

        postScorecard(dto)
    }

    const postScorecard = async (scoreCard) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await postScoreCard(scoreCard);
            fetchTournamentLeaderboard();
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : 'Scorecard Updated Successfully!',
                timer               : 1500,
                animation           : false,
                showConfirmButton   : false
            });
            closeDrawer();
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const sendScorecard = async () => {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});
            const conditional = (tournamentDetails.Higher != 100) ? "*" : "";
            const playerNameList = []

            playerNameList.push({
                ID: 1,
                Name: `${playerList.Player1.UserName} - ${selectedPlayer.Handicap} ${conditional}`,
                Email: playerList.Player1.Email
            })

            const scoreCardList = [];

            const rows = document.querySelectorAll('#table-scorecard > tbody > tr');
            rows.forEach((row, index) => {
                const userControl = row.querySelector(`[id^=txtPlayer${index}Score]`);
                const userInputVal = userControl ? userControl.value : '0';            
                const grossScore = parseInt(userInputVal, 10);

                const pointElement = row.querySelector('[id^=lblScore]');
                const extractedPoint = pointElement.getAttribute('data-score')
                const point = (extractedPoint == '-' || extractedPoint == 'E') ? 0 : parseInt(extractedPoint, 10);

                const totalPointElement = row.querySelector('[id^=lblTotalScore]');
                const extractedTotalPoint = totalPointElement.getAttribute('data-score')
                const totalPoint = (extractedTotalPoint == '-' || extractedTotalPoint == 'E') ? 0 : parseInt(extractedTotalPoint, 10);

                scoreCardList.push({
                    Hole: $(row).children('td').eq(0).text(),
                    Par: $(row).children('td').eq(1).text(),
                    Stroke: $(row).children('td').eq(2).text(),
                    Player1GrossScore:grossScore,
                    Player1Point: point,
                    Total: totalPoint
                })
            })

            
            const tournament = {
                ID      : tournamentDetails.ID,
                Name    : tournamentDetails.Name,
                Format  : format?.Format,
                Round   : selectedPlayer.Round,
                Date    : tournamentDetails.StartDate
            };

            await sendScorecardMail(playerNameList, scoreCardList, gameDetails.TeeBoxes.Par, userTotal, footerTotalScore, tournament)
            swal.close();
            swal.fire({
                icon: 'success',
                titleText: 'Scorecard Email Sent',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }
    
    return (
        <>
            <div className="kt-portlet kt-portlet--mobile padding-lr20">
                <div className="kt-portlet__head kt-portlet__head--lg  d-block padding-lr0 border-0">
                    <div id="roundInfoPanel" className="kt-notification margin-t10 mb-3" style={{backgroundColor: "rgb(247, 248, 250)"}}>
                        <div className="kt-notification__item d-block">
                            <div className="row">
                                <div className="col-3">
                                    <div className="kt-user-card-v2 mb-2">
                                        <div className="kt-user-card-v2__pic">
                                            <img id="txtTournamentImg" src={tournamentDetails?.Icon} alt="tournament-logo" />
                                        </div>
                                        <div className="kt-user-card-v2__details ">
                                            <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tournamentDetails?.Name} </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtRound"> Round {selectedPlayer?.Round} </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2 ">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name ">Date </span>
                                            <span className="kt-user-card-v2__desc">
                                                <span id="txtStartDate">{moment(tournamentDetails?.StartDate).format('DD-MMM-YYYY')}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name ">Format </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtFormat">{format?.Format}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {!!tournamentRound && tournamentRound?.length > 0 && (
                                    <div className="col-3 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name ">Tee Of Option </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtTeeOption">{format?.TeeOffOption}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2 ">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name " id="lblStartTime">StartTime </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtStartTime">{selectedPlayer.Slot}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="row">		
                                <div className="col-9">
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success mb-0">
                                        <input 
                                            value="verify"
                                            name='verify'
                                            checked={scorecardStatus == 'verify'}
                                            onChange={() => toggleStatus('verify')}
                                            type="checkbox" 
                                            className="form-verify"/> 
                                            Verify Scorecard <span></span>
                                    </label>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="kt-checkbox-inline">										
                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger mb-0 mr-2">
                                            <input 
                                                value="disqualify"
                                                name='disqualify' 
                                                checked={scorecardStatus == 'disqualify'}
                                                onChange={() => toggleStatus('disqualify')}
                                                type="checkbox" 
                                                className="form-disqualify" />
                                                Disqualify <span></span>
                                        </label>
                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--brand mb-0">
                                            <input 
                                                value="withdraw"
                                                checked={scorecardStatus == 'withdraw'}
                                                onChange={() => toggleStatus('withdraw')}
                                                name='withdraw' 
                                                type="checkbox" 
                                                className="form-withdraw" />
                                                Withdraw <span></span>
                                        </label>
                                    </div>														
                                </div>  
                            </div>
                            {(scorecardStatus == 'withdraw' || scorecardStatus == 'disqualify') && (
                                <div className="row justify-content-end mt-2" id="pnlComment">                                
                                    <div className="col-4 text-right">
                                        <textarea 
                                            name="txtComment" 
                                            id="txtComment" 
                                            className="form-control"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)} 
                                            cols="3" rows="2" placeholder="Comments" 
                                        ></textarea>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="table-register">

                        <table id="table-scorecard" className="table table-bordered table-striped table-card">
                            <thead>
                                <tr id="mainHeader">
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Hole   </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Par    </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Stroke </th>
                                    {selectedPlayer && (
                                        <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                            <span className="mr-2">{selectedPlayer.Player1} ({(selectedPlayer.Handicap < 0) ? "+" + Math.abs(selectedPlayer.Handicap) : selectedPlayer.Handicap})</span>
                                        </th>
                                    )}
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Score </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Total Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gameDetails && gameDetails.TeeBoxes.ScoreCard.map((item, index) => {
                                    const score = scores[index] || 0;
                                    const totalScore = totalScores[index] || { score: '-', totalScore: '-' };
                                    return(
                                        <tr key={`row_${item.HoleNoValue}`} className="row-score" data-masterid={item.ID}>
                                            <td style={{ textAlign: 'center'}}>{item.HoleNoValue}</td>
                                            <td style={{ textAlign: 'center'}}>{item.Par}</td>
                                            <td style={{ textAlign: 'center'}}>{item.Stroke}</td>
                                            <td key={`cell_${item.HoleNoValue}_${selectedPlayer.PlayerID}`} className={`card-data-${index} player-td`} data-playerid={selectedPlayer.PlayerID} data-scoreid='0'>
                                                <div className="input-group">
                                                    <input
                                                        id={`txtPlayer${index}Score`}
                                                        type="number"
                                                        className="form-control input-sm input-numeric"
                                                        value={score}
                                                        onChange={(e) => handleScoreChange(index, e.target.value)}
                                                        // onKeyDown={(e) => handleKeyDown(e, index)}
                                                        ref={el => inputRefs.current[index] = el}
                                                    />
                                                </div>
                                            </td>
                                            <td id={`lblScore${index + 1}`} className="card-score" style={{ textAlign: 'center' }} data-score={totalScore.score}>
                                                {totalScore.score}
                                            </td>
                                            <td id={`lblTotalScore${index + 1}`} className="card-total-score" style={{ textAlign: 'center' }} data-score={totalScore.totalScore}>
                                                {totalScore.totalScore}
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>   
                            <tfoot>
                                <tr className="bg-light font-weight500">
                                    <td style={{textAlign: 'center'}}>OUT</td>
                                    <td style={{textAlign: 'center'}} id="lblTotalPar"> {gameDetails.TeeBoxes.Par} </td>
                                    <td style={{textAlign: 'center'}}> Total Score </td>
                                    <td style={{textAlign: 'center'}}>{userTotal}</td>
                                    <td colSpan={2} style={{ textAlign: 'center' }}>
                                        {footerTotalScore}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                        <div className="row">
                            <div className="col-lg-12 ml-lg-auto text-center">
                                <button id="btnSave" type="button" className="btn btn-success d-inline-block mr-2" onClick={saveScorecard}> Save </button>
                                <button id="btnDelete" type="button" className="btn btn-danger d-inline-block" onClick={deleteScoreCard}> Delete </button>
                                <button id="btnBack" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Close </button>
                            </div>
                            {(selectedPlayer.IsVerified == 'Y' || selectedPlayer.IsVerified == 'WD' || selectedPlayer.IsVerified == 'DQ') && (
                                <button id="btnEmailCard" type="button" className="btn btn-message pull-right" onClick={sendScorecard}><i className="fa fa-mail-forward bigger-110"></i> Email Card </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StrokePlayGross