import { useState } from "react";
import { useEffect } from "react";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { getSportMasterAll } from "../api/sportsAPI";
import RightSideDetails from "../global/rightSideDetails";
import { getGuestAll, getMobileCodeAll, getViewUserAll } from "../api/usersAPI";
import AddGuest from "../teeTime/addGuest";
import { addPaymentRegistration, getPayablesForPaymentRegister, getViewPaymentRegistrationByDate, resendPaymentLink } from "../api/paymentAPI";
import defaultUserImg from '../../assets/images/user.jpg'
import VerificationCodeModal from "./verificationCodeModal";

const CollectionRegister = (props) => {

    const [rightSideDetails,                setRightSideDetails]         = useState(false);
    const [sports,                          setSports]                   = useState([]);
    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [checkInTable,                    setCheckInTable]             = useState(null);
    const [isInit,                          setIsInit]                   = useState(true);
    const [checkInReport,                   setCheckInReport]            = useState([]);
    const [paymentDetails,                  setPaymentDetails]           = useState({UserType: "Member", IsCart: "Y", Bucket: 1, Charge: 'Green Fee', GuestType: "Users Guest"});
    const [type,                            setType]                     = useState({});
    const [typeOption,                      setTypeOption]               = useState([{ value:  'Member', label: 'Member'}, { value:  'Guest', label: 'Guest'}]);
    const [guestTypeOption,                 setGuestTypeOption]          = useState([{ value:  'Users Guest', label: 'Users Guest'}, { value:  'Visitor', label: 'Visitor'}]);
    const [chargeTypeOption,                setChargeTypeOption]         = useState([{ value:  'Green Fee', label: 'Green Fee'}, { value:  'Additional Bucket', label: 'Additional Bucket'}]);
    const [bucketOption,                    setBucketOption]             = useState([{ value:  '1', label: '1'}, { value:  '2', label: '2'},{ value:  '3', label: '3'}, { value:  '4', label: '4'}, { value:  '5', label: '5'}]);
    const [paymentOption,                   setPaymentOption]            = useState([ { value:  'On Account', label: 'On Account'} ,{ value:  'Online', label: 'Online'} ,{ value:  'Offline', label: 'Offline'}]);
    const [usersList,                       setUsersList]                = useState([]);
    const [filteredUsersList,               setFilteredUsersList]        = useState([]);
    const [guestList,                       setGuestList]                = useState([]);
    const [mobileCodeList,                  setMobileCodeList]           = useState([]);
    const [userName,                        setUserName]                 = useState('');
    const [tagName,                         setTagName]                  = useState('');
    const [showAddGuest,                    setShowAddGuest]             = useState(false);
    const [payable,                         setPayable]                  = useState({});
    const [rateDetails,                     setRateDetails]              = useState({});
    const [userDetails,                     setUserDetails]              = useState({});
    const [orderID,                         setOrderID]                  = useState(null);
    const [serverTimeStamp,                 setServerTimeStamp]          = useState();

    useEffect(() => {
        props.title('Collection Register','collection-register')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchSports();
        fetchUsers();
        fetchGuest();
    },[]);
 
    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setPaymentDetails({UserType: "Member", IsCart: "Y", Bucket: 1, Charge: 'Green Fee', GuestType: "Users Guest"})
            setUserName('')
            setTagName('')
        }
    },[rightSideDetails]);
 
    useEffect(() => {
        if(paymentDetails?.SportID && usersList.length > 0){
            const filteredList = (paymentDetails.SportID == 1 || paymentDetails.SportID == 2) ? usersList.filter(f => f.MembershipType == "Golf Membership") : usersList;
            setFilteredUsersList(filteredList)
        };
        if(paymentDetails?.UserID && paymentDetails?.SportID){
            const memberDetails = paymentDetails.UserType == "Member" ? filteredUsersList.find(m => m.ID == paymentDetails.UserID) : {...guestList.find(m => m.ID == paymentDetails?.UserID), GroupID: (paymentDetails.GuestType == "Users Guest" || !paymentDetails.GuestType) ? -1 : -2};
            fetchPayables(memberDetails.GroupID, memberDetails?.DOB || '2001-01-01', paymentDetails?.SportID);
        };

    },[paymentDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [checkInReport]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate){
            fetchCheckInReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }
    },[selectedFromDate, selectedToDate]);

    useEffect(() => {
        if(type && !isInit){
            $('#tblCheckInReport').KTDatatable().destroy(); 
            fillDataTable();
        }
    },[type]);

    useEffect(() => {
        if(!!payable && paymentDetails?.SportID){
            calculateTotalWithTaxes(paymentDetails?.SportID, payable)
        }
    },[payable]);

    const updatePaymentDetails = (field, value) => {   

        if(field == 'UserType'){
            setPaymentDetails(a => ({...a, UserID: '', PaymentMode: '', GuestType: 'Users Guest'}))
            setUserName('')
            setTagName('')
        };
        if(value == 'Visitor'){
            setPaymentDetails(a => ({...a, MemberID: '', PaymentMode: ''}))
            setTagName('')
        };
        setPaymentDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchSports = async() => {
        try {
            const res =  await getSportMasterAll();
            const activeMaster = res.filter(f => f.ActiveAdmin == "Y")
            const masters = [{ID: '0', Name: 'All'}, ...activeMaster]
            masters.length > 0 && setType({'SportID': masters[0].ID})
            setSports(masters)        
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };
 

    const fetchUsers = async() => {
        try {
            const users = await getViewUserAll();
            setUsersList(users);
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
 
    const fetchGuest = async() =>{
        try {
            const guests = await getGuestAll()    
            const mobileCodeArr = await getMobileCodeAll() 
            setMobileCodeList(mobileCodeArr); 
            setGuestList(guests);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchCheckInReport = async(fdate, tDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getViewPaymentRegistrationByDate(fdate, tDate)

            isInit ? setIsInit(false) : $('#tblCheckInReport').KTDatatable().destroy(); 
            setCheckInReport(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };
 
    const fetchPayables = async(groupID, dob, sportID) => {
        try {
            const payables = await getPayablesForPaymentRegister(groupID, dob, sportID);
            setPayable(payables);
            setUserDetails({GroupID: groupID, DOB: dob})
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const sendPaymentLink = async(paymentDetails) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure',
            text                : `You want to resend payment link to ${paymentDetails.UserName}`,
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Confirm',
            preConfirm: async () => {
              try { 
                await resendPaymentLink(paymentDetails)
                setTimeout(() => {
                    fetchCheckInReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                }, 1600);
              } catch (err) {
                swal.fire({
                  icon                : 'error',
                  titleText           : 'Error!',
                  text                : err.message,
                  buttonsStyling      : false,
                  confirmButtonClass  : 'btn btn-brand',
                });
                return;
              }
            },
        });
        if (!swalResult.value) return;

        swal.fire({
            icon              : 'success',
            titleText         : 'Email Send Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    function exportSheet(type) {
        checkInTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - CheckIn Report` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    };

    function fillDataTable() {
        let reportList;

        if(type.SportID == 0){
            reportList = checkInReport.PaymentRegistrationList
        }else{
            reportList = checkInReport.PaymentRegistrationList.filter(f => f.SportID == type.SportID)

        };
        const dataTable = $('#tblCheckInReport').KTDatatable({
            data: {
                saveState: false,
                source: reportList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'FirstName',
                    title: 'Member',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.UserName} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.UserType} </span>
                                    </div>
                                </div>`
                    }
                },
                {
                    field: ' ',
                    title: 'Contact',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Mobile} </span> 
                                        <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    },
                    width: 180,

                }, 
                {
                    field: 'Facility',
                    title: 'Facility',
                    width: 90   

                },
                {
                    field: 'MembershipType',
                    title: 'Membership Type',
                    width: 150

                },
                {
                    field: 'BillNumber',
                    title: 'Bill Number',
                    width: 85,
                },
                {
                    field: 'Amount',
                    title: 'Bill Amount',
                    width: 80,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.Amount}</strong>`
                    }
                }, 
                {
                    field: 'TransactionDate',
                    title: "Transaction Date",
                    width: 120,
                    template: function (row) {
                        return moment(row.TransactionDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'PaymentType',
                    title: 'Type',
                    width: 80,
                    template: function (row) {
                        return `<span style="width: 70px;"><div ><span> ${row.PaymentType} </span></div></span>`;
                    }
                },
                {
                    field: 'OrderStatus',
                    title: 'Status',
                    width: 80,
                    template: function (row) {
                        if (row.OrderStatus == 'Success') {
                            return `<div><span class="btn btn-bold btn-font-sm  btn-label-success" style="padding-left: 9px"> Success</span></div>`;
                        } else if(row.OrderStatus == 'Pending'){
                            return `<div><span class="btn btn-bold btn-font-sm  btn-label-warning">Pending</span></div>`;
                        }  else {
                            return `<div><span class="btn btn-bold btn-font-sm  btn-label-danger">Fail</span></div>`;
                        }
                    }
                },
                {
                    field: 'Comment',
                    title: ' ',
                    width: 60,
                    template: function (row) {
                        const comment = row?.Comment || ''; // Get comment or empty string
                        const linkID = row?.LinkID || ''; // Get comment or empty string
                        return `<center>
                            ${row?.OrderStatus == 'Success' ? '<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-invoice" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="View Receipt"><i class="fa fa-file-alt"></i></button>' : ''}
                            ${comment && `&nbsp;<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover" data-trigger="hover click" data-placement="left" data-content="${comment}"><i class="fa fa-info"></i></button>`}
                            <span style="display: none;">${comment}</span>
                            ${ (row.PaymentType != 'On Account' && linkID && row.OrderStatus != 'Success') ? `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-email" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="Resend Payment Link"><i class="flaticon2-email"></i></button>` : ''}
                        </center>`; 
                    }
                    
                },
            
            ]
            ,
            rows: {
                autoHide: false,
            }
        });
        Config.Core.InitPopover();

        dataTable.on('click', '.btn-invoice', function (ss) {  
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                window.open(`https://gbgc.golfez.in/invoice?id=${dataTable.dataSet[index].PaymentOrderID}`, '_blank', 'noreferrer');
            } 
        });
        dataTable.on('click', '.btn-email', function (ss) {  // Email for individual
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                sendPaymentLink(dataTable.dataSet[index])
            } 
        });
        // export to excel
        const checkInTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - CheckIn Report`,
        });
        setCheckInTable(checkInTable);
        $('.tableexport-caption').addClass('hidden');
        
    };
    
    const addCheckInHandler = async() => {
        var form = $('#add-CheckIn-form');
        
        form.validate({
            rules: {
                ddlSport: {
                    required: true
                },          
                ddlType: {
                    required: true
                },          
                ddlUser: {
                    required: true,
                },        
                ddlGuest: {
                    required: true
                },          
                ddlGuestType: {
                    required: true,
                },        
                ddlUserTag: {
                    required: true
                },          
                ddlCharge: {
                    required: true,
                },        
                ddlBucket: {
                    required: true
                },          
                ddlPaymentMode: {
                    required: true
                },
                txtEmail: {
                    required: true,
                }        
            },
            messages: {
                ddlSport: {
                    required: "Please select sports"
                },
                ddlType: {
                    required: "Please select type",
                },
                ddlUser: {
                    required: "Please select user",
                },
                ddlGuest: {
                    required: "Please select guest"
                },
                ddlGuestType: {
                    required: "Please select guest type",
                },
                ddlUserTag: {
                    required: "Please select user to tag"
                },
                ddlCharge: {
                    required: "Please select charge",
                },
                ddlBucket: {
                    required: "Please select bucket"
                },
                ddlPaymentMode: {
                    required: "Please select mode",
                },
                txtEmail: {
                    required: "Please enter email",
                }
            }
        });
        
        if (!form.valid()) return;
        if(paymentDetails?.UserID && !userName) return
        swal.fire({
            titleText           : 'Loading',
            text                : 'Please Wait...',
            showConfirmButton   : false,
            allowOutsideClick   : false,
            onOpen: () => {
                swal.showLoading();
            }
        });
        try {
            const response = await addPaymentRegistration({...paymentDetails, ...userDetails})
            if(paymentDetails.PaymentMode == "On Account"){
                setOrderID(response.orderID)
                setServerTimeStamp(response.serverTimestamp)
                swal.close(); 
            }else{
                swal.close(); 
                setTimeout(() => {
                    fetchCheckInReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                }, 1600);
                closeDrawer(); 
                swal.fire({
                    icon: 'success',
                    titleText: 'Payment registered successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    };

    // const resetUser = () => {
    //     setPaymentDetails(a => ({...a, UserID: ''}))
    // }

    const deleteUserHandler = () => {
        setUserName('');
        setPaymentDetails(a => ({...a, UserID: '', Email: ''}));
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    };

    const addUser = () => {
        setUserName(filteredUsersList?.find(m => m.ID == paymentDetails?.UserID)?.Name);
        updatePaymentDetails('Email', filteredUsersList?.find(m => m.ID == paymentDetails?.UserID)?.Email);
    };
      
    const addGuest = () => {
        setUserName(guestList?.find(m => m.ID == paymentDetails?.UserID)?.Name);
        if(!((paymentDetails.GuestType == "Users Guest" || !paymentDetails.GuestType) && paymentDetails.UserType == "Guest")){
            updatePaymentDetails('Email', guestList?.find(m => m.ID == paymentDetails?.UserID)?.Email);
        };
    };

    const addTagUser = () => {
        setTagName(filteredUsersList.find(m => m.ID == paymentDetails?.MemberID)?.Name)
        updatePaymentDetails('Email', filteredUsersList?.find(m => m.ID == paymentDetails?.MemberID)?.Email);
    };

    const calculateTotalWithTaxes = (sportID, obj) => {
 
        let total = 0;
        let bucket;
        const cart = paymentDetails?.IsCart == "Y" ? obj?.Cart : 0
    
        // Select the appropriate object and calculate the total
        if (sportID === 1) {
            total = obj.GreenFee + cart;
        } else if (sportID === 2) {
            bucket = paymentDetails?.Charge == 'Additional Bucket' ? obj.Bucket2 * paymentDetails?.Bucket : obj.Bucket1 + (paymentDetails?.Bucket >= 2 ? obj.Bucket2 * (paymentDetails?.Bucket - 1) : 0)
            obj.Bucket = bucket
            obj.GreenFee = paymentDetails?.Charge == 'Additional Bucket' ? 0 : obj.GreenFee 
            total = paymentDetails?.Charge == 'Additional Bucket' ? bucket : obj.GreenFee + bucket;
        } else {
            total = obj.Rate;   
        }
    
        // Calculate CGST and SGST at 9% each
        const cgst = total * 0.09;
        const sgst = total * 0.09;
    
        // Calculate the final total including taxes
        const finalTotal = (total + cgst + sgst);
    
        // Create an object to store the breakdown and total
        const result = {
            ...obj,
            Cart: cart,
            Bucket: obj.Bucket,
            Cgst: Math.round(cgst),
            Sgst: Math.round(sgst),
            FinalTotal: Math.round(finalTotal),
        };
        setRateDetails(result);
    };
 
    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    {/* <label className="col-form-label col-sm-1 text-right">From Date</label> */}
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <label className="col-form-label col-sm-1 text-right">To Date</label> */}
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div> 
                                    <label className="col-form-label col-sm-1 text-right">Facility</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlFacility" 
                                                name="ddlFacility"
                                                className="multi-custom width-95p"
                                                value={((sports && sports.length > 0 && type?.SportID) && {label: (sports.find((m) => m.ID == type?.SportID) && sports.find((m) => m.ID == type?.SportID).Name), value: type.SportID}) || ''}
                                                onChange={(event) => setType({SportID: event.value})}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="kt-portlet__head-actions" style={{textAlign: 'center'}}>
                                            <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>Add Check In</button>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="row monthly-payemnt-amount-box" style={{color: '#535354', fontSize: '14px'}}>
                                <label className="col-12"><span style={{fontWeight: '400'}}>Total Amount</span><strong style={{fontWeight: '600'}}> :  ₹ {checkInReport.Total}</strong>  </label>
                            </div>
                            <div id="tblCheckInReport" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && <RightSideDetails title={moment(moment(), 'DD-MM-YYYY').format('DD MMMM YYYY')} onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-CheckIn-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Facility <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlSport" 
                                                                name="ddlSport"
                                                                className="multi-custom"
                                                                placeholder="Select Sports"
                                                                value={(sports && sports.length > 0 && paymentDetails.SportID) && {label: sports.find((m) => m.ID == paymentDetails.SportID).Name ,value: paymentDetails.SportID}}
                                                                onChange={(event) => updatePaymentDetails('SportID', event.value)}
                                                                options={sports.filter(f => f.ID != 0).map(c => ({
                                                                    label: c.Name,
                                                                    value: c.ID
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> User Type <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlType" 
                                                                name="ddlType"
                                                                className="multi-custom"
                                                                placeholder="Select User Type"
                                                                defaultValue={ typeOption.find((m) => m.value[0] )  || ''}
                                                                onChange={(event) => updatePaymentDetails('UserType', event.value)}
                                                                options={typeOption}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {(paymentDetails.UserType == "Member" || !paymentDetails.UserType) ? (
                                                    <div id="UserHolder">
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">User <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group">
                                                                <div style={{ width: "60%" }}>
                                                                    <Select
                                                                        value={
                                                                            filteredUsersList && filteredUsersList.length > 0 && paymentDetails.UserID
                                                                                ? { label: `${filteredUsersList.find(m => m.ID === paymentDetails.UserID).Name} (${filteredUsersList.find(m => m.ID === paymentDetails.UserID).Mobile})`, value: paymentDetails.UserID }
                                                                                : ''
                                                                        }
                                                                        onChange={(event) => {
                                                                            updatePaymentDetails('UserID', event.value);
                                                                            document.getElementById("btnAddUser").classList.add("pulse-animation");
                                                                        }}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        isDisabled={!!userName}
                                                                        className="ddlUser"
                                                                        options={filteredUsersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <button
                                                                    id="btnAddUser"
                                                                    className="btn btn-label-brand margin-l10 margin-r0"
                                                                    type="button"
                                                                    disabled={!!userName}
                                                                    onClick={addUser}
                                                                    onAnimationEnd={() => document.getElementById("btnAddUser").classList.remove("pulse-animation")}
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div id="GuestHolder" >
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Guest <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div className="input-group">
                                                                    <div style={{width: "60%"}}  >
                                                                        <Select
                                                                            value={(guestList && guestList.length > 0 && paymentDetails.UserID) && {label: `${guestList.find(m => m.ID == paymentDetails?.UserID)?.Name} (${guestList.find(m => m.ID == paymentDetails?.UserID)?.Mobile})`, value: paymentDetails?.UserID}}
                                                                            onChange={(event) => {updatePaymentDetails('UserID', event.value), document.getElementById("btnAddGuest").classList.add("pulse-animation")}}
                                                                            name="ddlGuest"
                                                                            placeholder="Select Guest"
                                                                            id="ddlGuest"
                                                                            data-live-search="true"
                                                                            className="ddlGuest"
                                                                            isDisabled={!!userName}
                                                                            options={guestList.map(c => ({
                                                                                label: `${c.Name} (${c.Mobile})`,
                                                                                value: c.ID
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="input-group-append"  style={{zIndex: 0}}>
                                                                        <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                    </div> */}
                                                                    <button id="btnAddGuest" className="btn btn-label-brand margin-l10 margin-r0" type="button" disabled={!!userName} onClick={addGuest} onAnimationEnd={() => document.getElementById("btnAddGuest").classList.remove("pulse-animation")}>Add</button>
                                                                    <button id="btnNewGuest" className="btn btn-label-brand margin-l10" type="button" disabled={!!userName} data-toggle="modal" data-target="#modalGuest"  onClick={() => setShowAddGuest(true)} >New</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* /////guest type //// */}
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Guest Type <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div>
                                                                    <Select 
                                                                        id="ddlGuestType" 
                                                                        name="ddlGuestType"
                                                                        className="multi-custom"
                                                                        placeholder="Select Guest Type"
                                                                        defaultValue={ guestTypeOption.find((m) => m.value[0] )  || ''}
                                                                        onChange={(event) => updatePaymentDetails('GuestType', event.value)}
                                                                        options={guestTypeOption}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                               {/* /////tagmember //// */}
                                                {((paymentDetails.GuestType == "Users Guest" || !paymentDetails.GuestType) && paymentDetails.UserType == "Guest") && (
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Member <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(filteredUsersList && filteredUsersList.length > 0 && paymentDetails.MemberID) && {label: `${filteredUsersList.find(m => m.ID == paymentDetails.MemberID).Name} (${filteredUsersList.find(m => m.ID == paymentDetails.MemberID).Mobile})`, value: paymentDetails.MemberID} || ''}
                                                                        onChange={(event) => {updatePaymentDetails('MemberID', event.value), document.getElementById("btnTagUser").classList.add("pulse-animation");}}
                                                                        name="ddlUserTag"
                                                                        placeholder="Select User"
                                                                        id="ddlUserTag"
                                                                        className="ddlUserTag"
                                                                        options={filteredUsersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                {/* <div className="input-group-append" style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div> */}
                                                                <button id="btnTagUser" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addTagUser} onAnimationEnd={() => document.getElementById("btnTagUser").classList.remove("pulse-animation")}>Tag</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                               {/* /////tagmember //// */}

                                                {/* /////Greenfee and bucket //// */}
                                                {paymentDetails.SportID == 1 && (
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Cart <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8"> 
                                                            <span className="kt-switch kt-switch--icon">
                                                                <label>
                                                                    <input 
                                                                        id="chkActive" 
                                                                        name="chkActive" 
                                                                        type="checkbox" 
                                                                        defaultChecked={paymentDetails.IsCart == "N" ? '' : 'checked'} 
                                                                        onChange={(event) => updatePaymentDetails('IsCart', event.target.checked ? 'Y' : 'N')}
                                                                    />
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {paymentDetails.SportID == 2 && (
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Charge <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div>
                                                                    <Select 
                                                                        id="ddlCharge" 
                                                                        name="ddlCharge"
                                                                        className="multi-custom"
                                                                        placeholder="Select Charge"
                                                                        defaultValue={ chargeTypeOption.find((m) => m.value[0] )  || ''}
                                                                        onChange={(event) => updatePaymentDetails('Charge', event.value)}
                                                                        options={chargeTypeOption}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* /////Bucket List//// */}

                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Bucket <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div>
                                                                    <Select 
                                                                        id="ddlBucket" 
                                                                        name="ddlBucket"
                                                                        className="multi-custom"
                                                                        placeholder="Select Bucket"
                                                                        defaultValue={ bucketOption.find((m) => m.value[0] )  || ''}
                                                                        onChange={(event) => updatePaymentDetails('Bucket', event.value)}
                                                                        options={bucketOption}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                               {/* /////paypent mode//// */}
                                               <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Payment Mode <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div>
                                                            <Select 
                                                                id="ddlPaymentMode" 
                                                                name="ddlPaymentMode"
                                                                className="multi-custom"
                                                                placeholder="Select Payment Mode"
                                                                value={(paymentOption && paymentOption.length > 0 && paymentDetails.PaymentMode) && {label: paymentOption.find((m) => m.label == paymentDetails.PaymentMode).label ,value: paymentDetails.PaymentMode}}
                                                                // value={paymentOption.find((m) => m.value[0] )}
                                                                onChange={(event) => updatePaymentDetails('PaymentMode', event.value)}
                                                                options={paymentOption.filter(f => paymentDetails.GuestType == "Visitor" ? f.label != "On Account" : rateDetails?.FinalTotal != 0 ? f : f.label == "Offline")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={paymentDetails.Comment || ''}
                                                            onChange={(event) => updatePaymentDetails('Comment', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                {paymentDetails?.PaymentMode == 'Online' && (
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Email <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtEmail" 
                                                                name="txtEmail" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={paymentDetails.Email || ''}
                                                                onChange={(event) => updatePaymentDetails('Email', event.target.value)} 
                                                            />
                                                            <small className="form-text text-muted">
                                                                Payment link will be sent to this email address.
                                                            </small>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                        {paymentDetails?.UserID && !!userName && (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                                {/* Table for Player and Action */}
                                                <table id="table-user" className="table table-bordered table-hover margin-b30" style={{ flex: 3, height: 'auto', tableLayout: 'fixed', maxHeight: '100px', overflowY: 'auto' }}>
                                                    <thead className="light-grey-background">
                                                        <tr>
                                                            <th>Player</th>
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className={`kt-badge kt-badge--${paymentDetails.UserType == "User" ? 'success' : (paymentDetails.UserType == "Guest" || paymentDetails.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot`}></span>
                                                                &nbsp; &nbsp;{paymentDetails?.UserID && userName} {!!tagName && '- ' + tagName}
                                                            </td>
                                                            <td>
                                                                <center>
                                                                    <button className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type="button" onClick={() => deleteUserHandler()}>
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                    </button>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot></tfoot>
                                                </table>
                                                {/* New table for SGST, CGST, and Total */}
                                                <table id="table-tax" className="table table-bordered table-hover margin-b30" style={{ flex: 1 }}>
                                                    <thead className="light-grey-background">
                                                        <tr>
                                                            <th>Description</th>
                                                            <th className="text-right">Amount (₹)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(paymentDetails?.SportID != 2 && rateDetails?.GreenFee != 0) && (
                                                            <tr>
                                                                <td>{paymentDetails?.SportID == 1 || paymentDetails?.SportID == 2 ? 'GreenFee' : 'Rate'}</td>
                                                                <td className="text-right">₹ {paymentDetails?.SportID == 1 || paymentDetails?.SportID == 2 ? rateDetails.GreenFee : rateDetails.Rate}</td>
                                                            </tr>
                                                        )}
                                                        {(paymentDetails?.SportID == 1 || paymentDetails?.SportID == 2 ) && (paymentDetails?.SportID != 1 && rateDetails?.Cart != 0) && (
                                                            <tr>
                                                                <td>{paymentDetails?.SportID == 1 ? 'Cart' : paymentDetails.Bucket + ' Bucket'} </td>
                                                                <td className="text-right">₹ {paymentDetails?.SportID == 1 ? rateDetails?.Cart : rateDetails?.Bucket}</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>SGST (9%)</td>
                                                            <td className="text-right">₹ {rateDetails?.Sgst}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CGST (9%)</td>
                                                            <td className="text-right">₹ {rateDetails?.Cgst}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Total</strong></td>
                                                            <td className="text-right"><strong>₹ {rateDetails?.FinalTotal}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot></tfoot>
                                                </table>
                                            </div>
                                        )}

                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCheckInHandler}>Confirm</button>
                                                    <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}                
                        </div>
                    </div>
                </div>
            </div>
            {showAddGuest && (
                <AddGuest
                    show={showAddGuest}
                    onDismissModal={setShowAddGuest} 
                    mobileCodeList={mobileCodeList}
                    fetchBookingDetails={() => fetchGuest()} 
                    setPaymentDetails={setPaymentDetails}
                    from={"#checkInPayment"}
                />
            )}
             {!!orderID && (
                <VerificationCodeModal
                    onDismissModal = {() => setOrderID()}
                    refechData = {() => {fetchCheckInReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) , setRightSideDetails(false)}}
                    orderID ={orderID}
                    userName={paymentDetails.GuestType == "Users Guest" && paymentDetails.UserType != "Member" ? tagName: userName}
                    timeStamp={serverTimeStamp}
                />
            )}  
        </>
    );
};

export default CollectionRegister;