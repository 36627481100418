import { useEffect } from "react";
import { useState } from "react";
import { getOnAccountPaymentByDate } from "../api/paymentAPI";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import defaultUserImg from '../../assets/images/user.jpg'

const OnAccountPayment = (props) => {

    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().subtract(30, "days").toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [status,                          setStatus]                   = useState([{ value:  'All', label: 'All'},{ value:  'Success', label: 'Success'},{ value:  'Fail', label: 'Fail'}]);
    const [isInit,                          setIsInit]                   = useState(true);
    const [orderSheet,                      setOrderSheet]               = useState([]);
    const [statusType,                      setStatusType]               = useState(status[0].value);
    const [orderTable,                      setOrderTable]               = useState(null);

    useEffect(() => {
        props.title('On Account Report','on-account-payment')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
    },[])

    useEffect(() => {       
        if (!isInit) {
            fillTable();
        }
    }, [orderSheet]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate){
            fetchOrderSheet(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),)
        }
    },[selectedFromDate, selectedToDate])

    useEffect(() => {
        if(statusType && !isInit){
            $('#tblOrderSheet').KTDatatable().destroy(); 
            fillTable();
        }
    },[statusType])

    const fetchOrderSheet = async(fdate, tDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getOnAccountPaymentByDate(fdate, tDate);
            isInit ? setIsInit(false) : $('#tblOrderSheet').KTDatatable().destroy(); 
            setOrderSheet(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }


    function exportSheet(type) {
        orderTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - On Account Sheet - ${statusType}` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    }


    function fillTable() {
        var filteredList = [];

        if (statusType == 'All') {
            filteredList = orderSheet.OnAccountPaymentList;
        }else{
            filteredList = orderSheet.OnAccountPaymentList.filter(i => i.OrderStatus == statusType);

        }

        Swal.close();

        Config.Core.InitPopover();

        const dataTable = $('#tblOrderSheet').KTDatatable({
            
            data: {
                saveState: false,
                source: filteredList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'FirstName',
                    title: 'Member',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.MembershipNo} </span>
                                    </div>
                                </div>`
                    }
                }, 
                {
                    field: ' ',
                    title: 'Contact',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Mobile} </span> 
                                        <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    },
                    width: 180,

                },
                {
                    field: 'MembershipType',
                    title: 'Membership Type',
                    width: 130,
                },
                {
                    field: 'BillNumber',
                    title: 'Bill Number',
                    width: 85,
                },
                {
                    field: 'Amount',
                    title: 'Bill Amount',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.Amount}</strong>`
                    }
                },
                {
                    field: 'TransactionDate',
                    title: "Transaction Date",
                    width: 120,
                    template: function (row) {
                        return moment(row.TransactionDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'OrderStatus',
                    title: 'Status',
                    width: 80,
                    template: function (row) {
                        if (row.OrderStatus == 'Success') {
                            return `<div><span class="btn btn-bold btn-font-sm  btn-label-success" style="padding-left: 9px"> Success</span></div>`;
                        } else {
                            return `<div><span class="btn btn-bold btn-font-sm  btn-label-danger">Fail</span></div>`;
                        }
                    }
                },
                {
                    field: '',
                    title: ' ',
                    width: 60,
                    template: function (row) {
                        if (row.OrderStatus == 'Success') {
                            return `<center>
                                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-invoice" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="View Receipt"><i class="fa fa-file-alt"></i></button>${row?.Comment && `&nbsp;<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover" data-trigger="hover click" data-placement="left" data-content="${row?.Comment}"><i class="fa fa-info"></i></button>`}
                                    </center>`
                        } else {
                            return '';
                        }
                    }
                },
            ],
            rows: {
                autoHide: false,
            }
            
        });
        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        dataTable.on('click', '.btn-invoice', function (ss) {  
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                window.open(`https://gbgc.golfez.in/invoice?id=${dataTable.dataSet[index].PaymentOrderID}`, '_blank', 'noreferrer');
            } 
        });


        // export to excel
        const orderTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - On Account Sheet`,
        });
        setOrderTable(orderTable);
        $('.tableexport-caption').addClass('hidden');
    }


    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">From Date</label>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">To Date</label>
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="col-sm-1">
                                    </div>
                                    <div  className="form-group col-sm-2 pnlStatus ">
                                        <div >
                                            <Select 
                                                id="ddlStatus" 
                                                name="ddlStatus "
                                                className="multi-custom "
                                                defaultValue={ status.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => setStatusType(event.value)}
                                                options={status}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Member..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row monthly-payemnt-amount-box" style={{color: '#535354', fontSize: '14px'}}>
                                    <label className="col-12"><span style={{fontWeight: '400'}}>Total Amount</span><strong style={{fontWeight: '600'}}> :  ₹ {orderSheet.Total}</strong>  </label>
                                </div>
                            </div>
                            <div id="tblOrderSheet" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnAccountPayment;