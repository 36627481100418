import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getTournamentCalenderAll, postTournamentCalender } from "../api/golfClubAPI";


const TournamentCalender = (props) =>{

    const [tournamentCalender,         setTournamentCalender]         = useState({});
    const [tournamentCalenderList,     setTournamentCalenderList]     = useState([]);
    const [isInit,                     setIsInit]                     = useState(true);
    const [rightSideDetails,           setRightSideDetails]           = useState(false);

    
    useEffect(() => {
        props.title("Tournament Calender", "tournament-calender")    
        fetchTournamentCalender();
    }, []);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
            $('#txtStartDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtStartDate').on('change', function (event) {
                updateTournamentCalenderDetails('StartDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtEndDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtEndDate').on('change', function (event) {
                updateTournamentCalenderDetails('EndDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
 
        }else{
            setTournamentCalender({});
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [tournamentCalenderList]);

    const fetchTournamentCalender = async() =>{
        try {
            const calender = await getTournamentCalenderAll()   
            isInit ? setIsInit(false) : $('#tblTournamentCalender').KTDatatable().destroy(); 
            setTournamentCalenderList(calender);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblTournamentCalender').KTDatatable({
            data: {
                saveState: false,
                source: tournamentCalenderList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'ID',
                    title: 'ID',
                    width: 20,
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Name',
                    title: 'Name',

                },
                {
                    field: 'StartDate',
                    title: 'Start Date',
                    template: function (row) {
                        return moment(row.StartDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'EndDate',
                    title: 'End Date',
                    template: function (row) {
                        return moment(row.EndDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'Index',
                    title: 'Index',
                    type: 'number',

                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTournamentCalender .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTournamentCalender(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });

    }

    
    const updateTournamentCalenderDetails = (field, value) => {   
        setTournamentCalender((d) => {
            return { ...d, [field]: value };
        });
    };

    const addTournamentCalenderHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-tournament-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtStartDate: {
                    required: true,
                },         
                txtEndDate: {
                    required: true
                },
                txtIndex: {
                    required: true,
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtStartDate: {
                    required: "Please select start date"
                },
                txtEndDate: {
                    required: "Please select end date"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        if((moment(tournamentCalender.StartDate).isAfter(tournamentCalender.EndDate)) || tournamentCalenderList.find((d) => d.ID !== tournamentCalender.ID && (moment(tournamentCalender.StartDate) < moment(d.StartDate) && moment(tournamentCalender.EndDate) > moment(d.EndDate)  || moment(tournamentCalender.StartDate).isBetween(d.StartDate, d.EndDate) || moment(tournamentCalender.EndDate).isBetween(d.StartDate, d.EndDate) || moment(tournamentCalender.StartDate).isSame(d.StartDate) || moment(d.StartDate).isSame(tournamentCalender.EndDate) || moment(tournamentCalender.EndDate).isSame(d.EndDate) || moment(d.EndDate ).isSame(tournamentCalender.StartDate)))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        }

        helper.StartProcessing(btn);

        try {
            await postTournamentCalender(tournamentCalender);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchTournamentCalender();
            if (tournamentCalender.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Tournament calender updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Tournament calender added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Calender..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Calender</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTournamentCalender" className="kt-datatable table-striped clickable"></div>
                            <RightSideDetails title="Calender Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-tournament-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={tournamentCalender.Name || ''}
                                                        onChange={(event) => updateTournamentCalenderDetails('Name', event.target.value)}  
                                                    />
                                                    </div>
                                            </div>    
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Start Date <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="input-group date">
                                                        <input 
                                                            id="txtStartDate" 
                                                            name="txtStartDate" 
                                                            type="text" 
                                                            className="form-control date-input" 
                                                            readOnly={true} 
                                                            placeholder="Select date" 
                                                            value={tournamentCalender.StartDate ? moment(tournamentCalender.StartDate).format('DD-MM-YYYY') : ''}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>   
                                                </div>
                                                <div className="form-group row">
                                                <label className="col-form-label col-lg-3">End Date <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="input-group date">
                                                        <input 
                                                            id="txtEndDate" 
                                                            name="txtEndDate" 
                                                            type="text" 
                                                            className="form-control date-input" 
                                                            readOnly={true} 
                                                            placeholder="Select date"
                                                            value={tournamentCalender.EndDate ? moment(tournamentCalender.EndDate).format('DD-MM-YYYY') : ''}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                </div>     
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                    <input 
                                                        id="txtIndex" 
                                                        name="txtIndex" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={tournamentCalender.Index || ''}
                                                        onChange={(event) => updateTournamentCalenderDetails('Index', event.target.value)}  
                                                    />
                                                    </div>
                                                </div>                                       
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                        <div className="col-lg-9 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTournamentCalenderHandler}>Save</button>
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>
                        </div>
                    </div>
                </div>           
            </div>
        </>
    )
}

export default TournamentCalender;