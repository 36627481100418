import { useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { useEffect } from "react";
import { getSportMasterAll } from "../api/sportsAPI";
import swal from 'sweetalert2';
import { deleteRateCalenderByID, getRateCalenderBySportID, getRateMasterBySportID, postRateCalender } from "../api/paymentAPI";
import * as helper from '../global/helper';
import RateTable from "./rateTable";
import GolfRateTable from "./golfRateTable";
import RangeRateTable from "./rangeRateTable";

const RateMaster  = (props) => {
    const [rightSideDetails,        setRightSideDetails]    = useState(false);
    const [sportsList,              setSportList]           = useState([]);
    const [isInit,                  setIsInit]              = useState(true);
    const [sports,                  setSports]              = useState({});
    const [rateCalenderList,        setRateCalenderList]    = useState([]);
    const [rateCalender,            setRateCalender]        = useState({});
    const [isEdit,                  setIsEdit]              = useState(false);
    const [expandedMenu,            setExpandedMenu]        = useState(null);
    const [serverDate,              setServerDate]          = useState(null);
    const [rateMasterList,          setRateMasterList]      = useState([]);

    useEffect(() => {
        props.title("Rate Master", "rate-master")
        fetchSportsMaster();
    },[])

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [sportsList]);
    
    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            $('#txtFromDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtFromDate').on('change', function (event) {
                updateRateCalender('FromDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtToDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtToDate').on('change', function (event) {
                updateRateCalender('ToDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
        }else{
            setRateCalenderList([])
            setRateCalender({})
            setExpandedMenu(null)
            setIsEdit(false)
        }
    }, [rightSideDetails]);

    const updateRateCalender = (field, value) => {   
        setRateCalender((d) => {
            return { ...d, [field]: value };
        });
    };
    const fetchSportsMaster = async() => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const sportMasters =  await getSportMasterAll();
            isInit ? setIsInit(false) : $('#tblSports').KTDatatable().destroy(); 
            setSportList(sportMasters);
            swal.close();
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };

    const fetchRateCalenderBySportID = async(sportID) => {
        try {
            const rateCalender =  await getRateCalenderBySportID(sportID);
            setRateCalenderList(rateCalender.Master);
            setServerDate(rateCalender.ServerDate);
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };

    const fetchRateMasterBySportID = async(sportID) => {
        try {
            const rateMaster =  await getRateMasterBySportID(sportID);
            setRateMasterList(rateMaster);
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };

    const editHandler = (id) => {
        setExpandedMenu(null);
        setIsEdit(true);
        const d = rateCalenderList.find(a => a.ID == id);
        setRateCalender(d)
    };

    const deleteCalender = async(id, sportID) => {   
        swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
        }).then(async(result) => {
            if (result.value) {
                try {
                    await deleteRateCalenderByID(id, sportID)  
                    swal.fire({
                        icon: 'success',
                        titleText: 'Calender Deleted Successfully!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setRateCalender({});
                    setIsEdit(false);
                    fetchRateCalenderBySportID(sports.ID)
                    fetchRateMasterBySportID(sports.ID)
                } catch (err) {
                    swal.fire({
                        icon                  : 'error',
                        titleText             : 'Error!',
                        text                  : err.message,
                        buttonsStyling        : false,
                        confirmButtonClass    : 'btn btn-brand',
                    });
                    return;
                }
            }
        })
    };

    const handleToggle = (menuId) => {
        setExpandedMenu(expandedMenu === menuId ? null : menuId);
    };

    const postRateCalenderData = async() => {
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addCalenderForm');

        form.validate({
            rules: { txtFromDate: { required: true }, txtToDate  : { required: true }, txtSAC  : { required: true } },
            messages: {
                txtFromDate: { required: "Please select from Date"},
                txtToDate  : { required: "Please select to Date"}, 
                txtSAC  : { required: "Please enter sac"}, 
            }
        });

        if (!form.valid()) return;
        if((moment(rateCalender.FromDate).isAfter(rateCalender.ToDate)) || rateCalenderList.find((d) => d.ID !== rateCalender.ID && (moment(rateCalender.FromDate) < moment(d.FromDate) && moment(rateCalender.ToDate) > moment(d.ToDate)  || moment(rateCalender.FromDate).isBetween(d.FromDate, d.ToDate) || moment(rateCalender.ToDate).isBetween(d.FromDate, d.ToDate) || moment(rateCalender.FromDate).isSame(d.FromDate) || moment(d.FromDate).isSame(rateCalender.ToDate) || moment(rateCalender.ToDate).isSame(d.ToDate) || moment(d.ToDate ).isSame(rateCalender.FromDate)))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        }
        helper.StartProcessing(btn);
        try {
            await postRateCalender({...rateCalender, SportID: sports.ID});
            setExpandedMenu(null)
            fetchRateCalenderBySportID(sports.ID);
            fetchRateMasterBySportID(sports.ID);
            
            helper.StopProcessing(btn);                  
            if (rateCalender.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Calender updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Calender added successfully!',
                    showConfirmButton: false,
                    timer: 2000,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
                        
            //Clear State ->
            clearAction();

          } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    function fillDataTable() {

        const dataTable = $('#tblSports').KTDatatable({
            data: {
                saveState: false,
                source: sportsList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Sport',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.BookingType} </span>
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSports .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSports(dataTable.dataSet[index]);  
                fetchRateCalenderBySportID(dataTable.dataSet[index].ID);
                fetchRateMasterBySportID(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    const clearAction = () => {       
        setRateCalender({});
        setIsEdit(false);
    }; 

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Sport..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSports" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Rate Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        {sports.Logo && sports.Logo != '' ? 
                                                            <img className="kt-widget__pic kt-widget__pic" src={sports.Logo} alt="user-profile"/> :
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-`}>
                                                                {sports.Name.slice(0, 1)}
                                                            </div>
                                                        }   
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {sports.Name}{' '}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={`kt-badge kt-badge--${sports.ActiveAdmin == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                        {sports.ActiveAdmin == 'Y' ? 'Active' : 'Inactive'}
                                                                    </span> 
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="fa fa-clock"></i>
                                                                    {sports.BookingType} 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                        <form className="kt-form margin-b10 padding-t15 padding-b10 bg-white" id="addCalenderForm" >                
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form " role="form">
                                                <div className="row mb-0">
                                                    <label className="col-form-label col-sm-2 text-right"> From Date </label> 
                                                    <div className="col-sm-2">
                                                        <div className="input-group date">
                                                            <input id="txtFromDate" name="txtFromDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select date"
                                                                disabled={rateCalender?.ID && rateCalender?.FromDate && !moment(serverDate).isBefore(moment(rateCalender?.FromDate))}
                                                                value={rateCalender.FromDate ? moment(rateCalender.FromDate).format('DD-MM-YYYY') : ''}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label className="col-form-label col-sm-1 text-right"> To Date </label> 
                                                    <div className="col-sm-2">
                                                        <div className="input-group date">
                                                            <input id="txtToDate" name="txtToDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select date"
                                                                value={rateCalender.ToDate ? moment(rateCalender.ToDate).format('DD-MM-YYYY') : ''}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label className="col-form-label col-sm-1 text-right"> SAC </label> 
                                                        <div className="col-sm-2">
                                                            <input 
                                                                id="txtSAC" 
                                                                name="txtSAC" 
                                                                type="text" 
                                                                placeholder="SAC"
                                                                className="form-control" 
                                                                value={rateCalender.SAC || ''}
                                                                onChange={(event) => updateRateCalender('SAC', event.target.value)}
                                                            />
                                                      </div>
                                                    <div className="col-sm-2">
                                                        {(!isEdit) ? (
                                                            <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0`} onClick={postRateCalenderData} ><i className="la la-plus"></i> Add </button>
                                                        ):(
                                                            <div className="btn-group">
                                                                <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postRateCalenderData} > Update </button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                            {(rateCalenderList && rateCalenderList.length > 0) && (
                                                rateCalenderList.map((m, i) => (
                                                    <div className="card" key={i} style={{pointerEvents: isEdit ? 'none' : '' }}>
                                                         <div className="card-header" id={`menuHeading-${m.ID}`}>
                                                            <div className="card-title collapsed" data-toggle="collapse" onClick={() => handleToggle(m.ID)}>
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="28" height="28" />
                                                                            <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" fill="#000000" opacity="0.3" />
                                                                            <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <div className="container-calender" style={{ color: expandedMenu === m.ID || rateCalender.ID === m.ID ? '#5d78ff' : '' }}>
                                                                    <span className="date-text" style={{opacity: isEdit && rateCalender.ID != m.ID ? 0.8 : '' }}>
                                                                        {moment(moment(m.FromDate).format('YYYY-MM-DD')).isSame(moment(m.ToDate).format('YYYY-MM-DD'))
                                                                            ? moment(m.FromDate).format('DD MMM YYYY')
                                                                            : moment(m.FromDate).format('DD MMM YYYY') + ' - ' + moment(m.ToDate).format('DD MMM YYYY')}
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;{m.IsActive && <span className={`kt-badge kt-badge--success kt-badge--inline kt-badge--pill padding-lr10`}>{m.IsActive && 'Active'}</span>}
                                                                    </span>
                                                                    <div className="button-container">
                                                                        <span className="date-text padding-r100">
                                                                            {m.SAC}
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"
                                                                            disabled={isEdit}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                editHandler(m.ID);
                                                                            }}
                                                                        >
                                                                            <i className="flaticon2-edit"></i>
                                                                        </button>
                                                                        {moment(serverDate).isBefore(moment(m.FromDate)) ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15"
                                                                                disabled={isEdit}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    deleteCalender(m.ID, m.SportID);
                                                                                }}
                                                                            >
                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                            </button>
                                                                        ) : (
                                                                            <a className="btn btn-elevate btn-circle btn-icon btn-xs margin-r15"></a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {sports.ID == 1 ? (
                                                            <GolfRateTable
                                                                expandedMenu={expandedMenu}
                                                                ID={m.ID}
                                                                sportID={sports.ID}
                                                                rateMaster={rateMasterList.length > 0 && rateMasterList.filter(f => f.CalenderID == m.ID)}
                                                                fetchRateMasterBySportID={() => fetchRateMasterBySportID(sports.ID)}
                                                            />
                                                            
                                                        ) : ( sports.ID == 2 ? (
                                                            
                                                            <RangeRateTable
                                                                expandedMenu={expandedMenu}
                                                                ID={m.ID}
                                                                sportID={sports.ID}
                                                                rateMaster={rateMasterList.length > 0 && rateMasterList.filter(f => f.CalenderID == m.ID)}
                                                                fetchRateMasterBySportID={() => fetchRateMasterBySportID(sports.ID)}
                                                            />
                                                        ):(
                                                            <RateTable
                                                                expandedMenu={expandedMenu}
                                                                ID={m.ID}
                                                                sports={sports}
                                                                rateMaster={rateMasterList.length > 0 && rateMasterList.filter(f => f.CalenderID == m.ID)}
                                                                fetchRateMasterBySportID={() => fetchRateMasterBySportID(sports.ID)}
                                                            />

                                                        ))}
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>   
            </div>
        </>
    )
};

export default RateMaster;