import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const QuickAction = (props) => {

    const history = useHistory();
    const [tournamentID,   setTournamentID]   = useState();

    useEffect(() => {
        const path = history.location.pathname
        const splitedPath = path.split('/')
        const tID = splitedPath?.find(p => !isNaN(p) && p !== '');
        setTournamentID(tID)    
    }, [history.location.pathname])

    const redirectHandler = (action) => {
        if (history.location.pathname.includes('master')) {
            window.removeCkeditor("txtDescription");
        }

        if (tournamentID) {
            switch (action) {
                case "master" :
                    history.push(`/tournament-master/${tournamentID}`);
                    break
                case "register" :
                    history.push(`/tournament-register/${tournamentID}`);
                    break
                case "draw" :
                    history.push(`/tournament-draw/${tournamentID}`);
                    break
                case "leaderboard" :
                    history.push(`/tournament-leaderboard/${tournamentID}`);
                    break
                case "winner" :
                    history.push(`/tournament-winner/${tournamentID}`);
                    break
                case "live" :
                    history.push(`/tournament-live`);
                    break
            }
        } else if (!props.selectedMaster) {
            switch (action) {
                case "master" :
                    history.push(`/tournament-master`);
                    break
                case "register" :
                    history.push(`/tournament-register`);
                    break
                case "draw" :
                    history.push(`/tournament-draw`);
                    break
                case "leaderboard" :
                    history.push(`/tournament-leaderboard`);
                    break
                case "winner" :
                    history.push(`/tournament-winner`);
                    break
                case "live" :
                    history.push(`/tournament-live`);
                    break
            }
        } else
        if (props.selectedMaster == 0) {
            switch (action) {
                case "master" :
                    history.push(`/tournament-master`);
                    break
                case "register" :
                    history.push(`/tournament-register`);
                    break
                case "draw" :
                    history.push(`/tournament-draw`);
                    break
                case "leaderboard" :
                    history.push(`/tournament-leaderboard`);
                    break
                case "winner" :
                    history.push(`/tournament-winner`);
                    break
                case "live" :
                    history.push(`/tournament-live`);
                    break
            }
        } else {
            switch (action) {
                case "master" :
                    history.push(`/tournament-master/${props.selectedMaster.ID}`);
                    break
                case "register" :
                    history.push(`/tournament-register/${props.selectedMaster.ID}`);
                    break
                case "draw" :
                    history.push(`/tournament-draw/${props.selectedMaster.ID}`);
                    break
                case "leaderboard" :
                    history.push(`/tournament-leaderboard/${props.selectedMaster.ID}`);
                    break
                case "winner" :
                    history.push(`/tournament-winner/${props.selectedMaster.ID}`);
                    break
                case "live" :
                    history.push(`/tournament-live`);
                    break
            }
        }

    }
    return (
        <div className="kt-header__topbar-item kt-header__topbar-item--user margin-r10" data-toggle="kt-tooltip" data-original-title="Quick Actions">
            <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
                <div className="kt-header__topbar-user">
                    <div className="kt-notification__item-icon">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="#000000" />
                                <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="#000000" opacity="0.3" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround nav-url">
                <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
                    <li className="kt-nav__item linkMaster" >
                        <a onClick={() => redirectHandler('master')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-golf-ball"></i></span>
                            <span className="kt-nav__link-text">Master</span>
                        </a>
                    </li>
                    <li className="kt-nav__item kt-nav__item linkRegister">
                        <a onClick={() => redirectHandler('register')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-edit"></i></span>
                            <span className="kt-nav__link-text">Register</span>
                        </a>
                    </li>
                    <li className="kt-nav__item linkDraw">
                        <a onClick={() => redirectHandler('draw')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-user-friends"></i></span>
                            <span className="kt-nav__link-text">Draw</span>
                        </a>
                    </li>
                    <li className="kt-nav__item  linkLeaderBoard" >
                        <a onClick={() => redirectHandler('leaderboard')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-chalkboard"></i></span>
                            <span className="kt-nav__link-text">Leader Board</span>
                        </a>
                    </li>
                    <li className="kt-nav__item linkWinner" >
                        <a onClick={() => redirectHandler('winner')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-trophy"></i></span>
                            <span className="kt-nav__link-text">Winner</span>
                        </a>
                    </li>
                    <li className="kt-nav__item linkLive" >
                        <a onClick={() => redirectHandler('live')} className="kt-nav__link">
                            <span className="kt-nav__link-icon">
                                <i className="fa fa-circle"></i></span>
                            <span className="kt-nav__link-text">Live</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default QuickAction