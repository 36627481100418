import React, {useEffect, useState, useRef} from 'react';
import swal from 'sweetalert2';

import { getStrokeExtraShot, getPlayerScore, getPercentage } from '../tournamentHelper';
import { deleteScorecard, postScoreCard, sendFourballScorecardMail } from '../../api/tournamentAPI';

const CumulativeFourBall =({
    playerList,
    tournamentDetails,
    tournamentDraw,
    tournamentRound,
    selectedPlayer,
    gameDetails, 
    format,
    scorecardStatus,
    setScorecardStatus,
    pointData,
    setShowPointModal,
    closeDrawer,
    fetchTournamentLeaderboard
})=>{

    const [scores,                  setScores]                  = useState(null);
    const [totalScores,             setTotalScores]             = useState([]);
    const [footerTotalScore,        setFooterTotalScore]        = useState('-');
    const [comment,                 setComment]                 = useState("");
    const [calculatedHandicap1,     setCalculatedHandicap1]     = useState();
    const [calculatedHandicap2,     setCalculatedHandicap2]     = useState();

    const inputRefs = useRef([])

    //#region Effects
        useEffect(() => {        
            const initialScores = {};
            if (!!tournamentDraw && !!selectedPlayer) {
                const Handicap1 = Math.round(getPercentage(selectedPlayer.Handicap, selectedPlayer.Player1Gender == 'Female' ? tournamentDraw.Lower : tournamentDraw.Higher))
                setCalculatedHandicap1(Handicap1)        
                const Handicap2 = Math.round(getPercentage(selectedPlayer.Player2Handicap, selectedPlayer.Player2Gender == 'Female' ? tournamentDraw.Lower : tournamentDraw.Higher))
                setCalculatedHandicap2(Handicap2)        
            }
            if(gameDetails.Card.length > 0){
                gameDetails.Card.forEach((item) => {
                    const newIndex = (item.HoleValue - 1);
                    const existingScore = initialScores[newIndex];
                    const scoreKey = item.PlayerID === selectedPlayer.PlayerID ? 'player1Score' : 'player2Score';
                    const newScore = { [scoreKey]: item.Scratch == "Y"? "X": item.Score };

                    initialScores[newIndex] = (!!existingScore)? {...existingScore, ...newScore } : newScore;                                       
                });     
                for (let i = 0; i < 18; i++) {
                    if (!initialScores[i]) {
                        initialScores[i] = { player1Score: 0, player2Score: 0 };
                    } else {
                        // If a hole is missing player1Score or player2Score, add default values
                        if (!initialScores[i].hasOwnProperty('player1Score')) {
                            initialScores[i].player1Score = 0;
                        }
                        if (!initialScores[i].hasOwnProperty('player2Score')) {
                            initialScores[i].player2Score = 0;
                        }
                    }
                } 
                            
            }else{
                gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {                    
                    initialScores[index] = {player1Score: 0, player2Score: 0};                    
                });
            }          

            if(!!gameDetails?.Result){           
                let scStatus = (gameDetails?.Result.IsVerified == 'Y'? 'verify':(gameDetails?.Result.IsVerified == 'DQ' ? 'disqualify': (gameDetails?.Result.IsVerified == 'WD' ? 'withdraw' : 'N')))                
                setScorecardStatus(scStatus);
                setComment(gameDetails?.Result.Comment);
            }

            setScores(initialScores);
            // calculateScores(initialScores);
        }, [gameDetails, tournamentDraw]);

        useEffect(() => {
            if (scores) calculateScores(scores)
        }, [scores, calculatedHandicap1, calculatedHandicap2])

        useEffect(() => {
            if (selectedPlayer) {
                setScorecardStatus(selectedPlayer.IsVerified == 'Y' ? "verify" : selectedPlayer.IsVerified == "WD" ? 'withdraw' : selectedPlayer.IsVerified == 'DQ' ? 'disqualify' : '')
            }
        }, [selectedPlayer])   
    //#endregion

    //#region API's   
        const deleteScoreCard = async() => {
            try {
                swal.fire({
                    titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false,
                    onOpen: () => { swal.showLoading() }
                });

                await deleteScorecard(selectedPlayer.DrawID);
                fetchTournamentLeaderboard();                
                swal.fire({
                    icon: 'success', timer: 1500, animation: false, showConfirmButton: false,
                    onOpen:() => swal.hideLoading(),
                    titleText: 'Scorecard Updated Successfully!',
                });
                closeDrawer();
            } catch(err){
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                    onOpen:() => swal.hideLoading()
                });
            }
        }

        const postScorecard = async(scoreCard) => {
            try {
                swal.fire({
                    titleText: 'Loading', showConfirmButton: false,
                    text: 'Please Wait...', onOpen: () => { swal.showLoading() }
                });

                await postScoreCard(scoreCard);
                fetchTournamentLeaderboard();             
                swal.fire({
                    icon: 'success', timer: 1500, animation: false, showConfirmButton: false,
                    onOpen:() => swal.hideLoading(),
                    titleText: 'Scorecard Updated Successfully!',
                });
                closeDrawer();
            } catch(err){               
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                    onOpen:() => swal.hideLoading()
                });
            }
        }
    //#endregion

    //#region Helpers   
    const calculateScores = (updatedScores) => {
        const updatedTotalScores = [];
        let cumulativeTotalScore = 0;      
        let allScoresZero = true;           

        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            let player1Point, player2Point;
            let totalScore = 0;        
                            
            const maxScore = pointData[0]?.Score || 0;
            const minScore = pointData[pointData.length - 1]?.Score || 0;

            const player1Input = updatedScores[index].player1Score || 0;
            const player2Input = updatedScores[index].player2Score || 0;

            const player1Score = getPlayerScore(player1Input, calculatedHandicap1, item.Stroke, item.Par);
            const player2Score = getPlayerScore(player2Input, calculatedHandicap2, item.Stroke, item.Par);

            let player1GrossScore = player1Score.grossScore;
            let player2GrossScore = player2Score.grossScore;
            let player1NetScore   = player1Score.netScore;
            let player2NetScore   = player2Score.netScore;
            
            if (player1GrossScore != 0) {
                let player1AdjScore = (!!player1Score.IsScratch) ? player1NetScore : (player1NetScore - item.Par);

                player1AdjScore = (player1AdjScore > maxScore) ? maxScore : player1AdjScore;
                player1AdjScore = (player1AdjScore < minScore) ? minScore : player1AdjScore;

                player1Point = $.grep(pointData, function (s) { return s.Score === player1AdjScore })[0].Point;                
            } else {
                player1Point = 0;                
            }

            if (player2GrossScore != 0) {
                let player2AdjScore = (!!player2Score.IsScratch) ? player2NetScore : (player2NetScore - item.Par);

                player2AdjScore = (player2AdjScore > maxScore) ? maxScore : player2AdjScore;
                player2AdjScore = (player2AdjScore < minScore) ? minScore : player2AdjScore;

                player2Point = $.grep(pointData, function (s) { return s.Score === player2AdjScore })[0].Point;                
            } else {
                player2Point = 0;                
            }

            totalScore = player1Point + player2Point;        

            if (totalScore != 0) {
                allScoresZero = false;
                cumulativeTotalScore += totalScore;
            }

            updatedTotalScores[index] = {
                HoleNo: item.HoleNoValue,
                player1GrossScore, player2GrossScore, 
                player1NetScore, player2NetScore, 
                player1Point, player2Point, 
                maxTotal: totalScore, 
                player1Scratch : player1Score.IsScratch,
                player2Scratch : player2Score.IsScratch,
            };            
        });      

        setTotalScores(updatedTotalScores);      
        setFooterTotalScore(allScoresZero ? '-' : (cumulativeTotalScore == 0 ? 'E' : (cumulativeTotalScore < 0 ? cumulativeTotalScore : "+" + cumulativeTotalScore)));
    };

    const handleScoreChange = (playerKey, index, value) => {
        if (!(/^[0-9X]*$/).test(value))  return;  // If the value matches the regex, update the state
        
        const oldScore      = scores[index];        
        const updatedScores = { ...scores, [index]: {...oldScore, [playerKey]:value } };
        setScores(updatedScores);
        // calculateScores(updatedScores);

        // Move focus to the next input field if it exists
        const nextKey = playerKey === 'player1Score' ? `${index}_player2Score` : `${index + 1}_player1Score`;
        if (inputRefs.current[nextKey]) {
            inputRefs.current[nextKey].focus();
            inputRefs.current[nextKey].select(); // Auto-select the next input's text
        }
    };

    const toggleStatus = (value) => setScorecardStatus(prevStatus => (prevStatus == value ? '' : value));

    const saveScorecard = () => {
        let newScoreCard = [];
        let notComplete  = false;
        let hole = 0;

        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            const cHole = item.HoleNoValue;
            let cScore  = totalScores.find(t => t.HoleNo == cHole);

            if (((isNaN(cScore.player1GrossScore) || cScore.player1GrossScore == 0)|| (isNaN(cScore.player2GrossScore) || cScore.player2GrossScore == 0)) && scorecardStatus == 'verify') {
                notComplete = true;
                return;
            }else{
                //Player1 Scores
                newScoreCard.push({
                    ID                  : 0,
                    TournamentDrawID    : selectedPlayer.DrawID,
                    PlayerID            : selectedPlayer.PlayerID,
                    PlayerType          : selectedPlayer.Player1Type,
                    ScoreCardMasterID   : item.ID,
                    Score               : cScore.player1GrossScore,
                    HandicapScore       : cScore.player1NetScore,
                    DriveUserID         : 0,
                    Point               : cScore.player1Point,
                    TotalPoint          : cScore.player1Point,
                    Scratch             : (cScore.player1Scratch) ? "Y" : "N",
                });
                            
                //Player2 Scores
                newScoreCard.push({
                    ID                  : 0,
                    TournamentDrawID    : selectedPlayer.DrawID,
                    PlayerID            : selectedPlayer.Player2ID,
                    PlayerType          : selectedPlayer.Player2Type,
                    ScoreCardMasterID   : item.ID,
                    Score               : cScore.player2GrossScore,
                    HandicapScore       : cScore.player2NetScore,
                    DriveUserID         : 0,
                    Point               : cScore.player2Point,
                    TotalPoint          : cScore.player2Point,
                    Scratch             : (cScore.player2Scratch) ? "Y" : "N",
                });

                // if(cScore.maxTotal != 0) hole++;
                if(cScore.player1GrossScore != 0 || cScore.player2GrossScore != 0) hole++;
            }
        });

        if(notComplete) {
            swal.fire({ icon: "warning", 
                titleText: "Incomplete Scorecard", text: "Please complete the scorecard..!" 
            });
            return;
        }    

        const result = {
            ID: 0, Hole: hole, TournamentDrawID: selectedPlayer.DrawID,
            Point      : (footerTotalScore == 'E') ? 0 : footerTotalScore,
            TotalPoint : (footerTotalScore == 'E') ? 0 : footerTotalScore,
            IsVerified : (scorecardStatus == 'verify') ? 'Y' : (scorecardStatus == 'disqualify' ? 'DQ' : (scorecardStatus == 'withdraw' ? 'WD' : 'N')),
            Comment    : (scorecardStatus == 'disqualify' || scorecardStatus == 'withdraw') ? comment : ''
        };

        const dto = { scoreCard: newScoreCard, result: result };

        postScorecard(dto)
    }

    const sendScorecard = async () => {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});
            const player1Conditional = playerList["Player1"].gender == 'Female' ? ((tournamentDetails.Lower != 100) ? "*" : "") : (((tournamentDetails.Higher != 100) ? "*" : ""));
            const player2Conditional = playerList["Player2"].gender == 'Female' ? ((tournamentDetails.Lower != 100) ? "*" : "") : (((tournamentDetails.Higher != 100) ? "*" : ""));

            let playerNameList = [];

            playerNameList.push({ ID: 1, Name: playerList["Player1"].UserName + ' - ' + calculatedHandicap1 + '' + player1Conditional, Email: playerList["Player1"].Email });
            playerNameList.push({ ID: 2, Name: playerList["Player2"].UserName + ' - ' + calculatedHandicap2 + '' + player2Conditional, Email: playerList["Player2"].Email });

            let scoreCardList = [];

            gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
                const par = item.Par;
                const cHole = item.HoleNoValue
                const stroke = item.Stroke

                let cScore  = totalScores.find(t => t.HoleNo == cHole);

                scoreCardList.push({
                    Hole    : cHole,
                    Par     : par,
                    Stroke  : stroke,
                    Player1GrossScore   : cScore.player1GrossScore,
                    Player1NetScore     : cScore.player1NetScore,
                    Player1Point        : cScore.player1Point,
                    Player2GrossScore   : cScore.player2GrossScore,
                    Player2NetScore     : cScore.player2NetScore,
                    Player2Point        : cScore.player2Point,
                    Total               : cScore.maxTotal
                })   
            })

            const tournament = {
                ID      : tournamentDetails.ID,
                Name    : tournamentDetails.Name,
                Format  : format?.Format,
                Round   : selectedPlayer.Round,
                Date    : tournamentDetails.StartDate
            };

            await sendFourballScorecardMail(playerNameList, scoreCardList, gameDetails.TeeBoxes.Par, footerTotalScore, tournament)
            swal.close();
            swal.fire({
                icon: 'success',
                titleText: 'Scorecard Email Sent',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }
    //#endregion

    return(<>
        <div className="kt-portlet kt-portlet--mobile padding-lr20">
            <div className="kt-portlet__head kt-portlet__head--lg  d-block padding-lr0 border-0">

                <div id="roundInfoPanel" className="kt-notification margin-t10 mb-3" style={{backgroundColor: "rgb(247, 248, 250)"}}>
                    <div className="kt-notification__item d-block">
                        <div className="row">
                            <div className="col-3">
                                <div className="kt-user-card-v2 mb-2">
                                    <div className="kt-user-card-v2__pic">
                                        <img id="txtTournamentImg" src={tournamentDetails?.Icon} alt="tournament-logo" />
                                    </div>
                                    <div className="kt-user-card-v2__details ">
                                        <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tournamentDetails?.Name} </span>
                                        <span className="kt-user-card-v2__desc ">
                                            <span id="txtRound"> Round {selectedPlayer?.Round} </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex-center">
                                <div className="kt-user-card-v2 ">
                                    <div className="kt-user-card-v2__details ">
                                        <span className="kt-user-card-v2__name ">Date </span>
                                        <span className="kt-user-card-v2__desc">
                                            <span id="txtStartDate">{moment(tournamentDetails?.StartDate).format('DD-MMM-YYYY')}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex-center">
                                <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__details ">
                                        <span className="kt-user-card-v2__name ">Format </span>
                                        <span className="kt-user-card-v2__desc ">
                                            <span id="txtFormat">{format?.Format}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {!!tournamentRound && tournamentRound?.length > 0 && (
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2 mb-2">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name ">Tee Of Option </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtTeeOption">{format?.TeeOffOption}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-1 d-flex-center">
                                <div className="kt-user-card-v2 ">
                                    <div className="kt-user-card-v2__details ">
                                        <span className="kt-user-card-v2__name " id="lbltxtSlot"> Slot </span>
                                        <span className="kt-user-card-v2__desc ">
                                            <span id="txtSlot">{selectedPlayer.Slot}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 d-flex-center justify-content-end">
                                {!!pointData && pointData.length > 0 && (
                                    <button id="btnPoint" type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm m-0"
                                        onClick={()=> setShowPointModal(true)}
                                    > 
                                        <i className="la la-list"></i> Point 
                                    </button>
                                )}
                            </div>
                        </div>
                        <hr />

                        <div className="row">		
                            <div className="col-9">
                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success mb-0">
                                    <input 
                                        value="verify"
                                        name='verify'
                                        checked={scorecardStatus == 'verify'}
                                        onChange={() => toggleStatus('verify')}
                                        type="checkbox" 
                                        className="form-verify"/> 
                                        Verify Scorecard <span></span>
                                </label>
                            </div>
                            <div className="col-3 text-right">
                                <div className="kt-checkbox-inline">										
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger mb-0 mr-2">
                                        <input 
                                            value="disqualify"
                                            name='disqualify' 
                                            checked={scorecardStatus == 'disqualify'}
                                            onChange={() => toggleStatus('disqualify')}
                                            type="checkbox" 
                                            className="form-disqualify" />
                                            Disqualify <span></span>
                                    </label>
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--brand mb-0">
                                        <input 
                                            value="withdraw"
                                            checked={scorecardStatus == 'withdraw'}
                                            onChange={() => toggleStatus('withdraw')}
                                            name='withdraw' 
                                            type="checkbox" 
                                            className="form-withdraw" />
                                            Withdraw <span></span>
                                    </label>
                                </div>														
                            </div>  
                        </div>
                        {(scorecardStatus == 'withdraw' || scorecardStatus == 'disqualify') && (
                            <div className="row justify-content-end mt-2" id="pnlComment">                                
                                <div className="col-4 text-right">
                                    <textarea 
                                        name="txtComment" 
                                        id="txtComment" 
                                        className="form-control"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)} 
                                        cols="3" rows="2" placeholder="Comments" 
                                    ></textarea>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="table-register">
                    <table id="table-scorecard" className="table table-bordered table-striped table-card">
                        <thead>
                            <tr id="mainHeader">
                                <th rowSpan={2} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Hole   </th>
                                <th rowSpan={2} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}> 
                                    {(gameDetails && gameDetails?.TeeBoxes?.ScoreCard.length > 0) ? (`${gameDetails.TeeBoxes.ScoreCard[0].TeeBoxName} : `) : '' } Par    
                                </th>
                                <th rowSpan={2} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>
                                    {(gameDetails && gameDetails?.TeeBoxes?.ScoreCard.length > 0) ? (`${gameDetails.TeeBoxes.ScoreCard[0].TeeBoxName} : `) : '' } Stroke 
                                </th>
                                {/* {selectedPlayer && (
                                    <th colSpan={3} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                        <span className="mr-2">{selectedPlayer.Player1} ({selectedPlayer.Handicap})</span>
                                    </th>
                                )}
                                {selectedPlayer && (
                                    <th colSpan={3} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                        <span className="mr-2">{selectedPlayer.Player2} ({selectedPlayer.Player2Handicap})</span>
                                    </th>
                                )}                         */}
                                {selectedPlayer && (
                                    <th colSpan={3} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                        <span className="mr-2">{selectedPlayer.Player1} ({calculatedHandicap1 < 0 ? "+" + Math.abs(calculatedHandicap1) : calculatedHandicap1}) {((selectedPlayer?.Player1Gender == 'Female' && tournamentDraw?.Lower != 100) || (tournamentDraw?.Higher != 100)) ? '*' : ''}</span>
                                    </th>
                                )}
                                {selectedPlayer && (
                                    <th colSpan={3} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                        <span className="mr-2">{selectedPlayer.Player2} ({calculatedHandicap2 < 0 ? "+" + Math.abs(calculatedHandicap2) : calculatedHandicap2}) {((selectedPlayer?.Player2Gender == 'Female' && tournamentDraw?.Lower != 100) || (tournamentDraw?.Higher != 100)) ? '*' : ''}</span>
                                    </th>
                                )}  
                                <th rowSpan={2} style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}> Total Points</th>
                            </tr>
                            <tr>
                                <th className="text-center">Gross Score</th>
                                <th className="text-center">Net Score</th>
                                <th className="text-center">Points</th>
                                <th className="text-center">Gross Score</th>
                                <th className="text-center">Net Score</th>
                                <th className="text-center">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(gameDetails && scores) && gameDetails.TeeBoxes.ScoreCard.map((item, index) => {
                                const player1Score = scores[index]?.player1Score || 0;
                                const player2Score = scores[index]?.player2Score || 0;
                                const totalScore   = totalScores[index] || { score: '-', totalScore: '-' };

                                const player1ExtraShot = (getStrokeExtraShot(item.Stroke, calculatedHandicap1) || 0);
                                const player2ExtraShot = (getStrokeExtraShot(item.Stroke, calculatedHandicap2) || 0);

                                return(
                                    <tr key={`row_${item.HoleNoValue}`} className="row-score" data-masterid={item.ID}>
                                        <td className='text-center'> {item.HoleNoValue} </td>
                                        <td className='text-center'> {item.Par} </td>
                                        <td className='text-center'> {item.Stroke} </td>
                                        <td key={`cell_${item.HoleNoValue}_${selectedPlayer.PlayerID}`} className={`card-data-${index} player-td`} data-playerid={selectedPlayer.PlayerID} data-extra={player1ExtraShot} data-scoreid='0'>
                                            <div className="input-group">
                                                <input
                                                    id={`txtPlayer${index}Score`} className="form-control input-sm input-numeric"
                                                    type="text" value={player1Score}
                                                    ref={el => inputRefs.current[`${index}_player1Score`] = el}
                                                    onChange={(e) => handleScoreChange('player1Score', index, parseInt(e.target.value))}
                                                />
                                                <div className="input-group-append"> 
                                                    <span className="input-group-text clickable" onClick={()=> handleScoreChange('player1Score', index, "X")}>
                                                        <i className="la la-times"></i>
                                                    </span> 
                                                </div>
                                            </div>
                                        </td>
                                        <td id={`lblPlayer1Score$${index + 1}`} className="card-score text-center" data-score={totalScore.player1NetScore}>
                                            {totalScore.player1NetScore}
                                        </td>
                                        <td id={`lblPlayer1Point$${index + 1}`} className="card-point text-center" data-score={totalScore.player1Point}>
                                            {totalScore.player1Point}
                                        </td>
                                        <td key={`cell_${item.HoleNoValue}_${selectedPlayer.Player2ID}`} className={`card-data-${index} player-td`} data-playerid={selectedPlayer.Player2ID} data-extra={player2ExtraShot} data-scoreid='0'>
                                            <div className="input-group">
                                                <input
                                                    id={`txtPlayer${index}Score`} className="form-control input-sm input-numeric"
                                                    type="text" value={player2Score}
                                                    ref={el => inputRefs.current[`${index}_player2Score`] = el}
                                                    onChange={(e) => handleScoreChange('player2Score', index, parseInt(e.target.value))}
                                                />
                                                <div className="input-group-append"> 
                                                    <span className="input-group-text clickable" onClick={()=> handleScoreChange('player2Score', index, "X")}>
                                                        <i className="la la-times"></i>
                                                    </span> 
                                                </div>
                                            </div>
                                        </td>
                                        <td id={`lblPlayer2Score$${index + 1}`} className="card-score text-center" data-score={totalScore.player2NetScore}>
                                            {totalScore.player2NetScore}
                                        </td>
                                        <td id={`lblPlayer2Point$${index + 1}`} className="card-point text-center" data-score={totalScore.player2Point}>
                                            {totalScore.player2Point}
                                        </td>
                                        <td id={`lblTotalScore${index + 1}`} className="card-total-score text-center" data-score={totalScore.maxTotal}>
                                            {totalScore.maxTotal}
                                        </td>
                                    </tr>
                                )}
                            )}
                        </tbody>   
                        <tfoot>
                            <tr className="bg-light font-weight500">
                                <td className='text-center'>OUT</td>
                                <td className='text-center' id="lblTotalPar"> {gameDetails.TeeBoxes.Par} </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>
                                <td className='text-center'> </td>                   
                                <td className='text-center'> {footerTotalScore} </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                    <div className="row">
                        <div className="col-lg-12 ml-lg-auto text-center">
                            <button id="btnSave" type="button" className="btn btn-success d-inline-block mr-2" onClick={saveScorecard}> Save </button>
                            <button id="btnDelete" type="button" className="btn btn-danger d-inline-block" onClick={deleteScoreCard}> Delete </button>
                            <button id="btnBack" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Close </button>
                        </div>
                        {(selectedPlayer.IsVerified == 'Y' || selectedPlayer.IsVerified == 'WD' || selectedPlayer.IsVerified == 'DQ') && (
                            <button id="btnEmailCard" type="button" className="btn btn-message pull-right" onClick={sendScorecard}><i className="fa fa-mail-forward bigger-110"></i> Email Card </button>
                        )}
                    </div>
                </div>
            </div>       
        </div>       
    </>)
}

export default CumulativeFourBall;