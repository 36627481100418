import React, { useState, useEffect } from 'react'; 
import swal from 'sweetalert2';
import { getRangePayableBySubscriptionID, updateRangePayable } from '../api/paymentAPI';

const RangePayables = (props) => {
    const {subscriptionMasterID, sportID, expandedSport} = props;

    const [rangePayableList, setRangePayableList] = useState([]);

    useEffect(() => {
        if(subscriptionMasterID){
            fetchRangePayables(subscriptionMasterID);
        } 
    }, [subscriptionMasterID])
 
    const fetchRangePayables = async(subID) => {
        try {
            const payablesList = await getRangePayableBySubscriptionID(subID);
            setRangePayableList(payablesList);
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };
  
    const updateRangePayables = async(data) => {
        try {
            const rangePayableMat = rangePayableList.find(f => f.ID == data.ID);
            const rangePayableGrass = rangePayableList.find(f => f.UserType == rangePayableMat.UserType &&  f.RangeType == 'Mat' && f.Type === rangePayableMat.Type);
            const payablesList = await updateRangePayable([data, {...data, ID: rangePayableGrass.ID}]);
            setRangePayableList(payablesList);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `${data.Flag == 'Y' ? 'Allowed' : 'Disallow'}`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3500,  
                onOpen: () => swal.hideLoading()                                     
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };
    
    return(
        <>
            <div>
                <div id="accordionExample4">
                    <div id={`collapse-${sportID}`} className={`collapse ${expandedSport === sportID ? 'show' : ''}`} aria-labelledby={`menuHeading-${sportID}`} data-parent="#accordionExample4">
                        <div className="card-body">
                            <div className="kt-widget4">
                                <div className="kt-widget4__item padding-tb5">
                                    <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}>No</th>
                                                <th style={{ width: '15%' }}>Particular</th>
                                                <th style={{ width: '15%' }}>Type</th>
                                                <th style={{ width: '10%' }}>Days</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Green Fee</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Bucket 1</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Bucket 2</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {rangePayableList.length > 0  && rangePayableList.filter((f) => f.RangeType !== 'Grass').map((detail, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{detail.UserType}</td>
                                                <td>{detail.RangeType}</td>
                                                <td>{detail.Type}</td> 
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.GreenFee == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateRangePayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'GreenFee', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.Bucket1 == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateRangePayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'Bucket1', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.Bucket2 == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateRangePayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'Bucket2', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RangePayables;
