import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getCoachMasterAllUrl                                      = '/api/admin/coach/getCoachMasterAll';
const getSportTaggingByCoachIDUrl                               = (coachID) => `/api/admin/coach/getSportTaggingByCoachID/${coachID}`;
const postCoachUrl                                              = '/api/admin/coach/postCoach';
const getDaysUrl                                                = '/api/admin/coach/getDays';
const postCalendarUrl                                           = '/api/admin/coach/postCalendar';
const getCalendarByCoachIDUrl                                   = (caochID, dayID) => `/api/admin/coach/getCalendarByCoachID/${caochID}/${dayID}`;
const deleteCalendarUrl                                         = (id) => `/api/admin/coach/deleteCalendar/${id}`;

export const getCoachMasterAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachMasterAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getDays = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getDaysUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSportTaggingByCoachID = async (coachID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getSportTaggingByCoachIDUrl(coachID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCoach = async (coachDetails) => {
    console.log(coachDetails)
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCoachUrl,
        { coachDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCalendar = async (calendarDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCalendarUrl,
        { calendarDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCalendarByCoachID = async (caochID, dayID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCalendarByCoachIDUrl(caochID, dayID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteCalendar = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteCalendarUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
