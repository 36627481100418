import React,{ useState, useEffect } from 'react'
import * as helper from "../global/helper";
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import { requestEmailSendOTP } from '../api/adminAPI';
import { sendPromotionalMail } from '../api/golfClubAPI';
import { getAuthState } from '../../assets/scripts/login-util';
import { deleteOnboarding } from '../api/onboardingAPI';

const OtpModal = (props) => {

    const User = getAuthState('user');
 
    const  { onDismissModal, emailData, documents, from, refechData } = props;

    const [OTP,             setOtp]             = useState();
    const [timer,           setTimer]           = useState(20)
    const [otpTimer,        setOtpTimer]        = useState(180)
    const [counter,         setCounter ]        = useState({m:3, s: 0})
    const [intervalId,      setIntervalId]      = useState({});
    const [serverTimeStamp, setServerTimeStamp] = useState();

    useEffect(() => {
        $('#otp-modal').modal({ backdrop: 'static' });
        $('#sendEmail-modal').addClass('modal-blur'); 

        $('#otp-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');
          $('#sendEmail-modal').removeClass('modal-blur');  
          onDismissModal(); 
        });

        $('#otp-modal').modal('toggle');
    }, []);


    useEffect(() => {
        let ignore = false;
       
        const requestOtp = async() => {
            const response =  await requestEmailSendOTP();
            if(response) setServerTimeStamp(response.serverTimestamp);
        }
        if(!ignore) requestOtp();
        return(() => {
            ignore = true;
        })
    },[])


    useEffect(() => {
        if(serverTimeStamp){
            const OTPExpirationSeconds = moment().diff(serverTimeStamp, 'seconds')
            showTimer(180 - OTPExpirationSeconds);
        }
    }, [serverTimeStamp])


    useEffect(()=>{
        let ignore = false;

        const updateCounter = () =>{   
            let d = otpTimer % (60 * 60 );
            let ds = Math.ceil(d % 60);
            let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
            setCounter(t)
        }

        if(!ignore) updateCounter();
        return () =>{
            ignore = true;
        }
    }, [otpTimer])

    const showTimer = (secondsToExpire) => {
        if(secondsToExpire < 0) {
            setOtpTimer(0);
            setTimer(0)
            return
        } 
        //OTP RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t =>{
                if(t > 0){
                    return (t-1)
                }else{
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);
        
        //OTP EXP TIMER
        let otpInterval;
        if(secondsToExpire <= 180 && secondsToExpire > 0){
            setOtpTimer(secondsToExpire);
            otpInterval = setInterval(() => {
                setOtpTimer(t =>{
                    if(t > 0){
                        return (t-1)
                    }else{
                        clearInterval(otpInterval)
                        return 0
                    }
                })
            }, 1000);
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval, otpInterval });        
    }

    const resetTime = () => {
        clearInterval(intervalId.resendInterval);
        clearInterval(intervalId.otpInterval);
        helper.StopProcessing($("#save-btn"));
    }

    const resendHandler = async () => {
        try {
            let response = await requestEmailSendOTP();
            if(response){
                //Clear Inputs
                setOtp('');
                resetTime();
                setServerTimeStamp(response.serverTimestamp);
            }
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }

    const sendEmail = async (e) => {
        e.preventDefault();

        const form = $("#otp-form");
        form.validate({
            rules: {
                OTP: { required: true },
            },
        });

        if (!form.valid())  return;
    
        helper.StartProcessing($("#save-btn"));

        try {
            if(from == '#sendEmail-modal'){
                emailData.enteredOTP = OTP;
                await sendPromotionalMail(emailData, documents);
                $(from).modal("hide");
                
            }else{
                await deleteOnboarding(emailData, OTP);
                refechData()
            };

            helper.StopProcessing($("#save-btn"));
            resetTime();
            $("#otp-modal").modal("hide");
            swal.fire({
                icon: 'success',
                titleText: from == '#sendEmail-modal' ?  'Email sending processed!' : 'User deleted successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
            helper.StopProcessing($("#save-btn"));    
        } 
    }

    return (
        <div className="modal fade" id="otp-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">  Validate OTP </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                    </div>
                    <div className="modal-body">
                        <div className="client-container">
                            <div className="row margin-b20">
                                <div className="col text-center">
                                    <div className="kt-login__desc"> 
                                        Verify OTP sent to <b>{User.email}</b>.
                                        <br/>
                                        <small>( Check your spam or update folder in case you haven't received OTP)</small>
                                    </div>
                                    <h5 className="text-center margin-t10"> {(counter.m == 0 && counter.s == 0)?' OTP Expired ':`${counter.m}:${counter.s}`} </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <form id="otp-form" onSubmit={(e) => {  e.preventDefault()}}>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    id="OTP"
                                                    value={OTP || ''}
                                                    name="OTP"
                                                    placeholder="Enter OTP"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    minLength='6'
                                                    maxLength='6'
                                                    onChange={({ target: { value } }) => setOtp(value)}
                                                />          
                                            </div>
                                        </div>
                                    </form>  
                                </div>
                            </div>
                            <div className="row margin-t20">
                                <div className="col text-center">                                                
                                    <span className="kt-login__account-msg">
                                        Didn't get it ?
                                    </span>
                                    &nbsp;&nbsp;
                                    {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP `
                                        :<span id="kt_login_otp_resend" className="kt-login__account-link clickable" onClick={resendHandler} > Resend OTP </span>
                                    }
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal" 
                            onClick={resetTime}
                        >   
                            Close
                        </button>
                        <button
                            id="save-btn"
                            type="button"
                            className="btn btn-brand margin-0"
                            onClick={sendEmail}
                        >   
                            {from == '#sendEmail-modal' ? 'Send Email' : "Delete"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OtpModal;
