import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/styles/dark.css';
import BannerLogoLight from '../../assets/images/GBGC_Banner.png';
import LogoLight from '../../assets/images/ms-icon-70x70.png';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdminMenu } from '../api/adminAPI';
import { useHistory } from 'react-router-dom';

const sideMenu = (props) => {

    const [menuList,        setMenuList]       = useState([]);
    const [subMenuList,     setSubMenuList]    = useState([]);
    const history 							   = useHistory();

    useEffect(() => {
        $('.kt-menu__item').removeClass('kt-menu__item--active');
        if (props.menuID) $('#' + props.menuID).addClass('kt-menu__item--active');
    });

    useEffect(() => {
        fetchAdminMenu()
    },[])

    useEffect(() => {
       if (props.menuID && subMenuList.length > 0 && !subMenuList.find(f => f.Url === props.menuID)) {
            history.push(subMenuList[0].Url);      
       }
    },[props.menuID])

    useEffect(() => {
        if (menuList.length > 0) {
            $('#form1').click(function (e) {
                $('.subMenuLink').off();
            });
            $('.kt-menu__item').click(function (e) {  
                const item = $(this);
              
                if ($('body').hasClass('kt-aside--minimize')) {
    
                    const subMenu = item.find('.ddlSubmenu');
                    const windowHeight = $(window).height();
                    let fixed = Math.round((item.offset().top - 90) / 45);
                    let counter = 0;
    
                    while ((subMenu.height() + 40) > (windowHeight - (item.offset().top) + (counter * 45))) {
                        fixed--;
                        counter++;
                    };
    
                    for (var i = 0; i < 21; i++) {
                        if (subMenu.hasClass(`fixed-${i}`)) {
                            subMenu.removeClass(`fixed-${i}`);
                        }
                    }
    
                    subMenu.addClass(`fixed-${fixed}`);
                    $('.kt-menu__item').removeClass('kt-menu__item--open');
                    item.find('.kt-menu__link-icon').tooltip('hide');
                }

            });
        }

    },[menuList])

    useEffect(() => {
        if (subMenuList.length > 0 && !props.menuID) {
            props.title(subMenuList[0].Name, subMenuList[0].Url)
        }
    },[subMenuList])
    
    

    const toggleSubMenu = (e) => {
        e.preventDefault();
        if ($('body').hasClass('kt-aside--minimize')) {
          $('.kt-aside__brand-tools').addClass('aside-brand-tools');
          $('#asideBrandLogo').attr('src', LogoLight);
          $('#asideBrandLogo').attr('height', '35');
          $('.subMenuLink').attr('data-toggle', 'dropdown');
          $('.subMenuLink').each(function (i, e) {
              $(e).find('.kt-menu__link-icon .kt-svg-icon').attr('data-original-title', $(e).find('.kt-menu__link-text').text());                                       
          })
        } else {
          $('.kt-aside__brand-tools').removeClass('aside-brand-tools');
          $('#asideBrandLogo').attr('src', BannerLogoLight);
          $('#asideBrandLogo').attr('height', '35');
          $('.subMenuLink').removeAttr('data-toggle');
          $('.kt-menu__link-icon .kt-svg-icon').removeAttr('data-original-title');
          $('.kt-nav__item').removeClass('show');    
          $('.ddlSubmenu').removeClass('show');
        }
    };    

    const fetchAdminMenu = async() =>{
        try {
            const menu = await getAdminMenu()
            setMenuList(menu.menuList)
            setSubMenuList(menu.subMenuList)           
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }


    return (
        <div className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
            <div className="kt-aside__brand kt-grid__item" id="kt_aside_brand">
                <div className="kt-aside__brand-logo">
                    <NavLink disabled={props.disabled} to="/">
                        <img id="asideBrandLogo" alt="Logo" src={BannerLogoLight} height="35"/>
                    </NavLink>
                </div>
                <div className="kt-aside__brand-tools">
                    <button className="kt-aside__brand-aside-toggler" onClick={(e) => toggleSubMenu(e)} id="kt_aside_toggler">
                    <span>
                        <svg className="nav-movment" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                    </span>
                    <span>
                        <svg className="nav-movment margin-l65" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                    </span>
                    </button>
                </div>
            </div>
            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="kt-aside-menu kt-scroll margin-t0" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500" style={{ marginTop: '0px', height: '835px', overflow: 'auto' }}>
                    {menuList.length > 0 && (
                        <ul className="kt-menu__nav padding-t0">
                            {menuList.map((m, i) => (
                                <li id={helper.SanitizeUrl(m.Name)} key={i} className="kt-menu__item kt-menu__item--submenu dropdown" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <NavLink disabled={props.disabled} to={'/'+ m.Url} className="kt-menu__link kt-menu__toggle subMenuLink" aria-expanded='true'>
                                        <span className="kt-menu__link-icon" data-original-title={m.ID}  data-toggle="kt-tooltip" data-trigger="hover" data-placement="right">
                                            <span dangerouslySetInnerHTML={{__html: m.Icon}}/>
                                        </span>
                                        <span className="kt-menu__link-text">{m.Name}</span>
                                        {m.Name != 'Dashboard' && (
                                            <i className="kt-menu__ver-arrow la la-angle-right"></i>   
                                        )}
                                    </NavLink>
                                    <div className='dropdown-menu dropdown-menu-anim ddlSubmenu fixed'>
                                            <ul className="kt-nav padding-0">
                                                <label className="submenuHeading">{m.Name}</label>
                                                <hr />
                                                {subMenuList.filter(f => f.ParentMenuID == m.MenuID).map((s, i) => (
                                                    <li className="kt-nav__item" key={i}>
                                                        <NavLink to={'/'+s.Url} className="kt-nav__link">   
                                                            <span className="kt-nav__link-icon"><span className="bullet bullet-dot"></span></span>
                                                            <span className="kt-nav__link-text submenuTitle">{s.Name}</span>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                    </div>
                                    {m.Name != 'Dashboard' && (
                                        <>
                                            <div className="kt-menu__submenu" kt-hidden-height="80" style={{display:'none', overflow:'hidden'}}>
                                                <span className="kt-menu__arrow"></span>
                                                <ul className="kt-menu__subnav">
                                                    {subMenuList.filter(f => f.ParentMenuID == m.MenuID).map((s, i) => (
                                                        <li id={s.Url} className="kt-menu__item" key={i} >
                                                            <NavLink to={'/'+s.Url} className="kt-menu__link" >
                                                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                                                <span className="kt-menu__link-text">{s.Name}</span>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </li>)

                            )}
                        </ul>

                    )}
                </div>
            </div>
        </div>
    );

};

export default sideMenu;


