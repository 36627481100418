import React, { useState, useEffect } from 'react'
import TournamentList from './tournamentList';
import { getTournamentRoundByTournamentID, updateTournamentRound } from '../api/tournamentAPI';
import swal from 'sweetalert2';

const TournamentLive = (props) => {

    const [tMaster,             setTMaster]             = useState();
    const [tournamentRound,     setTournamentRound]     = useState([]);

    //#region Effects ---
    useEffect(() =>   props.title("Live", "tournament-live"), []);

    useEffect(() => {
        if(!!tMaster) fetchTournamentRoundData(tMaster?.ID);
    }, [tMaster])
    
    //#endregion
    
    const onSelectHandler =(tMaster)=> {
        if(!tMaster) return;       
        setTMaster(tMaster);                                     
    }

    //Reset on drawer close
    const onReset =()=> {
        setTMaster();
        setTournamentRound([])
    }

    const roundUpdateLive = (id, value) => {
        setTournamentRound(prevRounds => {
            const updatedRounds = prevRounds.map(round => 
                round.ID === id ? { ...round, IsLive: value ? 'Y' : 'N' } : round
            );
            return updatedRounds;
        });
    }

    const roundUpdateScoreLive = (id, value) => {
        setTournamentRound(prevRounds => {
            const updatedRounds = prevRounds.map(round => 
                round.ID === id ? { ...round, IsScoringLive: value ? 'Y' : null } : round
            );
            return updatedRounds;
        });
    }

    const fetchTournamentRoundData = async (tournamentId)=> {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});
            const res = await getTournamentRoundByTournamentID(tournamentId);
            if(!!res) {                             
                setTournamentRound(res);                                    
            }              
            swal.close();
        } catch (err) {          
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }            
    }

    const UpdateRound = async () => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await updateTournamentRound(tournamentRound);
            fetchTournamentRoundData(tMaster?.ID);
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : 'Round Updated Successfully!',
                timer               : 1500,
                animation           : false,
                showConfirmButton   : false
            });
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setTMaster();
            setTournamentRound([])
        }, 200);
    }

    return (
        <>
            <TournamentList title={"Live"}  comp={"live"} onSelectHandler={onSelectHandler}  resetData={onReset}>
                <div className="kt-portlet">
                    <div className="kt-portlet__body">
                        {!!tMaster && (
                            <>
                                <div id="tournamentInfoPanel" className="kt-notification mb-3 bg-lightGrey">
                                    <div className="kt-notification__item d-block">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="kt-user-card-v2 mb-2">
                                                    <div className="kt-user-card-v2__pic">
                                                        <img id="txtTournamentImg" src={tMaster.Icon} alt="tournament-logo" />
                                                    </div>
                                                    <div className="kt-user-card-v2__details ">
                                                        <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tMaster?.Name} </span>
                                                        <span className="kt-user-card-v2__desc ">
                                                            <span id="txtType"> {tMaster?.Type} </span> | <span id="txtMembership">  {tMaster?.MembershipType} </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 d-flex-center">
                                                <div className="kt-user-card-v2 mb-2">
                                                    <div className="kt-user-card-v2__details ">
                                                        <span className="kt-user-card-v2__name "> Start </span>
                                                        <span className="kt-user-card-v2__desc">
                                                            <span id="txtStartDate"> {moment(tMaster?.StartDate).format('DD-MMM-YYYY')} </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 d-flex-center">
                                                <div className="kt-user-card-v2 mb-2">
                                                    <div className="kt-user-card-v2__details ">
                                                        <span className="kt-user-card-v2__name"> End </span>
                                                        <span className="kt-user-card-v2__desc ">
                                                            <span id="txtEndDate"> {moment(tMaster?.EndDate).format('DD-MMM-YYYY')} </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table id="tblRound" className="table table-bordered table-hover margin-b30">
                                    <thead className="light-grey-background">
                                        <tr>
                                            <th>Round    </th>
                                            <th>Format    </th>
                                            <th>Date    </th>
                                            <th>Tee Off Options    </th>
                                            <th>Live    </th>
                                            <th>Scoring Live    </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!tournamentRound && tournamentRound.length > 0 ? (
                                        tournamentRound.map((r, i) => (
                                            <tr key={i} >
                                                <td>{r.Round}</td>
                                                <td>{r.Format}</td>
                                                <td>{moment(r.Date).format('DD MMM YYYY')}</td>
                                                <td>{r.TeeOffOption}</td>
                                                <td>
                                                    <span className="kt-switch kt-switch--icon"> 
                                                        <label > 
                                                            <input 
                                                                className="chkIsLive" 
                                                                name="chkIsLive" 
                                                                type="checkbox" 
                                                                checked={r.IsLive == 'Y'}
                                                                onChange={({target}) => roundUpdateLive(r.ID, target.checked)}
                                                            />
                                                            <span></span> 
                                                        </label >
                                                    </span >
                                                </td>
                                                <td>
                                                    <span className="kt-switch kt-switch--icon"> 
                                                        <label > 
                                                            <input 
                                                                className="chkIsScoringLive" 
                                                                name="chkIsScoringLive" 
                                                                type="checkbox" 
                                                                checked={r.IsScoringLive == 'Y'}
                                                                onChange={({target}) => roundUpdateScoreLive(r.ID, target.checked)}
                                                            />
                                                            <span></span> 
                                                        </label >
                                                    </span >
                                                </td>
                                            </tr>
                                        ))) : (
                                            <tr >
                                                <td className="text-align-center" colSpan="6">No Rounds</td>
                                            </tr>
                                            
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <div className="row">
                                <div className="col-lg-9 ml-lg-auto">
                                    <button 
                                        id="btnSave" type="button" className="btn btn-brand d-inline-block"
                                        onClick={UpdateRound}
                                    >Save</button>
                                    {/* <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TournamentList>
        </>
    )
}

export default TournamentLive