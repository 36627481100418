import { useState, useEffect} from "react";
import Select from 'react-select';
import swal from 'sweetalert2';
import moment from "moment";
import * as helper from '../global/helper';
import { useHistory } from 'react-router-dom';

import ImageCropper from "../global/imageCropper";
import TournamentList from "./tournamentList";
import TournamentRoundTable from "./tournamentRoundTable";
import CategoryTable from "./categoryTable";
import { getRoundCase, getDates, getFormatValue } from "./tournamentHelper";

import { uploadPublicFile } from "../api/fileAPI";
import { getTeeBoxByCourseID } from "../api/golfClubAPI";
import { getMasterPageData, postTournamentData, getTournamentData, deleteTournamentData, updateTournamentPublish, getTournamentByID, getTeeSlotByTournamentTeeID } from "../api/tournamentAPI";

const DeleteModal =(props)=>{
    
    //Modal Show /Blur Other modals
    useEffect(() => {
        $("#deleteModal").modal({ backdrop: "static" });      
        $("#deleteModal").on("hidden.bs.modal", function () { props?.onDismissModal() });
        $("#deleteModal").modal("toggle");
    }, [props?.show]);

    const[password, setPassword] = useState('');
    
    const deleteMaster =async()=>{
        try {
          if(!password || password == ''){
            swal.fire({ icon: 'warning', showConfirmButton: false, timer: 2500, titleText: 'Please enter the password'});
            return;
          }

          props.deleteTournamentMaster(password);
          $('#deleteModal').modal('hide');
          
        } catch (err) {            
            swal.fire({
                icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                titleText: 'Error!', text: err.message,
            });
            return;
        }
    }

    return(<>
        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">       
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Delete Tournament </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                
                    <div className="modal-body">
                        <div id="ModalHolder">
                            <p className="text-center" style={{fontSize: '15px'}}> Are you sure want to delete tournament ? </p>

                            <div id="passwordContainer" className="margin-t25">
                                <div className="form-group row text-center">                                    
                                    <label htmlFor="txtDeletePassword" className="col-form-label col-xl-3 offset-md-2"> Password <span className="red">*</span></label>
                                    <div className="col-xl-4">
                                        <input id="txtDeletePassword" name="txtDeletePassword" type="text" className="form-control"
                                            onChange={({target})=> setPassword(target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="modal-footer">                        
                        <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Cancel </button>
                        <button type="button" id="btnYes" name="btnYes" className="btn btn-danger margin-0" onClick={deleteMaster}> Delete </button>                                                    
                    </div>                
                </div>
            </div>
        </div>
    </>);
}


const TournamentMaster = (props) => { 
    const defaultState = {
        IsOpen: true, Gender: "Both", Round: 1, Days: "Single",  
        Type: 'Singles',  MembershipType: 'All', IsPublished: 'N',
        MenHandicapStart: 0 , MenHandicapEnd:36, WomenHandicapStart: 0 , WomenHandicapEnd:36,
        HandicapMen: 24, HandicapWomen:30, Hole:18,
        CourseID: 1,          
        OpenDate: moment().format('YYYY-MM-DD HH:mm')
    };
    
    const defaultCategory = [{
        ID: 0, TournamentID: 0,  
        Name: "Default", Gender: 'Both', Type: 'All',                    
        MinAge: 1, MaxAge: 100,
        HandicapStart: -8, HandicapEnd: 36,
        MaleTeeBoxID: 0, FemaleTeeBoxID: 0,
        IsGross: "N", IsDefault: "Y",
    }];
	const history                                    					= useHistory();
    const [refetchData, setRefetchData]  = useState(false);
    const [deleteModal,  setDeleteModal] = useState(false);

    const [tMaster,  setTMaster]        = useState(defaultState);
    const [pageData, setPageData]       = useState(null);
    const [teeBoxes, setTeeBoxes]       = useState([]);
    
    const [isEdit,   setIsEdit]         = useState(false);
    const [flags,   setFlags]           = useState({ IsRegister: false, IsDrawCreated: false, IsWinnerDeclared: false });
    
    //<-- Tables -->
    const [allowCategories, setAllowCategories] = useState({ isAllow:false, CategoryCount: 0 });
    const [showCategoryTbl, setShowCategoryTbl] = useState(false);
    const [showRoundTable,  setShowRoundTable]  = useState(false);

    //Rounds & Categories
    const [categoryList, setCategoryList] = useState(defaultCategory);
    const [roundList,    setRoundList]    = useState([]);

    //<--- Cropper -->
    const [imgBlob, SetImgBlob]         = useState(null);
    const [iconBlob, SetIconBlob]       = useState(null);
    const [cropperData, setCropperData] = useState(null);
    const [tournamentID,   setTournamentID]   = useState();

    const [teeSheet,    setTeeSheet] = useState([]);
    //#region Effects      
        useEffect(() => {
            props.title("Tournament Master", "tournament-master");   
            const path = history.location.pathname
            const splitedPath = path.split('/')
            const tID = splitedPath?.find(p => !isNaN(p) && p !== '');
            setTournamentID(tID)
            fetchMasterPageData();
        },[]);

        useEffect(() => {
            if (tournamentID) {
                fetchTournamentByID(tournamentID)
            }
        }, [tournamentID])

        useEffect(() => {
            if (roundList && roundList.length > 0) {
                fetchTeeSlotByTournamentTeeID(roundList[0].TournamentTeeID)
            }
        }, [roundList])
    //#endregion

    //#region API Calls

        const fetchMasterPageData = async() => {
            try {
                const res = await getMasterPageData();
                const tBoxes = await getTeeBoxByCourseID(1)

                if(tBoxes && tBoxes.length > 0) {
                    setTeeBoxes(tBoxes);
                }                    
                
                dataHandler('CourseID', 1); 
                if(!!res) setPageData(res);                
            } catch (error) {
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const fetchTournamentByID = async (id) => {
            try {
                const res = await getTournamentByID(id)
                // console.log('master', res)
                onSelectHandler(res)
            } catch (error) {
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const fetchTeeSlotByTournamentTeeID = async (id) => {
            try {
                const res = await getTeeSlotByTournamentTeeID(id)
                setTeeSheet(res)
            } catch (error) {
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const fetchTournamentData = async(tournamentId) => {
            try {
                swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});
                const res = await getTournamentData(tournamentId);
                if(!!res) {                     
                    if(res?.tRounds.length > 0){
                        setRoundList(res.tRounds);
                        setShowRoundTable(true);
                    }

                    if(res?.tCategory.length > 0){                        
                        const isDefault = res.tCategory.find(d => d.IsDefault == "Y"); //CheckDefault
                        
                        setCategoryList(res.tCategory);
                        if(!isDefault){
                            setAllowCategories({isAllow:true, CategoryCount: res.tCategory.length})                            
                            setShowCategoryTbl(true);
                        }
                    }

                    setFlags({ IsRegister: res.IsRegister, IsDrawCreated: res.IsDrawCreated, IsWinnerDeclared: res.IsWinnerDeclared});    
                    swal.close();
                }              
            } catch (error) {
                swal.close();
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const onCourseChange = async(courseId) => {
            try {
                if(!courseId) return;
                swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...'});
                
                const res = await getTeeBoxByCourseID(courseId);
                if(res && res.length > 0) {
                    setTeeBoxes(res);
                    
                    // const defMaleTeeBox   = res?.find(r => r.Gender == "Male"); 
                    // const defFemaleTeeBox = res?.find(r => r.Gender == "Female"); 
                    
                    //setTMaster(d =>({...d, MaleTeeBoxID: defMaleTeeBox?.ID, FemaleTeeBoxID: defFemaleTeeBox?.ID}));
                    // changeDefaultCategory('MaleTeeBoxID', defMaleTeeBox?.ID);
                    // changeDefaultCategory('FemaleTeeBoxID', defFemaleTeeBox?.ID);
                }                    
                
                dataHandler('CourseID', courseId);                        
                swal.close();
            } catch (error) {
                swal.close();
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const postMaster = async(btnId)=> {
            const btn = $(`#${btnId}`), form = $('#addMasterForm');
            
            //Validation Goes Here -->
            form.validate({
                rules: {
                    tournamentName               : { required: true },
                    txtStartDate          : { required: true },        
                    txtEndDate            : { required: true }, 
                    txtMenStartHandicap   : { required: true }, 
                    txtMenEndHandicap     : { required: true }, 
                    txtWomenStartHandicap : { required: true }, 
                    txtWomenEndHandicap   : { required: true }, 
                    txtMenHandicap        : { required: true }, 
                    txtWomenHandicap      : { required: true }, 
               
                },
                messages: {
                    tournamentName              : { required: "Please enter name" },
                    txtStartDate         : { required: "Please select Start Date"},
                    txtEndDate           : { required: "Please select End Date"},
                    txtMenStartHandicap  : { required: "Enter Men Handicap Start"},
                    txtMenEndHandicap    : { required: "Enter Men Handicap End"},
                    txtWomenStartHandicap: { required: "Enter Women Handicap Start"},
                    txtWomenEndHandicap  : { required: "Enter Women Handicap End"},
                    txtMenHandicap       : { required: "Enter Men Handicap"},
                    txtWomenHandicap     : { required: "Enter Women Handicap"},
                }
            });
            
            if (!form?.valid()) return;
          
            try {                     
                const tournamentMaster      = tMaster;
                const tournamentRounds      = roundList;
                const tournamentCategories  = categoryList;               

                //Check Rounds -- 
                if(roundList.length == 0){
                    swal.fire({
                        icon: "warning", titleText: 'Oops..', buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                        titleText: 'Oops..', text: 'Kindly create rounds..!',
                    });
                    return;
                }else{
                    //validate rounds// checks round reg limit
                    var isRoundValid = {flag: true, msg:''};
                    for (const r of roundList) {                    
                        let teeOffOption = r?.TeeOffOption;
                        
                        if (r.Round == 1) {                  
                            if ((teeOffOption != 'Shot Gun Start') && (!r.MorningRegisterLimit || r.MorningRegisterLimit == 0 || !r.AfternoonRegisterLimit || r.AfternoonRegisterLimit == 0)) {                   
                                isRoundValid = {flag: false, msg:'Kindly enter round registration limit.'};
                                break;
                            } else {   //For Shotgun 
                                var isAfternoon = moment(r.TeeOffTime, 'hh:mm A').isSameOrAfter(moment(pageData?.AfternoonSessionTime, 'hh:mm A'));                            

                                if ((isAfternoon && (!r.AfternoonRegisterLimit || r.AfternoonRegisterLimit == 0)) || (!isAfternoon && (!r.MorningRegisterLimit || r.MorningRegisterLimit == 0) )  ) {                      
                                    isRoundValid = {flag: false, msg:'Kindly enter round registration limit.'};
                                    break;
                                } 
                            }
                        }    
                        
                        if(r?.TournamentTeeID == 0 ){
                            r.TournamentTeeID = pageData?.TournamentTee?.[0].ID
                            // isRoundValid = {flag: false, msg:'Please select tournament tee.'};                           
                            // break;
                        }
                    }

                    if (!isRoundValid.flag) {
                        swal.close();
                        swal.fire({
                            icon: "warning", titleText: 'Oops..', buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                            titleText: 'Oops..', text: isRoundValid.msg,
                        });
                        return;
                    }  
                }
                 
                if(!!allowCategories.isAllow){
                    if(categoryList.count == 0){
                        swal.fire({
                            icon: "warning", titleText: 'Oops..', buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                            titleText: 'Oops..', text: 'Kindly create categories..!',
                        });
                        return;
                    }else{ //validate categories
                        let isCategoryValid = true;
                        
                        for (const cat of categoryList) {                                                     
                            if(cat.Name == "" || cat.HandicapStart == "" || cat.HandicapEnd == "" || cat.MinAge == "" || cat.MaxAge == "" ){
                                isCategoryValid = false;
                                break;
                            }

                            //check teebox
                            if (((cat?.Gender == "Both" || cat?.Gender == "Male") &&  (cat.MaleTeeBoxID == 0) ) || 
                                ((cat?.Gender == "Both" || cat?.Gender == "Female")  && (cat.FemaleTeeBoxID == 0) )
                            ) {
                                isCategoryValid = false;
                                break;
                            }                            
                        }

                        if (!isCategoryValid) {
                            swal.fire({
                                icon: "warning", buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                                titleText: 'Oops..', text: 'Kindly enter all mandatory category fields ',
                            });
                            return;
                        }                        
                    }                    
                } else {
                    if (((categoryList[0]?.Gender == "Both" || categoryList[0]?.Gender == "Male") &&  (categoryList[0].MaleTeeBoxID == 0) ) || 
                        ((categoryList[0]?.Gender == "Both" || categoryList[0]?.Gender == "Female")  && (categoryList[0].FemaleTeeBoxID == 0) )
                    ) {
                        swal.fire({
                            icon: "warning", buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                            titleText: 'Oops..', text: 'Kindly select teebox ',
                        });
                        return;
                    }
                }
                                                             
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });
                helper.StartProcessing(btn);

                //Image & Icon Call upload API -->
                if(imgBlob){
                    const res = await uploadPublicFile(imgBlob, 'Content');
                    tournamentMaster.Image = res.fileName;            
                }else{
                    if(tournamentMaster?.Image) tournamentMaster.Image = tournamentMaster?.Image.split('/Content/')[1];
                }

                if(iconBlob){
                    const res = await uploadPublicFile(iconBlob, 'Content');
                    tournamentMaster.Icon = res.fileName;            
                }else{
                    if(tournamentMaster?.Icon) tournamentMaster.Icon = tournamentMaster?.Icon.split('/Content/')[1];
                }

                //Description
                const Content = window.getEditorValue("#txtDescription");
                if(!!Content) tournamentMaster.Description = Content;

                tournamentMaster.IsPublished = (btnId =='btnPublish') ? "Y": "N";

                //API Call to Post Tournament Data -->             
                await postTournamentData(tournamentMaster, tournamentRounds, tournamentCategories);
                
                if(!tournamentMaster.ID){
                    swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                        titleText: 'Master added successfully', 
                        onOpen: () => swal.hideLoading()                                     
                    });
                }else{
                    swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                        titleText: 'Master updated successfully', 
                        onOpen: () => swal.hideLoading()                                     
                    });
                }
                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {
                helper.StopProcessing(btn);
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }
            
        const updatePublish = async(btnId, isPublish)=> {
            const btn = $(`#${btnId}`);

            try {
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });
                helper.StartProcessing(btn);                                

                //API Call to Post Tournament Data -->             
                await updateTournamentPublish(tMaster.ID, isPublish);

                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master Update successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });

                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {
                console.log(err)
                helper.StopProcessing(btn);
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }
       
        const deleteTournamentMaster = async(pswd)=>{
            const btn = $(`#btnDelete`);
            try {                
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                helper.StartProcessing(btn);                
                
                await deleteTournamentData(tMaster?.ID, pswd);

                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master Deleted successfully',  onOpen: () => swal.hideLoading()                                     
                });
                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {       
                helper.StopProcessing(btn);       
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }

    //#endregion

    //#region handlers            
        const dataHandler = (field, value) => {          
            if(field == "IsOpen" && !value)  setTMaster(d => ({...d, OpenDate: moment().format('YYYY-MM-DD HH:mm')})); //RESET OpenDate to Current Date is if false
            
            //Reset Round Table onchange -            
            if(!!showRoundTable && ['Days', 'Round', 'Type'].includes(field)) {         
                setShowRoundTable(false);
                swal.close();
                swal.fire({
                    icon: "warning", buttonsStyling: false, confirmButtonClass: "btn btn-brand",
                    titleText: 'Oops..', text: 'Please re-create rounds..',
                });
            }

            if(['Gender','MembershipType'].includes(field)) {         
                if(!!showCategoryTbl && allowCategories.CategoryCount > 0){
                    setShowCategoryTbl(false);
                    setAllowCategories(d => ({...d, CategoryCount : 0}));
                    setCategoryList(defaultCategory);
                    swal.close();
                    swal.fire({ icon: "warning", buttonsStyling: false, confirmButtonClass: "btn btn-brand", titleText: 'Oops..', text: 'Please re-create categories..'});
                }else{
                    changeDefaultCategory(field, value);
                }
            }
            
            setTMaster(d => ({...d, [field]: value}));   
        }

        const changeDefaultCategory =(field, value)=>{                 
            field = (field == "MembershipType")? "Type" : field;

            setCategoryList(d=> {
                const newCat  = d[0];
                newCat[field] = value;

                return [newCat]
            })       
        }

        const onchangeAllowCategory = (field, value) => {
            //Reset Count, refill  defaultCategory on toggle close
            if(field == "isAllow" && !value ){
                setAllowCategories(d=>({[field]: value, CategoryCount: 0}));   
                setCategoryList(defaultCategory);
                setShowCategoryTbl(false);
            }else{
                setAllowCategories(d => ({...d, [field]: value}));  
            }
        }
    
        const createRounds =()=>{
            const { StartDate, EndDate, OpenDate, CloseDate, Type, Round, Days} = tMaster;

            let isValid = {flag: true , msg : ''};
            
            //validate Tournament Dates
            if (!StartDate || !EndDate || StartDate == 'Invalid date' || EndDate == 'Invalid date') { isValid.flag = false; isValid.msg ='Please check tournament dates'; };   
            if (!Type) { isValid.flag  = false; isValid.msg ='Please select playing format'};            
            if (!Round){ isValid.flag  = false; isValid.msg ='Please select rounds'};            
            if (!Days) { isValid.flag  = false; isValid.msg ='Please select days'};        

            if(StartDate != EndDate){            
                if(moment(EndDate).isBefore(moment(StartDate))) {isValid.flag = false; isValid.msg ='End Date should be greater than Start Date';}
                if(Days == "Single") {isValid.flag = false; isValid.msg ='The tournament start and end dates must be same if played over single day.';}                                                                                        
            }
          
            if(moment(StartDate).isBefore(moment(CloseDate, "YYYY-MM-DD HH:mm"))) {isValid.flag = false; isValid.msg ='Registration Close Date should come before tournament Start Date.';}
            if(moment(CloseDate, "YYYY-MM-DD HH:mm").isBefore(moment(OpenDate, "YYYY-MM-DD HH:mm")))  {isValid.flag = false; isValid.msg ='Registration Open Date should come before tournament Close Date.';}            
            
            if(StartDate == EndDate){
                if(Round > 2){ isValid.flag = false; isValid.msg ='Cannot have more than 2 round on a single day'; }
                if(Days == "Multiple"){ isValid.flag = false; isValid.msg ='The tournament cannot be played over multiple days since tournament start and end dates are same.'; }
            }
            
            if(!isValid.flag){
                swal.fire({
                    icon: "warning", buttonsStyling: false, confirmButtonClass: "btn btn-brand", titleText: 'Oops..', 
                    text: isValid.msg,
                });  
                //Clear Round Table                
                return;
            }        

            const currentCase  = getRoundCase(tMaster?.Days, tMaster?.Round);
            const roundDates   = getDates(tMaster?.StartDate, tMaster?.EndDate);
            const RoundFormats = pageData?.TournamentFormats?.filter(f => f.Type == tMaster?.Type);
            let RoundLimit     = (currentCase != 3)? tMaster.Round : roundDates.length;

            const tRound   = [];
            for (let i = 0; i < RoundLimit; i++) {
                const roundNo       = (tMaster?.Round == 1) ? 1 : (i + 1);
                let defaultDate     = (currentCase < 3) ? roundDates[0] : (i < roundDates.length) ? roundDates[i] : roundDates[roundDates.length - 1];
                const roundDt       = defaultDate ;  // Update this on edit --               
                
                const defaultFormat = (RoundFormats[0]?.Format);
                const formatValue   = getFormatValue(defaultFormat);
    
                tRound.push({
                    ID                     : 0,
                    TournamentTeeID        : pageData?.TournamentTee?.[0].ID,
                    Round                  : roundNo,
                    Date                   : roundDt.value, // For SingleDay use 1st Date
                    Format                 : defaultFormat,
                    TeeOffOption           : pageData?.TeeOffOptions[0],
                    TeeOffTime             : '7:00 AM',
                    TeeOffTimeSplit        : '12:00 PM',
                    LowerMiddle            : !!formatValue? formatValue.lowerMiddle  : 0,
                    Middle                 : !!formatValue? formatValue.middle : 0,
                    Lower                  : !!formatValue? formatValue.lower  : 0,
                    Higher                 : !!formatValue? formatValue.higher : 0,
                    MinDrive               : !!formatValue? formatValue.minDrive : 0,
                    MorningRegisterLimit   : 0,
                    AfternoonRegisterLimit : 0,
                    Active                 : 'Y',
                });                                           
            }
            setRoundList(tRound);    
                                                  
            setShowRoundTable(true);
        }

        const createCategories =()=> { 
            if(!tMaster.CourseID){
                swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Please select course', buttonsStyling: false, confirmButtonClass: "btn btn-brand" });
                return;
            }

            if(allowCategories.CategoryCount > 10){
                swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Too many categories', buttonsStyling: false, confirmButtonClass: "btn btn-brand" });
                return;
            }
                   
            //Creates New Categories 
            const tCategory = [];
            if(!!allowCategories?.isAllow && allowCategories?.CategoryCount > 0){
                for (let index = 0; index < allowCategories?.CategoryCount; index++) {                
                    //Update this for custom categories
                    tCategory.push({
                        ID              : 0,
                        TournamentID    : 0,
                        Name            : "",
                        HandicapStart   : -8,
                        HandicapEnd     : 36,
                        MinAge          : 1,
                        MaxAge          : 100,
                        Gender          : tMaster.Gender,
                        Type            : tMaster.MembershipType,                    
                        MaleTeeBoxID    :  0,
                        FemaleTeeBoxID  :  0,
                        IsGross         : "N",
                        IsDefault       : "N",
                    });                                       
                }                
            }

            setCategoryList(tCategory);                  
            setShowCategoryTbl(true);
        }
    
        const openCropper = (imgType) =>{
            if(imgType == "Icon"){
                setCropperData({
                    header      : 'Upload Icon',
                    imageRatio  : { width : 64, height : 64},
                    dataHandler : (val) =>  SetIconBlob(val),
                })
            }else{
                setCropperData({
                    header      : 'Upload Banner',
                    imageRatio  : { width : 500, height : 300},
                    dataHandler : (val) =>  SetImgBlob(val),
                })
            }           
        }
    
        const removeCategory = (catIndex)=>{
            const filtered = categoryList.filter((r,i) => (i != catIndex));
            if(filtered.length > 0){
                setCategoryList(filtered);                
                setAllowCategories(d => ({...d, CategoryCount: filtered.length}));
            }  
        }
    
        const addNewCategory =()=> {      
            let categories = categoryList;       
                       
            categories.push({
                ID              : 0,
                TournamentID    : 0,  //Update for Edit
                Name            : "",
                HandicapStart   : -8,
                HandicapEnd     : 36,
                MinAge          : 1,
                MaxAge          : 100,
                Gender          : tMaster.Gender,
                Type            : tMaster.MembershipType,                    
                MaleTeeBoxID    :  0,
                FemaleTeeBoxID  :  0,
                IsDefault       : "N",
                IsGross         : "N",
            });       
            
            if(categories.length > 0){  
                setCategoryList(categories);
                setAllowCategories(d => ({...d, CategoryCount:categories.length}));
            }            
        } 

        //onMasterSelect
        const onSelectHandler = (tMaster)=> {
            if(!tMaster) return;
            onCourseChange(tMaster?.CourseID); //GetTeeboxes;

            setTMaster(tMaster);            
            if(!!tMaster.Description) setTimeout(()=>{ window.setEditorValue('#txtDescription', tMaster.Description)}, 200)                        
            
            fetchTournamentData(tMaster?.ID);         
            setIsEdit(true);
        }

        const onDeleteMaster= async()=> {         
            if(!tMaster?.ID) return;

            try {                        
                //check validations
                const isRegistered = flags?.IsRegister; //Update this After Registration

                if(!isRegistered){                   
                    deleteTournamentMaster('noPass');
                }else{
                    setDeleteModal(true);
                }          
            } catch (err) {              
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }           
        }

        //Reset on drawer close
        const onReset =()=> {
            setIsEdit(false)
            setTMaster(defaultState);
            setTeeBoxes([]);
            setAllowCategories({isAllow:false, CategoryCount:0});
            setShowCategoryTbl(false);
            setShowRoundTable(false);
            setCategoryList(defaultCategory);
            setRoundList([]);
            SetImgBlob(null);
            SetIconBlob(null);
            setCropperData(null);
            setFlags({ IsRegister: false, IsDrawCreated: false, IsWinnerDeclared: false });
        }      
    //#endregion
    
    return(<>
        <TournamentList tournamentID={tournamentID} setTournamentID={setTournamentID} title={"Tournament Master"} comp={'Master'} refetchData={refetchData} resetRefetch={()=> setRefetchData(false)}  defaultState={defaultState} stateHandler={setTMaster} onSelectHandler={onSelectHandler} resetData={onReset} >          
            <div className="kt-portlet">
                <div className="kt-portlet__body padding-t30 padding-b0">
                    <form className="kt-form" id="addMasterForm" role="form">
                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10">                                       
                            
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Tournament Name <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <input 
                                        id="tournamentName"  name="tournamentName" 
                                        type="text" className="form-control"
                                        value={tMaster.Name || ''}
                                        onChange={({target}) => dataHandler("Name", target.value)}  
                                    />
                                </div>
                            </div> 

                            {/* <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Course <span className="red">*</span> </label>
                                <div className="col-xl-3 col-lg-4">
                                    <Select
                                        // value    = {(pageData?.CourseID && pageData?.AllCourses.length > 0 && tMaster?.CourseID) && pageData?.AllCourses.find(m => m.value == tMaster?.CourseID)}
                                        id       = "ddlCourseID" className= "ddlCourseID" name= "ddlCourseID" placeholder= "Select Course"
                                        options  = {pageData?.AllCourses.filter(c => c.value == 1) || []} isDisabled={flags?.IsRegister}
                                        value    = {(pageData?.AllCourses.length > 0 && tMaster?.CourseID) && pageData?.AllCourses.find(m => m.value == tMaster?.CourseID)}
                                        onChange = {(event) => onCourseChange(event.value)}
                                    />
                                </div>
                            </div> */}

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Tournament Calender</label>
                                <div className="col-xl-3 col-lg-4">
                                    <Select
                                        id       = "ddlTournamentCalendar" className= "ddlTournamentCalendar" name= "ddlTournamentCalendar" placeholder= "Select Calendar"
                                        options  = {pageData?.TournamentCalendar || []}
                                        value    = {(pageData?.TournamentCalendar && pageData?.TournamentCalendar.length > 0 && tMaster.TournamentCalendarID) && pageData?.TournamentCalendar.find(m => m.value == tMaster.TournamentCalendarID)}
                                        onChange = {(event) => dataHandler('TournamentCalendarID', event.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Opens To <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="input-group">                                       
                                        <select id="ddlMembership" name="ddlMembership" title="Select" className="form-control"
                                            value={tMaster?.MembershipType} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MembershipType", target.value)}
                                        >
                                            {pageData?.MembershipType.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Gender <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="input-group">                                       
                                        <select id="ddlGender" name="ddlGender" title="Select" className="form-control"
                                            value={tMaster?.Gender} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("Gender", target.value)}
                                        >
                                            {pageData?.Genders.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Tournament Hole <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="input-group">                                       
                                        <select id="ddlHole" name="ddlHole" title="Select" className="form-control"
                                            value={tMaster?.Hole} disabled={flags?.IsDrawCreated}
                                            onChange={({target}) => dataHandler("Hole", target.value)}
                                        >
                                           <option value={18}> 18 </option>
                                           <option value={10}> 10 </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Registration Handicap */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Registration Handicap <span className="red">*</span></label>

                                {tMaster?.Gender != "Female" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Min                                                     
                                        <input id="txtMenStartHandicap" name="txtMenStartHandicap" type="number" className="form-control"                                             
                                            value={tMaster?.MenHandicapStart} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenHandicapStart", target.value)}
                                        />
                                    </div>                                               
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Max                                                  
                                        <input id="txtMenEndHandicap" name="txtMenEndHandicap" type="number" className="form-control"                                         
                                            value={tMaster?.MenHandicapEnd}  disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenHandicapEnd", target.value)}
                                        />
                                    </div>
                                </>)}

                                {tMaster?.Gender != "Male" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Min
                                        <input id="txtWomenStartHandicap" name="txtWomenStartHandicap" type="number" className="form-control"                                        
                                          value={tMaster?.WomenHandicapStart} disabled={flags?.IsRegister}
                                          onChange={({target}) => dataHandler("WomenHandicapStart", target.value)}
                                       />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Max
                                            <input id="txtWomenEndHandicap" name="txtWomenEndHandicap" type="number" className="form-control"                                             
                                                value={tMaster?.WomenHandicapEnd} disabled={flags?.IsRegister}
                                                onChange={({target}) => dataHandler("WomenHandicapEnd", target.value)}
                                            />
                                    </div>
                                </>)}                                                                
                            </div>

                            {/* Max Handicap */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Max Handicap <span className="red">*</span></label>
                                {(tMaster?.Gender != "Female") && (<>
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men
                                        <input id="txtMenHandicap" name="txtMen" type="number" className="form-control"
                                        value={tMaster?.HandicapMen || 24} disabled={flags?.IsRegister}
                                        onChange={({target}) => dataHandler("HandicapMen", parseInt(target.value))}   
                                    />
                                    </div>
                                </>)}
                                
                                {(tMaster?.Gender != "Male") && (<>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women
                                        <input id="txtWomenHandicap" name="txtWomen" type="number" className="form-control"
                                            value={tMaster?.HandicapWomen || 30} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("HandicapWomen", parseInt(target.value))}   
                                        />
                                    </div>
                                </>)}
                            </div>

                             {/* Fee */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Fee </label>                               
                                <div className="col-xl-2 col-lg-2"> Member
                                    <input id="txtMemberFee" name="txtMemberFee" type="number" className="form-control"
                                        value={tMaster?.MemberFee || 0}
                                        onChange={({target}) => dataHandler("MemberFee", parseInt(target.value))}   
                                    />
                                </div>
                             
                                <div className="col-xl-2 col-lg-2"> Guest
                                    <input id="txtGuestFee" name="txtGuestFee" type="number" className="form-control"
                                        value={tMaster?.GuestFee || 0}
                                        onChange={({target}) => dataHandler("GuestFee", parseInt(target.value))}   
                                    />
                                </div>                               
                            </div>
                            
                            {/* Banner Img */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Banner Image </label>
                                <div className="col-sm-9">                                                        
                                    {(!!imgBlob || tMaster.Image) &&(<>
                                        <img height={150} src={imgBlob ? URL.createObjectURL(imgBlob) : tMaster.Image && (tMaster.Image) }/>
                                    </>) }
                                    <p className="mt-3">
                                        <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={() => openCropper('Banner')}>
                                            {(!!imgBlob || tMaster.Image) ? 'Change Image' : 'Upload Image'}
                                        </button>
                                    </p>
                                    <p className="">(500 W x 300 H pixels)</p>                                                       
                                </div>
                            </div>

                            {/* Trophy Icon */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Trophy Icon </label>
                                <div className="col-sm-9">                                                        
                                    {(!!iconBlob || tMaster.Icon) &&(<>
                                        <img height={150} src={iconBlob ? URL.createObjectURL(iconBlob) : tMaster.Icon && (tMaster.Icon) }/>
                                    </>) }
                                    <p className="mt-3">
                                        <button id="btn_Logo_Icon" type="button" className="btn btn-secondary" onClick={() => openCropper('Icon')}>
                                            {(!!iconBlob || tMaster.Icon) ? 'Change Image' : 'Upload Image'}
                                        </button>
                                    </p>
                                    <p className="">(64 W x 64 H pixels)</p>                                                       
                                </div>
                            </div>                 
                                     
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Description </label>
                                <div className="col-xl-6 col-lg-8">
                                    <textarea id="txtDescription" name="txtDescription" className="form-control" rows="3"></textarea>
                                </div>
                            </div>
                            
                            {/* EventNo */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Event No </label>
                                <div className="col-xl-6 col-lg-8">
                                    <input id="txtEventNo" name="txtEventNo" type="text" className="form-control" value={tMaster?.EventNo || ''}
                                       onChange={({target}) => dataHandler("EventNo", parseInt(target.value))} />
                                </div>
                            </div>
                            
                            {/* Label */}
                            {/* <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Tournament Label </label>
                                <div className="col-xl-6 col-lg-8">
                                    <input id="txtLabel" name="txtLabel" type="text" className="form-control" value={tMaster?.Label || ''}
                                       onChange={({target}) => dataHandler("Label", target.value)} />
                                </div>
                            </div> */}

                            {/* Start Date*/}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Tournament Start Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtStartDate" name="txtStartDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select start date"
                                            value={tMaster?.StartDate ? moment(tMaster.StartDate).format('DD-MM-YYYY') : ''}
                                            disabled={flags?.IsRegister}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* End Date */}            
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Tournament End Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtEndDate" name="txtEndDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select end date"
                                            value={tMaster.EndDate ? moment(tMaster.EndDate).format('DD-MM-YYYY') : ''}
                                            disabled={flags?.IsRegister}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Registration Open On Publish</label>
                                <div className="col-xl-6 col-lg-8">
                                    <span className="kt-switch kt-switch--icon">
                                        <label>
                                            <input id="chkOpen" name="chkOpen" type="checkbox" defaultChecked="checked"  
                                                value={tMaster?.IsOpen} 
                                                onChange={({target}) => dataHandler("IsOpen", target.checked)}    
                                            />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                                                     
                            <div id="pnlOpen" className={`form-group row ${!(tMaster?.IsOpen)? '' : 'hidden'}`}>
                                <label className="col-form-label col-lg-3">Registration Open Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtOpenDate" name="txtOpenDate" type="text" className="form-control dateTime-input" readOnly={true}  placeholder="Select open date" 
                                            value={tMaster.OpenDate ? moment(tMaster.OpenDate).format('YYYY-MM-DD HH:mm'): ''} 
                                            disabled={flags?.IsRegister}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Registration Close Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtCloseDate" name="txtCloseDate" type="text" className="form-control dateTime-input" readOnly={true} placeholder="Select close date" 
                                            value={tMaster.CloseDate ? moment(tMaster.CloseDate).format('YYYY-MM-DD HH:mm'): ''} 
                                            disabled={flags?.IsRegister}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {/* Tournament Rounds */}                            
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Playing Format <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group">
                                        <select id="ddlType" name="ddlType" title="Select" className="form-control" 
                                          value={tMaster?.Type} disabled={flags?.IsRegister}
                                          onChange={({target}) => dataHandler("Type", target.value)}    
                                        >
                                            {pageData?.TournamentType.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Round Played by each Player <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group">
                                        <select id="ddlRound" name="ddlRound" title="Select" className="form-control"
                                          value    = {tMaster?.Round} disabled={flags?.IsRegister}
                                          onChange = {({target}) => dataHandler("Round", parseInt(target.value))}    
                                        >
                                            {pageData?.TournamentRoundNumbers.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Played Over (Days) <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group">
                                        <select id="ddlDay" name="ddlDay" title="Select" className="form-control kt-selectpicker show-tick"
                                            value    = {tMaster?.Days} disabled={flags?.IsRegister}
                                            onChange = {({target}) => dataHandler("Days", target.value)}    
                                        >
                                            {pageData?.TournamentDays.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            {!showRoundTable && (
                                <div className="form-group row">
                                    <div className="col-xl-3 offset-xl-3 col-lg-2  offset-lg-2">
                                        <button id="btnCreate" className="btn btn-label-brand margin-l10 margin-r0" type="button" 
                                        onClick={createRounds}> Create </button>
                                    </div>
                                </div>
                            )}

                            {(showRoundTable && teeSheet.length > 0) && ( 
                                <TournamentRoundTable 
                                    tMaster={tMaster} tData={pageData} 
                                    teeSheet={teeSheet}
                                    roundList={roundList} setRoundList={setRoundList}
                                    flags={flags}  // isEdit={isEdit} 
                                /> 
                            )}
                                                                           
                            {/* Tournament Categories */}
                            <div className="form-group row mt-5">
                                <label className="col-form-label col-lg-3">Allow Categories</label>
                                <div className="col-xl-1 col-lg-1">
                                    <span className="kt-switch kt-switch--icon">
                                        <label>
                                            <input id="checkCategory" name="checkCategory" type="checkbox"   
                                                checked={ allowCategories?.isAllow }  disabled={flags?.IsRegister}                                            
                                                onChange={({target}) => onchangeAllowCategory('isAllow', target.checked)}     
                                            />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                                {!!allowCategories?.isAllow && (<div className="col-lg-8 d-flex">
                                    <label className="col-form-label mr-2"> No. of Categories <span className="red">*</span> </label>
                                    
                                    <input style={{width:"110px"}} id="txtCategoryNo" name="txtCategoryNo" type="text" className="form-control"
                                        value={allowCategories?.CategoryCount} disabled={showCategoryTbl}                                      
                                        onChange={({target}) => onchangeAllowCategory('CategoryCount', target.value)}   
                                    />
                                    
                                    {!showCategoryTbl && (
                                        <button id="btnCategory" className="btn btn-label-brand mt-0 margin-l10 mr-0" type="button"
                                        onClick={createCategories} > Create Category </button> 
                                    )}
                                    {(!!allowCategories && !!showCategoryTbl && (
                                        <button id="btnAddCategory" className="btn btn-label-brand mt-0 margin-l10" type="button"
                                            onClick={addNewCategory} disabled={flags?.IsDrawCreated}
                                        > 
                                        <i className="la la-plus"></i></button>
                                    ))}
                                </div>)}
                            </div>

                            {!!allowCategories && showCategoryTbl && pageData && ( 
                                <CategoryTable 
                                    tMaster={tMaster} tData={pageData} 
                                    teeBoxes={teeBoxes}  
                                    categoryList={categoryList} setCategoryList={setCategoryList}
                                    removeCategory={removeCategory}
                                    cData={allowCategories} 
                                    flags={flags}
                                    //isEdit={isEdit} 
                                /> 
                            )}

                            {!allowCategories?.isAllow && (tMaster?.CourseID) && (
                                <div id="pnlTeeBox">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3 hc_men"> Male Teebox <span className="red">*</span></label>
                                        <div className="col-xl-2 col-lg-2 ddl_teebox_master hc_men">                                                           
                                            <select id="ddlMaleTeeBox" name="ddlMaleTeeBox" title="Select" className="form-control"                                                
                                                value={categoryList[0]?.MaleTeeBoxID || 0} disabled={flags?.IsDrawCreated}
                                                onChange = {({target}) => changeDefaultCategory("MaleTeeBoxID", parseInt(target.value))}    
                                            >
                                                <option value={0}> Select Teebox </option>
                                                {teeBoxes.filter(t=> t.Gender == "Male").map((g) => ( <option key={g.ID} value={g.ID}> {g.Name} </option> ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-7 hc_women">
                                            <div className="row">
                                                <div className="col-lg-1 hc_extra"></div>
                                                <label className="col-form-label col-lg-4"> Female Teebox <span className="red">*</span></label>
                                                <div className="col-lg-2 ddl_teebox_master"> 
                                                    <select id="ddlFemaleTeeBox" name="ddlFemaleTeeBox" title="Select" className="form-control"
                                                        value={categoryList[0]?.FemaleTeeBoxID || 0} disabled={flags?.IsDrawCreated}
                                                        onChange = {({target}) => changeDefaultCategory("FemaleTeeBoxID", parseInt(target.value))}    
                                                    >
                                                        <option value={0}> Select Teebox </option>
                                                        {teeBoxes.filter(t=> t.Gender == "Female").map((g) => ( <option key={g.ID} value={g.ID}> {g.Name} </option> ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                        
                            )}            

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <div className="row">
                                        <div className="col-lg-9 ml-lg-auto">
                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" 
                                                onClick={() => postMaster('btnSave')}
                                            > Save </button>        
                                            
                                            {(tMaster?.IsPublished == "Y") ? (
                                                <button id="btnUnPublish" type="button" className="btn btn-warning d-inline-block ml-4 text-white" 
                                                    onClick={() => updatePublish('btnUnPublish', "N")}
                                                > UnPublish </button> 
                                            ):((flags?.IsWinnerDeclared)?(<>
                                                    <button id="btnTournamentPublish" type="button" className="btn btn-success d-inline-block ml-4" 
                                                        onClick={() => updatePublish('btnTournamentPublish', "Y")}
                                                    > Publish </button>            
                                                </>):(<>
                                                    <button id="btnPublish" type="button" className="btn btn-success d-inline-block ml-4" 
                                                        onClick={() => postMaster('btnPublish')}
                                                    > Save & Publish </button>                                                            
                                                </>)                                                
                                            )}    

                                            {(!!isEdit) && (                                                    
                                                <button id="btnDelete" type="button" className="btn btn-danger d-inline-block ml-4" 
                                                    onClick={() => onDeleteMaster()}
                                                > Delete </button>       
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>                      
        </TournamentList>

    {cropperData && (
        <ImageCropper
            show           = { cropperData }
            header         = { cropperData.header }   
            imageRatio     = { cropperData.imageRatio }
            dataHandler    = { cropperData.dataHandler }
            onDismissModal = {() => setCropperData(null) }
        />
    )}

    {deleteModal && (
        <DeleteModal
            show           = { deleteModal }         
            onDismissModal = {() => setDeleteModal(null) }
            deleteTournamentMaster = {(v)=> deleteTournamentMaster(v)}
        />
    )}        

    </>)    
}

export default TournamentMaster;