
import React,{ useState, useEffect } from 'react'
import * as helper from "../global/helper";
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import { getViewUserAll } from '../api/usersAPI';
import AttachmentModal from './attachmentModal';
import OtpModal from '../global/otpModal';


const SendEmailModal = (props) => {
    const  { selectedEmailTemplate } = props
    const [emailData,       setEmailData]       = useState({ targetAudience:'AllUsers', senderEmail: 'no-reply@barodagolf.in' });
    const [users,           setUsers]           = useState([]);
    const [selectedUsers,   setSelectedUsers]   = useState([]);
    const [loading,         setLoading]         = useState(false);
    const [searchTerm,      setSearchTerm]      = useState('');
    const [currentStatus,   setCurrentStatus]   = useState('All');
    const [filteredUsers,   setFilteredUsers]   = useState([]);
    const [showOtpModal,    setshowOtpModal]    = useState(false);
    const [addDocument,     setAddDocument]     = useState(false);
    const [documents,       setDocuments]       = useState([]);

    useEffect(() => {
        $('#sendEmail-modal').modal({ backdrop: 'static' });
  
        $('#sendEmail-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          props.onDismissModal(); 
        });
    }, []);

    
    useEffect(() => {
        let ignore = false;
        const fetchUsersAll = async () => {
            try {
                setLoading(true);
                const response = await getViewUserAll();
                if(response && response.length > 0){
                    setUsers(response);
                    setFilteredUsers(response);
                    setLoading(false);
                }
            } catch (error) {
                swal.fire({
                    icon: "error",
                    titleText: "Error!",
                    text: error.message,
                    buttonsStyling: false,
                    confirmButtonClass: "btn btn-brand",
                });
                setLoading(false);
            }
        };
        if (!ignore ) {
            if(emailData.targetAudience === 'Custom'){
                fetchUsersAll();
            }else{
                setCurrentStatus('All')
                setSelectedUsers([])
            }
        }
        return () => {
          ignore = true;
        };
    }, [emailData.targetAudience]);

    useEffect(() => {
        filterUsers();
    }, [currentStatus]);

    useEffect(() => {
        if(searchTerm == ''){
            setCurrentStatus('All')
            return setFilteredUsers(users);
        }
        filterUsers();
    }, [searchTerm]);

    const filterUsers = () => {
        let allUsers = users;
        
        if(currentStatus && currentStatus === 'All'){
            setFilteredUsers(allUsers)
        }

        if(currentStatus && currentStatus != 'All') {
            allUsers = allUsers.filter( a => a.MembershipType === currentStatus);
            setFilteredUsers(allUsers)
        }

        if(searchTerm != ''){
          const matchedData = allUsers.filter(c => {
            let matchString = ` ${c.FirstName} ${c.LastName} ${c.Email}`;
            let matchFound = 0;
      
            const searchArr = searchTerm.split(' ');
            searchArr.forEach(term => {
              matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
            });
      
            return matchFound === searchArr.length;
          })
          setFilteredUsers(matchedData);
        }
  
    }

    const setDocumentHandler = (d) => {
        setDocuments((a) => {
          const newA = [...a];
          newA.push(d);
          return newA;
        });    
    };

    const handleInput = (key, value) => {
        setEmailData( d  => { return {...d, [key]: value}})
    }

    const handleUserCheckbox = (a) => {
        if(selectedUsers.findIndex(u => u.ID === a.ID) === -1){
            setSelectedUsers( d => [...d, { ID : a.ID, Email : a.Email, FirstName: a.FirstName, LastName: a.LastName }])
        }else{
            const newUsers = selectedUsers.filter( u => u.ID !== a.ID);
            setSelectedUsers(newUsers)
        }
    }


    const sendEmailHandler = async(e) => {
        e.preventDefault();

        const form = $("#email-form");
        form.validate({
            rules: {
                subject: { required: true },
            },
        });

        if (!form.valid())  return;

        if (emailData.targetAudience === 'Custom' && !selectedUsers.length > 0) {
            return swal.fire({
              icon  : "error",
              title : "Please select the User !",
            });
        }
        
        try {
            //will accept template ID, subject, target audience, users
            setEmailData( d => {
                return { ...d, users : selectedUsers, templateID : selectedEmailTemplate.ID}
            })

            if(Object.keys(emailData).length > 0){
                setshowOtpModal(true);
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
        }
    }
    const removeDocumentHandler = (index) => {
        setDocuments((a) => {
          const nA = a.filter((b, i) => index !== i);
          return nA;
        })
    };

    return(
        <>
            <div className="modal fade" id="sendEmail-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">  Send {selectedEmailTemplate ? selectedEmailTemplate.Name : 'Template'}   </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div className="modal-body  padding-tb0">
                            <div className="client-container padding-b0">
                                <div className='row'>
                                    <div className='col-12'>
                                    <form id="email-form" onSubmit={(e) => {  e.preventDefault()}}>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    id="subject"
                                                    value={emailData.subject || ''}
                                                    name="subject"
                                                    placeholder="subject"
                                                    className="form-control margin-b10"
                                                    type="text"
                                                    onChange={({ target: { value } }) => handleInput('subject', helper.capitalize(value)) }
                                                />          
                                            </div>
                                        </div>
                                        <div className='row'>       
                                            <div className="col-4">
                                                <input
                                                    id="senderName"
                                                    value={emailData.senderName || ''}
                                                    name="senderName"
                                                    placeholder="from"
                                                    className="form-control margin-b10"
                                                    type="text"
                                                    onChange={({ target: { value } }) => handleInput('senderName', helper.capitalize(value)) }
                                                />          
                                            </div> 
                                            <div className="col-4">
                                                <select 
                                                    id = 'selectSenderEmail'
                                                    className = "form-control margin-b10 margin-t10"
                                                    value = {emailData.senderEmail}
                                                    onChange={({ target }) => { handleInput('senderEmail', target.value) }}
                                                >
                                                    <option className='no-reply@barodagolf.in' > no-reply@barodagolf.in </option>
                                                </select>    
                                            </div>
                                            <div className="col-4">
                                                <select 
                                                    id = 'selectSenderEmail'
                                                    className = "form-control margin-b10 margin-t10"
                                                    value = {emailData.targetAudience}
                                                    onChange={({ target }) => { handleInput('targetAudience', target.value) }}
                                                >
                                                    <option value='AllUsers'>           All Users</option>
                                                    <option value='Golf Membership'>    Golf Members </option>
                                                    <option value='Club Membership'>    Club Members </option>
                                                    <option value='N'>                  Non-App User </option>
                                                    <option value='Custom'>             Custom </option>
                                                </select>
                                            </div>
                                        </div>
                                    
                                    </form>  
                                    </div>
                                </div>
                                {emailData.targetAudience === 'Custom' && users.length > 0  &&( <>
                                    <div className='row mt-2' >
                                        <div className='col-12'>
                                            <div className="kt-portlet kt-portlet--mobile ">
                                                <div className="kt-portlet__head kt-portlet__head--lg padding-l0 " style={{minHeight : '1px'}}>
                                                    <div className="kt-portlet__head-label">
                                                        <nav className="file-nav navbar navbar-top">
                                                            <span className='top-nav-active padding-b11 margin-t5' >
                                                                Users
                                                            </span>
                                                        </nav>
                                                    </div>
                                                    <div className="kt-portlet__head-toolbar">
                                                        <div className="kt-portlet__head-wrapper">
                                                            <div className="kt-portlet__head-actions">
                                                                <div className = "kt-portlet__head_subhead" >
                                                                    <span className="">
                                                                        <i className="fa fa-users"></i> Selected Users : <strong> {selectedUsers.length || 0}</strong>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="kt-portlet__body kt-portlet__body--fit">

                                                    {loading ? <Loading/> 
                                                    :(  <div>  
                                                            <div className='row align-items-center stickyFilter'>
                                                                <div className="col-xl-12 order-2 order-xl-1 px-0">
                                                                    <div className="row align-items-center">
                                                                        <div className='col-md-3 kt-margin-b-20-tablet-and-mobile'>
                                                                                
                                                                            <div className="kt-form__control">
                                                                                <select
                                                                                    id="statusFilter" 
                                                                                    value={currentStatus}  
                                                                                    className="form-control kt-selectpicker margin-b10"  
                                                                                    onChange={({ target }) => { setCurrentStatus(target.value) }}
                                                                                >
                                                                                    <option value='All'>All</option>
                                                                                    <option value='Golf Membership'>Golf Members</option>
                                                                                    <option value='Club Membership'>Club Members</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-4 kt-margin-b-20-tablet-and-mobile'>
                                                                            <div className="kt-input-icon kt-input-icon--left">
                                                                                <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Search Users..."
                                                                                id="generalSearch"
                                                                                onChange={({target}) => setSearchTerm( target.value)}>
                                                                                </input>
                                                                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                                                    <span>
                                                                                        <i className="la la-search"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div 
                                                                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable margin-b0"
                                                                onScroll={helper.setStickyHeader} >
                                                                <table className="kt-datatable__table table-striped">
                                                                    <tbody className="kt-datatable__body" style= {{ display: 'block' }}>
                                                                        {filteredUsers.map( (a, i) =>{
                                                                            return (
                                                                                <tr className="kt-datatable__row block-row" key={i} >
                                                                                    <td width="70%" title='' className="kt-datatable__cell text-clip">
                                                                                        <div className="kt-user-card-v2 text-clip">
                                                                                            <div className="kt-user-card-v2__pic">
                                                                                                <div className={`kt-badge kt-badge--xl kt-badge--${a.Category == "Organization" ? 'success' : 'info' }`}>                                                                                 
                                                                                                    {a.FirstName.slice(0,1)}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="kt-user-card-v2__details text-clip">
                                                                                                <span className="kt-user-card-v2__name text-clip"> 
                                                                                                    {`${a.FirstName} ${a.MiddleName} ${a.LastName}`}
                                                                                                </span>
                                                                                                <span className="kt-user-card-v2__desc text-clip"> 
                                                                                                    {a.Email.toLowerCase()}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className ="kt-datatable__cell text-clip text-center" width="15%">
                                                                                        <label className ="kt-checkbox kt-checkbox--brand">
                                                                                            <input 
                                                                                                type="checkbox" 
                                                                                                id="chkActive" 
                                                                                                name="chkActive" 
                                                                                                readOnly 
                                                                                                checked={(selectedUsers && selectedUsers.find((m) => m.ID === a.ID ? 'checked' : '')) || ''}
                                                                                                onClick={() => handleUserCheckbox(a)}
                                                                                            />&nbsp;<span></span>
                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>   
                                                        </div>    
                                                    )}   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)} 
                            </div>
                            {documents && documents.length > 0 && 
                            (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="kt-notification margin-b30">
                                            {
                                            documents.map((d, i) =>
                                                <a key={i} className="kt-notification__item">
                                                <div className="kt-notification__item-icon margin-r10">
                                                    <img height="30px" width="30px" 
                                                        src={helper.getFileLogo(d.FileName)} 
                                                    />
                                                </div>
                                                <div className="kt-notification__item-details text-clip">
                                                    <div className="kt-notification__item-title kt-font-bold text-clip">
                                                        {d.Name}
                                                    </div>
                                                </div>
                                                <button 
                                                    type="button"                    
                                                    onClick={() => removeDocumentHandler(i)}
                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                </button>
                                                </a>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}   
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => setAddDocument(true)}
                                type="button"
                                className="btn btn-label-brand margin-0 margin-r5"
                                // disabled={btnLoading} 
                                >                 
                                <i className="flaticon-doc"></i> 
                                Attachment
                              </button>
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">   
                                Close
                            </button>
                            <button
                                id="save-btn1"
                                type="button"
                                className="btn btn-brand margin-0"
                                onClick={sendEmailHandler}
                            >   
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showOtpModal && (
                <OtpModal
                    onDismissModal = {() => setshowOtpModal(false)}
                    emailData = {emailData}
                    documents = {documents}
                    from = '#sendEmail-modal'

                />
            )}  
            {addDocument && (
                <AttachmentModal
                    onDismissModal = {() => setAddDocument(false)}
                    setDocumentHandler={setDocumentHandler}
                    from = '#sendEmail-modal'

                />
            )}
             
        </>
    )
}

export default SendEmailModal;
