import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getSubscriptionMasterAll, postSubscriptionMaster } from "../api/paymentAPI";
import Select from 'react-select';
import subscriptionImg from '../../assets/images/subscription_img.png'
import subscriptionImgClub from '../../assets/images/subscription_img_club.png'

const SubscriptionMaster = (props) => {
    const [subscriptionMaster,     setSubscriptionMaster]      = useState({Active: 'Y'});
    const [subscriptionMasterList, setSubscriptionMasterList]  = useState([]);
    const [isInit,                 setIsInit]                  = useState(true);
    const [membershipTypeOption,   setMembershipTypeOption]    = useState([{ value:  'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);
    const [rightSideDetails,       setRightSideDetails]        = useState(false);

    useEffect(() => {
        props.title("Subscription Master", "subscription-master")
        fetchSubscriptionMaster();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setSubscriptionMaster({Active: 'Y'});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [subscriptionMasterList]);

    const fetchSubscriptionMaster = async() =>{
        try {
            const master = await getSubscriptionMasterAll()
            isInit ? setIsInit(false) : $('#tblSubscriptionMaster').KTDatatable().destroy(); 
            setSubscriptionMasterList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    function fillDataTable() {

        const dataTable = $('#tblSubscriptionMaster').KTDatatable({
            data: {
                saveState: false,
                source: subscriptionMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2">
                                ${`<img src="${row.MembershipType == 'Golf Membership' ? subscriptionImg : subscriptionImgClub}" alt="user-profile" height="40" width="40">`}
                            </div>
                            <div class="kt-user-card-v2__details text-clip ml-3">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Code} </span>
                                </div>
                           </div>`
                    }
                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSubscriptionMaster .kt-datatable__table').addClass('overflow-x-auto');


        dataTable.on('click', 'tr', function () {

            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSubscriptionMaster(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    };

    const updateSubscriptionDetails = (field, value) => {   
        setSubscriptionMaster((d) => {
            return { ...d, [field]: value };
        });
    };

    const addSubscriptionHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-subscriptionMaster-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtCode: {
                    required: true,
                },
                ddlMembershipType: {
                    required: true
                }                                     
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtCode: {
                    required: "Please enter code"
                },
                ddlMembershipType: {
                    required: "Please select membership type"
                }                          
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
                      
            await postSubscriptionMaster(subscriptionMaster);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchSubscriptionMaster();
            if (subscriptionMaster.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Master updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Master added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {          
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Master</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSubscriptionMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Subscription Master Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-subscriptionMaster-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={subscriptionMaster.Name || ''}
                                                            onChange={(event) => updateSubscriptionDetails('Name', event.target.value)}   
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Code <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtCode" 
                                                            name="txtCode" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={subscriptionMaster.Code || ''}
                                                            onChange={(event) => updateSubscriptionDetails('Code', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> MembershipType <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <Select 
                                                            id="ddlMembershipType" 
                                                            name="ddlMembershipType"
                                                            className="multi-custom"
                                                            placeholder="Select Membership Type"
                                                            value={subscriptionMaster && membershipTypeOption.find((m) => m.value == subscriptionMaster.MembershipType) || ''}
                                                            onChange={(event) => updateSubscriptionDetails('MembershipType', event.value)}
                                                            options={membershipTypeOption}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Description</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtDescription" 
                                                            name="txtDescription" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={subscriptionMaster.Description || ''}
                                                            onChange={(event) => updateSubscriptionDetails('Description', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox"
                                                                    defaultChecked={subscriptionMaster.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateSubscriptionDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block"  onClick={addSubscriptionHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SubscriptionMaster;