


export const getRoundCase = (days, round) => {
    if (round == 1 && days == 'Single') 
       return 1;
    else if (round > 1 && days == 'Single') 
       return 2;
    else if (round == 1 && days == 'Multiple') 
       return 3;    
    else if (round > 1 && days == 'Multiple') 
       return 4;
    else
        return 0    
}

export function getDates(startDate, endDate) {
    let startString = moment(startDate).format("DD/MM/YYYY");
    let [sday, smonth, syear] = startString.split('/');

    let endString = moment(endDate).format("DD/MM/YYYY");
    let [eday, emonth, eyear] = endString.split('/')

    var start = moment(new Date(+syear, +smonth - 1, +sday));
    var end   = moment(new Date(+eyear, +emonth - 1, +eday));
    var count = end.diff(start, "days");
    var temp  = start.clone();

    var dates = [ { label : temp.format('DD-MM-YYYY'), value: temp.format('YYYY-MM-DD') } ];
    for (var i = 1; i <= count; i++) {
        var item = temp.add(1, 'd');
        dates.push({ label : item.format('DD-MM-YYYY'), value: item.format('YYYY-MM-DD') });
    }

    return dates;
}

export const getFormatValue=(format)=>{
    let val;
    
    switch (format) { 
        case 'Foursomes':
            val = {
                label1: 'Higher', label2: 'Lower',
                higher:50 , lower:50, middle:0, lowerMiddle:0, minDrive:0, isMin: false
            }
            break;           
        case 'Scramble - Stroke Play Net':
            val = {
                label4: 'Higher', label3: 'Middle', label2: 'Lower Middle', label1: 'Lower',
                higher:50, middle:50, lowerMiddle:50, lower:50, minDrive:0, isMin: false
            }
            break;           
        case 'Greensome':
            val = {
                label1: 'Higher', label2: 'Lower',
                higher:40 , lower:60, middle:0, lowerMiddle:0, minDrive:9, isMin: true
            }
            break;
        case 'Scramble':
            val = {
                label1: 'Higher', label2: 'Lower',
                higher:15 , lower:35, middle:0, lowerMiddle:0, minDrive:9, isMin: true
            }
            break;                
        case 'Cumulative Stableford':
        case 'Fourball Betterball':
        case 'Betterball Bogey':
        case 'Net Stableford':
            val = {
                label1: 'Male', label2: 'Female',
                higher:100 , lower:100, middle:0, lowerMiddle:0, minDrive:0, isMin: false
            }
            break;       
    }

    return val;
}

export const getStrokeExtraShot = (strokeIndex, playerHandicap) => {
    let extraShot = 0;

    if (playerHandicap < 0) { //HANDICAP in -ve
        let diff = (18 + playerHandicap)
        if (strokeIndex > diff) extraShot = 1
    } else {
        if (playerHandicap > 18) {
            let diff = playerHandicap - 18;
            extraShot = ((strokeIndex <= diff) ? 2 : 1)
        } else if (playerHandicap >= strokeIndex) {
            extraShot = 1
        }
    }

    return (extraShot)
}

export const getPlayerScore =(score, handicap, stroke, par)=> {
    if (score == '' || score == undefined || score == 0) return { grossScore: 0, netScore: 0, IsScratch: false };

    let grossScore = 0, netScore = 0;
    let IsScratch  = false;     

    const extraShot = getStrokeExtraShot(stroke, handicap);
    
    if (score == "X") {
        IsScratch = true;
        grossScore = (extraShot == 0 && handicap < 0) ? (par + 2) : (par + 2 + extraShot);
    } else {
        grossScore = parseInt(score);
    }

    // if (!IsScratch) {
        if (handicap < 0) {
            netScore = (stroke > (18 + handicap)) ? (grossScore + extraShot) : grossScore;
        } else {
            netScore = stroke <= handicap ? (grossScore - extraShot) : grossScore;
        }
    // } else {
    //     netScore = grossScore;
    // }

    return { grossScore, netScore, IsScratch };
}

export function getAdjustedHandicap(higher, lower, handicap1, handicap2) {
    let handicap = 0.0;

    if (handicap1 > handicap2) {
        handicap = Math.round(getPercentage(handicap1, higher) + getPercentage(handicap2, lower));
    } else {
        handicap = Math.round(getPercentage(handicap1, lower) + getPercentage(handicap2, higher));
    }

    return handicap;
}

export function getPercentage(value, percentage) {
    return (value * percentage) / 100.0;
}

export const getAdjustedHandicap4Player = (higher, middle, lowerMiddle, lower, handicap1, handicap2, handicap3, handicap4) => {
    const allHandicap = [handicap1, handicap2, handicap3, handicap4];
    const sortedHandicap = allHandicap.sort((a, b) => b - a);
    const cHandicap1 = getPercentage(sortedHandicap[0], higher)
    const cHandicap2 = getPercentage(sortedHandicap[1], middle)
    const cHandicap3 = getPercentage(sortedHandicap[2], lowerMiddle)
    const cHandicap4 = getPercentage(sortedHandicap[3], lower)

    return (cHandicap1 + cHandicap2 + cHandicap3 + cHandicap4).toFixed(1)
}