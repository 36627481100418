import React, { useState, useEffect } from 'react'
import { getCheckInReportByMasterID, getCheckInReportByType, getSportReportByType } from '../api/dashboardAPI';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import ReactApexChart from "react-apexcharts";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DashBoardHeader from './DashBoardHeader';
import { getFeedbackAll } from '../api/golfClubAPI';
import FeedBackModal from './FeedBackModal';
const moment = extendMoment(Moment);

const Dashboard = (props) => {

    const [loading,                             setLoading]                             = useState(false);
    const [feedBackLoading,                     setFeedBackLoading]                     = useState(false);

    const [bookingCount,                        setBookingCount]                        = useState({TotalCount: 0, PresentCount: 0, NoShowCount: 0, TotalCancelCount: 0});
    const [prevBookingCount,                    setPrevBookingCount]                    = useState({TotalCount: 0, PresentCount: 0, NoShowCount: 0, TotalCancelCount: 0});

    const [checkInCount,                        setCheckInCount]                        = useState({TotalCount: 0});
    const [prevCheckInCount,                    setPrevCheckInCount]                    = useState({TotalCount: 0});
    const [checkInAverage,                      setCheckInAverage]                      = useState(0);
    const [totalCheckInAvg,                     setTotalCheckInAvg]                     = useState(null);
    const [totalCheckIn,                        setTotalCheckIn]                        = useState([]);
    const [prevTotalCheckIn,                    setPrevTotalCheckIn]                    = useState([]);
    const [totalCheckInChartData,               setTotalCheckInChartData]               = useState(null);
    const [prevTotalCheckInChartData,           setPrevTotalCheckInChartData]           = useState(null);
    const [checkInData,                         setCheckInData]                         = useState(null);
    const [prevCheckInData,                     setPrevCheckInData]                     = useState(null);
    const [totalCheckInChart,                   setTotalCheckInChart]                   = useState(null);
    const [facilityChart,                       setFacilityChart]                       = useState(null);
    const [facilityData,                        setFacilityData]                        = useState([]);
    const [facilityChartData,                   setFacilityChartData]                   = useState(null);

    // const [maxCheckIn,                          setMaxCheckIn]                          = useState([]);
    // const [prevMaxCheckIn,                      setPrevMaxCheckIn]                      = useState([]);
    // const [maxCheckInCount,                     setMaxCheckInCount]                     = useState(0);
    // const [prevMaxCheckInCount,                 setPrevMaxCheckInCount]                 = useState(0);
                    
    const [totalCountAverage,                   setTotalCountAverage]                   = useState(0);
    const [totalCountChart,                     setTotalCountChart]                     = useState(null);
    const [totalCountChartData,                 setTotalCountChartData]                 = useState(null);
    const [prevTotalCountChartData,             setPrevTotalCountChartData]             = useState(null);
    const [totalCountAvg,                       setTotalCountAvg]                       = useState(null);
    const [totalBookings,                       setTotalBookings]                       = useState([]);
    const [prevTotalBookings,                   setPrevTotalBookings]                   = useState([]);
                
    const [presentAverage,                      setPresentAverage]                      = useState(0);
    const [presentAvg,                          setPresentAvg]                          = useState(null);
    const [presentChart,                        setPresentChart]                        = useState(null);
    const [presentChartData,                    setPresentChartData]                    = useState(null);
    const [prevPresentChartData,                setPrevPresentChartData]                = useState(null);
    const [presentBookings,                     setPresentBookings]                     = useState([]);
    const [prevPresentBookings,                 setPrevPresentBookings]                 = useState([]);

    const [noShowAverage,                       setNoShowAverage]                       = useState(0);
    const [noShowAvg,                           setNoShowAvg]                           = useState(null);
    const [noShowChart,                         setNoShowChart]                         = useState(null);
    const [noShowChartData,                     setNoShowChartData]                     = useState(null);
    const [prevNoShowChartData,                 setPrevNoShowChartData]                 = useState(null);
    const [noShowBookings,                      setNoShowBookings]                      = useState([]);
    const [prevNoShowBookings,                  setPrevNoShowBookings]                  = useState([]);

    const [totalCancelCountAverage,             setTotalCancelCountAverage]             = useState(0);
    const [totalCancelCountChart,               setTotalCancelCountChart]               = useState(null);
    const [totalCancelCountChartData,           setTotalCancelCountChartData]           = useState(null);
    const [prevTotalCancelCountChartData,       setPrevTotalCancelCountChartData]       = useState(null);
    const [totalCancelCountAvg,                 setTotalCancelCountAvg]                 = useState(null);
    const [totalCancelBookings,                 setTotalCancelBookings]                 = useState([]);
    const [prevTotalCancelBookings,             setPrevTotalCancelBookings]             = useState([]);

    const [bookingChart,                        setBookingChart]                        = useState(null);
    const [defaultBookingChart,                 setDefaultBookingChart]                 = useState(null);
    const [bookingLength,                       setBookingLength]                       = useState(null);
    const [bookingData,                         setBookingData]                         = useState(null);
    const [prevBookingData,                     setPrevBookingData]                     = useState(null);
    const [chartlength,                         setChartLength]                         = useState(null);
    const [currentDate,                         setCurrentDate]                         = useState(null);

    const [sportChartData,                      setSportChartData]                      = useState(null);
    const [defaultSportChartData,               setDefaultSportChartData]               = useState(null);
    const [sportData,                           setSportData]                           = useState([]);
    const [feedBackList,                        setFeedBackList]                        = useState([]);
    const [selectedFeedBack,                    setSelectedFeedBack]                    = useState(null);
    
    useEffect(() => {
        props.title("Dashboard", "dashboard");

        setDefaultBookingChart({
            series: [{
                name: 'Previous Month',  
                data:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }, {
                name: 'Current Month',
                data:  [0]                          
            }],
            options: {
                colors: ['#d2ebfe', '#d3faed'],      
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                },
                legend: {
                    show: true,
                    height:40,
                    markers: {
                        fillColors: ['#066cb9', '#00e396'],
                    },
                },
                markers: {
                    colors: ['#066cb9', '#00e396'],
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#066cb9', '#00e396'],
                    dashArray: [3, 0],
                    width: 2
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        rotate: 0,
                    },
                    tickAmount: 5,
                },       
            },
        })
        fetchFeedBack();
    }, []);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    });

    useEffect(() => {
        if (sportData && sportData.length == 0) {
            setDefaultSportChartData({
                series: [1],
                options: {
                    labels: ['No Bookings'],
                    colors: ['#7fbdff'],
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 40,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken'
                            }
                        }
                    }
                },
            });
        } else if (sportData && sportData.length > 0) {
            setSportChartData({
                series: sportData.map(p => p.Occurrence),
                options: {
                    labels: sportData.map(p => `${p.SportName} ${p.Percentage}%`),
                    colors: ['#00e396', '#008ffb', '#feb019', '#7fbdff', '#ff4560', '#775dd0',
                    '#9c5b39',
                    '#4e7d92',
                    '#a34f9e',
                    '#3d8251',
                    '#d6a158',
                    '#6e477d',
                    '#93784f',
                    '#5c748e',
                    '#ad5f3c',],    
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            background: 'transparent',
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 50,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken',
                                value: 0.5
                            }
                        }
                    },
                }
            })
        }
            
    }, [sportData])

    useEffect(()=> {
        if(props.fetchSportReportInHeader){
            if (props.selectedType == 0) {
                fetchSportReport(props.sportType);
                props.setCustomReport(false)
            } 
            // else {
            //     fetchCheckInReport(props.sportType)
            //     props.setCustomCheckInReport(false)
                
            // }
        }
        if (props.fetchCheckInReportInHeader) {
            if (props.selectedType == 1) {
                fetchCheckInReport(props.sportType)
                props.setCustomCheckInReport(false)
            }
        }
    }, [props.fetchSportReportInHeader, props.selectedType, props.fetchCheckInReportInHeader])

    useEffect(() => {
        if(props.sportType && props.sportsList && props.sportsList.length > 0) {
            if (props.selectedType == 0) {
                fetchSportReport(props.sportType);
            } else {
                fetchCheckInReport(props.sportType)
            }
        }
    }, [props.sportType, props.selectedType])

    useEffect(() => {
        if (props.currentPeriod.id != 6) {
            if (props.selectedType == 0) {
                fetchSportReport(props.sportType);
            } else {
                fetchCheckInReport(props.sportType)
            }
        }
    }, [props.fromDate, props.toDate, props.selectedType]);

    useEffect(() => {
        if (totalCheckIn) {
            const {chunks, booking} = getBookingCount(totalCheckIn, 'current', 'totalBooking')
            setTotalCheckInChartData(chunks)
            setCheckInData(booking)
            //Get current month dates
            const currentMonthDates = Array.from({length: moment().daysInMonth()}, (x, i) => moment().startOf('month').add(i, 'days'));
            setCurrentDate(currentMonthDates.map(c => c.format('DD-MMM')));
        }
    }, [totalCheckIn])

    useEffect(() => {
        if (prevTotalCheckIn) {
            const {chunks, booking} = getBookingCount(prevTotalCheckIn, 'previous', 'totalBooking')
            setPrevTotalCheckInChartData(chunks)
            setPrevCheckInData(booking);
        }
    }, [prevTotalCheckIn])

    useEffect(() => {
        if ((checkInCount || checkInCount.TotalCount == 0) && (prevCheckInCount || prevCheckInCount.TotalCount == 0)) {
            startCounter('totalCheckIn', checkInCount.TotalCount);
            if (checkInCount.TotalCount == prevCheckInCount.TotalCount) {
                setCheckInAverage(0);
            }else if(checkInCount.TotalCount == 0){
                setCheckInAverage(-100);
            }else if(prevCheckInCount.TotalCount == 0){
                setCheckInAverage(100);
            }else{
                const cTotalCountAverage = ((checkInCount.TotalCount / prevCheckInCount.TotalCount) * 100) - 100;
                setCheckInAverage(Math.ceil(cTotalCountAverage))
            }          
        }
    }, [checkInCount, prevCheckInCount])

    useEffect(() => {
        if (totalCheckInChartData && prevTotalCheckInChartData) {
            let avg = []
            for (let i = 0; i < totalCheckInChartData.length; i++) {
                if (i == 0) {
                    avg.push(totalCheckInChartData[i] - prevTotalCheckInChartData[i])
                } else {
                    const sum1 = totalCheckInChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevTotalCheckInChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            totalCheckInChartData.length == 1 ? setTotalCheckInAvg([prevTotalCheckInChartData[0], totalCheckInChartData[0]]) : setTotalCheckInAvg(data)
        }

    }, [totalCheckInChartData, prevTotalCheckInChartData])

    useEffect(() => {
        if (totalCheckInAvg && (checkInAverage || checkInAverage == 0)) {
            setTotalCheckInChart({
                series: [{
                    data: totalCheckInAvg,
                }],
                options: {
                    colors: [checkInAverage > 0 ? '#00e396' : checkInAverage >= -10 ? '#1f89e5' : checkInAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [checkInAverage, totalCheckInAvg])

    useEffect(() => {
        const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
        if ((prevCheckInData && prevCheckInData.length > 0) && (checkInData && checkInData.length > 0)) {
            setFacilityChart({
                series: [{
                        name: props.currentPeriod.id == 3 ? moment(props.fromDate).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(props.fromDate).format('DD MMM YYYY')} To ${moment(props.toDate).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Current Year' :  props.currentPeriod.id == 9 ? moment(props.fromDate).format('YYYY') : 'Current Month',
                        data:  checkInData.map(i => i.Count)   
                    },
                    {
                        name: props.currentPeriod.id == 3 ? moment(prev.prevFrom).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(prev.prevFrom).format('DD MMM YYYY')} To ${moment(prev.prevTo).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Previous Year' :  props.currentPeriod.id == 9 ? moment(prev.prevFrom).format('YYYY') : 'Previous Month',  
                        data:  prevCheckInData.map(i => i.Count)
                    }],
                options: {
                    colors: ['#d3faed','#d2ebfe'],      
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    tooltip: {
                        enabled: true,
                        fillSeriesColor: true,
                        x: {
                            formatter: (value, { seriesIndex, dataPointIndex, w}) => {
                                let date = [];
                                if (seriesIndex == 0) {
                                    if (w.globals.initialSeries[0].data[dataPointIndex] === undefined) {
                                        const previousDate = prevCheckInData.map(i => moment(i.CreatedDate).format('DD-MMM'))
                                        date = previousDate[dataPointIndex]
                                    } else {
                                        date = w.globals.categoryLabels[dataPointIndex]
                                    }
                                } else {
                                    const previousDate = (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? prevCheckInData.map(i => moment(i.CreatedDate).format('DD-MMM')) : props.currentPeriod.id == 6 ?  prevCheckInData.map(i => moment(i.CreatedDate).format('DD-MM-YYYY')) : prevCheckInData.map(i => moment(i.CreatedDate).format('DD-MMM'))
                                    date = previousDate[dataPointIndex]
                                }
                                return date
                            }
                        },
                        y: {
                            title: {
                                formatter: () => {
                                    return 'CheckIn:'
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        height:40,
                        markers: {
                            fillColors: ['#00e396', '#066cb9'],
                        },
                    },
                    markers: {
                        colors: ['#00e396', '#066cb9'],
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: ['#00e396', '#066cb9'],
                        dashArray: [0, 3],
                        width: 2
                    },
                    xaxis: {
                        type: 'category',
                        labels: {
                            rotate: 0,
                        },
                        crosshairs: {
                            show: true,
                            width: 1,
                            position: 'back',
                            opacity: 0.9, 
                            stroke: {
                                color: '#b6b6b6',
                                width: 1,
                                dashArray: 0,
                            },   
                        },  
                        categories: props.currentPeriod.id == 1 ? currentDate : (props.currentPeriod.id == 3 || props.currentPeriod.id == 5) ? checkInData.map(i => moment(i.CreatedDate).format('DD-MMM')) : checkInData.map(i => i.CreatedDate),
                        tickAmount: 5,
                        max: (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? 31 : props.currentPeriod.id == 5 ? 93 : chartlength > 93 ? 93 : chartlength
                    },       
                },
            })
        }
    }, [prevCheckInData, checkInData]);

    useEffect(() => {
        if (facilityData && facilityData.length == 0) {
            setDefaultSportChartData({
                series: [1],
                options: {
                    labels: ['No Facilities'],
                    colors: ['#7fbdff'],
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 40,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken'
                            }
                        }
                    }
                },
            });
        } else if (facilityData && facilityData.length > 0) {
            setFacilityChartData({
                series: facilityData.map(p => p.Occurrence),
                options: {
                    labels: facilityData.map(p => `${p.Facility} <b>${p.Occurrence}</b>`),
                    colors: ['#00e396', '#008ffb', '#feb019', '#7fbdff', '#ff4560', '#775dd0',
                    '#9c5b39',
                    '#4e7d92',
                    '#a34f9e',
                    '#3d8251',
                    '#d6a158',
                    '#6e477d',
                    '#93784f',
                    '#5c748e',
                    '#ad5f3c',],    
                    plotOptions: {
                        pie: {
                            expandOnClick: true,
                            background: 'transparent',
                            donut: {
                                size: '50%',
                            },
                            customScale: 1
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        height: 50,
                        itemMargin: {
                            horizontal: 7,
                            vertical: 0
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '100px',
                        }
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'darken',
                                value: 0.5
                            }
                        }
                    },
                }
            })
        }
            
    }, [facilityData])

    useEffect(() => {
        if (totalBookings) {
            const {chunks, booking} = getBookingCount(totalBookings, 'current', 'totalBooking')
            setTotalCountChartData(chunks)
            setBookingData(booking)
             //Get current month dates
             const currentMonthDates = Array.from({length: moment().daysInMonth()}, (x, i) => moment().startOf('month').add(i, 'days'));
             setCurrentDate(currentMonthDates.map(c => c.format('DD-MMM')));
        }
    }, [totalBookings])

    useEffect(() => {
        if (totalCancelBookings) {
            const {chunks} = getBookingCount(totalCancelBookings, 'current', 'otherBooking')
            setTotalCancelCountChartData(chunks)
        }
    }, [totalCancelBookings])

    useEffect(() => {
        if (noShowBookings) {
            const {chunks} = getBookingCount(noShowBookings, 'current', 'otherBooking')
            setNoShowChartData(chunks)
        }
    }, [noShowBookings])

    useEffect(() => {
        if (presentBookings) {
            const {chunks} = getBookingCount(presentBookings, 'current', 'otherBooking')
            setPresentChartData(chunks)
        }
    }, [presentBookings])

    useEffect(() => {
        if (prevTotalCancelBookings) {
            const {chunks} = getBookingCount(prevTotalCancelBookings, 'previous', 'otherBooking')
            setPrevTotalCancelCountChartData(chunks)
        }
    }, [prevTotalCancelBookings])

    useEffect(() => {
        if (prevTotalBookings) {
            const {chunks, booking} = getBookingCount(prevTotalBookings, 'previous', 'totalBooking')
            setPrevTotalCountChartData(chunks)
            setPrevBookingData(booking);
        }
    }, [prevTotalBookings])

    useEffect(() => {
        if (prevPresentBookings) {
            const {chunks} = getBookingCount(prevPresentBookings, 'previous', 'otherBooking')
            setPrevPresentChartData(chunks)
        }
    }, [prevPresentBookings])

    useEffect(() => {
        if (prevNoShowBookings) {
            const {chunks} = getBookingCount(prevNoShowBookings, 'previous', 'otherBooking')
            setPrevNoShowChartData(chunks)
        }
    }, [prevNoShowBookings])

    useEffect(() => {
        if ((bookingCount || bookingCount.TotalCancelCount == 0) && (prevBookingCount || prevBookingCount.TotalCancelCount == 0)) {
            startCounter('cancelCount', bookingCount.TotalCancelCount);
            if (bookingCount.TotalCancelCount == prevBookingCount.TotalCancelCount) {
                setTotalCancelCountAverage(0);
            }else if(bookingCount.TotalCancelCount == 0){
                setTotalCancelCountAverage(-100);
            }else if(prevBookingCount.TotalCancelCount == 0){
                setTotalCancelCountAverage(100);
            }else{
                const cTotalCancelCountAverage = ((bookingCount.TotalCancelCount / prevBookingCount.TotalCancelCount) * 100) - 100;
                setTotalCancelCountAverage(Math.ceil(cTotalCancelCountAverage))
            }          
        }
        if ((bookingCount || bookingCount.TotalCount == 0) && (prevBookingCount || prevBookingCount.TotalCount == 0)) {
            startCounter('totalCount', bookingCount.TotalCount);
            if (bookingCount.TotalCount == prevBookingCount.TotalCount) {
                setTotalCountAverage(0);
            }else if(bookingCount.TotalCount == 0){
                setTotalCountAverage(-100);
            }else if(prevBookingCount.TotalCount == 0){
                setTotalCountAverage(100);
            }else{
                const cTotalCountAverage = ((bookingCount.TotalCount / prevBookingCount.TotalCount) * 100) - 100;
                setTotalCountAverage(Math.ceil(cTotalCountAverage))
            }          
        }
        if ((bookingCount || bookingCount.PresentCount == 0) && (prevBookingCount || prevBookingCount.PresentCount == 0)) {
            startCounter('presentCount', bookingCount.PresentCount);
            if (bookingCount.PresentCount == prevBookingCount.PresentCount) {
                setPresentAverage(0);
            }else if(bookingCount.PresentCount == 0){
                setPresentAverage(-100);
            }else if(prevBookingCount.PresentCount == 0){
                setPresentAverage(100);
            }else{
                const cPresentAverage = ((bookingCount.PresentCount / prevBookingCount.PresentCount) * 100) - 100;
                setPresentAverage(Math.ceil(cPresentAverage))
            }          
        }
        if ((bookingCount || bookingCount.NoShowCount == 0) && (prevBookingCount || prevBookingCount.NoShowCount == 0)) {
            startCounter('noShowCount', bookingCount.NoShowCount);
            if (bookingCount.NoShowCount == prevBookingCount.NoShowCount) {
                setNoShowAverage(0);
            }else if(bookingCount.NoShowCount == 0){
                setNoShowAverage(-100);
            }else if(prevBookingCount.NoShowCount == 0){
                setNoShowAverage(100);
            }else{
                const cNoShowAverage = ((bookingCount.NoShowCount / prevBookingCount.NoShowCount) * 100) - 100;
                setNoShowAverage(Math.ceil(cNoShowAverage))
            }          
        }
    }, [bookingCount, prevBookingCount]);  

    useEffect(() => {
        if (presentChartData && prevPresentChartData) {
            let avg = []
            for (let i = 0; i < presentChartData.length; i++) {
                if (i == 0) {
                    avg.push(presentChartData[i] - prevPresentChartData[i])
                } else {
                    const sum1 = presentChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevPresentChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            presentChartData.length == 1 ? setPresentAvg([prevPresentChartData[0], presentChartData[0]]) : setPresentAvg(data)
        }

    }, [presentChartData, prevPresentChartData])

    useEffect(() => {
        if (noShowChartData && prevNoShowChartData) {
            let avg = []
            for (let i = 0; i < noShowChartData.length; i++) {
                if (i == 0) {
                    avg.push(noShowChartData[i] - prevNoShowChartData[i])
                } else {
                    const sum1 = noShowChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevNoShowChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            noShowChartData.length == 1 ? setTotalCountAvg([prevNoShowChartData[0], noShowChartData[0]]) : setNoShowAvg(data)
        }

    }, [noShowChartData, prevNoShowChartData])

    useEffect(() => {
        if (totalCountChartData && prevTotalCountChartData) {
            let avg = []
            for (let i = 0; i < totalCountChartData.length; i++) {
                if (i == 0) {
                    avg.push(totalCountChartData[i] - prevTotalCountChartData[i])
                } else {
                    const sum1 = totalCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevTotalCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            totalCountChartData.length == 1 ? setTotalCountAvg([prevTotalCountChartData[0], totalCountChartData[0]]) : setTotalCountAvg(data)
        }

    }, [totalCountChartData, prevTotalCountChartData])

    useEffect(() => {
        if (totalCancelCountChartData && prevTotalCancelCountChartData) {
            let avg = []
            for (let i = 0; i < totalCancelCountChartData.length; i++) {
                if (i == 0) {
                    avg.push(totalCancelCountChartData[i] - prevTotalCancelCountChartData[i])
                } else {
                    const sum1 = totalCancelCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    const sum2 = prevTotalCancelCountChartData.slice(0, i + 1).reduce((curr, prev) => curr + prev, 0)
                    avg.push(sum1 - sum2)
                } 
            }
            const diff = Math.min(...avg);
            const data = diff < 0 ? avg.map(a => a - (diff * 2)) : avg;
            totalCancelCountChartData.length == 1 ? setTotalCancelCountAvg([prevTotalCancelCountChartData[0], totalCancelCountChartData[0]]) : setTotalCancelCountAvg(data)
        }

    }, [totalCancelCountChartData, prevTotalCancelCountChartData])

    useEffect(() => {
        if (presentAvg && (presentAverage || presentAverage == 0)) {
            setPresentChart({
                series: [{
                    data: presentAvg,
                }],
                options: {
                    colors: [presentAverage > 0 ? '#00e396' : presentAverage >= -10 ? '#1f89e5' : presentAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [presentAverage, presentAvg])

    useEffect(() => {
        if (noShowAvg && (noShowAverage || noShowAverage == 0)) {
            setNoShowChart({
                series: [{
                    data: noShowAvg,
                }],
                options: {
                    colors: [noShowAverage > 0 ? '#00e396' : noShowAverage >= -10 ? '#1f89e5' : noShowAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [noShowAverage, noShowAvg])

    useEffect(() => {
        if (totalCountAvg && (totalCountAverage || totalCountAverage == 0)) {
            setTotalCountChart({
                series: [{
                    data: totalCountAvg,
                }],
                options: {
                    colors: [totalCountAverage > 0 ? '#00e396' : totalCountAverage >= -10 ? '#1f89e5' : totalCountAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [totalCountAverage, totalCountAvg])

    useEffect(() => {
        if (totalCancelCountAvg && (totalCancelCountAverage || totalCancelCountAverage == 0)) {
            setTotalCancelCountChart({
                series: [{
                    data: totalCancelCountAvg,
                }],
                options: {
                    colors: [totalCancelCountAverage > 0 ? '#00e396' : totalCancelCountAverage >= -10 ? '#1f89e5' : totalCancelCountAverage >= -20 ? '#feb019' : '#ff4560' ],
                    tooltip: {
                        enabled: false,
                    },
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        height:40,
                        width: '100px'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 0.8
                    },
                    grid: {
                        show: false
                    },
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false,
                        },
                    },
                }
            });
        }
    }, [totalCancelCountAverage, totalCancelCountAvg])

    useEffect(() => {
        const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
        if ((prevBookingData && prevBookingData.length > 0) && (bookingData && bookingData.length > 0)) {
            setBookingChart({
                series: [{
                        name: props.currentPeriod.id == 3 ? moment(props.fromDate).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(props.fromDate).format('DD MMM YYYY')} To ${moment(props.toDate).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Current Year' :  props.currentPeriod.id == 9 ? moment(props.fromDate).format('YYYY') : 'Current Month',
                        data:  bookingData.map(i => i.Count)   
                    },
                    {
                        name: props.currentPeriod.id == 3 ? moment(prev.prevFrom).format('MMMM') : (props.currentPeriod.id == 5 || props.currentPeriod.id == 6) ? `${moment(prev.prevFrom).format('DD MMM YYYY')} To ${moment(prev.prevTo).format('DD MMM YYYY')}` : props.currentPeriod.id == 7 ? 'Previous Year' :  props.currentPeriod.id == 9 ? moment(prev.prevFrom).format('YYYY') : 'Previous Month',  
                        data:  prevBookingData.map(i => i.Count)
                    }],
                options: {
                    colors: ['#d3faed','#d2ebfe'],      
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    tooltip: {
                        enabled: true,
                        fillSeriesColor: true,
                        x: {
                            formatter: (value, { seriesIndex, dataPointIndex, w}) => {
                                let date = [];
                                if (seriesIndex == 0) {
                                    if (w.globals.initialSeries[0].data[dataPointIndex] === undefined) {
                                        const previousDate = prevBookingData.map(i => moment(i.CreatedDate).format('DD-MMM'))
                                        date = previousDate[dataPointIndex]
                                    } else {
                                        date = w.globals.categoryLabels[dataPointIndex]
                                    }
                                } else {
                                    const previousDate = (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? prevBookingData.map(i => moment(i.CreatedDate).format('DD-MMM')) : props.currentPeriod.id == 6 ?  prevBookingData.map(i => moment(i.CreatedDate).format('DD-MM-YYYY')) : prevBookingData.map(i => moment(i.CreatedDate).format('DD-MMM'))
                                    date = previousDate[dataPointIndex]
                                }
                                return date
                            }
                        },
                        y: {
                            title: {
                                formatter: () => {
                                    return 'Bookings:'
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        height:40,
                        markers: {
                            fillColors: ['#00e396', '#066cb9'],
                        },
                    },
                    markers: {
                        colors: ['#00e396', '#066cb9'],
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: ['#00e396', '#066cb9'],
                        dashArray: [0, 3],
                        width: 2
                    },
                    xaxis: {
                        type: 'category',
                        labels: {
                            rotate: 0,
                        },
                        crosshairs: {
                            show: true,
                            width: 1,
                            position: 'back',
                            opacity: 0.9, 
                            stroke: {
                                color: '#b6b6b6',
                                width: 1,
                                dashArray: 0,
                            },   
                        },  
                        categories: props.currentPeriod.id == 1 ? currentDate : (props.currentPeriod.id == 3 || props.currentPeriod.id == 5) ? bookingData.map(i => moment(i.CreatedDate).format('DD-MMM')) : bookingData.map(i => i.CreatedDate),
                        tickAmount: 5,
                        max: (props.currentPeriod.id == 1 || props.currentPeriod.id == 3) ? 31 : props.currentPeriod.id == 5 ? 93 : chartlength > 93 ? 93 : chartlength
                    },       
                },
            })
        }
    }, [prevBookingData, bookingData]);

    // useEffect(() => {
    //     if (maxCheckIn && maxCheckIn.length > 0) {
    //         fetchPrevCheckInReport(maxCheckIn[0].CheckInMasterID)
    //     }
    // }, [maxCheckIn]);

    // useEffect(() => {
    //     console.log(prevMaxCheckIn)
    // }, [prevMaxCheckIn])

    const startCounter = (id, value) => {
        $(`#${id}`).prop('Counter', 0).animate({Counter: value.toString()}, {
            duration: 1500,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    }

    const getBookingCount = (bookingArray, month, from) => {
        //total booking chart start
        const sortedBooking = bookingArray.sort((a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate)).map(p => ({CreatedDate: p.CreatedDate}))
                    
        //adding same date booking count
        const countMap = countDates(sortedBooking);
        const sameDateBookingCount = convertToArray(countMap);

        //Getting dates
        let dates = []
        if (month == 'current') {
            const range = moment.range(moment(props.fromDate).format('YYYY-MM-DD'), props.currentPeriod.id == 1 ? moment().format('YYYY-MM-DD') : moment(props.toDate).format('YYYY-MM-DD'))
            dates = Array.from(range.by('days'));
        } else {
            const previousDate = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
            const range = moment.range(previousDate.prevFrom, previousDate.prevTo)
            dates = Array.from(range.by('days'));
        }

        //adding from-to date booking count
        const presentDayBookingCount = dates.map(d => {
            const matchDate = sameDateBookingCount.find(f => moment(f.CreatedDate).format('YYYY-MM-DD') === d.format('YYYY-MM-DD'))
            let count = 0;
            if (matchDate) count = matchDate.Count;
            return {Count: count, CreatedDate: d.format('YYYY-MM-DD')}
        });

        //Booking stats chart logic
        let booking = [];
        if (from == 'totalBooking') {
            //Getting how much size required to split
            const BookingLength = presentDayBookingCount.length
            let size = 1;
            if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9) {
                size = 4;
            } else if (props.currentPeriod.id == 6 && BookingLength > 93) {
                size = Math.ceil(BookingLength / 93);
            }
                if (props.currentPeriod.id == 7 || props.currentPeriod.id == 9 || props.currentPeriod.id == 6) {
                const chunk = [];
                //spliting array into small chunks
                for (let i = 0; i <= BookingLength; i+=size) {
                    chunk.push([...presentDayBookingCount].splice(i, size))
                }
                setChartLength(chunk.length);
                booking = chunk.filter(c => c.length != 0).map(p => {
                    let diff = false;
                    let yearDiff = false;
                    if (p.length > 1){
                        diff = moment(p[p.length-1].CreatedDate).format('MMM') == moment(p[0].CreatedDate).format('MMM');
                        yearDiff = moment(p[p.length-1].CreatedDate).format('YYYY') == moment(p[0].CreatedDate).format('YYYY');
                    }
                    return {
                        Count: p.map(c => c.Count).reduce((prev, curr) => prev + curr, 0),
                        CreatedDate:
                        p.length > 1 ? 
                        `${(props.currentPeriod.id == 6 && !yearDiff) ? moment(p[0].CreatedDate).format('DD MMM YYYY') : !diff ? moment(p[0].CreatedDate).format('DD MMM') : moment(p[0].CreatedDate).format('DD')}-${props.currentPeriod.id == 6 ?  moment(p[p.length-1].CreatedDate).format('DD MMM YYYY') : moment(p[p.length-1].CreatedDate).format('DD MMM')}` 
                        : moment(p[0].CreatedDate).format('DD MMM')
                    }
                })
            }else {
                booking = presentDayBookingCount;
            }
        }

        //upper four chart logic
        let chunks = []
        if (props.currentPeriod.id == 7) {
            const topChartArray = presentDayBookingCount.map(c => c.Count).slice(0, moment().format('DDD'))
            const length = topChartArray.length;

            const chunk = [];
            const size = Math.ceil(length / 11)
            for (let i = 0; i <= length; i+=size) {
                chunk.push([...topChartArray].splice(i, size))
            }
            chunks = chunk.map(p => p.reduce((prev, curr) => prev + curr, 0))
        } else {
            const topChartArray = presentDayBookingCount.map(c => c.Count)
            const length = topChartArray.length;
            const chunk = []
            const size = Math.ceil(length / 11)
            for (let i = 0; i <= length; i+=size) {
                chunk.push([...topChartArray].splice(i, size))
            }
            chunks = chunk.map(p => p.reduce((prev, curr) => prev + curr, 0))
        }

        return {chunks, booking}
    }

    function countDates(array) {
        return array.reduce((countMap, item) => {
            const date = item.CreatedDate;
            countMap[date] = (countMap[date] || 0) + 1;
            return countMap;
        }, {});
    }
      
    function convertToArray(countMap) {
        return Object.entries(countMap).map(([CreatedDate, Count]) => ({ CreatedDate, Count }));
    }

    const fetchSportReport = async (type) => {
        try {
            const response = await getSportReportByType(props.fromDate, props.toDate, type)
            if (type == 0) {
                setSportData(response.SportsWithPercentage)
            }
            setBookingCount(response)
            setTotalCancelBookings(response.TotalCancelBookings)
            setTotalBookings(response.TotalBookings)
            setPresentBookings(response.PresentBooking)
            setNoShowBookings(response.NoShowBooking)
    
            const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
            const prevReport = await getSportReportByType(prev.prevFrom, prev.prevTo, type)
            setPrevBookingCount(prevReport)
            setPrevTotalCancelBookings(prevReport.TotalCancelBookings)
            setPrevTotalBookings(prevReport.TotalBookings)
            setPrevPresentBookings(prevReport.PresentBooking)
            setPrevNoShowBookings(response.NoShowBooking)
        } catch(err){
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    // const fetchPrevCheckInReport = async (checkInMasterID) => {
    //     try {
    //         const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
    //         const response = await getCheckInReportByMasterID(prev.prevFrom, prev.prevTo, checkInMasterID)
    //         console.log(response)
    //         setPrevMaxCheckIn(response.CheckIn)
    //         setPrevMaxCheckInCount(response.CheckInCount)
    //     } catch(err){
    //         swal.fire({
    //             icon                : 'error',
    //             titleText           : 'Error!',
    //             text                : err.message,
    //             buttonsStyling      : false,
    //             confirmButtonClass  : 'btn btn-brand',
    //         });
    //     }
    // }

    const fetchCheckInReport = async (type) => {
        try {
            const response = await getCheckInReportByType(props.fromDate, props.toDate, type)
            if (type == 0) {
                setFacilityData(response.SportsWithPercentage);
                // setMaxCheckIn(response.MaxOccurrence);
                // setMaxCheckInCount(response.MaxOccurrenceCount)
            }
            setCheckInCount(response)
            setTotalCheckIn(response.TotalBookings)
    
            const prev = filterDate(props.fromDate, props.toDate, props.currentPeriod.id == 6 ? true : false);
            const prevCheckIn = await getCheckInReportByType(prev.prevFrom, prev.prevTo, type)
            setPrevCheckInCount(prevCheckIn)
            setPrevTotalCheckIn(prevCheckIn.TotalBookings)
        } catch(err){
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const fetchFeedBack = async () => {

        try {
            setFeedBackLoading(true);
            const response = await getFeedbackAll();
            const feedBack = response.slice(0, 10);
            setFeedBackList(feedBack);
            setFeedBackLoading(false);
        } catch (err) {
            setFeedBackLoading(false)
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }

        
    }

    const filterDate = (fromDate, toDate, isCustom) => {
        if (isCustom) {
            const diff      =  moment(toDate).diff(fromDate, 'days') + 1;
            const prevFrom  =  moment(fromDate).subtract(diff, 'days').format('YYYY-MM-DD')
            const prevTo    =  moment(toDate).subtract(diff, 'days').format('YYYY-MM-DD')
            return { prevFrom, prevTo }
        } else {
            const diff      =  moment(toDate).diff(fromDate, 'months') + 1;
            const prevFrom  =  moment(fromDate).subtract(diff, 'months').startOf('month').format('YYYY-MM-DD')
            const prevTo    =  moment(toDate).subtract(diff, 'months').endOf('month').format('YYYY-MM-DD')
            return { prevFrom, prevTo }
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row margin-t20">
                    {props.selectedType == 0 && (
                        <>
                            <div className="col-md-3">
                                <div className="kt-portlet" style={{ height: '150px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">BOOKINGS</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='totalCount' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{bookingCount.TotalCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${bookingCount.TotalCount > prevBookingCount.TotalCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: bookingCount.TotalCount > prevBookingCount.TotalCount ? 'green' : 'red' }}></i>{totalCountAverage}%</h6>
                                        </div>
                                    </div>
                                    {totalCountChart && (
                                        <ReactApexChart 
                                            options={totalCountChart.options}
                                            series={totalCountChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="kt-portlet" style={{ height: '150px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">PRESENT</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='presentCount' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{bookingCount.PresentCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${bookingCount.PresentCount > prevBookingCount.PresentCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: bookingCount.PresentCount > prevBookingCount.PresentCount ? 'green' : 'red' }}></i>{presentAverage}%</h6>
                                        </div>
                                    </div>
                                    {presentChart && (
                                        <ReactApexChart 
                                            options={presentChart.options}
                                            series={presentChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="kt-portlet" style={{ height: '150px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">NO SHOW</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='noShowCount' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{bookingCount.NoShowCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${bookingCount.NoShowCount > prevBookingCount.NoShowCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: bookingCount.NoShowCount > prevBookingCount.NoShowCount ? 'green' : 'red' }}></i>{noShowAverage}%</h6>
                                        </div>
                                    </div>
                                    {noShowChart && (
                                        <ReactApexChart 
                                            options={noShowChart.options}
                                            series={noShowChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="kt-portlet" style={{ height: '150px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">CANCEL</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='cancelCount' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{bookingCount.TotalCancelCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${bookingCount.TotalCancelCount > prevBookingCount.TotalCancelCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: bookingCount.TotalCancelCount > prevBookingCount.TotalCancelCount ? 'green' : 'red' }}></i>{totalCancelCountAverage}%</h6>
                                        </div>
                                    </div>
                                    {totalCancelCountChart && (
                                        <ReactApexChart 
                                            options={totalCancelCountChart.options}
                                            series={totalCancelCountChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {props.selectedType == 1 && (
                        <>
                            <div className="col-md-12">
                                <div className="kt-portlet" style={{ height: '200px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">TOTAL CHECKIN</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='totalCheckIn' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{checkInCount.TotalCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${checkInCount.TotalCount > prevCheckInCount.TotalCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: checkInCount.TotalCount > prevCheckInCount.TotalCount ? 'green' : 'red' }}></i>{checkInAverage}%</h6>
                                        </div>
                                    </div>
                                    {totalCheckInChart && (
                                        <ReactApexChart 
                                            options={totalCheckInChart.options}
                                            series={totalCheckInChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="kt-portlet" style={{ height: '150px' }}>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t75 dashboard-header-content">MAXIMUM CHECKIN</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <span className="kt-portlet__head-title padding-t150" style={{ fontFamily: 'arial,sans-serif', fontSize: '2.5rem' }}></span><h6 id='maximumCheckIn' className="kt-portlet__head-title padding-t150 counter" style={{ fontSize: '2.5rem' }}>{maxCheckInCount}</h6>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head justify-content-center dashboard-header-title">
                                        <div className="kt-portlet__head-label">
                                            <h6 className="kt-portlet__head-title padding-t225" style={{ fontSize: '0.9rem' }}><i className={`${checkInCount.TotalCount > prevCheckInCount.TotalCount ? 'fa fa-caret-up margin-r5' : 'fa fa-caret-down margin-r5' }`} style={{ color: checkInCount.TotalCount > prevCheckInCount.TotalCount ? 'green' : 'red' }}></i>{checkInAverage}%</h6>
                                        </div>
                                    </div>
                                    {totalCheckInChart && (
                                        <ReactApexChart 
                                            options={totalCheckInChart.options}
                                            series={totalCheckInChart.series}
                                            type="area"
                                            height='153px'

                                        />
                                    )}
                                </div>
                            </div> */}
                        </>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="kt-portlet" style={{ height: '400px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">{props.selectedType == 0 ? "Booking Stats" : "Facility Stats"}</h3>
                                </div>
                            </div>
                            {props.selectedType == 0 && (
                                <div className="kt-portlet__body">
                                    {loading ? <Loading/> : (
                                        <div className="mixed-chart">
                                            {defaultBookingChart && (
                                                <ReactApexChart 
                                                    options={bookingChart ? bookingChart.options : defaultBookingChart.options}
                                                    series={bookingChart ? bookingChart.series : defaultBookingChart.series}
                                                    type="area"
                                                    height='320px'
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {props.selectedType == 1 && (
                                <div className="kt-portlet__body">
                                    {loading ? <Loading/> : (
                                        <div className="mixed-chart">
                                            {defaultBookingChart && (
                                                <ReactApexChart 
                                                    options={facilityChart ? facilityChart.options : defaultBookingChart.options}
                                                    series={facilityChart ? facilityChart.series : defaultBookingChart.series}
                                                    type="area"
                                                    height='320px'
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="kt-portlet" style={{ height: '400px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        {props.selectedType === 0 ? "Sport Stats" : "Facility Stats"}
                                    </h3>
                                </div>
                                {props.selectedType == 1 && (
                                    <div className="kt-portlet__head-toolbar">
                                        <div className="kt-portlet__head-wrapper">
                                            <div className="kt-portlet__head-actions">
                                                <span style={{ fontSize: '1.2rem', fontWeight: '500', color: '#48465b' }}>Total CheckIn: {checkInCount.TotalCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {props.selectedType == 0 && (
                                <div className="kt-portlet__body">
                                    {loading ? <Loading /> : (
                                        <div className="mixed-chart">
                                            {(defaultSportChartData && sportData.length == 0) && (
                                                <ReactApexChart 
                                                    options={defaultSportChartData.options}
                                                    series={defaultSportChartData.series}
                                                    type="donut"
                                                    height={340}
                                                />
                                            )}
                                            {(sportChartData && sportData.length > 0) && (
                                                <ReactApexChart 
                                                    options={sportChartData.options}
                                                    series={sportChartData.series}
                                                    type="donut"
                                                    height={340}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {props.selectedType == 1 && (
                                <div className="kt-portlet__body">
                                    {loading ? <Loading /> : (
                                        <div className="mixed-chart">
                                            {(defaultSportChartData && facilityData.length == 0) && (
                                                <ReactApexChart 
                                                    options={defaultSportChartData.options}
                                                    series={defaultSportChartData.series}
                                                    type="donut"
                                                    height={340}
                                                />
                                            )}
                                            {(facilityChartData && facilityData.length > 0) && (
                                                <ReactApexChart 
                                                    options={facilityChartData.options}
                                                    series={facilityChartData.series}
                                                    type="donut"
                                                    height={340}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid" style={{ height: '425px' }}>
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Feedback</h3>
                                </div>
                            </div>
                            {feedBackLoading ? <Loading/> : (
                                feedBackList.length > 0 ? (
                                    <div 
                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                        <table className="kt-datatable__table table-striped">
                                            <tbody
                                                className="kt-datatable__body"
                                                style={{ display: 'block' }}>
                                                {feedBackList.map((t, i) => 
                                                    <tr key={i} className="kt-datatable__row block-row clickable" onClick={() => setSelectedFeedBack(t)}>
                                                        <td width="40%" title={t.Name} className="kt-datatable__cell text-clip padding-l20 padding-15">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {(t.Photo && t.Photo != '') ? 
                                                                    <img src={t.Photo} width="250px" heigth="250px" alt="user-profile" /> : 
                                                                    <div className="kt-badge kt-badge--xl kt-badge--info">{t.Name.substring(0, 1)}</div>}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip"> {t.Name} </span> 
                                                                    <span className="kt-user-card-v2__desc text-clip"> {t.MembershipNo} </span>
                                                                </div>
                                                                {t.IsNew && (
                                                                    <span className='kt-badge kt-badge--success kt-badge--inline kt-badge--pill policy-badge'>
                                                                        New
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td width="30%" title={t.Mobile} className="kt-datatable__cell text-clip padding-l0 padding-15">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip"> {t.Mobile} </span> 
                                                                    <span className="kt-user-card-v2__desc text-clip"> {t.Email} </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td width="20%" className="kt-datatable__cell text-clip" title={moment(t.CreatedDate).format('DD-MMM-YYYY hh:mm A')}>
                                                            {moment(t.CreatedDate).format('DD-MMM-YYYY hh:mm A')}
                                                        </td>
                                                        <td className="kt-datatable__cell text-clip text-right" width="10%">
                                                            <button 
                                                                type="button"
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover"
                                                                data-placement="left"
                                                                data-original-title="Message"
                                                                data-content={t.Message}>  
                                                                <i className="fa fa-info"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {selectedFeedBack && (
                <FeedBackModal
                    show={selectedFeedBack}
                    feedBack={selectedFeedBack}
                    onDismissModal={() => setSelectedFeedBack(null)}
                />
            )}
        </>
    )
}

export default Dashboard