import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getSubscriptionMasterAll } from "../api/paymentAPI";
import subscriptionImg from '../../assets/images/subscription_img.png'
import subscriptionImgClub from '../../assets/images/subscription_img_club.png'
import { getSportMasterAll } from "../api/sportsAPI";
import GolfPayables from "./golfPayables";
import RangePayables from "./rangePayables";
import SportPayables from "./sportsPayables";
 
const SubscriptionPayable  = (props) => {
    const [subscriptionMaster,          setSubscriptionMaster]            = useState({});
    const [subscriptionMasterList,      setSubscriptionMasterList]        = useState([]);
    const [isInit,                      setIsInit]                        = useState(true);
    const [rightSideDetails,            setRightSideDetails]              = useState(false);
    const [sportsList,                  setSportList]                     = useState([]);
    const [expandedSport,               setExpandedSport]                 = useState(null);

    useEffect(() => {
        props.title("Subscription Payables", "subscription-payables")
        fetchSubscriptionMaster();
    }, []);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            fetchSportsMaster();
        }else{
            setSubscriptionMaster({});
            setExpandedSport(null);
        }
     }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [subscriptionMasterList]);
  
    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
        helper.SetScrollHeight();
    });
 
    const fetchSubscriptionMaster = async() =>{
        try {
            const master = await getSubscriptionMasterAll()
            isInit ? setIsInit(false) : $('#tblSubscriptionMaster').KTDatatable().destroy(); 
            setSubscriptionMasterList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
  
    const fetchSportsMaster = async() => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const sportMasters =  await getSportMasterAll();
             setSportList(sportMasters);
            swal.close();
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };
    
    function fillDataTable() {

        const dataTable = $('#tblSubscriptionMaster').KTDatatable({
            data: {
                saveState: false,
                source: subscriptionMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2">
                                ${`<img src="${row.MembershipType == 'Golf Membership' ? subscriptionImg : subscriptionImgClub}" alt="user-profile" height="40" width="40">`}
                            </div>
                            <div class="kt-user-card-v2__details text-clip ml-3">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Code} </span>
                                </div>
                           </div>`
                    }
                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSubscriptionMaster .kt-datatable__table').addClass('overflow-x-auto');


        dataTable.on('click', 'tr', function () {

            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSubscriptionMaster(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    const handleToggle = (id) => {
        setExpandedSport(expandedSport === id ? null : id);
    };
    
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSubscriptionMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Subscription Payables Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        <img style={{height:'70px'}} src={subscriptionMaster.MembershipType == 'Golf Membership' ? subscriptionImg : subscriptionImgClub} alt="user-profile"/>
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head padding-b0">
                                                                <span className="kt-widget__username">
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {subscriptionMaster.Name}{' '}&nbsp;&nbsp;
                                                                        <span
                                                                            className={`kt-badge kt-badge--${
                                                                                subscriptionMaster.MembershipType === 'Golf Membership' ? 'success' : 'info'
                                                                            } kt-badge--inline kt-badge--pill padding-lr10`}
                                                                            >
                                                                            {subscriptionMaster.MembershipType}
                                                                        </span>
                                                                        &nbsp;
                                                                        <span
                                                                            type="button"
                                                                            className={`kt-badge kt-badge--inline kt-badge--pill padding-lr10`}
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover"
                                                                            data-placement="right"
                                                                            data-content={subscriptionMaster.Description}
                                                                        >
                                                                            <i className="la la-exclamation-circle" style={{ color: 'grey', fontSize: "26px", transform: 'rotate(180deg)' }}></i>
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span>
                                                                    {subscriptionMaster.Code} 
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-widget__action">
                                                            <span className="dropdown">
                                                                <span style={{height: '20px', width: '60px', fontSize: '12px'}} className={`kt-badge kt-badge--${subscriptionMaster.Active == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10 margin-r50`}>
                                                                    Active
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body">
                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                            {(sportsList && sportsList.length > 0) && (
                                                sportsList.filter(f => subscriptionMaster.MembershipType == 'Golf Membership' ? f : (f.ID != 1 && f.ID != 2)).map((m, i) => (
                                                    <div className="card" key={i} 
                                                    // style={{pointerEvents: isEdit ? 'none' : '' }}
                                                    >
                                                        <div className="card-header" id={`menuHeading-${m.ID}`}>
                                                            <div className="card-title collapsed padding-tb5" data-toggle="collapse" onClick={() => handleToggle(m.ID)}>
                                                                <span> 
                                                                    {m.Logo && m.Logo != '' ? 
                                                                        <img width="40px" height="40px" src={m.Logo} alt="user-profile" /> : 
                                                                        <div className={`kt-badge kt-badge--xl kt-badge--info`}> {m.Name.substring(0, 1)}</div>
                                                                    }
                                                                </span>
                                                                <div className="container-calender" style={{ color: expandedSport === m.ID ? '#5d78ff' : '' }}>
                                                                    <span className="date-text" 
                                                                    // style={{opacity: isEdit && rateCalender.ID != m.ID ? 0.8 : '' }}
                                                                    >
                                                                        {m.Name}
                                                                    </span> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {m.ID == 1 ? (
                                                            <GolfPayables
                                                                expandedSport={expandedSport}
                                                                sportID={m.ID}
                                                                subscriptionMasterID={subscriptionMaster.ID}
                                                            />
                                                            
                                                        ) : ( m.ID == 2 ? (
                                                            
                                                            <RangePayables
                                                                expandedSport={expandedSport}
                                                                sportID={m.ID}
                                                                subscriptionMasterID={subscriptionMaster.ID}
                                                            />
                                                        ):(
                                                            <SportPayables
                                                                expandedSport={expandedSport}
                                                                sportID={m.ID}
                                                                subscriptionMasterID={subscriptionMaster.ID}
                                                            />

                                                        ))}
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
  
export default SubscriptionPayable ;