import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import { getAffiliateClubAll, getConciergeServiceAll } from "../api/golfClubAPI";
import * as helper from '../global/helper';
    
const AddPayment = (props) => {
    
    const {setPaymentTagging, paymentTagging, MemberDetails, onDismissModal, MemberList, GuestList} = props

    const [paymentMode,                     setPaymentMode]                      = useState([{ value: 'Direct', label: 'Direct'}, { value: 'Member', label: 'Member'},{ value: 'Concierge', label: 'Concierge'}, { value: 'Affiliate Club', label: 'Affiliate Club'}, { value: 'Guest', label: 'Guest'}]);
    const [conciergeList,                   setConciergeList]                    = useState([]);
    const [affiliateClubList,               setAffiliateClubList]                = useState([]);
    const [paymentTaggingDetails,           setPaymentTaggingDetails]            = useState({});
    const [tagName,                         setTagName]                          = useState("Direct");
    const [referenceType,                   setReferenceType]                    = useState("Direct");

    useEffect(() => {
        $('#modalPayment').modal({
            backdrop: 'static',
        });
        $('#modalPayment').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            onDismissModal(false);
        });
        fetchConcierge();
        fetchAffiliateClub();
    }, [])

    useEffect(() => {
        if(paymentTagging && Object.keys(paymentTagging).length !== 0){
            setPaymentTaggingDetails({...paymentTagging, Edit: true, UserType: MemberDetails?.UserType})
            setTagName(paymentTagging?.TagName)
        }
    },[paymentTagging])

    useEffect(() => {
        if(MemberDetails?.UserType && MemberDetails.UserType == 'Marker'){
            setPaymentMode(paymentMode.filter(f => f.value == 'Member' || f.value == 'Guest'))
            setPaymentTaggingDetails( a => ({...a, PaymentMode: 'Member'}))
        }else{
            setPaymentMode(paymentMode.filter(f => f.value !== 'Guest'))
        }
    },[MemberDetails])

    const fetchConcierge = async() => {
        try {
            const concierges = await getConciergeServiceAll()
            setConciergeList(concierges)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAffiliateClub = async() => {
        try {
            const affiliateClub = await getAffiliateClubAll()
            setAffiliateClubList(affiliateClub)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const updatePaymentDetails = (field, value) => {   
        setPaymentTaggingDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addPaymentHandler = async() => {
        const btn = $('#btnSavePayment');
        var form = $('#add-Payment-form');
        
        form.validate({
            rules: {
                ddlPaymentMode: {
                    required: true,
                },                      
                ddlUser: {
                    required: true,
                },  
                ddlGuest: {
                    required: true,
                },                      
                ddlConcierge: {
                    required: true,
                },                      
                ddlAffiliateClub: {
                    required: true,
                }                   
            },
            messages: {
                ddlPaymentMode: {
                    required: "Please select payment mode"
                },
                ddlUser: {
                    required: "Please select user"
                },
                ddlGuest: {
                    required: "Please select guest"
                },
                ddlConcierge: {
                    required: "Please select concierge"
                },
                ddlAffiliateClub: {
                    required: "Please select affiliate club"
                }
            }
        });
        
        if (!form.valid()) return;
        helper.StartProcessing(btn);
        setPaymentTagging({...paymentTaggingDetails, UserID: MemberDetails?.UserID, TagTo: paymentTaggingDetails?.TagTo || tagName, ReferenceType: referenceType, UserType: MemberDetails?.UserType});
        setPaymentTaggingDetails({})
        setReferenceType()
        setTagName()
        $('#modalPayment').modal('hide');
    }

    const closeDrawer = () =>{
        if(MemberDetails.UserType == 'Marker'){
            var form = $('#add-Payment-form');
            
            form.validate({
                rules: {
                    ddlPaymentMode: {
                        required: true,
                    },                      
                    ddlUser: {
                        required: true,
                    },                      
                    ddlGuest: {
                        required: true,
                    },                      
                    ddlConcierge: {
                        required: true,
                    },                      
                    ddlAffiliateClub: {
                        required: true,
                    }                   
                },
                messages: {
                    ddlPaymentMode: {
                        required: "Please select payment mode"
                    },
                    ddlUser: {
                        required: "Please select user"
                    },
                    ddlGuest: {
                        required: "Please select guest"
                    },
                    ddlConcierge: {
                        required: "Please select concierge"
                    },
                    ddlAffiliateClub: {
                        required: "Please select affiliate club"
                    }
                }
            });
            
            if (!form.valid()) return;   
            setPaymentTagging({...paymentTaggingDetails, UserID: MemberDetails?.UserID, TagTo: paymentTaggingDetails?.TagTo || tagName, ReferenceType: referenceType, UserType: MemberDetails?.UserType});
            setPaymentTaggingDetails({})
            setReferenceType()
            setTagName()
            $('#modalPayment').modal('hide');        
        }
        $('#modalPayment').modal('hide');        
        
    };

    return(
        <>
            <div id="modalPayment" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Payment</h5>
                            <button type="button" className="close" aria-label="Close" onClick={closeDrawer}></button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-Payment-form">
                                <div id="ModalPaymentTagging" className="kt-form kt-form--label-right" role="form">
                                    <div id="pnlPaymentMode" className="form-group row">
                                        <label className="col-form-label col-lg-3">Payment Mode <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <div>
                                                <Select 
                                                    id="ddlPaymentMode" 
                                                    name="ddlPaymentMode "
                                                    className="multi-custom "
                                                    value={paymentTaggingDetails.PaymentMode && {label: paymentMode.find((m) => m.label == paymentTaggingDetails.PaymentMode ).label, value: paymentTaggingDetails.PaymentMode } || paymentMode.find((m) => m.value[0])}
                                                    onChange={(event) => {updatePaymentDetails('PaymentMode', event.value), setPaymentTaggingDetails( a => ({...a, ReferenceID: ''}))}}
                                                    options={paymentMode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {paymentTaggingDetails.PaymentMode == 'Member' && (
                                        <div id="pnlMember" className="form-group row">
                                            <label className="col-form-label col-lg-3">Member<span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div>
                                                    <Select
                                                        value={(MemberList && MemberList.length > 0 && paymentTaggingDetails.ReferenceID) && {label: !!MemberList.find(m => m.ID == paymentTaggingDetails.ReferenceID) && MemberList.find(m => m.ID == paymentTaggingDetails.ReferenceID).Name, value: paymentTaggingDetails.ReferenceID} || ''}
                                                        onChange={(event) => {updatePaymentDetails('ReferenceID', event.value), setTagName(event.label), setReferenceType("User")}}
                                                        name="ddlUser"
                                                        placeholder="Select User"
                                                        id="ddlUser"
                                                        className="ddlUser"
                                                        options={MemberList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {paymentTaggingDetails.PaymentMode == 'Concierge' && (
                                        <div id="pnlConcierge" className="form-group row">
                                            <label className="col-form-label col-lg-3">Concierge Service <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div>
                                                    <Select
                                                        value={(conciergeList && conciergeList.length > 0 && paymentTaggingDetails.ReferenceID) && {label: !!conciergeList.find(m => m.ID == paymentTaggingDetails.ReferenceID) && conciergeList.find(m => m.ID == paymentTaggingDetails.ReferenceID).Name, value: paymentTaggingDetails.ReferenceID} || ''}
                                                        onChange={(event) => {updatePaymentDetails('ReferenceID', event.value), setTagName(event.label), setReferenceType("ConciergeService")}}
                                                        name="ddlConcierge"
                                                        placeholder="Select Service"
                                                        id="ddlConcierge"
                                                        className="ddlConcierge"
                                                        options={conciergeList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {paymentTaggingDetails.PaymentMode == 'Affiliate Club' && (
                                        <div id="pnlAffiliateClub" className="form-group row">
                                            <label className="col-form-label col-lg-3"> Affiliate Club <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div>
                                                    <Select
                                                        value={(affiliateClubList && affiliateClubList.length > 0 && paymentTaggingDetails.ReferenceID) && {label: !!affiliateClubList.find(m => m.ID == paymentTaggingDetails.ReferenceID) && affiliateClubList.find(m => m.ID == paymentTaggingDetails.ReferenceID).Name, value: paymentTaggingDetails.ReferenceID} || ''}
                                                        onChange={(event) => {updatePaymentDetails('ReferenceID', event.value), setTagName(event.label), setReferenceType("AffiliateClub")}}
                                                        name="ddlAffiliateClub"
                                                        placeholder="Select Club"
                                                        id="ddlAffiliateClub"
                                                        className="ddlAffiliateClub"
                                                        options={affiliateClubList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {paymentTaggingDetails.PaymentMode == 'Guest' && (
                                        <div id="pnlGuest" className="form-group row">
                                            <label className="col-form-label col-lg-3"> Guest <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div>
                                                    <Select
                                                        value={(GuestList && GuestList.length > 0 && paymentTaggingDetails.ReferenceID) && {label: !!GuestList.find(m => m.ID == paymentTaggingDetails.ReferenceID) && GuestList.find(m => m.ID == paymentTaggingDetails.ReferenceID).Name, value: paymentTaggingDetails.ReferenceID} || ''}
                                                        onChange={(event) => {updatePaymentDetails('ReferenceID', event.value), setTagName(event.label), setReferenceType("Guest")}}
                                                        name="ddlGuest"
                                                        placeholder="Select Guest"
                                                        id="ddlGuest"
                                                        className="ddlGuest"
                                                        options={GuestList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Reference Number</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtReferenceNumber" 
                                                name="txtReferenceNumber" 
                                                type="text" 
                                                className="form-control" 
                                                value={paymentTaggingDetails.ReferenceNumber || ''}
                                                onChange={(event) => updatePaymentDetails('ReferenceNumber', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary margin-0 margin-r5" onClick={closeDrawer} >Close</button>
                            <button type="button" id="btnSavePayment" name="btnSavePayment" className="btn btn-brand margin-0" onClick={addPaymentHandler}>{(paymentTaggingDetails && paymentTaggingDetails.Edit) ? 'Update' : 'Add'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPayment;