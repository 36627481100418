import { useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { useEffect } from "react";
import { getActiveSportsMaster } from "../api/sportsAPI";
import FacilityBlock from "./facilityBlock";
import swal from 'sweetalert2';

const FacilityMaster = (props) => {

    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [sportsList,          setSportList]           = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [sports,              setSports]              = useState({});

    useEffect(() => {
        props.title("Facility Master", "facility-master")
        fetchSportsMaster();
    },[])

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [sportsList]);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : '';
    }, [rightSideDetails]);

    const fetchSportsMaster = async() => {
        try {
            const sportMasters =  await getActiveSportsMaster();
            isInit ? setIsInit(false) : $('#tblSports').KTDatatable().destroy(); 
            setSportList(sportMasters)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblSports').KTDatatable({
            data: {
                saveState: false,
                source: sportsList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Sport',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.BookingType} </span>
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSports .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSports(dataTable.dataSet[index]);  
                setRightSideDetails(true); 
            }    
        });
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Sport..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSports" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Facility Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        {sports.Logo && sports.Logo != '' ? 
                                                            <img className="kt-widget__pic kt-widget__pic" src={sports.Logo} alt="user-profile"/> :
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-`}>
                                                                {sports.Name.slice(0, 1)}
                                                            </div>
                                                        }   
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {sports.Name}{' '}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={`kt-badge kt-badge--${sports.ActiveAdmin == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                        {sports.ActiveAdmin == 'Y' ? 'Active' : 'Inactive'}
                                                                    </span> 
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="fa fa-clock"></i>
                                                                    {sports.BookingType} 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                        <FacilityBlock
                                            sports = {sports}
                                        />
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>   
            </div>
        </>
    )
}

export default FacilityMaster;