import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { deleteEventRegistration, getEventAll, getEventRegistrationByEventID } from "../api/eventAPI";
import AddEventRegistration from "./addEventRegistration";
import swal from 'sweetalert2';
import * as helper from '../global/helper';

export const DeleteEventRegistration = (props) => {

    useEffect(() => {
        $('#cancelModal').modal({
            backdrop: 'static',
        });
        $('#acancelModal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
    }, [])

    const deleteEvent = async(ID) => {
        try {
            await deleteEventRegistration(ID)
            swal.fire({
                icon: 'success',
                titleText: 'Event Deleted successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            $('#cancelModal').modal('hide');
            props.refetchRegistration()
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
        }
    }

    return(
        <>
            <div id="cancelModal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancel User Registration</h5>
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <label className="text-center" style={{fontSize: '15px'}}>Are you sure want to cancel ?</label>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal">No</button>
                            <button type="button" id="btnYes" name="btnYes" className="btn btn-danger margin-0" onClick={() => deleteEvent(props.eventRegistrationID.ID)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const EventRegistration = (props) =>{

    const [eventList,                  setEventList]                  = useState([]);
    const [eventRegistrationList,      setEventRegistrationList]      = useState([]);
    const [event,                      setEvent]                      = useState({});
    const [eventId,                    setEventId]                    = useState(null);
    const [deleteEventRegistration,    setDeleteRegistrationEvent]    = useState();
    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [isInit,                     setIsInit]                     = useState(true);
    const [isInitReg,                  setIsInitReg]                  = useState(true);
    const [addRegistration,            setAddRegistration]            = useState(false);
    const [refresh,                    setRefresh]                    = useState(false);

    useEffect(() => {
        props.title('Event Registration', 'event-registration')
        fetchEvent();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
           
        }else{
            setEvent({});
            setEventId(null);
            setIsInitReg(true);
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [eventList]);

    useEffect(() => {   
        if(eventId){
            fetchEventRegistration(eventId);
        }    
    }, [eventId, refresh]);

    useEffect(() => {       
        if (!isInitReg) {
            fillRegistraionData();        
        }
    }, [eventRegistrationList]);

    const fetchEvent = async() =>{
        try {
            const events = await getEventAll()   
            isInit ? setIsInit(false) : $('#tblEvent').KTDatatable().destroy(); 
            setEventList(events);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchEventRegistration = async(ID) =>{
        try {
            const eventRegistrations = await getEventRegistrationByEventID(ID)   
            isInitReg ? setIsInitReg(false) : $('#tblEventRegistration').KTDatatable().destroy(); 
            setEventRegistrationList(eventRegistrations);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    } 

    const refetchRegistration = () => setRefresh((r) => !r);

    function fillDataTable() {

        const dataTable = $('#tblEvent').KTDatatable({
            data: {
                saveState: false,
                source: eventList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Event',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Thumbnail && row.Thumbnail != '') ? `<img src="${row.Thumbnail}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.FromDate).format('YYYY-MM-DD')).isSame(moment(row.ToDate).format('YYYY-MM-DD')) ? moment(row.FromDate).format('DD MMM YYYY') : moment(row.FromDate).format('DD MMM YYYY') + ' - ' + moment(row.ToDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') {
                            if (row.FromDate && row.ToDate) {
                                if (moment(row.ServerDate).isBetween(moment(moment(row.FromDate).format('YYYY-MM-DD')), moment(moment(row.ToDate).format('YYYY-MM-DD')), '[]'))
                                    return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                                else
                                    return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                            } else
                                return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblEvent .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setEvent(dataTable.dataSet[index]);  
                setEventId(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });
    }

    function fillRegistraionData() {

        const dataTable = $('#tblEventRegistration').KTDatatable({
            data: {
                saveState: false,
                source: eventRegistrationList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#eventRegisterSearch'),
            },
            columns: [
                {
                    field: 'UserName',
                    title: 'User',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                        ${(row.UserPhoto && row.UserPhoto != '') ? `<img src="${row.UserPhoto}" alt="user-profile" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.UserName.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.UserName} </span>
                                        <span class="kt-user-card-v2__desc text-clip"> ${row.UserEmail} </span>
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'CreatedDate',
               
                    title: 'Registeration Date',
                    template: function (row) {
                        return moment(row.CreatedDate).format('DD-MMM-YYYY , h:mm:ss a');
                    }
                },
                {
                    field: 'Comment',
                    title: 'Special Request',

                },
                {
                    field: '',
                    title: '',
                    width: 30,
                    textAlign: 'center',
                    template: function (row) {
                        return '<button class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete delete-dynamic-row" data-toggle="modal" data-target="#cancelModal"  type="button"><i class="flaticon2-rubbish-bin-delete-button"></i></button>';
                    }
                }

            ]
        });
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblEventRegistration .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', '.btn-user-delete',  function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet[index]) {
                setDeleteRegistrationEvent(dataTable.dataSet[index])
            } 
        });
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Event..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblEvent" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Event Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-0">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                            <div className="kt-portlet__head-label">
                                                <div className="row align-items-center margin-l10">
                                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                                        <div className="kt-input-icon kt-input-icon--left">
                                                            <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-portlet__head-toolbar">
                                                <div className="kt-portlet__head-wrapper">
                                                    <div className="kt-portlet__head-actions">
                                                        <button type="button" onClick={() => setAddRegistration(true)}  className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Registraion</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body kt-portlet__body--fit">
                                            <div id="tblEventRegistration" className="kt-datatable table-striped clickable">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {addRegistration && (
                <AddEventRegistration
                    show                     = { addRegistration }
                    eventRegistrationID      = { event.ID }
                    onDismissModal           = {() => setAddRegistration(null) }
                    refetchRegistration      ={() => refetchRegistration()}
                />
            )}
            {deleteEventRegistration && (
                <DeleteEventRegistration
                    show                    = { deleteEventRegistration }
                    eventRegistrationID     = { deleteEventRegistration }
                    onDismissModal          = {() => setDeleteRegistrationEvent() }
                    refetchRegistration     = {() => refetchRegistration()}

                />
            )}
        </>
    )
}

export default EventRegistration;