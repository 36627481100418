import React, { useState, useEffect } from 'react'; 
import swal from 'sweetalert2';
import { getGolfPayableBySubscriptionID, updateGolfPayable } from '../api/paymentAPI';
 
const GolfPayables = (props) => {

    const {subscriptionMasterID, sportID, expandedSport} = props;

    const [golfPayableList, setGolfPayableList] = useState([]);

    useEffect(() => {
        if(subscriptionMasterID){
            fetchGolfPayables(subscriptionMasterID);
        } 
    }, [subscriptionMasterID]);
 
    const fetchGolfPayables = async(subID) => {
        try {
            const payablesList = await getGolfPayableBySubscriptionID(subID);
            setGolfPayableList(payablesList);
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };
  
    const updateGolfPayables = async(data) => {
        try {
            const golfPayable18 = golfPayableList.find(f => f.ID == data.ID);
            const golfPayable9 = golfPayableList.find(f => f.UserType == golfPayable18.UserType &&  f.Hole == 9 && f.Type === golfPayable18.Type);
            const payablesList = await updateGolfPayable([data, {...data, ID: golfPayable9.ID}]);
            setGolfPayableList(payablesList);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `${data.Flag == 'Y' ? 'Allowed' : 'Disallow'}`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3500,  
                onOpen: () => swal.hideLoading()                                     
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };
    
    return(
        <>
            <div>
                <div id="accordionExample4">
                    <div id={`collapse-${sportID}`} className={`collapse ${expandedSport === sportID ? 'show' : ''}`} aria-labelledby={`menuHeading-${sportID}`} data-parent="#accordionExample4">
                        <div className="card-body">
                            <div className="kt-widget4">
                                <div className="kt-widget4__item padding-tb5">
                                    <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}>No</th>
                                                <th style={{ width: '15%' }}>Particular</th>
                                                <th style={{ width: '15%' }}>Holes</th>
                                                <th style={{ width: '10%' }}>Days</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Green Fee</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Cart</th>
                                                <th style={{ width: '10%' }} className='column_rate_center'>Caddy</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {golfPayableList.length > 0 && golfPayableList.filter((f) => f.Hole !== 9).map((detail, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{detail.UserType}</td>
                                                <td>{detail.Hole}</td>
                                                <td>{detail.Type}</td> 
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.GreenFee == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateGolfPayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'GreenFee', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.Cart == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateGolfPayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'Cart', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td className='column_rate_center'>
                                                    <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={detail.Caddy == "N" ? '' : 'checked'} 
                                                            onChange={(event) => updateGolfPayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, Type: 'Caddy', 'Flag': event.target.checked ? 'Y' : 'N'})}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

 export default GolfPayables;