import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import TournamentList from "./tournamentList";
import swal from 'sweetalert2';
import { deleteTournamentDraw, emailDraw, getTournamentDrawData, postTournamentDraw, updateTournamentDrawPublish } from "../api/tournamentAPI";
import DrawTable from "./drawTable";
import SplitDrawTable from "./splitDrawTable";
import defaultUserImg from '../../assets/images/user.jpg'
// import * as helper from '../global/helper';

const str_Single    = "Singles";
const str_Double    = "Doubles";
const str_Quadraple = "Quads";


const TournamentDraw = (props) => { 
    const [tMaster,   setTMaster]   = useState();
    const [pageData,  setPageData]  = useState();
    const [selectedRound,  setSelectedRound]  = useState();
    const [tournamentRounds,  setTournamentRounds]  = useState([]);
    const [tournamentID,   setTournamentID]   = useState();
	const history = useHistory();

    //#region Effects --->
    useEffect(() => {
        props.title("Draw", "tournament-draw")
        const path = history.location.pathname
        const splitedPath = path.split('/')
        const tID = splitedPath?.find(p => !isNaN(p) && p !== '');
        setTournamentID(tID)
    }, []);    

    useEffect(()=>{
        if(!!selectedRound) fetchDrawData(tMaster.ID, selectedRound.ID);               
    },[selectedRound])

    //#endregion

    //#region API's -->

    const fetchDrawData = async(tournamentId, roundId = 0)=>{        
        try {     
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});                                     
            setPageData(); 
            const res = await getTournamentDrawData(tournamentId, roundId);           
            if(!!res) { 
                setPageData(res); 
                setTournamentRounds(res.TournamentRounds)
            }             
            swal.close();            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    } 

    const saveDrawTable = async(IsPublish)=> {
        try {         
            swal.fire({ titleText: 'Loading', showConfirmButton: false, text: 'Please Wait...', allowOutsideClick: false, onOpen: () => { swal.showLoading()} });
            const newDraw = [];
            const teeOffOption = selectedRound.TeeOffOption;
                        
            let isValid = true;
            $('[data-slotid]').each((index, row) => {            
                const round   = selectedRound;
                const slotId  = parseInt(row.getAttribute('data-slotid'));
                const flight  = row.getAttribute('data-flight');
                const players = $(row).find('.dragable-player ');
                // const hole    = parseInt(row.getAttribute('data-hole'));
                
                if (teeOffOption == "Shot Gun Start" && (!!flight && flight != '') && players.length == 0) { //for empty flight row                  
                    swal.fire({ icon: 'warning' ,text: 'Please remove empty flight..', onOpen: () => swal.hideLoading()});
                    isValid = false;
                    return false;
                }

                if (players.length > 0) {
                    players.each((index, row) => {
                        const registerId = parseInt(row.getAttribute('data-registerid'));
                        const drawId     = parseInt(row.getAttribute('data-drawid'));
                        
                        const hole      = (teeOffOption == "Shot Gun Start") ? slotId : 0;
                        const teeSlotId = (teeOffOption == "Shot Gun Start") ? 1 : slotId;

                        const playerData = { ID: drawId, TournamentRegisterID: registerId, TournamentTeeSlotID: teeSlotId, Hole: hole, Index: index + 1, TournamentRoundID: round.ID };
                        if(teeOffOption == "Shot Gun Start") playerData.Flight = flight;

                        newDraw.push(playerData)
                    });
                }
            });

            if(!isValid) return

            if (newDraw.length == 0) {
                swal.fire({
                    icon: 'warning', timer: 1000, showConfirmButton: false,
                    title: "Draw is Empty", text: 'Please create a draw..!',  onOpen: () => swal.hideLoading()
                });
                return;
            }

            //Filter Deleted DrawID
            const deletedDraw = pageData.TournamentDraw.flatMap(o => (newDraw.findIndex(n => n.ID == o.ID) == -1) ? o.ID : []);                  
            const res = await postTournamentDraw(selectedRound.ID, newDraw, deletedDraw, IsPublish);

            if(res && res?.TournamentRounds.length > 0){
                swal.fire({
                    icon: 'success', timer: 1500, animation: false, toast: true,
                    position: 'bottom-end', showConfirmButton: false, customClass: { popup: 'margin-20' },
                    titleText: 'Tournament Draw Saved Successfully',
                    onOpen: () => swal.hideLoading()
                });
                
                setTimeout(()=>{
                    setTournamentRounds(res.TournamentRounds);
                    setSelectedRound( res.TournamentRounds.find(d => d.ID == selectedRound.ID));
                }, 150)
            }

            // //Refetch Data
            //fetchDrawData(tMaster.ID, selectedRound.ID);

        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }
    
    const saveSplitDrawTable = async(IsPublish)=>{
        try {     
            swal.fire({ titleText: 'Loading', showConfirmButton: false, text: 'Please Wait...', allowOutsideClick: false, onOpen: () => { swal.showLoading()} });
            
            const newFirstDraw = [];
            const newTenDraw   = [];
            const teeOffOption = selectedRound.TeeOffOption;
            
            
            let isValid = true;
            //For First Tee Table
            $('#tblDrawFirst [data-slotId]').each((index, row) => {            
                const round   = selectedRound;
                const slotId  = parseInt(row.getAttribute('data-slotid'));                
                const flight  = row.getAttribute('data-flight');
                const players = $(row).find('.dragable-player ');
              
               if(teeOffOption === "Split Shot Gun Start" && (!!flight && flight != '') && players.length == 0) { //for empty flight row               
                    swal.fire({ icon: 'warning' ,text: 'Please remove empty flight..', onOpen: () => swal.hideLoading()});
                    isValid = false;
                    return false;
                }                

                if (players.length > 0) {
                    players.each((index, row) => {
                        const registerId = parseInt(row.getAttribute('data-registerid'));
                        const drawId     = parseInt(row.getAttribute('data-drawid'));
                        
                        const hole      = (teeOffOption === "Split Shot Gun Start") ? slotId : 1;
                        const teeSlotId = (teeOffOption === "Split Shot Gun Start") ? 1 : slotId;

                        const playerData = { ID: drawId, TournamentRegisterID: registerId, TournamentTeeSlotID: teeSlotId, Hole: hole, Index: index + 1, TournamentRoundID: round.ID };
                        if(teeOffOption === "Split Shot Gun Start") playerData.Flight = flight;

                        newFirstDraw.push(playerData)
                    });
                }
            });
            if(!isValid) return

            //For Ten Tee Table
            $('#tblDrawTen [data-slotId]').each((index, row) => {
                const round   = selectedRound;
                const slotId  = parseInt(row.getAttribute('data-slotid'));                
                const flight  = row.getAttribute('data-flight');
                const players = $(row).find('.dragable-player ');
                
                if(teeOffOption === "Split Shot Gun Start" && (!!flight && flight != '') && players.length == 0) { //for empty flight row               
                    swal.fire({ icon: 'warning' ,text: 'Please remove empty flight..', onOpen: () => swal.hideLoading()});
                    isValid = false;
                    return false;
                }   

                if (players.length > 0) {
                    players.each((index, row) => {
                        const registerId = parseInt(row.getAttribute('data-registerid'));
                        const drawId     = parseInt(row.getAttribute('data-drawid'));
                        
                        const hole      = (teeOffOption === "Split Shot Gun Start") ? slotId : 10;
                        const teeSlotId = (teeOffOption === "Split Shot Gun Start") ? 2 : slotId;

                        const playerData = { ID: drawId, TournamentRegisterID: registerId, TournamentTeeSlotID: teeSlotId, Hole: hole, Index: index + 1, TournamentRoundID: round.ID };
                        if(teeOffOption === "Split Shot Gun Start") playerData.Flight = flight;

                        newTenDraw.push(playerData)
                    });
                }
            });
            
            if(!isValid) return
            
            const newDraw = [...newFirstDraw, ...newTenDraw];
            if (newDraw.length == 0) {
                swal.fire({
                    icon: 'warning', timer: 1000, showConfirmButton: false,
                    title: "Draw is Empty", text: 'Please create a draw..!',  onOpen: () => swal.hideLoading()
                });
                return;
            }

            //Filter Deleted DrawID
            const deletedDraw = pageData.TournamentDraw.flatMap(o => (newFirstDraw.findIndex(n => n.ID == o.ID) == -1 && newTenDraw.findIndex(n => n.ID == o.ID) == -1) ? o.ID : []);         

            const res = await postTournamentDraw(selectedRound.ID, newDraw, deletedDraw,IsPublish);
            if(res && res?.TournamentRounds.length > 0){
                swal.fire({
                    icon: 'success', timer: 1500, animation: false, toast: true,
                    position: 'bottom-end', showConfirmButton: false, customClass: { popup: 'margin-20' },
                    onOpen: () => swal.hideLoading(),
                    titleText: 'Tournament Draw Saved Successfully', 
                });

                setTimeout(()=>{
                    setTournamentRounds(res.TournamentRounds);
                    setSelectedRound( res.TournamentRounds.find(d => d.ID == selectedRound.ID));
                }, 150)
            }

            //fetchDrawData(tMaster.ID, selectedRound.ID); //Refetch Data                        
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }

    const deleteDraw = async() => {                      
        swal.fire({
            title: "Are you sure, you want to delete ?",
            showDenyButton: true, showCancelButton: true,
            confirmButtonText: "Yes", denyButtonText: `No`
        }).then(async(result) => {           
            if (result.isConfirmed) {
                try {
                    swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false}); 
                    
                    await deleteTournamentDraw(selectedRound.ID);
                    
                    swal.fire({
                        icon: 'success', timer: 1500, animation: false, toast: true,
                        position: 'bottom-end', showConfirmButton: false, customClass: { popup: 'margin-20' },
                        titleText: 'Tournament Draw Deleted Successfully',
                        onOpen: () => swal.hideLoading()
                    });

                    fetchDrawData(tMaster.ID, selectedRound.ID);
        
                } catch (err) {
                    swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
                }
            }
        });                   
    }

    const handleImageError = (event) => {
        event.target.src = defaultUserImg;
    };

    const updatePublish = async(isPublish)=> {        
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });                                        

            //API Call to Post Tournament Data -->             
            const res = await updateTournamentDrawPublish(selectedRound.ID, isPublish);
          
            if(res && res?.TournamentRounds.length > 0){
                swal.fire({
                    icon: 'success', timer: 1500, animation: false, toast: true,
                    position: 'bottom-end', showConfirmButton: false, customClass: { popup: 'margin-20' },
                    titleText: 'Tournament Draw Saved Successfully',
                    onOpen: () => swal.hideLoading()
                });
                
                setTimeout(()=>{
                    setTournamentRounds(res.TournamentRounds);
                    setSelectedRound( res.TournamentRounds.find(d => d.ID == selectedRound.ID));
                }, 150)
            }
            
            //fetchDrawData(tMaster.ID, selectedRound.ID); //Refetch Data              
        } catch (err) {    
            swal.fire({ icon: 'error',titleText: 'Error!', text: err.message });
            return;                        
        }
    }

    const emailScorCard = async () => {
        try {    
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});
            await emailDraw(tMaster.ID, selectedRound.Round)
            swal.close();
            swal.fire({
                icon: 'success', timer: 1500, showConfirmButton: false,
                titleText: 'Email sent Successfully.',
            });            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }
    //#endregion -->

    //#region Handler    
    
    const onSelectHandler =(tMaster)=> {
        if(!tMaster) return;             
        setTMaster(tMaster);    
        fetchDrawData(tMaster.ID);                                 
    }
    
    const onRoundChange =(roundId)=> {
        if(roundId == 0) {
            setPageData();    
            setSelectedRound();
            return;
        }
        setPageData(); 
        const selectedRound = tournamentRounds.find(s => s.ID == roundId);       
        setSelectedRound(selectedRound);       
    }
   
    const createPlayerList =()=> {    
        const accordionCards = [];
        const tournamentDraw = pageData?.TournamentDraw || [];
        // const tournamentDraw = pageData?.TournamentDraw || [];
        const rounds = (selectedRound?.Round != 1)? [selectedRound] : pageData?.TournamentRounds.filter(r => r.Round == selectedRound?.Round); //show all 1st round or selectedRound only     
        for (const round of rounds) {          
            const roundPlayers     = (selectedRound?.Round != 1) ? pageData?.TournamentRegister : pageData?.TournamentRegister.filter(a => moment(moment(round.Date).format('YYYY-MM-DD')).isSame(moment(a.PreferedDate).format('YYYY-MM-DD')));                        
            const morningPlayers   = roundPlayers.filter(p => (p.PreferedSlot == "Morning") && !tournamentDraw.find(a => a.TournamentRegisterID == p.ID)); 
            const afternoonPlayers = roundPlayers.filter(p => (p.PreferedSlot == "Afternoon")  && !tournamentDraw.find(a => a.TournamentRegisterID == p.ID)); 
                                         
            accordionCards.push(
            <div className="card" key={round.ID}>
                <div className="card-header" id={`dateCard${round.ID}`} >
                    <div className="card-title collapsed" data-toggle="collapse" data-target={`#dateCollapse${round.ID}`} aria-expanded="false" aria-controls={`#dateCollapse${round.ID}`}>                    
                        {moment(round.Date).format('DD-MMM-YYYY')}
                    </div>
                </div>
                <div id={`dateCollapse${round.ID}`} className="collapse" aria-labelledby={`dateCard${round.ID}`} data-parent="#dateAccordion">
                    <div className="card-body p-1 padding-tb5">

                        <div id={`sessionAccordion${round.ID}`} className="accordion accordion-toggle-arrow">
                                <div className="card"  id={`morningCard${round.ID}`}>
                                    <div className="card-header" id={`morningCardHeader${round.ID}`} >
                                        <div className="card-title collapsed" data-toggle="collapse" data-target={`#morningCollapse${round.ID}`} aria-expanded="false" aria-controls={`#morningCollapse${round.ID}`}>                                        
                                            Morning
                                        </div>
                                    </div>
                                    <div id={`morningCollapse${round.ID}`} className="collapse" aria-labelledby={`morningCardHeader${round.ID}`}>
                                        <div className="card-body p-1">
                                            <table id={`tblMorningPlayer${round.ID}`} className="table table-register">
                                                <tbody>
                                                    <tr>                                                 
                                                        <td id={`player-div-morning-${round.ID}`} className="player-div" data-round={`${round.ID}`} data-date={`${round.Date}`} data-session="morning"> 
                                                            {(morningPlayers?.length > 0) && morningPlayers.map((p) =>  getDraggablePlayer(p))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>                               

                                <div className="card" id={`afternoonCard${round.ID}`}>
                                    <div className="card-header" id={`afternoonCardHeader${round.ID}`} >
                                        <div className="card-title collapsed" data-toggle="collapse" data-target={`#afternoonCollapse${round.ID}`} aria-expanded="false" aria-controls={`afternoonCollapse${round.ID}`}>                                        
                                            Afternoon
                                        </div>
                                    </div>
                                    <div id={`afternoonCollapse${round.ID}`} className="collapse" aria-labelledby={`afternoonCardHeader${round.ID}`}>
                                        <div className="card-body p-1">
                                            <table id={`tblafternoonPlayer${round.ID}`} className="table table-register">
                                                <tbody>
                                                    <tr>                                                 
                                                        <td id={`player-div-afternoon-${round.ID}`} className="player-div" data-round={`${round.ID}`} data-date={`${round.Date}`} data-session="afternoon"> 
                                                            {(afternoonPlayers?.length > 0) && afternoonPlayers.map((p) =>  getDraggablePlayer(p))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>                                                              
                        </div>
                    </div>
                </div>
            </div>);

        }

        //init Drag & Slotpicker -->
        setTimeout(() => { 
            createDragable();
            Config.Core.InitPopover(); 
        }, 300);

        return accordionCards;
    }
    
    const getDraggablePlayer =(register, tableName)=> {       
        let drawID     = (tableName == 'drawTable') ? register.ID : 0;
        let registerID = (tableName == 'drawTable') ? register.TournamentRegisterID : register.ID;
      
        return( <div key={registerID} className="dragable-player mb-1" data-registerid={`${registerID}`} data-drawid={`${drawID}`}> <span>
    
            {(register.User1Photo && register.User1Photo != '') ? (<img src={register.User1Photo} onError={handleImageError} className="badge-xs draw-player-img" alt="player-image"/>)
                :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {register.User1Name.substring(0, 1)}</span> )}
            {`${register.User1Name} (${register.User1Handicap < 0 ? '+' + Math.abs(register.User1Handicap) : register.User1Handicap})`}

            {(tMaster?.Type != str_Single) && (<>
                {(register.User2Photo && register.User2Photo != '') ? (<> | <img src={register.User2Photo} onError={handleImageError} className="badge-xs draw-player-img margin-l5" alt="player-image"/> {`${register.User2Name} (${register.User2Handicap < 0 ? '+' + Math.abs(register.User2Handicap) : register.User2Handicap})`} </>)
                    :(<> | <span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {register.User2Name.substring(0, 1)}</span> {`${register.User2Name} (${register.User2Handicap < 0 ? '+' + Math.abs(register.User2Handicap) : register.User2Handicap})`}</>)}
            </>)}   
             
            {(tMaster?.Type == str_Quadraple) && (<>            
                {(register.User3Photo && register.User3Photo != '') ? (<> | <img src={register.User3Photo} onError={handleImageError} className="badge-xs draw-player-img margin-l5" alt="player-image"/>  {`${register.User3Name} (${register.User3Handicap < 0 ? '+' + Math.abs(register.User3Handicap) : register.User3Handicap})`} </>)
                    :(<> | <span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {register.User3Name.substring(0, 1)}</span> {`${register.User3Name} (${register.User3Handicap < 0 ? '+' + Math.abs(register.User3Handicap) : register.User3Handicap})`}</>)}
    
                {(register.User4Photo && register.User4Photo != '') ? (<> | <img src={register.User4Photo} onError={handleImageError} className="badge-xs draw-player-img margin-l5" alt="player-image"/> {`${register.User4Name} (${register.User4Handicap < 0 ? '+' + Math.abs(register.User4Handicap) : register.User4Handicap})`} </>)
                    :(<> | <span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {register.User4Name.substring(0, 1)}</span> {`${register.User4Name} (${register.User4Handicap < 0 ? '+' + Math.abs(register.User4Handicap) : register.User4Handicap})`}</>)}
            </>)}
    
            {(register?.Comment) && (
                <button type="button" className="btn btn-xxs btn-secondary btn-circle btn-icon ml-3" data-container="body" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="top"
                    data-content={`${register.Comment ? register.Comment : ''}`} ><i className="fa fa-exclamation text-primary"></i></button>
            )}
                       
        </span></div>);
    }

    const createDragable = ()=> {                       
        let dragholder = [];        
        //dragable-days Holder -->    
        $('.player-holder').each((index, element) => dragholder.push(element));
        $('.player-div').each((i, element) => dragholder.push(element));
      
        dragholder.push(document.getElementById('player-holder'));   //dragable-days -->

        const onDragConfig = (el, target, source, sibling) =>{         
            const tournamentType =  $(target).data('type');
            if ($(target).attr('class') == 'player-div') return true;
        
            if (tournamentType == "Doubles") {
                return $(target).find('div').length < 2;
            } else {
                return $(target).find('div').length < 1;
            }
        }
        window.dragInit(dragholder, onDragConfig);
    }      

    //Reset on drawer close
    const onReset =()=> { 
        setTMaster()
        setPageData()
        setSelectedRound()
    }

    const postDrawTable = (IsPublish) =>{
        if(selectedRound?.TeeOffOption?.includes('Split')){
            saveSplitDrawTable(IsPublish);
        }else{            
            saveDrawTable(IsPublish);
        }

        //setSelectedRound();
    }

    const onPlayerSearch =(searchTerm)=> {     
        const tournamentType = tMaster.Type;       
        const allPlayers     = pageData.TournamentRegister;
        
        let filteredRegister = [];

        if (searchTerm != '' && searchTerm.length > 2) {
            filteredRegister = allPlayers.filter(r => {
                let matchString = `${r.User1Name}`;

                if (tournamentType == "Doubles") matchString += `${r.User2Name}`
                if (tournamentType == "Quadruple") matchString += `${r.User2Name} ${r.User3Name} ${r.User4Name}`

                let matchFound = 0;
                const searchArr = searchTerm.split(' ');

                searchArr.forEach(t => matchFound += matchString.toLowerCase().includes(t.toLowerCase()) ? 1 : 0);
                return (matchFound === searchArr.length);
            });
        } else if(searchTerm == '') {          
            filteredRegister = pageData.TournamentRegister;
            if (searchTerm.length > 2) $("#dateAccordion .card-title").trigger("click"); //close opened accordion
        }

        if (searchTerm != '' && searchTerm.length <= 2) return;

        $('.player-div').each((i, ele) => {
            const roundId   = $(ele).data('round');
            const session   = $(ele).data('session');
            const roundDate = $(ele).data('date');                        
            const roundPlayers = filteredRegister.filter(r => (selectedRound.Round == 1 ? moment(moment(roundDate).format('DD-MMM-YYYY')).isSame(moment(r.PreferedDate).format('DD-MMM-YYYY')) : true) && (r.PreferedSlot.toLowerCase() == session));

            if (roundPlayers.length > 0) {
                let playerString = '';

                for (let player of roundPlayers) {
                    let alreadyExist = false;  //Checks if player is already mapped. 

                    $('[data-slotId]').each((index, row) => {
                        const players = $(row).find('.dragable-player');
                        if (players.length > 0) {
                            players.each((index, row) => {
                                const registerId = parseInt(row.getAttribute('data-registerId'));
                                if (player.ID == registerId) alreadyExist = true;
                            });
                        }
                    });

                    if (alreadyExist) continue;

                    playerString += (`<div class="dragable-player mb-1" data-registerId="${player.ID}" data-drawId="0"> <span>
                        ${(player.User1Photo && player.User1Photo != '') ?(`<img src="${player.User1Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img"  alt="player-image"/> ${player.User1Name} (${player.User1Handicap < 0 ? '+' + Math.abs(player.User1Handicap) : player.User1Handicap})`):
                            (`<span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${player.User1Name.substring(0, 1)}</span> ${player.User1Name} (${player.User1Handicap < 0 ? '+' + Math.abs(player.User1Handicap) : player.User1Handicap})`)}

                        ${(tournamentType != str_Single)? ((player.User2Photo && player.User2Photo != '') ?(` | <img src="${player.User2Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${player.User2Name} (${player.User2Handicap < 0 ? '+' + Math.abs(player.User2Handicap) : player.User2Handicap})`):
                            (` | <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${player.User2Name.substring(0, 1)}</span> ${player.User2Name} (${player.User2Handicap < 0 ? '+' + Math.abs(player.User2Handicap) : player.User2Handicap})`)): ''}

                        ${(tournamentType == str_Quadraple) ? ((player.User3Photo && player.User3Photo != '') ?(` | <img src="${player.User3Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${player.User3Name} (${player.User3Handicap < 0 ? '+' + Math.abs(player.User3Handicap) : player.User3Handicap})`):
                            (` | <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${player.User3Name.substring(0, 1)}</span> ${player.User3Name} (${player.User3Handicap < 0 ? '+' + Math.abs(player.User3Handicap) : player.User3Handicap})`)):""}

                        ${(tournamentType == str_Quadraple) ? ((player.User4Photo && player.User4Photo != '') ?(` | <img src="${player.User4Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${player.User4Name} (${player.User4Handicap < 0 ? '+' + Math.abs(player.User4Handicap) : player.User4Handicap})`):
                        (` | <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${player.User4Name.substring(0, 1)}</span> ${player.User4Name} (${player.User4Handicap < 0 ? '+' + Math.abs(player.User4Handicap) : player.User4Handicap})`)):''}
                    </span></div>`);

                    const elementID = `#dateCard${roundId}`; //Open Specific accordion and set filtered User
                    const isExpanded = $(`#dateAccordion ${elementID} .card-title`).attr("aria-expanded");
                    if (isExpanded == 'false') $(`#dateAccordion ${elementID} .card-title`).trigger("click");

                    const sessionElementID = `#${session}Card${roundId}`; //Check and Open Session accordion
                    const isSessionExpanded = $(`${sessionElementID} .card-title`).attr("aria-expanded");
                    if (isSessionExpanded == 'false') $(`${sessionElementID} .card-title`).trigger("click");
                }

                //Clear & BIND DATA                                                    
                $(ele).empty().append(playerString);
            } else {
                $(ele).empty();
                const sessionElementID = `#${session}Card${roundId}`; //Check and close Session accordion
                const isSessionExpanded = $(`${sessionElementID} .card-title`).attr("aria-expanded");
                if (isSessionExpanded == 'true') $(`${sessionElementID} .card-title`).trigger("click");
            }
        });

    }

    //#endregion

    return(<>
        <TournamentList tournamentID={tournamentID} setTournamentID={setTournamentID} title={"Draw"}  comp={"draw"} onSelectHandler={onSelectHandler}  resetData={onReset}>
            <div className="kt-portlet">
                <div className="kt-portlet__body padding-t10 padding-b0">                                       
                    {!!tMaster && (
                        <div id="tournamentInfoPanel" className="kt-notification margin-t10 mb-3 bg-lightGrey">
                            <div className="kt-notification__item d-block">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__pic">
                                                <img id="txtTournamentImg" src={tMaster.Icon} alt="tournament-logo" />
                                            </div>
                                            <div className="kt-user-card-v2__details ">
                                                <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tMaster?.Name} </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtType"> {tMaster?.Type} </span> | <span id="txtMembership">  {tMaster?.MembershipType} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name "> Start </span>
                                                <span className="kt-user-card-v2__desc">
                                                    <span id="txtStartDate"> {moment(tMaster?.StartDate).format('DD-MMM-YYYY')} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name"> End </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtEndDate"> {moment(tMaster?.EndDate).format('DD-MMM-YYYY')} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {!!selectedRound && (<>
                                        <div className="col-2 d-flex-center startType-holder">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name "> Tee Off Option </span>
                                                    <span className="kt-user-card-v2__desc">
                                                        <span id="txtStartType">{selectedRound?.TeeOffOption} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex-center startType-holder">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name "> Format </span>
                                                    <span className="kt-user-card-v2__desc">
                                                        <span id="txtStartType">{selectedRound?.Format} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    )}
                    
                    <div id="ControlHolder" className="kt-form kt-form--label-right" role="form">
                        <div className="form-group row margin-b10">
                            <label className="col-form-label col-lg-5"> Round </label>
                            <div className="col-lg-2">
                                <select id="ddlRound" title="Select Round" name="ddlRound" className="form-control kt-selectpicker show-tick" onChange={({target}) => onRoundChange(target.value)}>
                                    <option value={0}> select round </option>
                                    {(!!tournamentRounds && tournamentRounds?.length > 0 ) && tournamentRounds.map(r=>(
                                        <option value={r.ID} key={r.ID}> {`Round ${r.Round} (${moment(r.Date).format('DD MMM YYYY')})`}  </option>
                                    ))}
                                </select>
                            </div>
                            <div className="kt-portlet__head-toolbar offset-2 col-lg-2">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions"></div>                                                                             
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="kt-portlet__body kt-portlet__body--fit padding-lr5">
                        {(!!selectedRound && !!pageData) && (
                            <div id="pnlDrawMaster" className="row pnlDrawMaster mt-3 prevent-select">

                                <div id="pnlDrawHolder" className="col-9">                                      
                                    {selectedRound?.TeeOffOption?.includes('Split')?
                                    (<SplitDrawTable selectedRound={selectedRound} pageData={pageData} tMaster={tMaster} createDragable={createDragable}/>)
                                    :(<DrawTable selectedRound={selectedRound} pageData={pageData} tMaster={tMaster} createDragable={createDragable} />)}                                                                      
                                </div>

                                <div id="pnlPlayerHolder" className="col-3">
                                    <div className="sticky">
                                        <table id="tblPlayers" className="table table-bordered table-register">
                                            <thead className="light-grey-background">
                                                <tr><th width="10%">Players</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="padding-5 vertical-align-base height-50">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="text" id="playerSearch" name="playerSearch" className="form-control" placeholder="Search Player"
                                                                    onChange={({target})=> onPlayerSearch(target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>                                      
                                            </tbody>
                                        </table>
                                        
                                        <div id="pnlDates" className="sticky padding-tb10">
                                            <div className="accordion  accordion-toggle-arrow" id="dateAccordion">
                                                {createPlayerList()}                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        )}                   
                    </div>

                    {/* <%--FOOter--%> */}
                    {!!selectedRound && (
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={()=> postDrawTable('N')}> Save </button>

                                        {selectedRound?.IsDrawPublish == "Y" ?(
                                            <button id="btnUnPublish" type="button" className="btn btn-warning d-inline-block mx-2" onClick={()=> updatePublish('N')}> UnPublish </button>
                                        ):(
                                            <button id="btnPublish" type="button" className="btn btn-success d-inline-block mx-2" onClick={()=> postDrawTable('Y')}> Save & Publish </button>
                                        )}                                                                                

                                        {(pageData?.IsScoringStarted || pageData?.TournamentDraw == 0) ? null : (
                                            <button id="btnDelete" type="button" className="btn btn-danger d-inline-block margin-l10" onClick={deleteDraw}><i className="fa fa-trash"></i> Delete </button>
                                        )}
                                        {(pageData && pageData.TournamentDraw.length > 0) && (<button type="button" id="btnEmailCard" onClick={emailScorCard} name="btnEmailCard" className="btn btn-success d-inline-block margin-l10 "> Email Draw </button>)}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </TournamentList>    
    </>)
}

export default TournamentDraw;