import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert2';
import { emailTournamentWinner, getLeaderboard, getLeaderboardGross, getPositionMasterAll, getTournamentBackScore, getTournamentData, getTournamentScorecard, getWinnerByTournamentIdAndCategoryId, postTournamentWinner } from '../api/tournamentAPI';
import { useHistory } from 'react-router-dom'
import RightSideDetails from '../global/rightSideDetails';
import { PointModal } from './leaderBoardPlayer';
import StableFordGross from './format/StableFordGross';
import ModifiedStableford from './format/ModifiedStableford';
import Bogey from './format/Bogey';
import StableFordNet from './format/StableFordNet';
import StrokePlayGross from './format/StrokePlayGross';
import StrokePlayNet from './format/StrokePlayNet';
import FourBall from './format/Fourball';
import CumulativeFourBall from './format/CumulativeFourball';
import Foursomes from './format/Foursomes';
import Greensomes from './format/Greensomes';
import Scramble from './format/Scramble';
import Loading from '../global/loading';
import ScrambleStrokePlayNet from './format/ScrambleStrokePlayNet';

export const BackScoreModal =(props)=> {

    useEffect(() => {
        $("#modalTie").modal({ backdrop: "static" });      
        $("#modalTie").on("hidden.bs.modal",()=>{             
            props?.onDismissModal() 
        });    
    }, [props?.show]);

    return(
        <>
            <div className="modal fade" id="modalTie" tabIndex="-1" role="dialog" aria-hidden="true">       
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTie-label">Back Scores (for tie)</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table id="tblModal" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>{props.backScore.Name}</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Back Nine</th>
                                        <td>{props.backScore.Nine}</td>
                                    </tr>
                                    <tr>
                                        <th>Back Six</th>
                                        <td>{props.backScore.Six}</td>
                                    </tr>
                                    <tr>
                                        <th>Back Three</th>
                                        <td>{props.backScore.Three}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const WinnerList = (props) => {

    const dropdownValues = [
        {ID: 1, Name: 'Winner'}, 
        {ID: 2, Name: 'First Runner Up'}, 
        {ID: 3, Name: 'Second Runner Up'}, 
        {ID: 4, Name: 'Third Runner Up'}, 
        {ID: 5, Name: 'Fourth Runner Up'}
    ];


    const param = useParams();
    const [tournamentDetails,       setTournamentDetails]           = useState(null)
    const [tournamentRound,         setTournamentRound]             = useState();
    const [tournamentCategory,      setTournamentCategory]          = useState([]);
    const [selectedCategory,        setSelectedCategory]            = useState();
    const [grossCategoryValue,      setGrossCategoryValue]          = useState();
    const [format,                  setFormat]                      = useState();
    const [tableWinner,             setTableWinner]                 = useState([]);
    const [winnerList,              setWinnerList]                  = useState([]);
    const [tableBoard,              setTableBoard]                  = useState([]);
    const [positionMaster,          setPositionMaster]              = useState([]);
    const [openBackScoreModal,      setOpenBackScoreModal]          = useState(null);
    const [selectedPlayer,          setSelectedPlayer]              = useState(null);
    const [gameDetails,             setGameDetails]                 = useState(null);
    const [pointData,               setPointData]                   = useState([]);
    const [scorecardStatus,         setScorecardStatus]             = useState('');
    const [showPointModal,          setShowPointModal]              = useState(false);
    const [loading,                 setLoading]                     = useState(false);
    const [position,                setPosition]                    = useState([]);
    const [comments,                setComments]                    = useState(Array(winnerList.length).fill(''));
    const [tournamentDraw,          setTournamentDraw]              = useState();
    const [playerList,              setPlayerList]                  = useState([]);
    
    const history = useHistory();

    useEffect(() => {
        props.title("Winners", 'tournament-winner');
        fetchTournamentData(param.id)
    }, []);

    useEffect(() => {
        if (!!selectedCategory) {
            if (selectedCategory == grossCategoryValue) {
                setFormat({ Format: 'Stroke Play Gross' })
            } else {
                if (!!tournamentRound) {
                    setFormat(tournamentRound)
                }
            }
        }
        if (!!selectedCategory && !!tournamentRound) {
            fetchTournamentLeaderboard(tournamentRound.Round, selectedCategory)
        }

    }, [selectedCategory, tournamentRound])

    useEffect(() => {
        if (winnerList && winnerList.length > 0) {
            const updatedPositions = [];
            const updatedComments = [];
            tableWinner.forEach((_, index) => {
                const winnerData = winnerList[index] || {};
                updatedPositions.push(winnerData.PositionID || '');
                updatedComments.push(winnerData.Comment || '');
            });
            setComments(updatedComments);
            const newPosition = updatedPositions.map(p => {
                const dd = dropdownValues.find(d => d.ID == p)
                return dd
            })
            setPosition(newPosition);
        } else {
            setPosition(positionMaster)
        }
    }, [winnerList, tableWinner, positionMaster])

    useEffect(() => {
        if (selectedPlayer) {
            Config.Core.OpenDrawer()
        }
    }, [selectedPlayer]);

    const handleChange = (index, newPosition) => {
        const updatedPositions = [...position];
        updatedPositions[index] = newPosition;
        setPosition(updatedPositions);
    };

    const handleCommentChange = (index, newComment) => {
        const updatedComments = [...comments];
        updatedComments[index] = newComment;
        setComments(updatedComments);
      };

    const fetchScorecard = async (drawID) => {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});        

            const response = await getTournamentScorecard(drawID);
            if(response?.TournamentPoint && response?.TournamentPoint.length > 0) setPointData(response.TournamentPoint);
            setGameDetails(response.Score);     
            if (response?.players) setPlayerList(response.players)
            setTournamentDraw(response.TournamentDraw)       
            swal.close();
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message, onOpen:() => swal.hideLoading() })
        }
    }

    const fetchTournamentData = async (tournamentId) => {
        try {
            const res = await getTournamentData(tournamentId);
            let tCategory = res.tCategory
            let isGross = tCategory.find(c => c.IsGross == "Y");
            if (!!isGross) tCategory = [...tCategory, { ID: res.GrossCategoryValue, Name: "Gross", IsGross: "Y" }];
            setTournamentDetails(res.tournament)
            const round = res.tRounds[res.tRounds.length - 1]
            setSelectedCategory(tCategory[0].ID)
            setTournamentRound(round)
            setTournamentCategory(tCategory)
            setGrossCategoryValue(res.GrossCategoryValue);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchTournamentLeaderboard = async (round, categoryID) => {
        try {
            setLoading(true);
            let res;
            if (categoryID == grossCategoryValue) {
                res = await getLeaderboardGross(param.id, round);
            } else {
                res = await getLeaderboard(param.id, round, categoryID);
            }
            const position = await getPositionMasterAll();
            const winners = await getWinnerByTournamentIdAndCategoryId(param.id, categoryID)
            setWinnerList(winners)
            if (res && res.length > 0) {
                const list = res[0].List
                setTableWinner(list.slice(0, 5))
                setTableBoard(list.slice(5))
            }
            setPositionMaster(position.slice(0, 5))
            setLoading(false);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const showTie = async (drawId, name) => {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});        
            const backScore = await getTournamentBackScore(drawId);
            setOpenBackScoreModal({...backScore, Name:name})
            swal.close();
        } catch (error) {
            swal.close();
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const formatValue = (value) => {
        if (value > 0) return `+ ${value}`;
        if (value === 0) return 'E';
        return value;
    };

    const openScorecard = (tDetails) => {
        setSelectedPlayer(tDetails);
        fetchScorecard(tDetails.DrawID)
    };

    const handleSave = async () => {
        let isDuplicate = false;
        let winners = [];

        $('.row-winner').each((index, element) => {
            let categoryID = selectedCategory

            if (selectedCategory == grossCategoryValue) categoryID = grossCategoryValue;   //Assign -2 if gross is Selected
            winners.push({
                ID: 0,
                TournamentCategoryID: (categoryID || 0),
                TournamentRegisterID: parseInt($(element).attr('data-register-id') || 0),
                PositionID: parseInt($(element).find('.form-status').val() || 0),
                Type: "All",
                Comment: $(element).find('.form-comment').val()
            });

            let filteredWinner = winners.filter(i => i.PositionID == parseInt($(element).find('.form-status').val() || 0));
            if (filteredWinner.length > 1) {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            swal.fire({
                icon: "error",
                titleText: 'Oops..',
                text: 'Position cannot be same',
                buttonsStyling: false,
                confirmButtonClass: "btn btn-brand",
            });
            return;
        }
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});        
            await postTournamentWinner(param.id, winners);
            swal.close();
            swal.fire({
                icon: 'success',
                titleText: 'Tournament Winner Saved Successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message, onOpen:() => swal.hideLoading() })
        }
    }

    const handleEmail = async () => {
        try {   

            let winners = [];

            $('.row-winner').each((index, element) => {
                const dropdowns = document.querySelectorAll('.form-status');
                winners.push({
                    Position: tableWinner[index].Position,
                    Player: tableWinner[index].Player,
                    Status: $(dropdowns[index]).find('option:selected').text(),
                    Comment: tableWinner[index].Comment ? tableWinner[index].Comment : "",
                    Thru: tableWinner[index].Thru,
                    Total:tableWinner[index].Total
                })
            })

            $('.row-participant').each((index, element) => {
                winners.push({
                    Position: tableBoard[index].Position,
                    Player: tableBoard[index].Player,
                    Status: "",
                    Comment: "",
                    Thru: tableBoard[index].Thru,
                    Total:tableBoard[index].Total
                })
            })

            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false});
            await emailTournamentWinner(tournamentDetails.ID, winners)
            swal.close();
            swal.fire({
                icon: 'success', timer: 1500, showConfirmButton: false,
                titleText: 'Email sent Successfully.',
            });    
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }
    }

    const handleBack = () => {
        history.push(`/tournament-winner`);
    }

    const handleExport = () => {

    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => { 
            setSelectedPlayer(null);
            setGameDetails(null); 
        }, 200);
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop: '0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile padding-lr20">
                        <div className="kt-portlet__head kt-portlet__head--lg  d-block padding-lr0 border-0">
                            <div id="roundInfoPanel" className="kt-notification margin-t10" style={{backgroundColor: "rgb(247, 248, 250)"}}>
                                <div className="kt-notification__item d-block">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__pic">
                                                    <img id="txtTournamentImg" src={tournamentDetails?.Icon} alt="tournament-logo" />
                                                </div>
                                                <div className="kt-user-card-v2__details ">
                                                    <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tournamentDetails?.Name} </span>
                                                    <span className="kt-user-card-v2__desc ">
                                                        <span id="txtType"> {tournamentDetails?.Type} </span> | <span id="txtMembership">  {tournamentDetails?.MembershipType} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {(tournamentCategory && tournamentCategory.length && tournamentCategory.find(t => t.Name != 'Default')) && (
                                            <div className="col-3 d-flex-center" id="pnlCategory">
                                                <div className="kt-user-card-v2 mb-2">
                                                    <div className="kt-user-card-v2__details ">
                                                        <span className="kt-user-card-v2__name ">Category </span>
                                                        <div className="kt-user-card-v2__desc">
                                                            <div className="input-group">
                                                                <select 
                                                                    id="ddlCategory" 
                                                                    title="Select Category" 
                                                                    name="ddlCategory" 
                                                                    value={selectedCategory || ''}
                                                                    onChange={(event) => setSelectedCategory(event.target.value)} 
                                                                    className="form-control kt-selectpicker show-tick"
                                                                >
                                                                    {(!!tournamentCategory && tournamentCategory.length > 0) && tournamentCategory.map((r, i) => (
                                                                        <option value={r.ID} key={r.ID}>{r.Name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-2 d-flex-center">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name ">Start </span>
                                                    <span className="kt-user-card-v2__desc">
                                                        <span id="txtStartDate">{moment(tournamentDetails?.StartDate).format('DD-MMM-YYYY')}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex-center">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name">End </span>
                                                    <span className="kt-user-card-v2__desc ">
                                                        <span id="txtEndDate">{moment(tournamentDetails?.EndDate).format('DD-MMM-YYYY')}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex-center">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name ">Format </span>
                                                    <span className="kt-user-card-v2__desc ">
                                                        <span id="txtFormat">{format?.Format}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit padding-t10 mb-3">
                            <div id="tblWinner" className="kt-datatable table-striped clickable custom-table-border kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                {loading ? (<Loading/>) : (
                                    (tableWinner && tableWinner.length > 0) && !!positionMaster ? (
                                        <table className="kt-datatable__table" style={{display: 'block'}}>
                                            <thead className="kt-datatable__head">
                                                <tr className="kt-datatable__row" style={{left: '0px'}}>
                                                    <th className="kt-datatable__cell" style={{ width: '5%'}}><span>Pos</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '8%'}}><span>Slot</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '19%'}}><span>Player</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '18%'}}><span>Status</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '18%'}}><span>Comment</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '8%'}}><span>Total</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '5%'}}><span>Thru</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '5%'}}><span>Point</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '7%'}}><span>Verified</span></th>
                                                    <th className="kt-datatable__cell" style={{ width: '7%'}}><span></span></th>
                                                </tr>
                                            </thead>
                                            <tbody className="kt-datatable__body ps">
                                                {!!tableWinner && tableWinner.map((element, index) => {
                                                    const isTie = element.Position.includes('T');
                                                    const total = selectedCategory == grossCategoryValue
                                                        ? formatValue(element.Total)
                                                        : element.Total;
                                                    const point = selectedCategory == grossCategoryValue
                                                        ? formatValue(element.Point)
                                                        : element.Point;

                                                    return (
                                                        <tr key={index} className="row-winner kt-datatable__row" style={{ left: '0px', background: '#0abb871a' }} data-register-id={element.RegisterID} data-category-id={element.CategoryID}>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Position}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '8%' }}><span>{element.Slot}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '19%' }}><span>{element.Player}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '18%' }}>
                                                                <span>
                                                                    <select
                                                                        title="Select Position"
                                                                        className="form-control kt-selectpicker show-tick form-status"
                                                                        data-live-search="true"
                                                                        value={position[index].ID}
                                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                                    >
                                                                        {dropdownValues.map((e, i) => (
                                                                            <option key={i} value={e.ID}>{e.Name}</option>
                                                                        ))}
                                                                    </select>
                                                                </span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '18%' }}>
                                                                <span>
                                                                    <input value={comments[index]}
                                                                        onChange={(e) => handleCommentChange(index, e.target.value)}
                                                                        type="text" className="form-control form-comment" />
                                                                </span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '8%' }}>
                                                                <span>{element.Total == null ? '-' : total}{isTie && <small><button type="button" className="btn btn-xs btn-link padding-0" onClick={() => showTie(element.DrawID, element.Player)}>(TIE)</button></small>}</span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Thru}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Point == null ? '-' : point}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '7%' }}>
                                                                <span>{element.IsVerified === "Y" ? <span className="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10">Yes</span> : <span className="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>}</span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '7%' }}>
                                                                {selectedCategory > 0 && (
                                                                    <span>
                                                                        <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-sm btn-template-edit" onClick={() => openScorecard(element)}>
                                                                        <i className="fa fa-address-card fs-17"></i>
                                                                        </button>
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )

                                                })}
                                                {(tableWinner && tableWinner.length > 0) && (
                                                    <tr className="kt-datatable__row" style={{ left: '0px' }}>
                                                        <td colSpan="10" className="kt-datatable__cell" style={{ width: '100%' }}>
                                                        <button id="btnSave" type="button" className="btn btn-primary pull-left" onClick={handleSave}>Save Tournament Winner</button>
                                                            {/* <button id="btnExport" type="button" className={`btn btn-label-brand pull-right margin-10 ${winnerList.length ? '' : 'hidden'}`} onClick={handleExport}>Export To Excel</button> */}
                                                            <button id="btnEmail" type="button" className="btn btn-label-brand pull-right margin-10" onClick={handleEmail}>Email Tournament Winner</button>
                                                            <button id="btnBack" type="button" className="btn btn-secondary pull-left margin-10" onClick={handleBack}>Back</button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {!!tableBoard && tableBoard.map((element, index) => {
                                                    const isTie = element.Position.includes('T');
                                                    const tieButton = isTie ? (
                                                        <small>
                                                        <button type="button" className="btn btn-xs btn-link padding-0" onClick={() => showTie(element.DrawID, element.Player)}>(TIE)</button>
                                                        </small>
                                                    ) : null;
                                                    return (
                                                        <tr key={index} className="row-board kt-datatable__row" style={{ left: '0px' }}>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Position}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '8%' }}><span>{element.Slot}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '55%' }}><span>{element.Player}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '0%' }}></td>
                                                            <td className="kt-datatable__cell" style={{ width: '0%' }}></td>
                                                            <td className="kt-datatable__cell" style={{ width: '8%' }}>
                                                                <span>
                                                                    {element.Total == null ? '-' : element.Total}
                                                                    {tieButton}
                                                                </span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Thru}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '5%' }}><span>{element.Point == null ? '-' : element.Point}</span></td>
                                                            <td className="kt-datatable__cell" style={{ width: '7%' }}>
                                                                <span>
                                                                {element.IsVerified === "Y" ? (
                                                                    <span className="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10">Yes</span>
                                                                ) : (
                                                                    <span className="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10">No</span>
                                                                )}
                                                                </span>
                                                            </td>
                                                            <td className="kt-datatable__cell" style={{ width: '7%' }}>
                                                                <span>
                                                                <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-sm btn-template-edit" onClick={() => openScorecard(element)}>
                                                                    <i className="fa fa-address-card fs-17"></i>
                                                                </button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='text-center'>
                                            <span className="empty-tbl-text">Winners Not Declared</span>
                                        </div>
                                    )
                                )}
                            </div>
                            {selectedPlayer && (
                                <RightSideDetails title="Tournament Scorecard" onCloseDrawer={() => closeDrawer()}>
                                    {gameDetails && selectedPlayer.Format == 'Gross Stableford' && (
                                        <StableFordGross
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                            pointData={pointData}
                                            setShowPointModal={setShowPointModal}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Modified Stableford' && (
                                        <ModifiedStableford
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                            pointData={pointData}
                                            setShowPointModal={setShowPointModal}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Bogey' && (
                                        <Bogey
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                            pointData={pointData}
                                            setShowPointModal={setShowPointModal}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Net Stableford' && (
                                        <StableFordNet
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            tournamentDraw={tournamentDraw}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                            pointData={pointData}
                                            setShowPointModal={setShowPointModal}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Stroke Play Gross' && (
                                        <StrokePlayGross
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Stroke Play Net' && (
                                        <StrokePlayNet
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentRound={tournamentRound}
                                            format={format}
                                            gameDetails={gameDetails}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Fourball Betterball' && (
                                        <FourBall
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Betterball Bogey' && (
                                        <BetterballBogey
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == 'Cumulative Stableford' && (
                                        <CumulativeFourBall
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == "Foursomes" && (
                                        <Foursomes
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == "Greensome" && (
                                        <Greensomes
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == "Scramble" && (
                                        <Scramble
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                    {gameDetails && selectedPlayer.Format == "Scramble - Stroke Play Net" && (
                                        <ScrambleStrokePlayNet
                                            playerList={playerList}
                                            tournamentDetails={tournamentDetails}
                                            tournamentDraw={tournamentDraw}
                                            tournamentRound={tournamentRound}
                                            gameDetails={gameDetails}
                                            currentRound={format}
                                            format={format}
                                            selectedPlayer={selectedPlayer}
                                            setScorecardStatus={setScorecardStatus}
                                            scorecardStatus={scorecardStatus}                                                                                                                   
                                            pointData = {pointData}
                                            setShowPointModal={setShowPointModal}
                                            closeDrawer={closeDrawer}
                                            fetchTournamentLeaderboard={() => fetchTournamentLeaderboard(selectedPlayer.Round, selectedCategory)}
                                        />
                                    )}
                                </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {openBackScoreModal && (
                <BackScoreModal
                    show={openBackScoreModal}
                    onDismissModal={()=> setOpenBackScoreModal(null)}
                    backScore={openBackScoreModal}
                />
            )}
            {showPointModal && (
                <PointModal
                    show={showPointModal}
                    onDismissModal={()=> setShowPointModal(false)}
                    pointData={pointData}
                />
            )}
        </>
    )
}

export default WinnerList