import { getAuthState } from "../../assets/scripts/login-util";
import { axiosGet, axiosPost, axiosDelete, axiosPatch } from "./axiosFunctions";

//#region Api URL's --> 
const getMasterPageDataAllUrl   = '/api/admin/tournament/getMasterPageData';
const getTournamentMasterAllUrl = '/api/admin/tournament/getTournamentMasterAll';
const postTournamentUrl         = '/api/admin/tournament/postTournament';
const postTournamentWinnerUrl   = '/api/admin/tournament/postTournamentWinner';
const getTournamentByIDUrl          = (tournamentId) => `/api/admin/tournament/getTournamentByID/${tournamentId}`;

const updateTournamentPublishUrl = '/api/admin/tournament/updateTournamentPublish';
const updateTournamentDrawPublishUrl = '/api/admin/tournament/updateTournamentDrawPublish';
const updateTournamentRoundUrl = '/api/admin/tournament/updateTournamentRound';

const postTournamentTeeUrl      = '/api/admin/tournament/postTournamentTee';
const getTournamentTeeAllUrl    = '/api/admin/tournament/getTournamentTeeAll';
const getTeeSlotByTournamentTeeIDUrl    = '/api/admin/tournament/getTeeSlotByTournamentTeeID';
const getTournamentBackScoreUrl   = '/api/admin/tournament/getTournamentBackScore';
const getPositionMasterAllUrl   = '/api/admin/tournament/getPositionMasterAll';
const getWinnerByTournamentIdAndCategoryIdUrl      = '/api/admin/tournament/getWinnerByTournamentIdAndCategoryId';
const getTournamentDataUrl      = '/api/admin/tournament/getTournamentData';
const getTournamentRoundByTournamentIDUrl = '/api/admin/tournament/getTournamentRoundByTournamentID';
const getLeaderboardUrl         = '/api/admin/tournament/getLeaderboard';
const getLeaderboardGrossUrl    = '/api/admin/tournament/getLeaderboardGross';
const getTournamentScorecardUrl = '/api/admin/tournament/getTournamentScorecard';
const postScoreCardUrl          = '/api/admin/tournament/postScoreCard';
const deleteScoreCardUrl        = '/api/admin/tournament/deleteScoreCard';
const deleteBookingUrl          = (id, pswd) => `/api/admin/tournament/deleteTournament/${id}/${pswd}`;
const emailDrawUrl              = (tournamentId, round) => `/api/admin/tournament/emailDraw/${tournamentId}/${round}`;
const emailLeaderboardUrl       = (tournamentId, round) => `/api/admin/tournament/emailLeaderboard/${tournamentId}/${round}`;
const sendScorecardMailUrl      = `/api/admin/tournament/sendScorecardMail`;
const sendFourballScorecardMailUrl = `/api/admin/tournament/sendFourballScorecardMail`;
const sendFoursomeScorecardMailUrl = `/api/admin/tournament/sendFoursomeScorecardMail`;
const emailTournamentWinnerUrl     = `/api/admin/tournament/emailTournamentWinner`;

const getRegistrationDataUrl    = '/api/admin/tournament/getRegistrationData';

const postRegisterUrl    = '/api/admin/tournament/postTournamentRegistration';
const deleteRegisterUrl  = (registerId, type) => `/api/admin/tournament/deleteTournamentRegistration/${registerId}/${type}`;

const getTournamentDrawDataUrl = (tournamentId, roundId) => `/api/admin/tournament/getTournamentDrawData/${tournamentId}/${roundId}`;

const postDrawUrl    = '/api/admin/tournament/postTournamentDraw';
const deleteTournamentDrawUrl =(roundId)=>`/api/admin/tournament/deleteTournamentDraw/${roundId}`;

//#endregion

export const getMasterPageData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMasterPageDataAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTournamentByID = async(tournamentId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( 
        getTournamentByIDUrl(tournamentId), 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}

export const getTournamentMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getTournamentMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTournamentData = async(tournamentId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTournamentDataUrl}/${tournamentId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getWinnerByTournamentIdAndCategoryId = async(tournamentId, categoryId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getWinnerByTournamentIdAndCategoryIdUrl}/${tournamentId}/${categoryId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getPositionMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getPositionMasterAllUrl}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTournamentBackScore = async (drawId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTournamentBackScoreUrl}/${drawId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTournamentTeeAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTournamentTeeAllUrl}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTeeSlotByTournamentTeeID = async (teeID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTeeSlotByTournamentTeeIDUrl}/${teeID}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postTournamentWinner = async (tournamentId, winners) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postTournamentWinnerUrl,
      { tournamentId, winners },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTournamentTee = async (tee, slotList, deletedSlot) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postTournamentTeeUrl,
      { tee, slotList, deletedSlot },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTournamentData = async (tournamentMaster, tournamentRound, tournamentCategory) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postTournamentUrl,
      { tournamentMaster, tournamentRound, tournamentCategory },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTournamentRoundByTournamentID = async(tournamentID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTournamentRoundByTournamentIDUrl}/${tournamentID}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getLeaderboard = async(tournamentID, round, categoryID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getLeaderboardUrl}/${tournamentID}/${round}/${categoryID}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getLeaderboardGross = async(tournamentID, round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getLeaderboardGrossUrl}/${tournamentID}/${round}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getTournamentScorecard = async(drawID, hole) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getTournamentScorecardUrl}/${drawID}/${hole}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteScorecard = async(drawID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete( `${deleteScoreCardUrl}/${drawID}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postScoreCard = async (scoreCard) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postScoreCardUrl, { scoreCard },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteTournamentData = async (tournamentId, password) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteBookingUrl(tournamentId, password),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getRegistrationData = async(tournamentId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getRegistrationDataUrl}/${tournamentId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postTournamentRegistration = async (tournamentRegister) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postRegisterUrl, { tournamentRegister},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteTournamentRegister = async (registerId, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteRegisterUrl(registerId, type),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTournamentDrawData = async(tournamentId, roundId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( 
        getTournamentDrawDataUrl(tournamentId, roundId), 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}


export const postTournamentDraw= async (roundId, tournamentDraw, deletedDraw, isPublish) => {  
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postDrawUrl, { roundId: roundId, tournamentDraw, deletedDraw, isPublish},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteTournamentDraw = async (roundId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteTournamentDrawUrl(roundId),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateTournamentPublish= async (tournamentId, isPublish) => { 

    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        updateTournamentPublishUrl, { tournamentId, isPublish },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateTournamentDrawPublish= async (roundId, isPublish) => { 

    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        updateTournamentDrawPublishUrl, { roundId, isPublish },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateTournamentRound = async (tournamentRound) => { 

    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPatch(
        updateTournamentRoundUrl, { tournamentRound },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const emailDraw = async(tournamentId, round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( 
        emailDrawUrl(tournamentId, round), 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}

export const emailLeaderboard = async(tournamentId, round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( 
        emailLeaderboardUrl(tournamentId, round), 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}

export const sendScorecardMail = async (playerNameList, scoreCardList, par, userTotal, footerTotalScore, tournament, totalNetScore = 0) => { 

    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        sendScorecardMailUrl, { playerNameList, scoreCardList, par, userTotal, footerTotalScore, tournament, totalNetScore },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const sendFourballScorecardMail = async (playerNameList, scoreCardList, par, footerTotalScore, tournament) => { 
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        sendFourballScorecardMailUrl, { playerNameList, scoreCardList, par, footerTotalScore, tournament },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const sendFoursomeScorecardMail = async (playerNameList, scoreCardList, par, userTotal, totalNetScore, footerTotalScore, tournament) => { 
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        sendFoursomeScorecardMailUrl, { playerNameList, scoreCardList, par, userTotal, totalNetScore, footerTotalScore, tournament },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const emailTournamentWinner = async(tournamentId, winnerList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( 
        emailTournamentWinnerUrl, { tournamentId, winnerList }, 
        { headers: { auth_token: `bearer ${token}` } } 
    );
    return response;
}