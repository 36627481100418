import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";


const getViewUserAllUrl                                  = '/api/admin/users/getViewUserAll';
const getGolfUsersUrl                                    = (type) =>`/api/admin/users/getGolfUsers/${type}`;
const getUserAllUrl                                      = '/api/admin/users/getUserAll';
const getMobileCodeUrl                                   = '/api/admin/users/getMobileCodeAll';
const getGuestAllUrl                                     = '/api/admin/users/getGuestAll';
const getMobileCodeAllUrl                                = '/api/admin/users/getMobileCodeAll';
const addUserUrl                                         = '/api/admin/users/addUser';
const postMemberUrl                                      = '/api/admin/users/postMember';
const updateMemberGroupUrl                               = '/api/admin/users/updateMemberGroup';
const verifyMembershipNoUrl                              = '/api/admin/users/verifyMembershipNo';
const verifyMobileNoUrl                                  = '/api/admin/users/verifyMobileNo';
const postMemberDocumentUrl                              = '/api/admin/users/postMemberDocument';
const getMemberDocumentsByGroupIDUrl                     = (groupID) => `/api/admin/users/getMemberDocumentsByGroupID/${groupID}`;
const deleteMemberDocumentsByIDAndGroupIDUrl             = (id, groupID) => `/api/admin/users/deleteMemberDocumentsByIDAndGroupID/${id}/${groupID}`;
const postGuestUrl                                       = '/api/admin/users/postGuest';
const sendGuestOTPUrl                                    = (mobile, id) => `/api/admin/users/sendGuestOTP/${mobile}/${id}`;
const updateUserHandicapUrl                              = (id, handicap, handicapIndex) => `/api/admin/users/updateUserHandicap/${id}/${handicap}/${handicapIndex}`;
const processFileUrl                                     = '/api/admin/users/processFile';

export const getViewUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getViewUserAllUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getGolfUsers = async (type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
      getGolfUsersUrl(type),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUserAllUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addUser = async (userList, memberGroup, subscriptionValidity, documentList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        addUserUrl,
      {  userList, memberGroup,subscriptionValidity, documentList},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postMember = async (user) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      postMemberUrl,
      {  user },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateMemberGroup = async (memberDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      updateMemberGroupUrl,
      {  memberDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};


export const verifyMembershipNo = async (membershipNo) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      verifyMembershipNoUrl,
      {  membershipNo },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const verifyMobileNo = async (mobileNo) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      verifyMobileNoUrl,
      {  mobileNo },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postMemberDocument = async (documentDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postMemberDocumentUrl,
    {  documentDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
}

export const getMemberDocumentsByGroupID = async (groupID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getMemberDocumentsByGroupIDUrl(groupID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deleteMemberDocumentsByIDAndGroupID = async (id, groupID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosDelete(
     deleteMemberDocumentsByIDAndGroupIDUrl(id, groupID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getMobileCode = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getMobileCodeUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getGuestAll = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getGuestAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getMobileCodeAll = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getMobileCodeAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postGuest = async (guest, otp, sessionId) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postGuestUrl,
    {  guest, otp, sessionId },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendGuestOTP = async (mobile, id) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    sendGuestOTPUrl(mobile, id),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updateUserHandicap = async (id, handicap, handicapIndex) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    updateUserHandicapUrl(id, handicap, handicapIndex),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const processFile = async (userHandicap, selectedDate) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    processFileUrl,
    { userHandicap, selectedDate },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};