import { useState, useEffect } from "react";
import { getRoundCase, getDates, getFormatValue } from "./tournamentHelper";
import moment from "moment";
import { getTeeSlotByTournamentTeeID } from "../api/tournamentAPI";


const TournamentRoundTable=(props)=> {
    const ShotgunString      = "Shot Gun Start";
    const SplitShotgunString = "Split Shot Gun Start";
    const FormatsWithInputs  = ['Net Stableford', 'Foursomes', 'Scramble', 'Greensome', 'Fourball Betterball', 'Cumulative Stableford', 'Betterball Bogey', 'Scramble - Stroke Play Net'];
       
    const [pageData,        setPageData]        = useState({});
    //#region Effects     
    // useEffect(()=> setRoundData(),[]);

    useEffect(() => {
        setRoundData()
    }, [])

    //#endregion

    const setRoundData =()=> {       
        // const { tMaster, tData, isEdit } = props;
        const { tMaster, tData} = props;

        const currentCase = getRoundCase(tMaster?.Days, tMaster?.Round);
        const roundDates  = getDates(tMaster?.StartDate, tMaster?.EndDate);

        let pData = { RoundCase: currentCase, Dates: roundDates, Rounds: tMaster.Round };
        if(!!tData) {           
            pData.TeeOffOptions        = tData.TeeOffOptions;
            pData.Formats              = tData.TournamentFormats.filter(f => f.Type == tMaster.Type);
            pData.TournamentTee        = tData.TournamentTee;
            pData.AfternoonSessionTime = tData.AfternoonSessionTime;
        }
        
        setPageData(pData);
              
        setTimeout(() =>{
            $(`.slot-picker`).timepicker({ minuteStep: 10, defaultTime: '07:00 AM' }).on('change', function(){
                const index = $(this).attr('data-index');
                const field = $(this).attr('data-field');
                const value = $(this).val();                
                
                onChangeHandler(field, value, index);
            });               
        }, 500);    
    }
    
    //#region handlers    
   
    const removeRound  = (roundIndex)=>{
        const filtered = props.roundList.filter((r,i) => (i != roundIndex));
        props.setRoundList(filtered);
    }

    const addRound =()=> {
        const { tMaster } = props;

        let rounds       = props.roundList;
        const roundDates = getDates(tMaster?.StartDate, tMaster?.EndDate);
        const roundDt    = roundDates[roundDates.length - 1];

        const defaultFormat = (pageData?.Formats[0]?.Format);
        const formatValue   = getFormatValue(defaultFormat);
                   
        rounds.push({
            ID                     : 0,
            TournamentTeeID        : 0,
            Round                  : tMaster.Round,
            Date                   : roundDt.value,
            Format                 : defaultFormat,
            TeeOffOption           : pageData.TeeOffOptions[0],
            TeeOffTime             : '',
            TeeOffTimeSplit        : '',
            Lower                  : !!formatValue? formatValue.lower  : 0,
            Higher                 : !!formatValue? formatValue.higher : 0,
            MinDrive               : !!formatValue? formatValue.minDrive : 0,
            MorningRegisterLimit   : 0,
            AfternoonRegisterLimit : 0,
            Active                 : 'Y',
        });      

        props.setRoundList(([...rounds]));
    }    

    const onChangeHandler =(field, value, rowIndex)=> { 
        if(['Format','TournamentTeeID', 'TeeOffOption'].includes(field)) {          
            props.setRoundList((d)=>{
                const currentRound = d[rowIndex];        
                //check rounds with same roundNo 
                const newRounds = d?.map((r,i)=>{
                    if(i == rowIndex || r.Round == currentRound?.Round){
                        if(field == 'Format'){ //Change format fields of same rounds
                            const formatValue  = getFormatValue(value);
                            if(i !== rowIndex) r.Format = value;
                            r.Higher   = formatValue ? formatValue?.higher   : 0;
                            r.Lower    = formatValue ? formatValue?.lower    : 0;
                            r.MinDrive = formatValue ? formatValue?.minDrive : 0;
                        }        
                            
                        if(field == 'TournamentTeeID') r.TournamentTeeID = value;  //Change Tee of same rounds
                        if(field == 'TeeOffOption'){ //Change TeeOffOption of same rounds
                            r.TeeOffOption = value;
                            if(!!value && value?.includes("Shot Gun")){                            
                                r.TournamentTeeID = ( pageData?.TournamentTee[0]?.ID || 0)                       
                            }
                        } 
                                                   
                        return ({...r,[field]: value})
                    }else{
                        return r
                    }                    
                });
                return newRounds;    
            });
        }else if(['Higher', 'Lower'].includes(field)){      
            //Handles Dynamic HC_% Calculation 
            props.setRoundList((d)=>{              
                const currentRound = d[rowIndex];     
                const newRounds = d?.map((r,i)=>{
                    if(i == rowIndex || r.Round == currentRound?.Round){                      
                        switch (r?.Format) {
                            case 'Foursomes':
                                if( !value || value == "" || value == 0 || value > 100){
                                    r.Higher = 50;
                                    r.Lower  = 50;
                                }else{
                                    if(field == "Higher"){
                                        let total = value + r?.Lower;
                                        if (total > 100) {
                                            let difference = (total - 100);
                                            r.Higher = value;
                                            r.Lower  = (r?.Lower - difference);                                           
                                        } else {
                                            r.Higher = value;                                            
                                        }
                                    }else{
                                        let total = value + r?.Higher;
                                        if (total > 100) {
                                            let difference = (total - 100);
                                            r.Higher = (r?.Higher - difference);                                           
                                            r.Lower  = value;
                                        } else {
                                            r.Lower = value;                                            
                                        }
                                    }
                                }
                                break;           
                            case 'Greensome':
                                if(!value || value == "" || value == 0 || value > 100){
                                    r.Higher = 60;
                                    r.Lower  = 40;
                                }else{
                                    if(field == "Higher"){
                                        let total = value + r?.Lower;
                                        if (total > 100) {
                                            let difference = (total - 100);
                                            r.Higher = value;
                                            r.Lower  = (r?.Lower - difference);                                           
                                        } else {
                                            r.Higher = value;                                            
                                        }
                                    }else{
                                        let total = value + r?.Higher;
                                        if (total > 100) {
                                            let difference = (total - 100);
                                            r.Higher = (r?.Higher - difference);                                           
                                            r.Lower  = value;
                                        } else {
                                            r.Lower = value;                                            
                                        }
                                    }
                                }
                                break;
                            case 'Scramble':
                                if(!value || value == "" || value == 0 || value >= 50){
                                    r.Higher = 15;
                                    r.Lower  = 35;
                                }else{
                                    if(field == "Higher"){                                      
                                        r.Higher = value;
                                        r.Lower  = (Math.abs(50 - value));     
                                    }else{
                                        r.Higher = (Math.abs(50 - value));    
                                        r.Lower  = value;
                                    }
                                }
                                break;                
                            case 'Cumulative Stableford':
                            case 'Betterball Bogey':
                            case 'Fourball Betterball':
                            case 'Scramble - Stroke Play Net':
                            case 'Net Stableford':                                
                                if(field == "Higher")   
                                    r.Higher = (!value || value == "" || value > 100 || value == 0) ? 100 : value;                                                                                       
                                else                                 
                                    r.Lower  = (!value || value == "" || value > 100 || value == 0) ? 100 : value;                                            
                                break;                    
                        }                                                     
                        return ({...r});
                    }else{
                        return r
                    }                    
                });
                return newRounds;    
            });
        }else if(field == "MinDrive"){
            props.setRoundList((d)=>{              
                const currentRound = d[rowIndex];        
                const newRounds = d?.map((r,i)=> {
                    if(i == rowIndex || r.Round == currentRound?.Round){                      
                        switch (r?.Format) {                                     
                            case 'Greensome':                                
                            case 'Scramble':
                                if(!value || value == "" || value == 0 || value > 9){
                                    r.MinDrive = 9;
                                }else{
                                   r.MinDrive = value;
                                }
                                break;                
                            default:
                                break;
                        }                                                  
                        return ({...r});
                    }else{
                        return r
                    }                    
                });
                return newRounds;    
            });


        }else{
            props.setRoundList((d) => {
                const newRound = d?.map((c,i)=> ((i== rowIndex)? ({...c,[field]: value}) : c)); 
                return newRound;
            });
        }                          
    } 
  
    const getSessionLimitElement = (elementIndex, roundData) => {
        const { AfternoonSessionTime }   = pageData;
        const {TeeOffOption, TeeOffTime, MorningRegisterLimit, AfternoonRegisterLimit} = roundData;
        let isMorning    = 'N'
        let isAfternoon  = 'N'
        if (props.teeSheet && props.teeSheet.length > 0) {
            if(TeeOffOption == 'Staggered Start' || TeeOffOption == 'Split Staggered Start') {
                    const startTime     = moment(props.teeSheet[0].Slot, 'h:mm A');
                    const endTime       = moment(props.teeSheet[props.teeSheet.length - 1].Slot, 'h:mm A');
                    const afternoonTime = moment(AfternoonSessionTime, 'h:mm A');
                    isMorning     = startTime.isBefore(afternoonTime) ? 'Y' : 'N';
                    isAfternoon   = endTime.isSameOrAfter(afternoonTime) ? 'Y' : 'N';
            } else {
                if (TeeOffOption == "Split Shot Gun Start") {
                    isMorning   = "Y";
                    isAfternoon = "Y";
                } else {
                    isMorning = moment(TeeOffTime, 'hh:mm A').isBefore(moment(AfternoonSessionTime, 'hh:mm A')) ? 'Y' : 'N'
                    isAfternoon = isMorning == "Y" ? "N" : "Y";
                }
            }
        }
        return (
            <>
                {isMorning == 'Y' && (
                    <div className= "round-MorningLimit margin-t15" id={`divMorningLimit${elementIndex}`}> <h6>Morning max registration</h6> 
                        <input className="form-control" id={`txtMorningLimit${elementIndex}`} name={`txtMorningLimit${elementIndex}`} type="number"
                            disabled={props?.flags?.IsRegister}
                            value={MorningRegisterLimit || 0} onChange={({target}) => onChangeHandler('MorningRegisterLimit', parseInt(target.value), elementIndex)}
                        />
                    </div>
                )}
                {isAfternoon == 'Y' && (
                    <div className={`round-AfternoonLimit margin-t10`} id={`divAfternoonLimit${elementIndex}`}> <h6>Afternoon max registration</h6> 
                        <input className="form-control" id={`txtAfternoonLimit${elementIndex}`} name={`txtAfternoonLimit${elementIndex}`} type="number"
                            disabled={props?.flags?.IsRegister}
                            value={AfternoonRegisterLimit || 0} onChange={({target}) => onChangeHandler('AfternoonRegisterLimit', parseInt(target.value), elementIndex)}
                        />
                    </div>   
                )}
            </>
        )
        // const isAfternoon = moment(TeeOffTime, 'hh:mm A').isSameOrAfter(moment(AfternoonSessionTime, 'hh:mm A'));        

        // //Hides morning session if shotgun and  teeoff time is morning || visible for other teeOffOptions
        // //Shows afternoon session if shotgun and teeOff time is afternoon || visible for other teeOffOptions
        // return (<>
        //     {(TeeOffOption != ShotgunString|| (TeeOffOption == ShotgunString && !isAfternoon)) &&(
        //         <div className= "round-MorningLimit margin-t15" id={`divMorningLimit${elementIndex}`}> <h6>Morning max registration</h6> 
        //             <input className="form-control" id={`txtMorningLimit${elementIndex}`} name={`txtMorningLimit${elementIndex}`} type="number"
        //                 disabled={props?.flags?.IsRegister}
        //                 value={MorningRegisterLimit || 0} onChange={({target}) => onChangeHandler('MorningRegisterLimit', parseInt(target.value), elementIndex)}
        //             />
        //         </div>   
        //     )}
            
        //     {(TeeOffOption != ShotgunString || (TeeOffOption == ShotgunString && isAfternoon)) && (
        //         <div className={`round-AfternoonLimit margin-t10`} id={`divAfternoonLimit${elementIndex}`}> <h6>Afternoon max registration</h6> 
        //             <input className="form-control" id={`txtAfternoonLimit${elementIndex}`} name={`txtAfternoonLimit${elementIndex}`} type="number"
        //                 disabled={props?.flags?.IsRegister}
        //                 value={AfternoonRegisterLimit || 0} onChange={({target}) => onChangeHandler('AfternoonRegisterLimit', parseInt(target.value), elementIndex)}
        //             />
        //         </div>   
        //     )}
        // </>);
    }

    const getFormatInputs =(elementIndex, roundData)=>{
        let defaultVal = getFormatValue(roundData?.Format);
        return(<>
            <div className="round-handicap row margin-t10">
                {roundData.Format != "Scramble - Stroke Play Net" && (
                    <>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label1} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.Higher}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("Higher", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label2} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.Lower}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("Lower", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                    </>
                )}
                {roundData.Format == "Scramble - Stroke Play Net" && (
                    <>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label1} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.Lower}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("Lower", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label2} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.LowerMiddle}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("LowerMiddle", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label3} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.Middle}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("Middle", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                        <div className={`col-sm-${defaultVal?.isMin? 4 : 6}`}>
                            <span> {defaultVal?.label4} Handicap % </span>
                            <input type="number" className="form-control handicap-per" maxLength={3} 
                                value={roundData?.Higher}  disabled={props?.flags?.IsDrawCreated}
                                onChange={({target})=> onChangeHandler("Higher", parseInt(target.value), elementIndex)} 
                            />
                        </div>
                    </>
                )}
                
                {defaultVal?.isMin && (
                    <div className="col-sm-4">
                        <span> Min Drive </span>
                        <input type="number" className="form-control min-drive" maxLength={2} 
                           value={roundData?.MinDrive} disabled={props?.flags?.IsDrawCreated}
                           onChange={({target})=> onChangeHandler("MinDrive", parseInt(target.value), elementIndex)} 
                        />
                    </div>
                )}
            </div>
        </>)
    }

    //#endregion  

    return(<>
        <div className="row mt-4">
            <div className="col-xl-12">
                <table id="table-round" className="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th style={{width:"9%"}}  > Round           </th>
                            <th style={{width:"25%"}} > Format          </th>
                            <th style={{width:"17%"}} > Date            </th>
                            <th style={{width:"17%"}} > Tee             </th>
                            <th style={{width:"18%"}} > Tee Off Options </th>
                            <th style={{width:"5%"}}  > Active          </th>
                            <th style={{width:"5%"}}  > Action          </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(props.roundList.length > 0) && props.roundList.map((r, index) => (
                            <tr key={index}>
                                <td className="round-no ddlTournamentRound" data-value={`${r?.Round}`}>
                                    <select id={`ddlRoundNo${index}`} name={`ddlRoundNo${index}`} className="form-control"
                                        value = {r?.Round}  disabled={(index == 0) || props?.flags?.IsDrawCreated}
                                        onChange={({target})=> onChangeHandler('Round', parseInt(target.value), index)}
                                    > 
                                        {[...Array(pageData.Rounds).keys()].map(n => ( <option key={n + 1} value={n + 1}> {n + 1} </option> ))}
                                   </select>
                                </td>

                                <td className="round-format">
                                    <select id={`ddlFormat${index}`} name={`ddlFormat${index}`} className="form-control" 
                                        value = {r?.Format || ''} disabled={props?.flags?.IsDrawCreated}
                                        onChange={({target})=> onChangeHandler('Format', target.value, index)}
                                    >
                                        {pageData?.Formats?.map((f) => ( <option key={f.Format} value={f.Format}> {f.Format} </option> ))}
                                    </select>

                                    {FormatsWithInputs.includes(r?.Format) && getFormatInputs(index,r)}
                                </td>
                              
                                <td className="round-date ddlTournamentDt">
                                    <select id={`ddlDate${index}`} name={`ddlDate${index}`} className="form-control"
                                        value = {moment(r?.Date).format('YYYY-MM-DD')} disabled={(index == 0) || props?.flags?.IsDrawCreated}
                                        onChange={({target})=> onChangeHandler('Date', target.value, index)}
                                    >
                                        {pageData?.Dates?.map((d,i) => ( <option key={i} value={d.value}> {d.label} </option> ))}
                                    </select>
                                </td>

                                <td className="round-teeId">
                                    {!r?.TeeOffOption.includes("Shot Gun") && (<>
                                        <select id={`ddlTeeTournament${index}`} name={`ddlTeeTournament${index}`} className="form-control"
                                            // value = {r?.TournamentTeeID} 
                                            value={r?.TournamentTeeID || (pageData?.TournamentTee?.[0]?.ID || "0")}
                                            disabled={props?.flags?.IsDrawCreated}
                                            onChange={({target})=> onChangeHandler('TournamentTeeID', parseInt(target.value), index)}
                                        >
                                            {pageData?.TournamentTee?.map((t)=>( <option key={t.ID} value={t.ID}> {t.Name} </option> ))}
                                        </select>
                                    </>)}
                                    {(r.Round == 1) && getSessionLimitElement(index, r)}
                                </td>

                                <td className="round-teeOption">
                                    <select id={`ddlTeeOffOption${index}`} name={`ddlTeeOffOption${index}`} className="form-control" data-index={`${index}`}
                                        value = {r?.TeeOffOption} disabled={props?.flags?.IsDrawCreated}
                                        onChange={({target})=> onChangeHandler('TeeOffOption', target.value, index)}
                                    >
                                        {pageData?.TeeOffOptions?.map((t)=>( <option key={t} value={t}> {t} </option> ))}
                                    </select>
                                                                        
                                    <div className={`round-teeTime${index} margin-t10 ${r?.TeeOffOption?.includes(ShotgunString) ? '' : 'hidden'}`}>
                                        <h6>Tee Off Time</h6>                                        
                                        <input className={`form-control slot-picker ${(r?.TeeOffOption == ShotgunString || r?.TeeOffOption == SplitShotgunString) ? '' : 'hidden'}`} id={`txtTeeOffTime${index}`} name="txtTeeOffTime"  readOnly={true} type="text" 
                                            data-index={index} data-field={"TeeOffTime"} value = {r?.TeeOffTime || '07:00 AM'}                                                                                               
                                        />
                                        <input className={`form-control slot-picker margin-t10 ${(r?.TeeOffOption == SplitShotgunString)? '' : 'hidden'}`} id={`txtSplitTeeOffTime${index}`} name="txtSplitTeeOffTime" type="text" readOnly={true}  
                                            data-index={index} data-field={"TeeOffTimeSplit"} value = {r?.TeeOffTimeSplit || '10:00 AM'}                                                 
                                        />	      
                                    </div>
                                </td>

                                <td className="round-check text-center">
                                    <div className="kt-switch kt-switch--sm kt-switch--icon padding-t0">
                                        <label className="margin-b0">
                                            <input id={`roundCheck${index}`} name="chkActive" type="checkbox" 
                                                checked={(r?.Active == "Y")} disabled={props?.flags?.IsRegister}
                                                onChange={({target})=> onChangeHandler('Active', (target.checked)? "Y" : "N", index)}
                                            />
                                            <span className="lbl"></span>
                                        </label>
                                    </div>
                                </td>
                                
                                {(index != 0)&&(
                                    <td className="round-delete text-center">
                                        <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-template-edit" 
                                            onClick={()=> removeRound(index)} disabled={props?.flags?.IsDrawCreated}
                                        ><i className="flaticon2-rubbish-bin-delete-button"></i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                    {(pageData?.RoundCase == 4 && !props?.flags?.IsDrawCreated) && (
                        <tfoot className="bg-light">
                            <tr>
                                <td colSpan="7">
                                    <button type="button" className="btn btn-brand btn-icon-sm text-white d-inline pull-right"
                                        onClick={addRound} ><i className="la la-plus"></i> New
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
        </div>    
    </>)
}

export default TournamentRoundTable;



// useEffect(()=> {     
//     if(!!props?.tMaster){
//         const { tMaster, tData } = props;

//         const currentCase = getRoundCase(tMaster?.Days, tMaster?.Round);
//         const roundDates  = getDates(tMaster?.StartDate, tMaster?.EndDate);

//         let pData = { RoundCase: currentCase, Dates: roundDates, Rounds: tMaster.Round };
//         if(!!tData) {
//             pData.TeeOffOptions        = tData.TeeOffOptions;
//             pData.Formats              = tData.TournamentFormats.filter(f => f.Type == tMaster.Type);
//             pData.TournamentTee        = tData.TournamentTee;
//             pData.AfternoonSessionTime = tData.AfternoonSessionTime;
//         }
        
//         setPageData(pData);
        
//         let RoundLimit = (currentCase != 3)? tMaster.Round : roundDates.length;
        
//         const tRound   = [];
//         for (let i = 0; i < RoundLimit; i++) {
//             const roundNo   = (tMaster?.Round == 1) ? 1 : (i + 1);
//             var defaultDate =  (currentCase < 3) ? roundDates[0] : (i < roundDates.length) ? roundDates[i] : roundDates[roundDates.length - 1];
//             const roundDt   = defaultDate ;  // Update this on edit --               
            
//             const defaultFormat = (pData?.Formats[0]?.Format);
//             const formatValue   = getFormatValue(defaultFormat);

//             tRound.push({
//                 ID                     : 0,
//                 TournamentTeeID        : 0,
//                 Round                  : roundNo,
//                 Date                   : roundDt.value, // For SingleDay use 1st Date
//                 Format                 : defaultFormat,
//                 TeeOffOption           : pData?.TeeOffOptions[0],
//                 TeeOffTime             : '',
//                 TeeOffTimeSplit        : '',
//                 Lower                  : !!formatValue? formatValue.lower  : 0,
//                 Higher                 : !!formatValue? formatValue.higher : 0,
//                 MinDrive               : !!formatValue? formatValue.minDrive : 0,
//                 MorningRegisterLimit   : 0,
//                 AfternoonRegisterLimit : 0,
//                 Active                 : 'Y',
//             });                                           
//         }
  
//         props.setRoundList(tRound);    
        
//         setTimeout(() =>{
//             $(`.slot-picker`).timepicker({ minuteStep: 10, defaultTime: '07:00 AM' }).on('change', function(){
//                 const index = $(this).attr('data-index');
//                 const field = $(this).attr('data-field');
//                 const value = $(this).val();                
                
//                 onChangeHandler(field, value, index);
//             });               
//         },500)
//     }
// },[props?.tMaster, props?.tData]);



// const getRoundCase = (days, round) => {
//     if (round == 1 && days == 'Single') 
//        return 1;
//     else if (round > 1 && days == 'Single') 
//        return 2;
//     else if (round == 1 && days == 'Multiple') 
//        return 3;    
//     else if (round > 1 && days == 'Multiple') 
//        return 4;
//     else
//         return 0    
// }

// function getDates(startDate, endDate) {
//     let startString = moment(startDate).format("DD/MM/YYYY");
//     let [sday, smonth, syear] = startString.split('/');

//     let endString = moment(endDate).format("DD/MM/YYYY");
//     let [eday, emonth, eyear] = endString.split('/')

//     var start = moment(new Date(+syear, +smonth - 1, +sday));
//     var end   = moment(new Date(+eyear, +emonth - 1, +eday));
//     var count = end.diff(start, "days");
//     var temp  = start.clone();

//     var dates = [ { label : temp.format('DD-MM-YYYY'), value: temp.format('YYYY-MM-DD') } ];
//     for (var i = 1; i <= count; i++) {
//         var item = temp.add(1, 'd');
//         dates.push({ label : item.format('DD-MM-YYYY'), value: item.format('YYYY-MM-DD') });
//     }

//     return dates;
// }

// const getFormatValue=(format)=>{
//     let val;
    
//     switch (format) { 
//         case 'Foursomes':
//             val = {
//                 label1: 'Higher', label2: 'Lower',
//                 higher:50 , lower:50, minDrive:0, isMin: false
//             }
//             break;           
//         case 'Greensome':
//             val = {
//                 label1: 'Higher', label2: 'Lower',
//                 higher:40 , lower:60, minDrive:9, isMin: true
//             }
//             break;
//         case 'Scramble':
//             val = {
//                 label1: 'Higher', label2: 'Lower',
//                 higher:15 , lower:35, minDrive:9, isMin: true
//             }
//             break;                
//         case 'Cumulative Fourball Better Ball':
//         case 'Fourball Betterball':
//         case 'Net Stableford':
//             val = {
//                 label1: 'Male', label2: 'Female',
//                 higher:100 , lower:100, minDrive:0, isMin: false
//             }
//             break;       
//     }

//     return val;
// }
