import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getGolfUsers, processFile } from "../api/usersAPI";
import * as XLSX from 'xlsx';

const HandicapUpload = (props) => {

    const [selectedDate,            setSelectedDate]             = useState(moment().toDate());
    const [step,                    setStep]                     = useState(false)
    const [step1,                   setStep1]                    = useState(false)
    const [step2,                   setStep2]                    = useState(false)
    const [showProcess,             setShowProcess]              = useState(false)
    const [memberList,              setMemberList]               = useState([]);

    useEffect(() => {
        props.title("Handicap Upload", "user-handicap-upload");
        $('#txtUpdatedDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtUpdatedDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
    }, []);

    useEffect(() => {
        if (memberList.length > 0) {
            setStep1(true);
            setStep2(false);
        }
    }, [memberList]);
    
    useEffect(() => {
        if (step && !showProcess && step1) {
            setStep2(true);
        }
    }, [step, showProcess, step1]);

    const fileHandler = async (file) => {
        try {
            // setStep2()
            const result = await readFile(file);
            const data = helper.extractExcel(result);

            const rows = parseCSV(data);
            if (rows.length < 2) {
                return showWarning('No data found');
            }

            const headings = rows[0].split(',');
            if (!validateHeadings(headings)) {
                return showWarning('Unknown file. Please upload the file in given format.');
            }


            const content = rows.slice(1)
            const userHandicap = [];
            content.forEach((element) => {
                const value = element.split(',');
                if (value[0] != "" && value[value.length - 1] != "") {
                    userHandicap.push({
                        Mobile          : value[0],
                        HandicapIndex   : value[value.length - 1]
                    })
                }
            });

            setStep(true)
            setShowProcess(true)
            const { Members, UserNotFoundList } = await processFile(userHandicap, selectedDate);

            swal.fire({
                icon                : 'success',
                titleText           : `Handicap Uploaded Successfully`,
                text                : (UserNotFoundList.length > 0) ? `${UserNotFoundList} this numbers are not found`: "",
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        
            if (Members) {
                setShowProcess(false);
                setMemberList(Members)
                setStep1(true); 
            }
        } catch (error) {
            setStep(false)
            setShowProcess(false)
            showError('Error reading file. Please try again.');
            console.error(error);
        }
    }

    const validateHeadings = (headings) => {
        const requiredHeadings = ['mobile', 'membershipno', 'name', 'handicapindex'];
        return requiredHeadings.every((heading, index) => 
            headings[index]?.trim().toLowerCase() === heading
        );
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(reader.error);
        });
    };

    const parseCSV = (data) => {
        return data.split('\n').filter((row) => row.split(',').length > 1);
    };

    const showWarning = (message) => {
        return swal.fire({
            icon: 'warning',
            titleText: 'Warning!',
            text: message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-brand',
        });
    };

    const showError = (message) => {
        return swal.fire({
            icon: 'error',
            titleText: 'Error!',
            text: message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-brand',
        });
    };

    const fetchExcelSheet = async(e) => {
        e.preventDefault();	
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getGolfUsers('handicapUpload');
            const workbook = XLSX.utils.book_new();
        
            // Convert the data array to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(response);
            
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            
            // Generate a binary string from the workbook
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            
            // Create a Blob from the binary string
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            link.click();

            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__body padding-t20 padding-b0">
                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                            <div className="row">
                                <div className="form-group col-12 pl-0 pt-4">
                                    <div className="input-group ">
                                        <label className="col-form-label col-lg-2">Updated Date </label>
                                        <div className="col-xl-3 col-lg-3">
                                            <div className="input-group date">
                                                <input 
                                                    id="txtUpdatedDate" 
                                                    name="txtUpdatedDate" 
                                                    type="text" 
                                                    className="form-control" 
                                                    readOnly
                                                    defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                    placeholder="Select date" />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="file_document" className="col-form-label col-lg-1">Upload File </label>
                                        <div className="col-lg-3">
                                            <input 
                                                type="file" 
                                                id="f1" 
                                                className="form-control" 
                                                accept=".csv, .xlsx, .xls"
                                                title="Choose a local CSV file" 
                                                onChange={({ target: { files } }) => fileHandler(files[0])}/>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="btn btn-label-brand"
                                                onClick={fetchExcelSheet}
                                            >
                                                <i className="fa fa-download" />
                                                Download Template for Handicap Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="alert alert-solid-brand alert-bold" role="alert">
                                        <div className="alert-text">Excel File Column ["Mobile", "MembershipNo", "Name", "HandicapIndex" ] </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {step && (
                            <div id="message-container" className="row">
                                <div className="col-12">
                                    <div id="step" className="alert alert-brand alert-bold text-center" role="alert">
                                        {showProcess && (<div className="alert-text"> <span><i className="fa fa-spinner fa-spin"></i> Processing Excel File</span> </div>)}
                                        {step1 && !step2 && (<div className="alert-text"> <span> Step 1 : Excel File Processed.</span> </div>)}
                                        {step2 && (<div className="alert-text"> <span> Step 2 : Data Inserted. </span> </div>)}
                                    </div>
                                </div>
                            </div>
                        )}
                        {(memberList && memberList.length > 0) && (
                            <div id="tblContainer" className="row">
                                <div className="col-12">
                                    <table id="tblHandicap" className="table table-bordered table-hover margin-b30 ">
                                        <thead className="light-grey-background">
                                            <tr>
                                                <th>Mobile </th>
                                                <th>Membership No. </th>
                                                <th>Name </th>
                                                <th>Handicap Index</th>
                                                <th>Handicap</th>
                                                <th style={{width: "10%"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {memberList.map((m, i) => (
                                                <tr key={i}>
                                                    <td><span>{m.Mobile}</span></td>
                                                    <td><span>{m.MembershipNo}</span></td>
                                                    <td><span>{m.Name}</span></td>
                                                    <td><span>{m.HandicapIndex}</span></td>
                                                    <td><span>{m.Handicap}</span></td>
                                                    <td><span className="action"><i className="fa fa-check text-success"></i></span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HandicapUpload