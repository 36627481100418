import React, { useEffect, useState } from 'react'
import { scorecardPeriods } from '../../assets/scripts/periodSelection';
import { getGameDetails, getScorecardSheet, postGameScores, verifyGameScorecard } from '../api/gameAPI';
import swal from 'sweetalert2';
import RightSideDetails from "../global/rightSideDetails";

const GameScorecard = (props) => {

    const [currentPeriod,                   setCurrentPeriod]                       = useState(scorecardPeriods[0]);
	const [fromDate,                        setFromDate]                            = useState(scorecardPeriods[0].from);
	const [toDate,                          setToDate]                              = useState(scorecardPeriods[0].to);
    const [isInit,                          setIsInit]                              = useState(true);
    const [scorecardSheet,                  setScorecardSheet]                      = useState([]);
    const [rightSideDetails,                setRightSideDetails]                    = useState(false);
    const [gameDetails,                     setGameDetails]                         = useState(null);
    const [gameResults,                     setGameResults]                         = useState([]);
    const [gameVerification,                setGameVerification]                    = useState(null);
    const [selectedGame,                    setSelectedGame]                        = useState(null);
    const [totalPar,                        setTotalPar]                            = useState(0);
    const [gameScore,                       setGameScore]                           = useState([]);
    useEffect(() => {
        props.title("Game Scorecard", "scorecard");
    }, []);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    });

    useEffect(() => {
        if(selectedGame)
            fetchGameDetails(selectedGame.ID)
    }, [selectedGame])

    useEffect(() => {
		if (currentPeriod.id != 6) {
			setFromDate(currentPeriod.from);
			setToDate(currentPeriod.to);                        
		}

        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
        });  
        $('#txtFromDate').on('change', function (event) {
            setFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
        });  
        $('#txtToDate').on('change', function (event) {
            setToDate(event.target.value)
        }); 
	}, [currentPeriod]);

    useEffect(() => {
        if (currentPeriod.id != 6) {
            fetchScorecardSheet(fromDate, toDate)
        }
    }, [fromDate, toDate]);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }
    }, [rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [scorecardSheet]);

    function fillDataTable() {
        swal.close();
        const dataTable = $('#tblScorecardSheet').KTDatatable({
            data : {
                saveState: false,
                source   : scorecardSheet,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'CourseName',
                    title: 'Course',
                    width: 90,
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                        ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="course-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.CourseName.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.ShortName} </span>
                                    </div>
                                </div>`
                    }
                },
                {
                    field: "Player1", title: "Player 1", width: 180,
                },
                {
                    field: "Player2", title: "Player 2", width: 180,
                },
                {
                    field: "Player3", title: "Player 3", width: 180,
                },
                {
                    field: "Player4", title: "Player 4", width: 180,
                },
                {
                    field: 'Start',
                    title: "Game Date",
                    width: 80,
                    template: function (row) {
                        return moment(row.Start).format('DD-MM-YYYY');
                    }
                },
                {
                    field: '',
                    title: 'Status',
                    width: 100,
                    template: function (row) {
                        return getVerificationStatus('badge-s', row);
                    }
                },
            ],
            rows: {
                autoHide: false,

            }
        });
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        dataTable.on('click', 'tr', function (ss) {            
            const index = ($("tr").index(this) - 1);

               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setSelectedGame(dataTable.dataSet[index]);
                setRightSideDetails(true); 
            } 
        });
    }

    function getVerificationStatus(size, data = gameVerification) {
          
        if (data.IsVerified == 'N') {
            return (`
               ${data.Reason ? `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs" data-toggle="kt-popover" data-trigger="hover click" data-placement="bottom" data-original-title="" data-content="${data.Reason}"><i class="fa fa-info"></i></button>` : ''}
               <span class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded ${size}"> Rejected</span>
            `);
        } else if (data.IsVerified == 'Y') {
            if (data.UserType == 'User') {
                return (`<span class="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded ${size}"> Verified</span>`);
            } else {
                return (`<span class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded ${size}"> Admin Verified</span>`);
            }
        } else {
            return (`<span class="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded ${size}"> Pending</span>`);
        }
    }

    const changePeriodHandler = ({ target }) => {
		const period = scorecardPeriods.find(p => p.id == target.value)
	    setCurrentPeriod(period);
	}

    const fetchScorecardSheet = async (fromDate, toDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const { ScorecardSheet } = await getScorecardSheet(fromDate, toDate);
            isInit ? setIsInit(false) : $('#tblScorecardSheet').KTDatatable().destroy();
            setScorecardSheet(ScorecardSheet)
            swal.close();
        } catch(err){
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const fetchGameDetails = async (ID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const { GameData, GameResults, GameVerification } = await getGameDetails(ID);
            // isInit ? setIsInit(false) : $('#tblScorecardSheet').KTDatatable().destroy();
            setGameDetails(GameData);
            setGameScore(GameData.GameScore)
            const tPar = GameData.ScoreCardMasters.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.Par;
            }, 0);
            setTotalPar(tPar)
            setGameResults(GameResults);
            setGameVerification(GameVerification)
            swal.close();
        } catch(err){
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const onGameVerify = async () => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });

            await verifyGameScorecard(selectedGame.ID)
            fetchGameDetails(selectedGame.ID)
            swal.close();
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const HandicapHandler = (value, id) => {
        const updatedPlayer = [...gameDetails.GamePlayers];
        const gamePlayerIndex = updatedPlayer.findIndex(p => p.ID == id);
        if (gamePlayerIndex != -1) {
            updatedPlayer[gamePlayerIndex].Handicap = parseInt(value);
            setGameDetails(g => ({...g, updatedPlayer}))
        }


    }

    const saveGameCard = async () => {
        try {
            for (let player of gameDetails.GamePlayers) {
                const front = gameScore.filter(i => i.Type == "OUT" && i.PlayerID == player.ID && i.ActualScore != '-').length;
                const back = gameScore.filter(i => i.Type == "IN" && i.PlayerID == player.ID && i.ActualScore != '-').length;
    
                if (front <= 6 && back <= 6) {
                    swal.fire({
                        icon: 'warning',
                        html: `<div> <h5> Minimum 7 holes required..! </h5> </div>`,
                        showConfirmButton: true,
                    });
                    return
                }
            }
    
            const filteredGameScores = gameScore.filter(s => s.ID != 0 || s.ActualScore != '-')
            const deletedGameScores = filteredGameScores.filter(g => g.ActualScore == 0 || g.ActualScore == '-');
            const gameScores = gameScore.filter(s => s.ActualScore != '-')
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await postGameScores(gameScores, deletedGameScores, gameDetails.GamePlayers)
            fetchGameDetails(selectedGame.ID)
            swal.close();
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Scorecard Updated Successfully!',
                position: 'bottom-end',
                timer: 1500,
                animation: false,
                showConfirmButton: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    function calculateScore(currentMaster, actualScore, currentPlayer) {

        const { Handicap } = currentPlayer;

        let HandicapScore = parseInt(actualScore)

        //calculate HandicapScore --> 
        if (Handicap >= currentMaster.Stroke) {

            var diff = parseInt(Handicap - 18);

            if (currentMaster.Stroke <= diff)
                HandicapScore = HandicapScore - 2;
            else
                HandicapScore = HandicapScore - 1;
        }

        const GrossScore = actualScore - currentMaster.Par;
        const NettScore  = HandicapScore - currentMaster.Par;

        return {
            HandicapScore,
            GrossScore,
            NettScore,
        };
    }

    function onScratched(element, playerId, holeNo, type) {
        const inputElement = element.target.closest('.input-group').querySelector('input');
        if (inputElement) {
            inputElement.value = 'X';
            calculateTotal(inputElement, playerId, holeNo, type);
        }
    }

    function calculateTotal(control, playerId, holeNo, type) {
        const { GamePlayers, ScoreCardMasters } = gameDetails;  // Destructing GameDetails
        const updatedScore = [...gameScore]; // Make a copy of gameScore array

        const scoreIndex = updatedScore.findIndex((o) => o.PlayerID == playerId && o.Type == type && o.Hole == holeNo);
        
        let newValue = control.value ? control.value.replace("-", "") : "";

        if (/^X[0-9]+$/.test(control.value)) {
            newValue = control.value.replace(/^X/, "");
        }
        const scoreCardMaster = ScoreCardMasters.find(s => s.HoleNo == holeNo && s.Type == type)
        const currentPlayer = GamePlayers.find(p => p.ID == playerId)

        let actualScore;
        let Scratch = 'N';

        if (newValue.toUpperCase() === 'X') {
            actualScore = currentPlayer.Handicap >= scoreCardMaster.Stroke ? scoreCardMaster.Par + 2 : scoreCardMaster.Par + 1;
            Scratch = 'Y';
        } else if (/^[A-Za-z]+$/.test(newValue)) { // Check if newValue is an alphabet
            actualScore = "-";
        } else {
            actualScore = newValue === "" || newValue === "0" ? "-" : parseInt(newValue, 10);
        }

        if (scoreIndex !== -1) {
            const score = updatedScore[scoreIndex];
    
            if (newValue === 'X') {
                score.ActualScore = currentPlayer.Handicap >= scoreCardMaster.Stroke ? scoreCardMaster.Par + 2 : scoreCardMaster.Par + 1;
                score.Scratch = 'Y';
            } else {
                score.ActualScore = actualScore;
                score.Scratch = 'N';
            }
    
            const scoreData = calculateScore(scoreCardMaster, score.ActualScore, currentPlayer);
            updatedScore[scoreIndex] = { ...score, ...scoreData };
        } else if (actualScore !== '-') {
            const newScore = {
                ActualScore: actualScore,
                GameID: gameDetails.Game.ID,
                Hole: scoreCardMaster.HoleNo,
                ID: 0,
                Par: scoreCardMaster.Par,
                PlayerID: playerId,
                Scratch: Scratch,
                Stroke: scoreCardMaster.Stroke,
                Type: scoreCardMaster.Type,
                ...calculateScore(scoreCardMaster, actualScore, currentPlayer)
            };
            updatedScore.push(newScore);
        }
        setGameScore(updatedScore);
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
            setSelectedGame(null)
        }, 200);
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row justify-content-center padding-lr10">
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <select                                              
                                                className="form-control kt-selectpicker"
                                                style={{ width: '150px' }}
                                                id="ddlPeriod"
                                                value={currentPeriod.id}
                                                onChange={changePeriodHandler}> 
                                                {scorecardPeriods.map((s) => (
                                                    <option value={s.id} key={s.id}>
                                                        {s.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {currentPeriod.id == 6 && (
                                        <>
                                            <div className='form-group col-sm-7'>
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <div className="input-group">
                                                            <input id="txtFromDate" name="txtFromDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select from date" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="input-group">
                                                            <input id="txtToDate" name="txtToDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select to date" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <span id="btnSearch" className="btn btn-primary margin-tb0" onClick={() => fetchScorecardSheet(moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))}><i className="fa fa-search"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="form-group col-sm-3">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input type="text" className="form-control clientSearch" placeholder="Search ..." id="generalSearch" />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblScorecardSheet" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && 
                                <RightSideDetails title="Scorecard Details" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <div className='row'>
                                                <div className='col-sm-9'>
                                                    <div className="kt-widget kt-widget--user-profile-3">
                                                        <div className="kt-widget__top">
                                                            {(selectedGame.Logo && selectedGame.Logo != '') ? 
                                                                <img src={selectedGame.Logo} height={80} alt="course-thumbail" className='kt-widget__pic kt-widget__pic'/> 
                                                                : 
                                                                <div className="kt-badge kt-badge--xl kt-badge--info">{selectedGame.CourseName.substring(0, 1)}</div>}

                                                            <div className="kt-widget__content">
                                                                <div className="kt-widget__head">
                                                                    <span id="top-title" className="kt-widget__username">{selectedGame.CourseName}</span>
                                                                </div>
                                                                <div className="kt-widget__subhead padding-t0">
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon2-calendar-3"></i>
                                                                        {moment(selectedGame.Start).format('DD-MM-YYYY')}
                                                                    </span><br></br>
                                                                    {gameVerification && (
                                                                        <span className="padding-r10"> 
                                                                            <i className= "flaticon2-user"> </i>  
                                                                            {gameVerification.IsVerified == 'Y' 
                                                                                ? 'Verified by'
                                                                                : 'Rejected by'}: {gameVerification.Name} 
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 padding-0 drawer-action padding-r10">
                                                    {(gameVerification && gameVerification.IsVerified == 'N') ? (
                                                        <>
                                                            {gameVerification.Reason ? (
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r5" 
                                                                    data-toggle="kt-popover" 
                                                                    data-trigger="hover click" 
                                                                    data-placement="bottom" 
                                                                    data-original-title=""
                                                                    data-content={gameVerification.Reason}>
                                                                    <i className="fa fa-info"></i>
                                                                </button> 
                                                            ) : ''}
                                                            <span className={`kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded margin-r5 badge-m`}> Rejected</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(gameVerification && gameVerification.IsVerified == 'Y') ? (
                                                                <>
                                                                    {gameVerification.UserType == 'User' ? (
                                                                        <span className={`kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded badge-m`}> Verified</span>
                                                                    ) : (
                                                                        <span className={`kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded badge-m`}> Admin Verified</span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <span className={`kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded badge-m margin-r15`}> Pending</span>
                                                            )}
                                                        </>
                                                    )}              
                                                    {/* <span id="top-status" className="margin-r10">{status}</span> */}
                                                    {(!gameVerification || gameVerification.IsVerified != 'Y') && (
                                                        <>
                                                            <button type="button" id="update-btn" className="btn btn-sm btn-brand d-inline-block margin-0 margin-r10" onClick={saveGameCard}>Update</button>
                                                        </>
                                                    )}
                                                    {(!gameVerification) && (
                                                        <button type="button" id="verify-btn" className="btn btn-sm btn-success d-inline-block margin-0" onClick={onGameVerify}><i className="flaticon2-check-mark"></i>Verify </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">

                                            <div className="table-register">

                                                <table id="table-scorecard" className="table table-bordered table-striped table-card">
                                                    <thead>
                                                        <tr id="mainHeader">
                                                            <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }} width="3%" className='mainHeader'>Hole   </th>
                                                            <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }} width="3%" className='mainHeader'>Par    </th>
                                                            <th rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }} width="3%" className='mainHeader'>Stroke </th>
                                                            {(gameDetails && gameDetails.GamePlayers.length > 0) && (
                                                                gameDetails.GamePlayers.map((p, i) => (
                                                                    <th key={i} className={`lblplayer txtPlayer${i}`} style={{ textAlign: 'center'}}> 
                                                                        <span className="mr-2">{p.Name}</span>
                                                                    </th>
                                                                ))
                                                            )}
                                                        </tr>
                                                        <tr id="subHeader">
                                                            {(gameDetails && gameDetails.GamePlayers.length > 0) && (
                                                                gameDetails.GamePlayers.map((p, i) => (
                                                                    <th key={i} style={{ width: '12%', textAlign: 'center' }}>
                                                                        <div className='form-group margin-b10'>
                                                                            <div className='row'>
                                                                                <div className='col-5 text-right'>
                                                                                    <label className="col-form-label"> Handicap </label>
                                                                                </div>
                                                                                <div className='col-7'>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id={`handicap${p.ID}`} 
                                                                                        name={`handicap${p.ID}`}
                                                                                        value={p.Handicap}
                                                                                        onChange={(e) => HandicapHandler(e.target.value, p.ID)}
                                                                                        disabled = {gameVerification && gameVerification.IsVerified == 'Y' ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group margin-b0" >
                                                                            <div className='row'>
                                                                                <div className='col-5 text-right'>
                                                                                    <label className="col-form-label"> Tee Box </label>
                                                                                </div>
                                                                                <div className='col-7'>
                                                                                    <select 
                                                                                        id={`ddl${p.ID}`} 
                                                                                        name={`ddl${p.ID}`} 
                                                                                        title="Select TeeBox" 
                                                                                        className="form-control playerTeebox" 
                                                                                        data-playerid={`ddl${p.ID}`}
                                                                                        disabled = {gameVerification && gameVerification.IsVerified == 'Y' ? true : false}
                                                                                    >
                                                                                        {(gameDetails && gameDetails.TeeBoxes.length > 0) && (
                                                                                            gameDetails.TeeBoxes.filter(f => (p.UserType != 'UsersGuest' ? f.Gender == p.Gender : true)).map((t, i) => (
                                                                                                <option key={i} value={t.ID}>{t.Name}</option>
                                                                                            ))
                                                                                        )}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                ))
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gameDetails && gameDetails.ScoreCardMasters.map((item, index) => {
                                                            return(
                                                                <tr key={`row_${item.HoleNoValue}`} className="row-score" data-masterid={item.ID}>
                                                                    <td style={{ textAlign: 'center' }}>{item.HoleNoValue}</td>
                                                                    <td style={{ textAlign: 'center' }}>{item.Par}</td>
                                                                    <td style={{ textAlign: 'center' }}>{item.Stroke}</td>
                                                                    {gameDetails && gameDetails.GamePlayers.map((player, i) => {
                                                                        const score = gameScore.find((s) => s.PlayerID === player.ID && s.Type === item.Type && s.Hole === item.HoleNo);
                                                                        const actualScore = score ? (score.Scratch === "Y" ? "X" : score.ActualScore) : "-";
                                                                        return (
                                                                            <td key={`cell_${item.HoleNoValue}_${player.ID}`} className={`card-data-${i} player-td`} data-playerid={player.ID} data-scoreid={score ? score.ID : 0}>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        id={`txtPlayer${i}Score`}
                                                                                        type="text"
                                                                                        className="form-control input-sm input-numeric"
                                                                                        value={actualScore}
                                                                                        onChange={(!gameVerification || gameVerification.IsVerified == 'N')? (e) => calculateTotal(e.target, player.ID, item.HoleNo, item.Type) : undefined}
                                                                                        disabled={gameVerification && gameVerification.IsVerified === 'Y'}
                                                                                    />
                                                                                    {(!gameVerification || gameVerification.IsVerified == 'N') && (
                                                                                        <div className="input-group-append">
                                                                                            <span className="input-group-text clickable" onClick={(e) => onScratched(e, player.ID, item.HoleNo, item.Type)}>
                                                                                                <i className="la la-times"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr className="bg-light font-weight500">
                                                            <td style={{textAlign: 'center'}}>OUT</td>
                                                            <td style={{textAlign: 'center'}} id="lblTotalPar"> {totalPar} </td>
                                                            <td style={{textAlign: 'center'}}> Total Score </td>
                                                            {gameDetails && gameDetails.GamePlayers.map((player, index) => {
                                                                const playerScores = gameScore.filter(score => score.PlayerID == player.ID && score.ActualScore != '-');
                                                                
                                                                const totalScore = playerScores.map(score => (score.ActualScore)).reduce((previousScore, currentScore) => previousScore + currentScore, 0);
                                                                
                                                                return (
                                                                    <td key={player.ID} id={`player${player.ID}TotalScore`}>
                                                                        {totalScore}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GameScorecard;