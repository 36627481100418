import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import defaultUserImg from '../../assets/images/user.jpg'
import Select from 'react-select';
import * as helper from '../global/helper';
import { getOnboardingUserAll, postOnboardingData, resendOnboardingMail } from "../api/onboardingAPI";
import swal from 'sweetalert2';
import OnboardingTracker from "./onboardingTracker";
import ic_check_on from '../../assets/images/ic_check_on.png'
import { getAuthState } from "../../assets/scripts/login-util";
import OtpModal from "../global/otpModal";

export const OverflowMenu = (props) => {
    return (
        <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
            <a className="dropdown-item" onClick={props.onResendClick}>
                <i className="la la-mail-reply-all"></i>
                Resend Mail
            </a>
            <a className="dropdown-item" onClick={props.onDeleteClick}>
                <i className="la la-trash"></i>
                Delete
            </a>
        </div>
    );
};

export const OverflowMenuResend = (props) => {
    return (
      <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
          <a className="dropdown-item" onClick={props.onEditClick}>
            <i className="la la-edit"></i>
            Resend
          </a>
      </div>
    );
};


const Onboarding = (props) => {
 
    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [onboardingUserList,         setOnboardingUserList]         = useState([]);
    const [userDetails,                setUserDetails]                = useState({});
    const [isInit,                     setIsInit]                     = useState(true);
    const [membershipTypeOption,       setMembershipTypeOption]       = useState([{ value:  'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);
    const [categoryOption,             setCategoryOption]             = useState([{ value:  'Individual', label: 'Individual'}, { value:  'Organization', label: 'Organization'} ]);
    const [spouseOption,               setSpouseOption]               = useState([{ value:  'Yes', label: 'Yes'}, { value:  'No', label: 'No'} ]);
    const [childOption,                setChildOption]                = useState([{ value:  0, label: 0}, { value:  1, label: 1}, { value:  2, label: 2 }, { value:  3, label: 3 }, { value:  4, label: 4 } ]);
    const [action,                     setAction]                     = useState('a');
    const [userID,                     setUserID]                     = useState(null);
    const [showOtpModal,               setshowOtpModal]               = useState(false);

    useEffect(() => {        
        const { user } = getAuthState();  
        setUserID(user.id);      
        props.title('Onboarding', 'onboarding')
        fetchOnboardingUsers();
    },[]); 

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setUserDetails({})
            setAction('a');
        }
    },[rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [onboardingUserList]);

    const fetchOnboardingUsers = async() => {
        try {
            const users = await getOnboardingUserAll()   
            isInit ? setIsInit(false) : $('#tblOnboardingUser').KTDatatable().destroy(); 
            setOnboardingUserList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
  
    function fillDataTable() {

        const dataTable = $('#tblOnboardingUser').KTDatatable({
            data: {
                saveState: false,
                source: onboardingUserList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.ProfilePhoto && row.ProfilePhoto != '') ? `<img src="${row.ProfilePhoto}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                        </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                               </div>`
                    },
                    width: 220,

                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                    width: 100, 
                }, 
                {
                    field: '',
                    title: 'Member',
                    template: function (row) {
                        return row.IsSubmit == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Submitted</span>'
                    },
                    width: 100,
                },
                {
                    field: 'AccountSignatureStatus',
                    title: 'Accounts',
                    template: function (row) {
                        return row?.AccountSignatureStatus  == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'MembershipSignatureStatus',
                    title: 'Membership',
                    template: function (row) {
                        return row?.MembershipSignatureStatus == 'Y'? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'AdminSignatureStatus',
                    title: 'Admin',
                    template: function (row) {
                        return row?.AdminSignatureStatus == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: 'PresidentSignatureStatus',
                    title: 'President',
                    template: function (row) {
                        return row?.PresidentSignatureStatus == 'Y' ? '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr35 padding-tb10">Approved</span>' : '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>'
                    },
                    width: 100,
                },
                {
                    field: ' ',
                    title: 'Status',
                    template: function (row) {
                        return row?.AccountSignatureStatus  == 'Y' &&  row?.MembershipSignatureStatus  == 'Y' && row?.AdminSignatureStatus  == 'Y' && row?.PresidentSignatureStatus  == 'Y' ? `<span class=""> <img src="${ic_check_on}" height="24"/></span>` :  `<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>`
                    },
                    width: 80,
                },
               
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblOnboardingUser .kt-datatable__table').addClass('overflow-x-auto');
        
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if(dataTable.dataSet[index]){
                setUserDetails(dataTable.dataSet[index])
                setAction('e');
                setRightSideDetails(true);           
             }
        });
    };
    
    const updateUserDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addChargeHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-onbording-form');
        
        form.validate({
            rules: {         
                txtCompanyName: {
                    required: true
                },          
                txtFirstName: {
                    required: true
                },          
                txtLastName: {
                    required: true
                },
                txtEmail: {
                    required: true,
                },         
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },         
                ddlMemberType: {
                    required: true
                },          
                ddlCategory: {
                    required: true
                },          
                ddlSpouse: {
                    required: true
                },          
                ddlChild: {
                    required: true
                }          
            },
            messages: {
                txtFirstName: {
                    required: "Please enter first name"
                },
                txtCompanyName: {
                    required: "Please enter company name"
                },
                txtLastName: {
                    required: "Please enter last name"
                },
                txtEmail: {
                    required: "Please enter email"
                },
                txtMobile: {
                    required: "Please enter mobile"
                },
                ddlMemberType: {
                    required: "Please select member type"
                },
                ddlCategory: {
                    required: "Please select category"
                },
                ddlSpouse: {
                    required: "Please select spouse"
                },
                ddlChild: {
                    required: "Please select child"
                }
            }
        });
        
        if (!form.valid()) return;
        
        helper.StartProcessing(btn);

        try {
            await postOnboardingData(userDetails);
            helper.StopProcessing(btn);

            swal.fire({
                icon: 'success',
                titleText: 'Mail sent successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            closeDrawer();
            fetchOnboardingUsers()
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        form.clearForm();
        form.validate().resetForm();
    };

    const handleResend = async() => {
        try { 
            const swalResult = await swal.fire({
                title               : 'Resend Onboarding Mail',
                icon                : 'question',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Proceed',
                preConfirm: async () => {
                    try {
                        await resendOnboardingMail(userDetails);
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
            if (!swalResult.value) return;
            swal.fire({
                icon: 'success',
                titleText: 'Mail sent successfully!',
                showConfirmButton: false,
                timer: 3500,
                onOpen: () => swal.hideLoading()    
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    };

    const viewForm = () => {
        window.open(`https://gbgc.golfez.in/membershipForm?id=${userDetails.UrlID}&userID=${userDetails.UrlUserID}`, '_blank', 'noopener');
    };

    return(
        <>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => {setRightSideDetails(true)}} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Member</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblOnboardingUser" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="User Details"  onCloseDrawer={() => closeDrawer()}>
                                {action == 'a' ? (
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="add-onbording-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Category <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlCategory" 
                                                                name="ddlCategory"
                                                                className="multi-custom"
                                                                placeholder="Select Category"
                                                                value={userDetails && categoryOption.find((m) => m.value == userDetails.Category )  || ''}
                                                                onChange={(event) => updateUserDetails('Category', event.value)}
                                                                options={categoryOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.Category == "Organization" && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Company Name <span className="red">*</span></label>
                                                            <div className="col-xl-4 col-lg-8">
                                                                <input 
                                                                    id="txtCompanyName" 
                                                                    name="txtCompanyName" 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    value={userDetails.CompanyName || ''}
                                                                    onChange={(event) => updateUserDetails('CompanyName', event.target.value)} 
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">First Name <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtFirstName" 
                                                                name="txtFirstName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.FirstName || ''}
                                                                onChange={(event) => updateUserDetails('FirstName', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Middle Name </label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtMiddleName" 
                                                                name="txtMiddleName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.MiddleName || ''}
                                                                onChange={(event) => updateUserDetails('MiddleName',  helper.camelCase(event.target.value))} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Last Name <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtLastName" 
                                                                name="txtLastName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.LastName || ''}
                                                                onChange={(event) => updateUserDetails('LastName',  helper.camelCase(event.target.value))} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Email <span className="red">*</span></label>
                                                            <div className="col-xl-4 col-lg-8">
                                                                <input 
                                                                    id="txtEmail" 
                                                                    name="txtEmail" 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    value={userDetails.Email || ''}
                                                                    onChange={(event) => updateUserDetails('Email', event.target.value)} 
                                                                />
                                                            </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                                        <div className="col-xl-3 col-lg-4">
                                                            <input 
                                                                id="txtMobile" 
                                                                name="txtMobile" 
                                                                type="text"
                                                                maxLength="10" 
                                                                className="form-control" 
                                                                value={userDetails.Mobile || ''}
                                                                onChange={(event) => updateUserDetails('Mobile', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Member Type <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlMemberType" 
                                                                name="ddlMemberType"
                                                                className="multi-custom"
                                                                placeholder="Select Member Type"
                                                                value={userDetails && membershipTypeOption.find((m) => m.value == userDetails.MembershipType )  || ''}
                                                                onChange={(event) => updateUserDetails('MembershipType', event.value)}
                                                                options={membershipTypeOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Spouse <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlSpouse" 
                                                                name="ddlSpouse"
                                                                className="multi-custom"
                                                                placeholder="Select Spouse"
                                                                value={userDetails && spouseOption.find((m) => m.value == userDetails.Spouse )  || ''}
                                                                onChange={(event) => updateUserDetails('Spouse', event.value)}
                                                                options={spouseOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Child <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlChild" 
                                                                name="ddlChild"
                                                                className="multi-custom"
                                                                placeholder="Select Child"
                                                                value={userDetails && childOption.find((m) => m.value == userDetails.Child )  || ''}
                                                                onChange={(event) => updateUserDetails('Child', event.value)}
                                                                options={childOption}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block"onClick={addChargeHandler}>Send Mail</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className={`col-12`}> 
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-widget kt-widget--user-profile-3">
                                                            <div className="kt-widget__top">
                                                                <img className="kt-widget__pic kt-widget__pic" src={userDetails.ProfilePhoto && userDetails.ProfilePhoto != '' ?  userDetails.ProfilePhoto : defaultUserImg } alt="user-profile"/> 
                                                                 <div className="kt-widget__content">
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <div className="kt-widget__head">
                                                                                <span className="kt-widget__username">
                                                                                    {userDetails.Name}{' '}
                                                                                </span>
                                                                            </div>
                                                                            <div className="kt-widget__subhead padding-t0">
                                                                                <span className="padding-r10">
                                                                                    <i className="flaticon2-new-email"></i>
                                                                                    {userDetails.Email} 
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-widget__action" style={{display: 'flex'}}>
                                                                            <span className="dropdown">
                                                                                <button type="button" className="btn btn-primary" onClick={viewForm}>View Form</button>
                                                                            </span>
                                                                            {userDetails.PresidentSignatureStatus == "N" && (userID == 5 || userID == 8 || userID == 2 || userID == 1 || userID == 9) ? (
                                                                                <span className="dropdown">
                                                                                    <a
                                                                                        className="btn btn-sm btn-clean btn-icon btn-icon-md  margin-l50"
                                                                                        style={{marginTop: '-20px'}}
                                                                                        data-toggle="dropdown"
                                                                                        aria-expanded="true">
                                                                                        <i className="flaticon-more-1"></i>
                                                                                    </a>
                                                                                    <OverflowMenu
                                                                                        onDeleteClick={() => {setshowOtpModal(true)}}
                                                                                        onResendClick={() => handleResend()}
                                                                                    />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="dropdown margin-l50">
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet kt-portlet--mobile">
                                            <div className="kt-portlet__body padding-t0">
                                                <OnboardingTracker
                                                    userDetails={userDetails}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {showOtpModal && (
                <OtpModal
                    onDismissModal = {() => setshowOtpModal(false)}
                    emailData = {userDetails.ID}
                    refechData = {() => {fetchOnboardingUsers(), setRightSideDetails(false)}}
                    from = ''
                />
            )}  
        </>
    )
};

export default Onboarding;