import React, { useState, useEffect } from 'react';
import { postRateMaster } from '../api/paymentAPI';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const RateTable = (props) => {

    const { ID, expandedMenu, sports, fetchRateMasterBySportID, rateMaster } = props;

    const rows = [
        { no: 1, UserType: 'Member', Type: 'Weekday'},
        { no: 2, UserType: 'Member', Type: 'Weekends'},
        { no: 3, UserType: 'Child', Type: 'Weekday'},
        { no: 4, UserType: 'Child', Type: 'Weekends'},
        { no: 5, UserType: 'Guest', Type: 'Weekday'},
        { no: 6, UserType: 'Guest', Type: 'Weekends'},
        { no: 7, UserType: 'Guest Child', Type: 'Weekday'},
        { no: 8, UserType: 'Guest Child', Type: 'Weekends'},
        { no: 9, UserType: 'Visitor', Type: 'Weekday'},
        { no: 10, UserType: 'Visitor', Type: 'Weekends'},
        { no: 11, UserType: 'Visitor Child', Type: 'Weekday'},
        { no: 12, UserType: 'Visitor Child', Type: 'Weekends'},
        { no: 13, UserType: sports?.MarkerDisplayName, Type: 'Weekday'},
        { no: 14, UserType: sports?.MarkerDisplayName, Type: 'Weekends'},
    ];
    
    const initialRateMasterDetails = rows.map(row => ({
        SportID: sports.ID,
        CalenderID: ID,
        UserType: row.UserType,
        Type: row.Type,
        Rate: 0,
        Tax: 0,
        Total: 0,
        Remark: '',
    }));

    const [rateMasterList, setRateMasterList] = useState(initialRateMasterDetails);
    
    useEffect(() => {
        if(expandedMenu === ID  && rateMaster.length > 0){
            setRateMasterList(rateMaster);
        }else{
        setRateMasterList(initialRateMasterDetails);

        }
    }, [rateMaster, expandedMenu])

    const updateRateMaster = (index, field, value) => {
        setRateMasterList((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [field]: value,
        };

        if (field === 'Rate') {
            const rate = parseFloat(value) || 0;
            const tax = rate * 0.18;
            const total = rate + tax;

            updatedDetails[index].Tax = Math.round(tax);
            updatedDetails[index].Total = Math.round(total);
        }

        return updatedDetails;
        });
    };

    const handleSave = async() => {
        const btn = $('#btnSave');
        helper.StartProcessing(btn);

        try { 
            await postRateMaster(rateMasterList);
            if (rateMasterList[0].ID) {            
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: 'Master updated successfully!',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3500,  
                onOpen: () => swal.hideLoading()                                     
            });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Master added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
            fetchRateMasterBySportID();
            helper.StopProcessing(btn);
                
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

  return (
    <>
        <div>
            <div id="accordionExample4">
                <div id={`collapse-${ID}`} className={`collapse ${expandedMenu === ID ? 'show' : ''}`} aria-labelledby={`menuHeading-${ID}`} data-parent="#accordionExample4">
                    <div className="card-body">
                        <div className="kt-widget4">
                            <div className="kt-widget4__item padding-tb5">
                                <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                <thead>
                                    <tr>
                                    <th style={{ width: '5%' }}>No</th>
                                    <th style={{ width: '15%' }}>Particular</th>
                                    <th style={{ width: '10%' }}>Days</th>
                                    <th style={{ width: '10%' }}>Rate</th>
                                    <th style={{ width: '10%' }}>Tax</th>
                                    <th style={{ width: '10%' }}>Total</th>
                                    <th style={{ width: '15%' }}>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rateMasterList.length > 0 && rateMasterList.map((detail, index) => (
                                    <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{detail.UserType}</td>
                                    <td>{detail.Type}</td>
                                    <td>
                                    <input
                                        type="number"
                                        className="form-control input-sm"
                                        value={detail.Rate || 0}
                                        onChange={(e) => updateRateMaster(index, 'Rate', e.target.value)}
                                    />
                                    </td>
                                    <td>
                                    <input
                                        type="number"
                                        className="form-control input-sm"
                                        value={Math.round(detail.Tax) || 0}
                                        disabled
                                    />
                                    </td>
                                    <td>
                                    <input
                                        type="number"
                                        className="form-control input-sm"
                                        value={Math.round(detail.Total) || 0}
                                        disabled
                                    />
                                    </td>
                                    <td>
                                    <textarea
                                        className="form-control input-sm"
                                        style={{ width: '100%', height: '40px' }}
                                        rows="3"
                                        value={detail.Remark || ''}
                                        onChange={(e) => updateRateMaster(index, 'Remark', e.target.value)}
                                    ></textarea>
                                    </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="clearfix form-actions">
                                <button type="button" id="btnSave" name="btnSave" className="btn btn-primary" onClick={handleSave}>
                                    <i className="fa fa-save"></i> Save
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default RateTable;
