import React, { useState, useEffect } from 'react'
import TournamentList from './tournamentList';

const TournamentWinner = (props) => {

    const [tMaster,             setTMaster]             = useState();

    //#region Effects ---
    useEffect(() =>   props.title("Winner", "tournament-winner"), []);

    // useEffect(() => {
    //     if(!!tMaster) fetchTournamentRoundData(tMaster?.ID);
    // }, [tMaster])
    
    //#endregion

    const onSelectHandler = (tMaster) => {
        if(!tMaster) return;       
        setTMaster(tMaster);                                     
    }
    
    //Reset on drawer close
    const onReset = () => {
        setTMaster();               
    }

    return (
        <>
            <TournamentList title={"Winner"}  comp={"winner"} onSelectHandler={onSelectHandler}  resetData={onReset}>
            </TournamentList>    
        </>
    )
}

export default TournamentWinner;