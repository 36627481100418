import React, { useState, useEffect } from 'react'; 
import swal from 'sweetalert2';
import { getSportPayableBySubscriptionIDandSportID, updateSportPayable } from '../api/paymentAPI';

const SportsPayables = (props) => {

    const {subscriptionMasterID, sportID, expandedSport} = props;

    const [sportsPayableList, setSportsPayableList] = useState([]);

    useEffect(() => {
        if(subscriptionMasterID  && sportID){
            fetchSportsPayables(subscriptionMasterID, sportID);
        } 
    }, [subscriptionMasterID, sportID])


    const fetchSportsPayables = async(subID, sportID) => {
        try {
            const payablesList = await getSportPayableBySubscriptionIDandSportID(subID, sportID);
            setSportsPayableList(payablesList);
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const updateSportsPayables = async(data) => {
        try {
            const payablesList = await updateSportPayable(data);
            setSportsPayableList(payablesList);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `${data.Rate == 'Y' ? 'Allowed' : 'Disallow'}`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3500,  
                onOpen: () => swal.hideLoading()                                     
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }
    return(
        <>
            <div>
                <div id="accordionExample4">
                    <div id={`collapse-${sportID}`} className={`collapse ${expandedSport === sportID ? 'show' : ''}`} aria-labelledby={`menuHeading-${sportID}`} data-parent="#accordionExample4">
                        <div className="card-body">
                            <div className="kt-widget4">
                                <div className="kt-widget4__item padding-tb5">
                                    <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '5%' }}>No</th>
                                            <th style={{ width: '15%' }}>Particular</th>
                                            <th style={{ width: '10%' }}>Days</th>
                                            <th style={{ width: '10%' }} className='column_rate_center'>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sportsPayableList.length > 0 && sportsPayableList.map((detail, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{detail.UserType}</td>
                                            <td>{detail.Type}</td> 
                                            <td className='column_rate_center'>
                                                 <label className="kt-checkbox kt-checkbox--tick kt-checkbox--brand">
                                                    <input 
                                                        type="checkbox" 
                                                        checked={detail.Rate == "N" ? '' : 'checked'} 
                                                        onChange={(event) => updateSportsPayables({'ID': detail.ID, SubscriptionID: detail.SubscriptionID, SportID: detail.SportID, 'Rate': event.target.checked ? 'Y' : 'N'})}
                                                    />
                                                    <span></span>
                                                </label>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SportsPayables;