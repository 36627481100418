import { useState, useEffect } from "react";
import TournamentList from "./tournamentList";


const TournamentLeaderboard = (props) =>{ 
    const [tMaster,   setTMaster]   = useState();

    //#region Effects ---
    useEffect(() =>   props.title("Leaderboard", "tournament-leaderboard"), []);
    
    //#endregion

    //#region Handler   
    
    const onSelectHandler =(tMaster)=> {
        if(!tMaster) return;       
        setTMaster(tMaster);                                     
    }
    
    //Reset on drawer close
    const onReset =()=> {
        setTMaster();               
    }

    //#endregion

    
    return(<>
        <TournamentList title={"Leaderboard"}  comp={"Leaderboard"} onSelectHandler={onSelectHandler}  resetData={onReset}>
        
        </TournamentList>    
    </>)
}

export default TournamentLeaderboard;