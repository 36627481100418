import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getSportReportByTypeUrl                                  = (fromDate, toDate, type) => `/api/admin/dashboard/getSportReportByType/${fromDate}/${toDate}/${type}`;
const getCheckInReportByTypeUrl                                = (fromDate, toDate, type) => `/api/admin/dashboard/getCheckInReportByType/${fromDate}/${toDate}/${type}`;
const getCheckInReportByMasterIDUrl                            = (fromDate, toDate, masterID) => `/api/admin/dashboard/getCheckInReportByMasterID/${fromDate}/${toDate}/${masterID}`;

export const getSportReportByType = async (fromDate, toDate, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getSportReportByTypeUrl(fromDate, toDate, type),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCheckInReportByType = async (fromDate, toDate, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCheckInReportByTypeUrl(fromDate, toDate, type),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCheckInReportByMasterID = async (fromDate, toDate, masterID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCheckInReportByMasterIDUrl(fromDate, toDate, masterID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};