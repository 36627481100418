import { useState, useEffect } from "react";
import swal from 'sweetalert2';

const str_Single    = "Singles";
const str_Double    = "Doubles";
const str_Quadraple = "Quads";
const asciiIndex    = 64;
const defaultUserImg = 'https://golfezdev.s3.ap-south-1.amazonaws.com/Public/GBGC/User/user.jpg';


const DrawTable =(props)=>{
    const {selectedRound, pageData, tMaster} = props;
    const [currentRound, setCurrentRound] = useState(selectedRound);       

    useEffect(()=>{
        if(!!selectedRound) setCurrentRound(selectedRound);       
    },[selectedRound])    

    const addFlightRow =(holeNo)=> {                       
        const holeElements = $(`#tblPlayerDraw > tbody > tr[data-hole='${holeNo}']`);  //Get Row with HoleNo        

        if (holeElements.length >= 4) {
            swal.fire({ icon: 'warning',timer: 1000, showConfirmButton: false, text: 'Max 3 Flights!' });
            return;
        }

        let playerTd = `
            <td class="player-holder" data-type="${tMaster?.Type}"> </td>
            ${(tMaster?.Type != str_Quadraple) && `<td class="player-holder" data-type="${tMaster?.Type}"> </td>`}
            ${(tMaster?.Type != str_Quadraple && tMaster?.Type != str_Double) && `<td class="player-holder" data-type="${tMaster?.Type}"> </td>`}
            ${(tMaster?.Type != str_Quadraple && tMaster?.Type != str_Double) && `<td class="player-holder" data-type="${tMaster?.Type}"> </td>`}
        `;      

        const rowString = (flightChar) => (`<tr data-slotId="${holeNo}" data-hole="${holeNo}" data-flight="${flightChar}">
            <td class="slot-name width-180"> <span class="holeText"> ${holeNo} ${flightChar} </span>
            <span class="btn btn-small btn-outline-danger btn-elevate btn-pill m-0 float-right btnRemoveFlight"> Remove flight </span>
            </td> 
            ${playerTd}           
        </tr>`);

        if (holeElements.length > 0) {
            let flight = String.fromCharCode(asciiIndex + holeElements.length);          
            holeElements.last().after(rowString(flight));
            $(document).on('click', '.btnRemoveFlight', function() { removeFlightRow($(this)) });
          
            window?.clearDrag()
            props?.createDragable(); //Init Dragable UI
        }
    }
    
    const removeFlightRow =(ele) => { 
        const tournamentType = tMaster?.Type;
        let holeElement      = ele.closest('tr'); 
        if(holeElement.length == 0) return;        
        
        const hole   = $(holeElement).data('hole')
        // const flight = $(holeElement).data('flight')      
        
        //reverts added players to list
        const players = $(holeElement).find('.dragable-player ');
        if(players.length > 0){           
            players.each((index, playerDiv) => {
                const registerId     = $(playerDiv).data('registerid');
                const playerRegister =  pageData?.TournamentRegister.find(f => f.ID == registerId);

                if (playerRegister) {                    
                    const dragPlayer = (`<div class="dragable-player mb-1" data-registerId="${registerId}" data-drawId="0"> <span>
                        ${(playerRegister.User1Photo && playerRegister.User1Photo != '') ?(`<img src="${playerRegister.User1Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img"  alt="player-image"/> ${playerRegister.User1Name} (${playerRegister.User1Handicap < 0 ? '+' + Math.abs(playerRegister.User1Handicap) : playerRegister.User1Handicap})`):
                            (`<span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${playerRegister.User1Name.substring(0, 1)}</span> ${playerRegister.User1Name} (${playerRegister.User1Handicap < 0 ? '+' + Math.abs(playerRegister.User1Handicap) : playerRegister.User1Handicap})`)}

                        ${(tournamentType != str_Single)? ((playerRegister.User2Photo && playerRegister.User2Photo != '') ?(`  |  <img src="${playerRegister.User2Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${playerRegister.User2Name} (${playerRegister.User2Handicap < 0 ? '+' + Math.abs(playerRegister.User2Handicap) : playerRegister.User2Handicap})`):
                            (`  |  <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${playerRegister.User2Name.substring(0, 1)}</span> ${playerRegister.User2Name} (${playerRegister.User2Handicap < 0 ? '+' + Math.abs(playerRegister.User2Handicap) : playerRegister.User2Handicap})`)): ''}

                        ${(tournamentType == str_Quadraple) ? ((playerRegister.User3Photo && playerRegister.User3Photo != '') ?(`  |  <img src="${playerRegister.User3Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${playerRegister.User3Name} (${playerRegister.User3Handicap < 0 ? '+' + Math.abs(playerRegister.User3Handicap) : playerRegister.User3Handicap})`):
                            (`  |  <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${playerRegister.User3Name.substring(0, 1)}</span> ${playerRegister.User3Name} (${playerRegister.User3Handicap < 0 ? '+' + Math.abs(playerRegister.User3Handicap) : playerRegister.User3Handicap})`)):""}

                        ${(tournamentType == str_Quadraple) ? ((playerRegister.User4Photo && playerRegister.User4Photo != '') ?(`  |  <img src="${playerRegister.User4Photo}" onerror="${defaultUserImg}" class="badge-xs draw-player-img margin-l5"  alt="player-image"/> ${playerRegister.User4Name} (${playerRegister.User4Handicap < 0 ? '+' + Math.abs(playerRegister.User4Handicap) : playerRegister.User4Handicap})`):
                        (`  |  <span class="kt-badge kt-badge--xl badge-xs text-white draw-icon">${playerRegister.User4Name.substring(0, 1)}</span> ${playerRegister.User4Name} (${playerRegister.User4Handicap < 0 ? '+' + Math.abs(playerRegister.User4Handicap) : playerRegister.User4Handicap})`)):''}
                    </span></div>`);

                    $('.player-div').each((i, ele) => {
                        const session = $(ele).data('session');
                        const roundDate = $(ele).data('date');
                        const isDateSame = (currentRound?.Round != 1) ? (true) : moment(moment(roundDate).format('DD-MMM-YYYY')).isSame(moment(playerRegister.PreferedDate).format('DD-MMM-YYYY'))

                        if ((playerRegister?.PreferedSlot?.toLowerCase() == session) && (isDateSame)) {
                            $(ele).append(dragPlayer);
                        }
                    })

                    $(playerDiv).remove();
                }
            });                    
        }
            
        ele.closest('tr').remove();
        //get remaing row of this hole and rename the flight 
        const elements = $(`#tblPlayerDraw > tbody > tr[data-hole='${hole}'][data-flight]:not([data-flight=""])`);
        if(elements.length > 0){
            elements.each(function (index, ele) {
                var newFlight = String.fromCharCode(asciiIndex + (index + 1));
                $(this).attr('data-flight', newFlight).find('.holeText').text(`${hole} ${newFlight}`);  //assign's new flight     
            });
        }
        
        swal.fire({ icon:'success',timer: 1000, showConfirmButton: false, text: 'All players moved to draw list..' });
    }   

    const handleImageError = (event) => {
        event.target.src = defaultUserImg;
    };

    const getPlayerHolder = (oldDraw) => {
        let tableTd = [];

        if (tMaster?.Type == str_Quadraple) {
            if (oldDraw.length > 0) {
                const d = oldDraw[0];             
                if (d) {
                    tableTd.push(<td  className="player-holder" data-type={tMaster?.Type}>
                        <div className="dragable-player margin-tb5" data-registerid={`${d.TournamentRegisterID}`} data-drawid={`${d.ID}`} >
                            <span>
                                {(d.User1Photo && d.User1Photo != '')?
                                    (<img src={d.User1Photo} onError={handleImageError} className="badge-xs draw-img" alt="player-image"/> )
                                    :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {d.User1Name.substring(0, 1)}</span>)
                                } {`${d.User1Name} (${d.User1Handicap < 0 ? '+' + Math.abs(d.User1Handicap) : d.User1Handicap})`}  | 

                                {(d.User2Photo && d.User2Photo != '')?
                                    (<img src={d.User2Photo} onError={handleImageError} className="badge-xs draw-img margin-l5" alt="player-image"/> )
                                    :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {d.User2Name.substring(0, 1)}</span>)
                                } {`${d.User2Name} (${d.User2Handicap < 0 ? '+' + Math.abs(d.User2Handicap) : d.User2Handicap})`}  |                                     

                                {(d.User3Photo && d.User3Photo != '')?
                                    (<img src={d.User3Photo} onError={handleImageError} className="badge-xs draw-img margin-l5" alt="player-image"/> )
                                    :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {d.User3Name.substring(0, 1)}</span>)
                                } {`${d.User3Name} (${d.User3Handicap < 0 ? '+' + Math.abs(d.User3Handicap) : d.User3Handicap})`}  |                                     

                                {(d.User4Photo && d.User4Photo != '')?
                                    (<img src={d.User4Photo} onError={handleImageError} className="badge-xs draw-img margin-l5" alt="player-image"/> )
                                    :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {d.User4Name.substring(0, 1)}</span>)
                                } {`${d.User4Name} (${d.User4Handicap < 0 ? '+' + Math.abs(d.User4Handicap) : d.User4Handicap})`}

                                {d.Comment && 
                                    <button type="button" className="btn btn-xxs btn-secondary btn-circle btn-icon ml-3" data-container="body" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="top" data-content={`${d.Comment ? d.Comment : ''}`}><i className="fa fa-exclamation text-primary"></i></button>}
                            </span>
                        </div>
                    </td>)                   
                } else {
                    tableTd.push(<td  className="player-holder" data-type={tMaster?.Type}> </td>);
                }
            } else {
                tableTd.push(<td className="player-holder" data-type={tMaster?.Type}> </td>);
            }
        }
        else if (tMaster?.Type == str_Double) {
            for (var i = 1; i <= 2; i++) {
                if (oldDraw.length > 0) {
                    const d = oldDraw.find(a => a.Index == i);
                    if (d) {
                        tableTd.push(<td key={i} className="player-holder" data-type={tMaster?.Type}>
                            <div className="dragable-player margin-tb5" data-registerid={`${d.TournamentRegisterID}`} data-drawid={`${d.ID}`} >
                                <span>
                                    {(d.User1Photo && d.User1Photo != '')?
                                        (<img src={d.User1Photo} onError={handleImageError} className="badge-xs draw-img" alt="player-image"/> )
                                        :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon"> {d.User1Name.substring(0, 1)}</span>)
                                    } {`${d.User1Name} (${d.User1Handicap < 0 ? '+' + Math.abs(d.User1Handicap) : d.User1Handicap})`} |

                                    {(d.User2Photo && d.User2Photo != '')?
                                        ( <img src={d.User2Photo} onError={handleImageError} className="badge-xs draw-img margin-l5" alt="player-image"/> )
                                        :( <span className="kt-badge kt-badge--xl badge-xs text-white draw-icon"> {d.User2Name.substring(0, 1)}</span>)
                                    } {`${d.User2Name} (${d.User2Handicap < 0 ? '+' + Math.abs(d.User2Handicap) : d.User2Handicap})`}                                
                                    
                                    {d.Comment && 
                                        <button type="button" className="btn btn-xxs btn-secondary btn-circle btn-icon ml-3" data-container="body" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="top" data-content={`${d.Comment ? d.Comment : ''}`}><i className="fa fa-exclamation text-primary"></i></button>}
                                </span>
                            </div>
                        </td>)                   
                    } else {
                        tableTd.push(<td key={i} className="player-holder" data-type={tMaster?.Type}></td>);
                    }
                } else {
                    tableTd.push (<td key={i} className="player-holder" data-type={tMaster?.Type}> </td>);
                }            
            }
        }
        else {
            for (var i = 1; i <= 4; i++) {                
                if (oldDraw.length > 0) {
                    const d = oldDraw.find(a => a.Index == i);
                    if (d) {
                        tableTd.push(<td key={i} className="player-holder" data-type={tMaster?.Type}>
                            <div className="dragable-player margin-tb5" data-registerid={`${d.TournamentRegisterID}`} data-drawid={`${d.ID}`} >
                                <span>
                                    {(d.User1Photo && d.User1Photo != '')?
                                        (<img src={d.User1Photo} onError={handleImageError} className="badge-xs draw-img" alt="player-image"/> )
                                        :(<span className="kt-badge kt-badge--xl badge-xs text-white draw-icon" > {d.User1Name.substring(0, 1)}</span>)
                                    } {`${d.User1Name} (${d.User1Handicap < 0 ? '+' + Math.abs(d.User1Handicap) : d.User1Handicap})`}
                                   
                                    {d.Comment && 
                                        <button type="button" className="btn btn-xxs btn-secondary btn-circle btn-icon ml-3" data-container="body" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="top" data-content={`${d.Comment ? d.Comment : ''}`}><i className="fa fa-exclamation text-primary"></i></button>}
                                </span>
                            </div>
                        </td>)                   
                    } else {                      
                        tableTd.push(<td key={i} className="player-holder" data-type={tMaster?.Type}> </td>);
                    }                    
                } else {                    
                    tableTd.push(<td key={i} className="player-holder" data-type={tMaster?.Type}> </td>);
                }
            }
        }
    
        return tableTd;
    }

    const createDrawTable =()=>{
        const teeOffOption    = selectedRound?.TeeOffOption;      
        const tournamentSlots = pageData?.TournamentTeeSlots || [];    
        const tournamentDraw  = pageData?.TournamentDraw || [] ;
        
        const tableTr = [];
        
        for(let item of tournamentSlots){
            let flightTr  = [];
            let oldDraw   = [];
            let playerTds = [];
        
            //check if draw is created for this slot;
            if(teeOffOption === "Shot Gun Start") {
                oldDraw = tournamentDraw.filter(d => d.Hole == item.Slot); 
                const withOutFlight  = oldDraw.filter(d => (!d.Flight || d.Flight == ''));  //Without flight
                const drawWithFlight = oldDraw.filter(d => !!d.Flight && d.Flight != '');  //with flight
                playerTds = getPlayerHolder(withOutFlight);                                          

                //Flight tr Code Goes Here--
                if(drawWithFlight.length > 0){
                    const uniqueFlights = new Set(drawWithFlight.map(f => f.Flight));   

                    flightTr = Array.from(uniqueFlights).map((flightChar, index)=>{
                        const flightPlayers  = drawWithFlight.filter(t => t.Flight == flightChar);
                        const flightPlayerTd = getPlayerHolder(flightPlayers);
                        
                        return(<tr key={`${item.Slot}${flightChar}`} data-slotid ={`${item.ID || item.Slot}`} data-hole={`${item.Slot}`}  data-flight={`${flightChar}`}>
                            <td className="slot-name width-180"> <span className="holeText"> {item.Slot} {flightChar} </span>
                                <span className="btn btn-small btn-outline-danger btn-elevate btn-pill m-0 float-right btnRemoveFlight"> Remove flight </span>
                            </td> 
                            {flightPlayerTd}           
                        </tr>);                        
                    })                                        
                }
            }else{
                oldDraw = tournamentDraw.filter(d => d.TournamentTeeSlotID == (item.ID)); 
                playerTds = getPlayerHolder(oldDraw);
            }                

            const drawTr = (<tr key={item.Slot} data-slotid ={`${item.ID || item.Slot}`} data-hole={`${item.Slot}`} >
                <td className="slot-name">
                    {item.Slot} 
                    {(selectedRound.TeeOffOption == "Shot Gun Start") && (
                        <span onClick={()=> addFlightRow(item.Slot)}  className="btn btn-small btn-outline-brand btn-elevate btn-pill d-inline-block m-0 float-right font-10"> 
                            <i className="fa fa-plus font-10"></i> Add Flight
                        </span>
                    )}
                </td>
                {playerTds}
            </tr>);

            tableTr.push(drawTr);
            for (const flightRow of flightTr) {
                tableTr.push(flightRow)
            }
        }

        //Register Onclick for Remove if Flight--
        if(teeOffOption === "Shot Gun Start") $(document).on('click', '.btnRemoveFlight', function() { removeFlightRow($(this)) });

        return tableTr;
    }

    return(<>   
        {!!currentRound && (
            <table id="tblPlayerDraw" className="table table-bordered table-hover margin-b30">
                <thead className="light-grey-background">
                    <tr>
                        <th>{(selectedRound.TeeOffOption == "Shot Gun Start") ? 'Hole': 'Slot'}</th>
                        {(tMaster?.Type == str_Quadraple)?(<th className="t-head"> Team  </th>):((tMaster?.Type == str_Double) ?(<>
                            <th className="t-head"> Team 1 </th><th className="t-head"> Team 2 </th>
                        </>):(<>
                            <th className="t-head"> Player 1 </th><th className="t-head"> Player 2 </th><th className="t-head"> Player 3 </th><th className="t-head"> Player 4 </th>
                        </>))}
                    </tr>
                </thead>
                <tbody>
                    {createDrawTable()}                   
                </tbody>
            </table>
        )}     
    </>)  
}



export default DrawTable;