import { useState, useEffect} from "react";


const CategoryTable = (props) => { 
    const [pageData, setPageData] = useState({});

    //#region Effects      
    useEffect(()=> setCategoryData(), []);
    //#endregion

    //#region Handler   
    const setCategoryData =()=> {
        const {tMaster, tData, teeBoxes} = props;                    
        setPageData({tMaster, teeBoxes, MembershipType: tData.MembershipType});              
    }

    const onChangeHandler =(field, value, rowIndex)=> {        
        props.setCategoryList((d) => {
            const newCat = d.map((c,i)=>((i == rowIndex)? ({...c,[field]: value}): c));                      
            return [...newCat];
        });       
    } 

    //#endregion

    return(<>
        {props?.cData?.isAllow && (props.categoryList.length > 0) && props.categoryList.map((c, rowIndex) =>{
            return(
                <div className="flex-container categoryRow" key={rowIndex}>

                    <div className="flex-item mr-3">
                        <div className="kt-user-card-v2 mb-2">
                            <div className="kt-user-card-v2__details" >
                                <span className="kt-user-card-v2__name"> Category Name <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc">
                                    <input id={`txtCategoryName${rowIndex}`} name={`txtCategoryName${rowIndex}`} type="text" className="form-control" 
                                        value={c?.Name || ''}
                                        onChange={({target})=> onChangeHandler('Name', target.value, rowIndex)}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-item">
                        <div className="kt-user-card-v2 mb-2">
                            <div className="kt-user-card-v2__details" >
                                <span className="kt-user-card-v2__name "> Handicap Range <span className="red">*</span> </span>
                                <span className="kt-user-card-v2__desc  txtTournamentRange mr-1">
                                    <input id={`txtHandicapStart${rowIndex}`} name={`txtHandicapStart${rowIndex}`} type="number" className="form-control" 
                                        value={c?.HandicapStart}
                                        onChange={({target})=> onChangeHandler('HandicapStart', target.value, rowIndex)}
                                    />
                                </span>
                                <span className="kt-user-card-v2__desc txtTournamentRange mr-10">
                                    <input id={`txtHandicapEnd${rowIndex}`} name={`txtHandicapEnd${rowIndex}`} type="number" className="form-control" 
                                        value={c?.HandicapEnd}
                                        onChange={({target})=> onChangeHandler('HandicapEnd', target.value, rowIndex)}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-item">
                        <div className="kt-user-card-v2 mb-2" >
                            <div className="kt-user-card-v2__details" >
                                <span className="kt-user-card-v2__name "> Age Range <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc txtTournamentRange mr-1">
                                    <input id={`txtMinAge${rowIndex}`} name={`txtMinAge${rowIndex}`} type="number" className="form-control" 
                                        value={c?.MinAge}
                                        onChange={({target})=> onChangeHandler('MinAge', target.value, rowIndex)}                    
                                    />
                                </span>
                                <span className="kt-user-card-v2__desc txtTournamentRange">
                                    <input id={`txtMaxAge${rowIndex}`} name={`txtMaxAge${rowIndex}`} type="number" className="form-control" 
                                        value={c?.MaxAge}
                                        onChange={({target})=> onChangeHandler('MaxAge', target.value, rowIndex)}                                          
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                                    
                    <div className="flex-item flex-grow-06">
                        <div className="kt-user-card-v2 mb-2">
                            <div className="kt-user-card-v2__details ">
                                <span className="kt-user-card-v2__name "> Type <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc ddlTournamentDt">
                                    <select id={`ddlCategoryType${rowIndex}`} name={`ddlCategoryType${rowIndex}`}  className="form-control"
                                        value={c?.Type}
                                        onChange={({target})=> onChangeHandler('Type', target.value, rowIndex)}    
                                    >
                                        {pageData?.MembershipType?.filter(d => (pageData?.tMaster?.MembershipType == 'All' || d == pageData?.tMaster?.MembershipType)).map((g) => ( <option key={g} value={g}> {g} </option> ))}                                                                             
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>   

                    <div className="flex-item flex-grow-06">
                        <div className="kt-user-card-v2 mb-2">
                            <div className="kt-user-card-v2__details ">
                                <span className="kt-user-card-v2__name "> Gender <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc ddlTournamentDt">
                                    <select id={`ddlCategoryGender${rowIndex}`} name={`ddlCategoryGender${rowIndex}`}  className="form-control"
                                        value={c?.Gender}
                                        onChange={({target})=> onChangeHandler('Gender', target.value, rowIndex)}    
                                    >
                                        {(pageData?.tMaster?.Gender == 'Both')   ? <option value="Both">   Both   </option>: ''}
                                        {(pageData?.tMaster?.Gender != 'Female') ? <option value="Male">   Male   </option>: ''}
                                        {(pageData?.tMaster?.Gender != 'Male')   ? <option value="Female"> Female </option>: ''}                                        
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>      
              
                    <div id={`ddlCatTeeBoxPnl${rowIndex}`} className="flex-item d-flex flex-grow-14">                         
                        <div id={`"ddlCatMaleTeeBoxPnl${rowIndex}`} className={`kt-user-card-v2 ${c?.Gender == "Female"? 'hidden':''} align-item-start`}>
                            <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name"> Male Teebox  <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc ddlTournamentDt">
                                    <select id={`ddlMaleTeeBox${rowIndex}`} name={`ddlMaleTeeBox${rowIndex}`} className="form-control"
                                        value={c?.MaleTeeBoxID}
                                        onChange={({target})=> onChangeHandler('MaleTeeBoxID', parseInt(target.value), rowIndex)}  
                                    >
                                        <option value="0"> Select Teebox </option>
                                        {pageData?.teeBoxes?.filter(t=> t.Gender == "Male").map((g) => ( <option key={g.ID} value={g.ID}> {g.Name} </option> ))}                                
                                    </select>
                                </span>
                            </div>
                        </div>
                            
                        <div id={`ddlCatFemaleTeeBoxPnl${rowIndex}`} className={`kt-user-card-v2 ${(c?.Gender == "Both" ? 'ml-3':(c?.Gender == "Male"?'hidden':''))} align-item-start`}>
                            <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name"> Female Teebox  <span className="red">*</span></span>
                                <span className="kt-user-card-v2__desc ddlTournamentDt">                                  
                                    <select id={`ddlFemaleTeeBox${rowIndex}`} name={`ddlFemaleTeeBox${rowIndex}`} className="form-control"
                                        value={c?.FemaleTeeBoxID}
                                        onChange={({target})=> onChangeHandler('FemaleTeeBoxID', parseInt(target.value), rowIndex)}  
                                    >
                                        <option value="0"> Select Teebox </option>
                                        {pageData?.teeBoxes?.filter(t=> t.Gender == "Female").map((g) => ( <option key={g.ID} value={g.ID}> {g.Name} </option> ))}                                
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>                    
        
                    <div className="flex-item flex-grow-06">
                        <div className="kt-user-card-v2 mb-2">
                            <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name"> Is Gross <span className="red">*</span> </span>
                                <span className="kt-user-card-v2__desc ddlTournamentDt d-flex">
                                    <span className="kt-switch kt-switch--icon mr-4">
                                        <label>
                                            <input id={`chkIsGross${rowIndex}`} name={`chkIsGross${rowIndex}`} type="checkbox"
                                                checked={(c?.IsGross == 'Y')}
                                                onChange={({target})=> onChangeHandler('IsGross', (target.checked)? "Y" : "N", rowIndex)}     
                                            /> <span></span>
                                        </label>
                                    </span>
                                    {(rowIndex) > 0 && (
                                        <span className="mb-4">
                                            <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-delete-category"
                                                id={`btnDeleteCategory${rowIndex}`}  onClick={() => props?.removeCategory(rowIndex)} 
                                            >                                    
                                                <i className="flaticon2-rubbish-bin-delete-button font-12"></i>
                                            </button>
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>                               
                </div>
            )
        })}
       
    </>)
}


export default CategoryTable; 