import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import swal from 'sweetalert2';
import moment from "moment";
import TournamentList from "./tournamentList";
import { deleteTournamentRegister, getRegistrationData, postTournamentRegistration } from "../api/tournamentAPI";

const preferedSlots = ['Morning', 'Afternoon'];
const str_Single    = "Singles";
const str_Double    = "Doubles";
const str_Quadraple = "Quads";

const RegisterModal =(props)=> {   
    const [tData,           setTData]           = useState({});
    const [tRegister,       setTregister]       = useState({});
    const [players,         setPlayers]         = useState([]);
    const [player1,         setPlayer1]         = useState([]);
    const [player2,         setPlayer2]         = useState([]);
    const [player3,         setPlayer3]         = useState([]);
    const [player4,         setPlayer4]         = useState([]);
    const [session,         setSession]         = useState([]);
    const [selectedRound,   setSelectedRound]   = useState();

    //#region Effects   
    useEffect(() => {
        $("#registerModal").modal({ backdrop: "static" });      
        $("#registerModal").on("hidden.bs.modal",()=>{ 
            clearModalStates();
            props?.onDismissModal() 
        });    
    }, [props?.show]);

    useEffect(() => {
        if (players && players.length > 0) {
            if (tRegister) {
                const filteredPlayer1 = players.filter(p => (p.value.ID != tRegister.User2ID || p.value.UserType != tRegister.User2Type) && (p.value.ID != tRegister.User3ID || p.value.UserType != tRegister.User3Type) && (p.value.ID != tRegister.User4ID || p.value.UserType != tRegister.User4Type))
                const filteredPlayer2 = players.filter(p => (p.value.ID != tRegister.User1ID || p.value.UserType != tRegister.User1Type) && (p.value.ID != tRegister.User3ID || p.value.UserType != tRegister.User3Type) && (p.value.ID != tRegister.User4ID || p.value.UserType != tRegister.User4Type))
                const filteredPlayer3 = players.filter(p => (p.value.ID != tRegister.User1ID || p.value.UserType != tRegister.User1Type) && (p.value.ID != tRegister.User2ID || p.value.UserType != tRegister.User2Type) && (p.value.ID != tRegister.User4ID || p.value.UserType != tRegister.User4Type))
                const filteredPlayer4 = players.filter(p => (p.value.ID != tRegister.User1ID || p.value.UserType != tRegister.User1Type) && (p.value.ID != tRegister.User2ID || p.value.UserType != tRegister.User2Type) && (p.value.ID != tRegister.User3ID || p.value.UserType != tRegister.User3Type))
                setPlayer1(filteredPlayer1)
                setPlayer2(filteredPlayer2)
                setPlayer3(filteredPlayer3)
                setPlayer4(filteredPlayer4)
            }
        }
    }, [tRegister, players])

    useEffect(()=>{       
        if(props?.tData) {
            let AllPlayers = [];
            const { UnRegistered, TournamentRound } = props?.tData;

            const FirstRounds = TournamentRound?.filter((r)=> r.Round == 1);              
            setTData({...props?.tData, FirstRounds});                    
    
            if(UnRegistered?.length > 0) {
                AllPlayers = UnRegistered.map(r =>({label:`${r.Name} ${ r?.Handicap == null ? ` (NH)`: ` (${r?.Handicap})`} ${r?.UserType == 'Guest'? ' (G)':''} `, value: r }));                
            }
            
            //For Edits          
            if(!!props?.selectedRegister) {
                const {selectedRegister} = props;
                
                setTregister(selectedRegister);                      
                onChangeHandler('PreferedDate', selectedRegister.PreferedDate);

                const players = [{
                    label : `${selectedRegister.User1Name} (${selectedRegister.User1Handicap}) ${(selectedRegister.User1Type == "Guest") ? '(G)':''}`,
                    value : {
                        ID       : selectedRegister.User1ID,
                        UserType : selectedRegister.User1Type,
                        Name     : selectedRegister.User1Name,
                        Email    : selectedRegister.User1Email,
                        DOB      : selectedRegister.User1DOB,                        
                        Handicap    : selectedRegister.User1Handicap,                              
                        MembershipNo: selectedRegister.User1MembershipNo,                       
                    },
                }]
               
                //Update for Doubles and Quadraple
                if(props?.tMaster?.Type != str_Single){
                    for (let index = 2; index <= 4 ; index++) {
                        const cPlayerID = selectedRegister[`User${index}ID`];
                        if(!!cPlayerID){
                            players.push({
                                label : `${selectedRegister[`User${index}Name`]} (${selectedRegister[`User${index}Handicap`]}) ${(selectedRegister[`User${index}Type`] == "Guest") ? '(G)':''}`,
                                value : {
                                ID          : cPlayerID,
                                UserType    : selectedRegister[`User${index}Type`],
                                Name        : selectedRegister[`User${index}Name`],
                                Email       : selectedRegister[`User${index}Email`],
                                DOB         : selectedRegister[`User${index}DOB`],                        
                                Handicap    : selectedRegister[`User${index}Handicap`],                              
                                MembershipNo: selectedRegister[`User${index}MembershipNo`],                       
                            }});                    
                        }
                    }
                }

                AllPlayers = [...AllPlayers, ...players];
            }
                   
            setPlayers(AllPlayers);

            // if(props?.tMaster?.Days != "Multiple" && FirstRounds.length == 1 ) fillSlotDropDown(); //Default Slot
            if(props?.tMaster?.Days == "Multiple"){
                if(FirstRounds.length == 1) fillSlotDropDown(); //Default Slot
            }else{
                fillSlotDropDown();
            }            
        }        
    },[props]);   
         
    //#endregion

    //#region API Calls
    const postTournamentRegister = async() => {      
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });

            //Check Users and Handicap
            const isValidUsers = checkUsers();
            if(!isValidUsers) return;

            if (!tRegister?.PreferedSlot || tRegister?.PreferedSlot == 0) {               
                swal.fire({ icon: 'warning', timer: 1500, showConfirmButton: false, titleText: 'Please select preferred slot.', onOpen: () => swal.hideLoading() });
                return;
            }

            let tournamentRegister = {TournamentID:props?.tMaster?.ID , ...tRegister};

            //If Prefered Date is not selected Enter first roundDate as default--
            if(!tRegister.PreferedDate) tournamentRegister.PreferedDate = moment(tData.FirstRounds[0].Date).format('YYYY-MM-DD');

            //For add Only
            if(!props?.selectedRegister) tournamentRegister.ID = 0;
                  
            await postTournamentRegistration(tournamentRegister);
            
            swal.fire({
                icon: 'success', timer: 1500, animation: false, toast: true,
                position: 'bottom-end', showConfirmButton: false, customClass: { popup: 'margin-20' },
                titleText: 'Tournament Registration Added successfully!',
                onOpen: () => swal.hideLoading()
            });
                        
            props.refetchData();
            $("#registerModal").modal('hide');                        
        } catch (err) {           
            swal.fire({ icon :'error', titleText : err.message,  onOpen: () => swal.hideLoading() })
        }
    }

    const checkUsers = () => {
        let isValid = true;
        //Check User1ID With All ID's in Registration 
        if(!tRegister.User1ID || (props?.tMaster?.Type != str_Single && !tRegister.User2ID) || 
            (props?.tMaster?.Type == str_Quadraple && (!tRegister.User3ID || !tRegister.User4ID))){          
            swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Please select player!', onOpen: () => swal.hideLoading(),});
            return isValid = false;
        }
        
        let isUser1Exist = tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User1ID && u.User1Type == tRegister?.User1Type) || (u.User2ID == tRegister?.User1ID && u.User2Type == tRegister?.User1Type)|| (u.User3ID == tRegister?.User1ID && u.User3Type == tRegister?.User1Type) || (u.User4ID == tRegister?.User1ID && u.User4Type == tRegister?.User1Type));
        let isWaiting    = false;
        
        if (!isUser1Exist) {   //Check User1ID With All ID's in Waiting  
            isUser1Exist = tData?.TournamentWaiting?.find(u => (u.User1ID == tRegister?.User1ID && u.User1Type == tRegister?.User1Type) || (u.User2ID == tRegister?.User1ID && u.User2Type == tRegister?.User1Type)|| (u.User3ID == tRegister?.User1ID && u.User3Type == tRegister?.User1Type) || (u.User4ID == tRegister?.User1ID && u.User4Type == tRegister?.User1Type));
            isWaiting    = true;
        }

        if (isUser1Exist && ((isWaiting && isUser1Exist.ID != tRegister?.ID) || (!isWaiting && isUser1Exist.ID != tRegister?.ID))) {          
            swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Player 1 already exists!', onOpen: () => swal.hideLoading() });           
            return  isValid = false;
        }
        
         //User1Handicap Check
        if (!tRegister?.User1Handicap) {         
            swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Please enter player 1 handicap', onOpen: () => swal.hideLoading() });
            return isValid = false;
        }
       
        if (props?.tMaster?.Type != str_Single) {
            let isUser2Exist =  tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User2ID && u.User1Type == tRegister?.User2Type) || (u.User2ID == tRegister?.User2ID && u.User2Type == tRegister?.User2Type)|| (u.User3ID == tRegister?.User2ID && u.User3Type == tRegister?.User2Type) || (u.User4ID == tRegister?.User2ID && u.User4Type == tRegister?.User2Type));
            isWaiting        = false;

            if (!isUser2Exist) {
                isUser2Exist = tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User2ID && u.User1Type == tRegister?.User2Type) || (u.User2ID == tRegister?.User2ID && u.User2Type == tRegister?.User2Type)|| (u.User3ID == tRegister?.User2ID && u.User3Type == tRegister?.User2Type) || (u.User4ID == tRegister?.User2ID && u.User4Type == tRegister?.User2Type));
                isWaiting    = true;
            }

            if (isUser2Exist && ((isWaiting && isUser2Exist.ID != tRegister?.ID) || (!isWaiting && isUser2Exist.ID != tRegister?.ID))) {              
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Player 2 already exists!', onOpen: () => swal.hideLoading()});
                return isValid = false;
            }
           
            //User2Handicap Check
            if (!tRegister?.User2Handicap) {         
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Please enter player 2 handicap', onOpen: () => swal.hideLoading() });
                return isValid = false;
            }
        }

        if (props?.tMaster?.Type == str_Quadraple) {
            let isUser3Exist =  tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User3ID && u.User1Type == tRegister?.User3Type) || (u.User2ID == tRegister?.User3ID && u.User2Type == tRegister?.User3Type)|| (u.User3ID == tRegister?.User3ID && u.User3Type == tRegister?.User3Type) || (u.User4ID == tRegister?.User3ID && u.User4Type == tRegister?.User3Type));
            isWaiting        = false;
            
            if (!isUser3Exist) {
                isUser3Exist = tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User3ID && u.User1Type == tRegister?.User3Type) || (u.User2ID == tRegister?.User3ID && u.User2Type == tRegister?.User3Type)|| (u.User3ID == tRegister?.User3ID && u.User3Type == tRegister?.User3Type) || (u.User4ID == tRegister?.User3ID && u.User4Type == tRegister?.User3Type));
                isWaiting    = true;
            }

            if (isUser3Exist && ((isWaiting && isUser3Exist.ID != tRegister?.ID) || (!isWaiting && isUser3Exist.ID != tRegister?.ID))) {              
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Player 3 already exists!', onOpen: () => swal.hideLoading()});
                return  isValid = false;
            }

            let isUser4Exist =  tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User4ID && u.User1Type == tRegister?.User4Type) || (u.User2ID == tRegister?.User4ID && u.User2Type == tRegister?.User4Type)|| (u.User3ID == tRegister?.User4ID && u.User3Type == tRegister?.User4Type) || (u.User4ID == tRegister?.User4ID && u.User4Type == tRegister?.User4Type));
            isWaiting        = false;
            
            if (!isUser4Exist) {
                isUser3Exist = tData?.TournamentRegister?.find(u => (u.User1ID == tRegister?.User4ID && u.User1Type == tRegister?.User4Type) || (u.User2ID == tRegister?.User4ID && u.User2Type == tRegister?.User4Type)|| (u.User3ID == tRegister?.User4ID && u.User3Type == tRegister?.User4Type) || (u.User4ID == tRegister?.User4ID && u.User4Type == tRegister?.User4Type));
                isWaiting    = true;
            }

            if (isUser4Exist && ((isWaiting && isUser4Exist.ID != tRegister?.ID) || (!isWaiting && isUser4Exist.ID != tRegister?.ID))) {            
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Player 4 already exists!', onOpen: () => swal.hideLoading()});
                return  isValid = false;
            }

            if (!tRegister?.User3Handicap) {             
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Please enter player 3 handicap', onOpen: () => swal.hideLoading() });
                return isValid = false;
            }

            if (!tRegister?.User4Handicap) {           
                swal.fire({ icon: 'warning', timer: 900, showConfirmButton: false, titleText: 'Please enter player 4 handicap', onOpen: () => swal.hideLoading() });
                return isValid = false;
            }
        }
        
        return  isValid; 
    }
    
    //#endregion

    //#region handlers  

    const fillSlotDropDown =(round)=> {          
        const { AfternoonSessionTime, TournamentTeeSlots, TournamentRound } = props?.tData;
        let teeSlots = TournamentTeeSlots;

        if (!!round){
            teeSlots = TournamentTeeSlots.filter(t => t.TournamentTeeID == round.TournamentTeeID);
        }else{
            round    = TournamentRound.find(t => (t.Round == 1)); //Fill Default round as 1   
        }

        if (round?.TeeOffOption == 'Staggered Start') {                                            
            if (teeSlots.length > 0) {   //Check if 1st Round TeeSlot Has Both Session ->    
                let IsMorningSession   = !!(teeSlots.find(t => moment(t.Slot, 'hh:mm A').isBefore(moment(AfternoonSessionTime, 'hh:mm A'))));
                let IsAfternoonSession = !!(teeSlots.find(t => moment(t.Slot, 'hh:mm A').isSameOrAfter(moment(AfternoonSessionTime, 'hh:mm A'))));
                let slotArr            = (IsMorningSession && IsAfternoonSession) ? preferedSlots : ((IsMorningSession) ? preferedSlots.filter(f => f == 'Morning') : ((IsAfternoonSession) ? preferedSlots.filter(f => f == 'Afternoon') : ([])));
              
                setSelectedRound(round);
                setSession(slotArr);
            }
        } else { 
            setSelectedRound(round);         
            setSession(preferedSlots);
            if (round?.TeeOffOption == 'Shot Gun Start') {
                let IsAfternoonSession = moment(round?.TeeOffTime, 'hh:mm A').isSameOrAfter(moment(AfternoonSessionTime, 'hh:mm A'));                                                
                onChangeHandler('PreferedSlot', IsAfternoonSession ? preferedSlots[1] : preferedSlots[0]);              
            } 
        }
    }

    const onChangeHandler = (field, value)=> {
        if(field == 'PreferedDate'){            
            const { TournamentRound } = props?.tData;        
            const selectedRound = TournamentRound?.find(r => r.ID == value || moment(moment(r.Date).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(moment(value).format('YYYY-MM-DD')));
            
            if (!selectedRound) return;
            const valueDate     = moment(selectedRound.Date).format('YYYY-MM-DD');           

            fillSlotDropDown(selectedRound);  //Update Prefered Slot Respectively.            
            setSelectedRound(selectedRound);
            setTregister(r => ({...r, [field]: valueDate}));
        }else{
            setTregister(r => ({...r, [field]:value}));
        }
    }

    const onPlayerSelect = (playerdd, player)=> {       
        if(!player) return;                           
        setTregister(r => ({...r, [`${playerdd}ID`]: player.ID, [`${playerdd}Handicap`]: player.Handicap, [`${playerdd}Type`]: player.UserType}));
    }

    const clearModalStates =()=>{
        setTregister({});
        setPlayers([]);
        setSession([]);
        setSelectedRound();
    }

    //#endregion

    return(<>
        <div className="modal fade" id="registerModal" tabIndex="-1" role="dialog" aria-hidden="true">       
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Tournament Registration </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>         
                    <div className="modal-body bg-grey">

                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <div className="kt-widget kt-widget--user-profile-3">
                                    <div className="form-group row">
                                        <label className="col-form-label offset-lg-1 col-lg-3"> Player 1 <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <div className="input-group ddlMobilePnl">                                            
                                                <Select                                       
                                                    id       = "ddlPlayer1" className="width-100p" name= "ddlPlayer1" placeholder= "Select Player 1"
                                                    options  = {player1 || []}
                                                    value    = {(players.length > 0 && tRegister?.User1ID) && players.find(m => (m.value.ID == tRegister?.User1ID) && (m.value.UserType == tRegister?.User1Type) )}
                                                    isDisabled={(!!props?.selectedRegister)}
                                                    onChange = {(event) => onPlayerSelect('User1', event.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {props?.tMaster?.Type != str_Single && (                            
                                        <div className="form-group row player2-holder">
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Player 2 <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div className="input-group ddlMobilePnl">
                                                    <Select                                       
                                                        id       = "ddlPlayer2" className="width-100p" name= "ddlPlayer2" placeholder= "Select Player 2"
                                                        options  = {player2 || []}  isDisabled={(!!props?.selectedRegister)}
                                                        value    = {(players.length > 0 && tRegister?.User2ID) && players.find(m =>(m.value.ID == tRegister?.User2ID) && (m.value.UserType == tRegister?.User2Type))}
                                                        onChange = {(event) => onPlayerSelect('User2',event.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {props?.tMaster?.Type == str_Quadraple && (<>                            
                                        <div className="form-group row player3-holder">
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Player 3 <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div className="input-group ddlMobilePnl">
                                                    <Select                                       
                                                        id       = "ddlPlayer3" className="width-100p" name= "ddlPlayer3" placeholder= "Select Player 3"
                                                        options  = {player3 || []}  isDisabled={(!!props?.selectedRegister)}
                                                        value    = {(players.length > 0 && tRegister?.User3ID) && players.find(m => (m.value.ID == tRegister?.User3ID) && (m.value.UserType == tRegister?.User3Type))}
                                                        onChange = {(event) => onPlayerSelect('User3',event.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row player4-holder">
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Player 4 <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8">
                                                <div className="input-group ddlMobilePnl">
                                                    <Select                                       
                                                        id       = "ddlPlayer4" className="width-100p" name= "ddlPlayer4" placeholder= "Select Player 4"
                                                        options  = {player4 || []}  isDisabled={(!!props?.selectedRegister)}
                                                        value    = {(players.length > 0 && tRegister?.User4ID) && players.find(m => (m.value.ID == tRegister?.User4ID) && (m.value.UserType == tRegister?.User4Type))}
                                                        onChange = {(event) => onPlayerSelect('User4', event.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>)}

                                    <div className="form-group row player1-handicap margin-b15">
                                        <label className="col-form-label offset-lg-1 col-lg-3"> Player 1 Handicap </label>
                                        <div className="col-xl-2 col-lg-2">
                                            <input id="txtHandicap1" name="txtHandicap1" type="number" className="form-control width-100" max={36}
                                                value={tRegister?.User1Handicap || ''}
                                                onChange={({target}) => onChangeHandler('User1Handicap', parseInt(target.value))}
                                            />
                                        </div>
                                        {props?.tMaster?.Type != str_Single && (
                                            <div className="col-lg-5 d-flex">                                            
                                                <label className="col-form-label ml-2 mr-2"> Player 2 Handicap </label>
                                                <input id="txtHandicap2" name="txtHandicap2" type="number" className="form-control width-100" max={36}
                                                    value={tRegister?.User2Handicap || ''} 
                                                    onChange={({target}) => onChangeHandler('User2Handicap', parseInt(target.value))}
                                                />                                      
                                            </div>
                                        )}
                                    </div>                      

                                    {props?.tMaster?.Type == str_Quadraple && (<>
                                        <div className="form-group row player3-handicap margin-b15">
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Player 3 Handicap </label>
                                            <div className="col-xl-2 col-lg-2">
                                                <input id="txtHandicap3" name="txtHandicap3" type="number" className="form-control width-100" max={36}
                                                    value={tRegister?.User3Handicap || ''}
                                                    onChange={({target}) => onChangeHandler('User3Handicap',  parseInt(target.value))}
                                                />
                                            </div>
                                            <div className="col-lg-5 d-flex">
                                                <label className="col-form-label mr-4"> Player 4 Handicap </label>
                                                <input id="txtHandicap4" name="txtHandicap4" type="number" className="form-control width-100" max={36}
                                                    value={tRegister?.User4Handicap || ''}
                                                    onChange={({target}) => onChangeHandler('User4Handicap',  parseInt(target.value))}
                                                />
                                            </div>
                                        </div>                                   
                                    </>)}

                                    {props?.tMaster?.Days == 'Multiple' && tData?.FirstRounds && (tData?.FirstRounds?.length > 1) && (<>
                                        <div className="form-group row">                                       
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Prefered Date <span className="red">*</span> </label>
                                            <div className="col-xl-3 col-lg-3">
                                                <div className="input-group ddlMobilePnl">
                                                    <select id="ddlPDate" title="Select Prefered Date" name="ddlPDate" className="form-control"
                                                        value    = {selectedRound?.ID || ''}   disabled={(!!props?.selectedRegister)}
                                                        onChange = {({target}) => onChangeHandler('PreferedDate', target.value)}
                                                    >
                                                        <option value={0}> Select date </option>
                                                        {tData?.FirstRounds?.map((r,i)=>(
                                                            <option key={i} value={r.ID}> {moment(r.Date).format('DD-MMM-YYYY')} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>                              
                                    </>)}        
                                    
                                    {!!selectedRound && (selectedRound.TeeOffOption != "Shot Gun Start") && (
                                        <div className="form-group row">
                                            <label className="col-form-label offset-lg-1 col-lg-3"> Prefered Slot <span className="red">*</span> </label>
                                            <div className="col-xl-3 col-lg-3">                                                                                        
                                                <select id="ddlPSlot" title="Select Prefered Slot" name="ddlPSlot" className="form-control"
                                                    value    = {tRegister.PreferedSlot || ''}   disabled={(!!props?.selectedRegister)}
                                                    onChange = {({target}) => onChangeHandler('PreferedSlot', target.value)}
                                                >
                                                    <option value={0}> Select slot </option>
                                                    {session?.map((s,i)=>( <option key={i} value={s}> {s} </option> ))}
                                                </select>                                               
                                            </div>
                                        </div>                                        
                                    )}                                                        
                                
                                    <div className="form-group row">
                                        <label className="col-form-label offset-lg-1 col-lg-3"> Special Request </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <textarea id="txtComment" name="txtComment" className="form-control width-340" rows="3"
                                                value={tRegister?.Comment || ''}
                                                onChange={({target}) => onChangeHandler('Comment', target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="btnSubmit" className="btn btn-primary margin-0 margin-r5" onClick={postTournamentRegister} > Save </button>                                                       
                        <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Close </button>                                                       
                    </div>            
                </div>
            </div>
        </div>
 </>);
}


const TournamentRegistraion = (props) => { 
    const [isInit, setIsInit]       = useState(true);
    const history                   = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [tMaster,   setTMaster]   = useState();
    const [pageData,  setPageData]  = useState({});
    const [filterReg, setFilterReg] = useState({ddDate:'All',ddSlot:'All'});
    
    const [tournamentRound,  setTournamentRound]  = useState([]);
    const [selectedRegister, setSelectedRegister] = useState(null);
    const [tournamentID,   setTournamentID]   = useState();

    //#region Effects ---
    useEffect(() => {
        props.title("Registration", "tournament-register");   
        const path = history.location.pathname
        const splitedPath = path.split('/')
        const tID = splitedPath?.find(p => !isNaN(p) && p !== '');
        setTournamentID(tID)        
    },[]);
    
    useEffect(() => {     
        if(!!tMaster) fetchTournamentData(tMaster?.ID);                                 
    },[tMaster]);

    useEffect(() => {     
        if(!!filterReg){ 
            if(!isInit){
                $('#tblTournamentRegister').KTDatatable().destroy();
                $('#tblTournamentWaiting').KTDatatable().destroy();
                fillRegistraionTable();
            }    
        }                              
    },[filterReg]);

    useEffect(() => {
        if (!isInit) {
            $('#tblTournamentRegister').KTDatatable().destroy();
            $('#tblTournamentWaiting').KTDatatable().destroy();
            fillRegistraionTable(pageData?.TournamentRegister, pageData?.TournamentWaiting);
        }
    }, [isInit]);

    //#endregion

    //#region Api Call's
    const fetchTournamentData = async(tournamentId)=> {
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});
            const res = await getRegistrationData(tournamentId);
            if(!!res) {                             
                setTournamentRound(res.TournamentRound);
                setPageData(res);                            

                if(isInit){
                    setIsInit(false);
                    fillRegistraionTable(res?.TournamentRegister, res?.TournamentWaiting);
                }else{
                    $('#tblTournamentRegister').KTDatatable().destroy();
                    $('#tblTournamentWaiting').KTDatatable().destroy();
                    fillRegistraionTable(res?.TournamentRegister, res?.TournamentWaiting);
                }       
                // if (isInit)
                //     setIsInit(false)     
            }              
            swal.close();
        } catch (err) {          
            swal.fire({ icon :'error', titleText : err.message, onOpen:() => swal.hideLoading() })
        }            
    }

    const deleteTournamentRegistration = async(registerId, type)=>{
        try {
            swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});        
            await deleteTournamentRegister(registerId, type);

            swal.fire({
                icon: 'success', toast: true, position: 'bottom-end', timer: 1500,
                showConfirmButton: false, animation: false, customClass: { popup: 'margin-20' },
                titleText: 'Tournament Registration Deleted successfully!',
                onOpen: () => swal.hideLoading()
            });

            refetchTournamentData();
        } catch (err) {            
            swal.fire({ icon :'error', titleText : err.message, onOpen: () => swal.hideLoading() })
        }
    }

    //#endregion

    //#region Handler    
    const GetFilteredList = (regList, waitList)=> {
        let filteredRegisterList = regList || pageData?.TournamentRegister || [];
        let filteredWaitingList  = waitList || pageData?.TournamentWaiting || [];  
        
        const {ddDate, ddSlot} = filterReg;

        if (ddDate != "All" && ddSlot != "All") {
            filteredRegisterList = pageData.TournamentRegister.filter(r => r.PreferedSlot == ddSlot && moment(moment(r.PreferedDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(moment(ddDate).format('YYYY-MM-DD')));
            filteredWaitingList  = pageData.TournamentWaiting.filter(r => r.PreferedSlot == ddSlot && moment(moment(r.PreferedDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(moment(ddDate).format('YYYY-MM-DD')));
        } else if (ddDate == "All" && ddSlot != "All") {
            filteredRegisterList = pageData.TournamentRegister.filter(r => r.PreferedSlot == ddSlot);
            filteredWaitingList  = pageData.TournamentWaiting.filter(r => r.PreferedSlot == ddSlot);        

        } else if (ddDate != "All" && ddSlot == "All") {        
            filteredRegisterList = pageData.TournamentRegister.filter(r => moment(moment(r.PreferedDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(moment(ddDate).format('YYYY-MM-DD')));
            filteredWaitingList  = pageData.TournamentWaiting.filter(r => moment(moment(r.PreferedDate).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(moment(ddDate).format('YYYY-MM-DD')));
        }        
        return { filteredRegisterList, filteredWaitingList }
    }
    
    const fillRegistraionTable =(regList, waitList)=> {
    
        let dataTableFields = [    
            {
                field: 'User1ID', maxWidth: 250,
                title: (tMaster?.Type == 'Singles') ? 'Player' : 'Player 1',
                width: (tMaster?.Type == 'Quads') ? 200 : (tMaster?.Type == 'Doubles') ? 230 : 250,
                template:(row)=> {
                    const userHandicap = (row?.User1Handicap < 0) ? `+ ${Math.abs(row.User1Handicap)}` : row.User1Handicap;

                    return( `<div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User1Photo) && (row.User1Photo != '') && (row.User1Photo != 'user.png')) ? `<img src="${row.User1Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User1Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User1Name) && (row.User1Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User1Name} (${userHandicap}) </span>` : ''}
                                </div>
                                </div>
                        </div>     
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>
                    </div>`);
                },            
            },
            {
                field: 'Created', title: 'Reg. Date', width: 100,
                template: (row)=> moment(row.Created).format('DD-MMM-YYYY'),
            },
            {
                field: 'PreferedDate', title: 'Prefered Date', width: 100,
                template: (row)=> moment(row.PreferedDate).format('DD-MMM-YYYY')
            },
            {
                field: 'PreferedSlot', title: 'Prefered Slot', width: 80,
            },
            {
                field: 'TeeBoxID', title: 'Tee Box', width: 60,
                template: (row)=> row.TeeBoxName,
            },
            {
                field: '', title: 'Action', width: 90,
                template: (row)=> {
                    return (`<span class="text-center">
                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r5 btn-edit" ><i class="flaticon2-edit"></i></button>
                        ${(!!row.Comment && row.Comment != '') ? '<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r5" data-toggle="kt-popover" data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content=" ' + row.Comment + '"><i class="fa fa-info"></i></button>' : ''}
                        ${(row.IsScoringStarted ? '' : '<button class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-delete-register" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="bottom" data-content="Cancel Registration"  type="button"><i class="flaticon2-rubbish-bin-delete-button"></i></button>')}
                    </span>`);
                }
            },
        ];

        let player2Field = {
            field: 'User2ID', title:'Player 2', 
            width: (tMaster?.Type == 'Quads') ? 200 : 230,
            // width: 230,
            template:(row)=>{
                let userHandicap = (row?.User2Handicap < 0) ? `+ ${Math.abs(row.User2Handicap)}` : row.User2Handicap;

                return (row.User2ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User2Photo) && (row.User2Photo != '') && (row.User2Photo != 'user.png')) ? `<img src="${row.User2Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User2Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User2Name) && (row.User2Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User2Name} (${userHandicap}) </span>` : ''}
                                </div>
                             </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        let player3Field = {
            field: 'User3ID', title:'Player 3', width: 200,
            template:(row)=>{
                let userHandicap = (row?.User3Handicap < 0) ? `+ ${Math.abs(row.User3Handicap)}` : row.User3Handicap;

                return (row.User3ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User3Photo) && (row.User3Photo != '') && (row.User3Photo != 'user.png')) ? `<img src="${row.User3Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User3Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User3Name) && (row.User3Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User3Name} (${userHandicap}) </span>` : ''}
                                </div>
                             </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        let player4Field = {
            field: 'User4ID', title:'Player 4', width: 200,
            template:(row)=>{
                let userHandicap = (row?.User4Handicap < 0) ? `+ ${Math.abs(row.User4Handicap)}` : row.User4Handicap;

                return (row.User4ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User4Photo) && (row.User4Photo != '') && (row.User4Photo != 'user.png')) ? `<img src="${row.User4Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User4Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User4Name) && (row.User4Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User4Name} (${userHandicap}) </span>` : ''}
                                </div>
                             </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        // if (tMaster?.Type != str_Single) dataTableFields[1] = player2Field;  
        // if (tMaster?.Type == str_Quadraple) dataTableFields[2] = player3Field;
        // if (tMaster?.Type == str_Quadraple) dataTableFields[3] = player4Field;
        
        if (tMaster?.Type != str_Single) dataTableFields.splice(1, 0, player2Field);
        if (tMaster?.Type == str_Quadraple) {
            dataTableFields.splice(2, 0, player3Field);
            dataTableFields.splice(3, 0, player4Field);
        }
        // const registerList = GetFilteredList(ddDate, ddSlot, regList, waitList);
        const registerList = GetFilteredList(regList, waitList);

        const dataTable = $('#tblTournamentRegister').KTDatatable({
            data    : { saveState: false, source: registerList.filteredRegisterList },
            layout  : { scroll: true, footer: false }, sortable: false, pagination: false,
            search  : { input: $('#tournamentRegisterSearch') },
            columns : dataTableFields,
            rows    : { autoHide: false }
        });

        let waitingdataTableFields = [           
            {
                field: 'User1ID', maxWidth: 250,
                title: tMaster?.Type == 'Singles' ? 'Player' : 'Player 1',
                width: (tMaster?.Type == 'Quads') ? 200 : (tMaster?.Type == 'Doubles') ? 230 : 250,
                template:(row)=> {
                    let userHandicap = (row?.User1Handicap < 0) ? `+ ${Math.abs(row.User1Handicap)}` : row.User1Handicap;

                    return `<div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User1Photo) && (row.User1Photo != '') && (row.User1Photo != 'user.png')) ? `<img src="${row.User1Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User1Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User1Name) && (row.User1Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User1Name} (${userHandicap}) </span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                    </div>`
                }
            },
            {
                field: 'PreferedDate', title: 'Prefered Date', width: 100,
                template: (row)=> moment(row.PreferedDate).format('DD-MMM-YYYY'),                
            },
            {
                field: 'PreferedSlot', title: 'Prefered Slot', width: 80,
            },
            {
                field: 'Created', title: 'Reg. Date', width: 90,
                template: (row)=> moment(row.Created).format('DD-MMM-YYYY'),   
            },              
            {
                field: '', title: 'Action', width: 70,
                template:(row)=> {
                    return (`<span>                           
                        ${(!!row.Comment && row.Comment != '') ? '<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r7" data-toggle="kt-popover" data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content=" ' + row.Comment + '"><i class="fa fa-info"></i></button>' : ''}
                        ${(row.IsScoringStarted ? '' : '<button class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r5 btn-delete-waiting" data-trigger="hover" data-offset="60px 0px" data-toggle="kt-popover" data-placement="bottom" data-content="Cancel Registration"  type="button"><i class="flaticon2-rubbish-bin-delete-button"></i></button>')}
                    </span>`);
                }            
            },
        ];

        let waitingplayer2Field = {
            field: 'User2ID', title: 'Player 2',
            width: (tMaster?.Type == 'Quads') ? 200 : 230,
            // width: 230,
            template:(row)=> {            
                const userHandicap = (row?.User2Handicap < 0) ? `+ ${ Math.abs(row.User2Handicap)}` : row.User2Handicap;

                return (row.User2ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User2Photo) && (row.User2Photo != '') && (row.User2Photo != 'user.png')) ? `<img src="${row.User2Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User2Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User2Name) && (row.User2Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User2Name} (${userHandicap}) </span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        let waitingplayer3Field = {
            field: 'User3ID', title: 'Player 3', width: 200,
            template:(row)=> {            
                const userHandicap = (row?.User3Handicap < 0) ? `+ ${ Math.abs(row.User3Handicap)}` : row.User3Handicap;

                return (row.User3ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User3Photo) && (row.User3Photo != '') && (row.User3Photo != 'user.png')) ? `<img src="${row.User3Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User3Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User3Name) && (row.User3Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User3Name} (${userHandicap}) </span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        let waitingplayer4Field = {
            field: 'User4ID', title: 'Player 4', width: 200,
            template:(row)=> {            
                const userHandicap = (row?.User4Handicap < 0) ? `+ ${ Math.abs(row.User4Handicap)}` : row.User4Handicap;

                return (row.User4ID ? `
                    <div class="row">
                        <div class="col-10">
                            <div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${((row.User4Photo) && (row.User4Photo != '') && (row.User4Photo != 'user.png')) ? `<img src="${row.User4Photo}" alt="user-Photo" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info" > ${row.User4Name.substring(0, 1)}</div >`}
                                </div>
                                <div class="kt-user-card-v2__desc text-clip">
                                    ${((row.User4Name) && (row.User4Name != '')) ?
                                    `<span class="kt-user-card-v2__name text-clip"> ${row.User4Name} (${userHandicap}) </span>` : ''}
                                </div>
                            </div>
                        </div>
                        <div  class="col-2">
                            ${row.FromAdmin == 'Y' ? `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M5,5 L5,16 L19,16 L19,5 L5,5 Z M5,3 L19,3 C20.4201608,3 21,3.7163444 21,4.6 L21,17.4 C21,18.2836556 20.4201608,19 19,19 L5,19 C3.57983921,19 3,18.2836556 3,17.4 L3,4.6 C3,3.7163444 3.57983921,3 5,3 Z M12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 C12.5,17.2238576 12.2761424,17 12,17 C11.7238576,17 11.5,17.2238576 11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
                                    <polygon fill="#000000" opacity="0.3" points="5 5 5 16 19 16 19 5"/>
                                    <rect fill="#000000" opacity="0.3" x="10" y="20" width="4" height="1" rx="0.5"/>
                                </g>
                            </svg>
                            ` : `
                            <svg viewBox="0 0 24 24" version="1.1" class="kt-svg-icon" style="height:30px; width:30px; float:right;">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"/>
                                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"/>
                                </g>
                            </svg>`}
                        </div>                                                      
                </div>` : ``)
            }
        };

        // if (tMaster?.Type != str_Single) waitingdataTableFields[2] = waitingplayer2Field;
        // if (tMaster?.Type == str_Quadraple) waitingdataTableFields[3] = waitingplayer3Field;
        // if (tMaster?.Type == str_Quadraple) waitingdataTableFields[4] = waitingplayer4Field;

        if (tMaster?.Type != str_Single) waitingdataTableFields.splice(1, 0, waitingplayer2Field);
        if (tMaster?.Type == str_Quadraple) {
            waitingdataTableFields.splice(2, 0, waitingplayer3Field);
            waitingdataTableFields.splice(3, 0, waitingplayer4Field);
        }

        const dataWaitingTable = $('#tblTournamentWaiting').KTDatatable({
            data    : { saveState: false, source: registerList.filteredWaitingList },
            layout  : { scroll: true, footer: false }, sortable: false, pagination: false,
            search  : { input: $('#tournamentRegisterSearch') },
            columns : waitingdataTableFields,
            rows: { autoHide: false }
        });
        
        Config.Core.InitPopover();
        $('.custom_table_header').remove();
        setTimeout(() => { 
            if ($('#tblTournamentWaiting .kt-datatable__head.custom_table_header').length === 0) {
                $('#tblTournamentWaiting > .kt-datatable__table').prepend('<thead class="kt-datatable__head custom_table_header"><tr class="kt-datatable__row"><th class="kt-datatable__cell kt-datatable__cell--sort center">Waiting List</th></tr></thead>') 
            }
        }, 100)            

        dataTable?.on('click', '.btn-edit', function (ss) {          
            const index = $(ss.currentTarget).parents('tr').index();               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setSelectedRegister(dataTable.dataSet[index]);              
                setShowModal(true);
            } 
        });

        //reg delete onclick
        dataTable?.on('click', '.btn-delete-register', function (ss) {          
            const index = $(ss.currentTarget).parents('tr').index();               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {               
               const registerID = dataTable.dataSet[index]?.ID;
               deleteTournamentRegistration(registerID, 'confirm');
            } 
        });

        //reg delete onclick
        dataWaitingTable?.on('click', '.btn-delete-waiting', function (w) {          
            const index = $(w.currentTarget).parents('tr').index();               
            if (dataWaitingTable.dataSet && dataWaitingTable.dataSet.length > 0 && dataWaitingTable.dataSet[index]) {                
                const waitingID = dataWaitingTable.dataSet[index]?.ID;
                deleteTournamentRegistration(waitingID, 'waiting');
            } 
        });
    }
  
    const onSelectHandler =(tMaster)=> {
        if(!tMaster) return;       
        setTMaster(tMaster);                                     
    }
    
    //Reset on drawer close
    const onReset =()=> {
        setTMaster();        
        setPageData({});
        setTournamentRound([]);

        $('#tblTournamentRegister').KTDatatable().destroy();          //Reset Ktdatatable
        $('#tblTournamentWaiting').KTDatatable().destroy();
        setIsInit(true);
    }

    const closeRegisterModal =()=> { 
        setShowModal(false);
        if(!!selectedRegister) setSelectedRegister();
    }

    const onFilterRegistration  = (field, value) => setFilterReg(v => ({...v, [field]: value}));
    const refetchTournamentData = ()=> fetchTournamentData(tMaster?.ID);
    const showRegisterModal     = ()=> setShowModal(true);

    //#endregion
    
    return(<>
        <TournamentList tournamentID={tournamentID} setTournamentID={setTournamentID} title={"Registration"}  comp={"register"} onSelectHandler={onSelectHandler}  resetData={onReset}>
            <div className="kt-portlet">
                <div className="kt-portlet__body padding-t10 padding-b0">
                    
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0 border-b0">                               
                        <div className="flex-container flex-grow-02 justify-space-evenly align-item-center">
                            <div className="flex-item flex-grow-03">
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input type="text" className="form-control clientSearch" placeholder="Search Player..." id="tournamentRegisterSearch" />
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                </div>
                            </div>
                            
                            <div className="flex-item flex-grow-03">
                                <select id="ddlRoundDate" title="Select Date" name="ddlRoundDate" className="form-control kt-selectpicker show-tick" 
                                    onChange={({target}) => onFilterRegistration('ddDate', target.value)}
                                >
                                    <option value="All"> All </option>
                                    {!!tournamentRound && (tournamentRound?.length > 0) && 
                                        // (tournamentRound?.filter(r => r.Round == 1)).map((r,i) => (<option key={i} value={`${moment(r.Date).format('DD-MM-YYYY')}`}> {moment(r.Date).format('DD-MMM-YYYY')} </option> ))
                                        (tournamentRound?.filter(r => r.Round == 1)).map((r,i) => (<option key={i} value={r.Date}> {moment(r.Date).format('DD-MMM-YYYY')} </option> ))
                                    }                                        
                                </select>                              
                            </div>

                            <div className="flex-item flex-grow-03">
                                <select id="ddlRoundSlot" title="Select Slot" name="ddlRoundSlot" className="form-control kt-selectpicker show-tick" 
                                    onChange={({target}) => onFilterRegistration( 'ddSlot', target.value)}
                                >
                                    <option value="All"      >  All       </option>
                                    <option value="Morning"  >  Morning   </option>
                                    <option value="Afternoon">  Afternoon </option>
                                </select>                                           
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    {/* <button id="btnRefresh" type="button" data-toggle="modal" data-target="#updateHandicapModal" className="btn btn-success btn-icon-sm text-white d-inline"><i className="la la-refresh"></i>Refresh Handicap</button> */}
                                    
                                    <button id="btnAddNew" type="button" className="btn btn-brand btn-icon-sm text-white d-inline margin-l5"
                                        onClick={showRegisterModal}
                                    ><i className="la la-plus"></i> Add New </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!!tMaster && (
                        <div id="tournamentInfoPanel" className="kt-notification margin-t10 mb-3 margin-lr20 bg-lightGrey">
                            <div className="kt-notification__item d-block">
                                <div className="row">
                                    <div className="col-5">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__pic">
                                                <img id="txtTournamentImg" src={tMaster.Icon} alt="tournament-logo" />
                                            </div>
                                            <div className="kt-user-card-v2__details ">
                                                <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tMaster?.Name} </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtType"> {tMaster?.Type} </span> | <span id="txtMembership">  {tMaster?.MembershipType} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {!!tournamentRound && tournamentRound?.length > 0 && (
                                        <div className="col-3 d-flex-center startType-holder">
                                            <div className="kt-user-card-v2 mb-2">
                                                <div className="kt-user-card-v2__details ">
                                                    <span className="kt-user-card-v2__name "> Tee Off Option </span>
                                                    <span className="kt-user-card-v2__desc">
                                                        <span id="txtStartType">{tournamentRound[0]?.TeeOffOption} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name "> Start </span>
                                                <span className="kt-user-card-v2__desc">
                                                    <span id="txtStartDate"> {moment(tMaster?.StartDate).format('DD-MMM-YYYY')} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name"> End </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtEndDate"> {moment(tMaster?.EndDate).format('DD-MMM-YYYY')} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div id="tblTournamentRegister" className="table-bordered table-hover table-header-grey custom-datatable margin-lr20 margin-b30"></div>
                    <div id="tblTournamentWaiting" className="table-bordered table-hover table-header-grey custom-datatable margin-lr20 margin-b30"></div>
                </div>
            </div>
        </TournamentList>   

        {!!showModal && (
            <RegisterModal
                show             ={showModal}
                onDismissModal   ={closeRegisterModal}
                tMaster          ={tMaster}
                tData            ={pageData}
                refetchData      ={()=> refetchTournamentData()}
                selectedRegister ={selectedRegister}
            />
        )} 
    </>)
}

export default TournamentRegistraion;


{/* {props?.tMaster?.Type != str_Single && (
    <div className="form-group row player2-handicap margin-b10">
        <label className="col-form-label offset-lg-1 col-lg-3"> Player 2 Handicap </label>
        <div className="col-xl-6 col-lg-8">
            <input id="txtHandicap2" name="txtHandicap2" type="text" className="form-control width-340" />
        </div>
    </div>
)} */}

{/* <div className="form-group row player4-handicap margin-b10">
    <label className="col-form-label offset-lg-1 col-lg-3"> Player 4 Handicap </label>
    <div className="col-xl-6 col-lg-8">
        <input id="txtHandicap4" name="txtHandicap4" type="text" className="form-control width-340" />
    </div>
</div> */}