import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getTeeSlotByTournamentTeeID, getTournamentTeeAll, postTournamentTee } from "../api/tournamentAPI";

const TournamentTee = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [teeDetails,          setTeeDetails]          = useState({});
    const [slot,                setSlot]                = useState();
    const [teeList,             setTeeList]             = useState([]);
    const [slotList,            setSlotList]            = useState([]);
    const [deletedSlot,         setDeletedSlot]         = useState([]);
    const [isEdit,              setIsEdit]              = useState(false);
    const [slotIndex,           setSlotIndex]           = useState();

    useEffect(() => {
        props.title("Tee", "tournament-tee")   
        fetchTeeList();
    }, []);

    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
            $('#txtSlot').timepicker({
                minuteStep: 10,
                defaultTime: ''
            });
    
            $('#txtSlot').on('change', ({ target }) => setSlot(target.value));
        } else {
            setTeeDetails({});
            setSlot();
            setSlotList([]);
            setDeletedSlot([])
        }
    }, [rightSideDetails]);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [teeList]);

    const fillDataTable = () =>{
        const dataTable = $('#tblTee').KTDatatable({
            data: {
                saveState: false,
                source: teeList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    width: 100
                },
            ]
        });

        // $('#root .kt-grid--hor').addClass('max-width100');
        // $('#tblTee .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setTeeDetails(dataTable.dataSet[index]);
                fetchTeeSlot(dataTable.dataSet[index].ID) 
                setRightSideDetails(true);           
            }                
        });
    }

    const updateTeeDetails = (field, value) => {
        setTeeDetails(d => ({...d, [field]: value}))
    }
    
    const addSlotHandler = () => {

        if (!slot) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Select slot!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        if (slotList.some(s => s.Slot == slot)) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Slot already present!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        if (isEdit) {
            setSlotList(prevSlotList => {
                const updatedSlotList = [...prevSlotList];
                const slotID = updatedSlotList[slotIndex].ID;
                updatedSlotList.splice(slotIndex, 1, { ID: slotID, Slot: slot });
                return updatedSlotList;
            });
        } else {
            setSlotList(a => ([...a, { ID: 0 , Slot: slot }]))
        }
        if (isEdit) {
            setSlotIndex()
            setIsEdit(false)
        } 
        setSlot()
    }

    const editSlotHandler = (index) => {
        const dSlot = slotList.find((s, i) => i == index)
        if (dSlot) {
            setIsEdit(true)
            setSlotIndex(index)
            setSlot(dSlot.Slot)
        }

    }

    const deleteSlotHandler = (index) => {
        const filteredSlot = slotList.filter((s, i) => i != index);
        const dSlot = slotList.find((s, i) => i == index)
        setSlotList(filteredSlot)
        if (dSlot.ID != 0)
            setDeletedSlot(s => [...s, dSlot])
    }

    const saveHandler = async () => {

        const btn  = $('#btnSave');
        const form = $('#addSlotForm');

        form.validate({
            rules: {
                txtName: {
                    required: true
                }                     
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });

        if (!form.valid()) return;

        if (slotList.length == 0) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Add atleast one slot!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        try {
            helper.StartProcessing(btn);           
            await postTournamentTee(teeDetails.ID ? teeDetails : {...teeDetails, ID: 0}, slotList, deletedSlot)
            if (teeDetails.ID) {            
                swal.fire({
                    icon: 'success',
                    titleText: 'Slot updated successfully!',
                    showConfirmButton: false,
                    timer: 2000                                 
                });
                fetchTeeSlot(teeDetails.ID)
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Slot added successfully!',
                    showConfirmButton: false,
                    timer: 2000 
                });
            }
            helper.StopProcessing(btn);
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const fetchTeeList = async () => {
        try {
            const response = await getTournamentTeeAll()
            isInit ? setIsInit(false) : $('#tblTee').KTDatatable().destroy(); 
            setTeeList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTeeSlot = async (teeID) => {
        try {
            const response = await getTeeSlotByTournamentTeeID(teeID);
            setSlotList(response)   
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }


    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Tee..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Tee</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblTee" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="Tournament Tee" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="addSlotForm">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                    
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-sm-4 col-lg-4">
                                                    <input 
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        onChange={(e) => updateTeeDetails('Name', e.target.value)}
                                                        value={teeDetails.Name || ''}
                                                        className="form-control"/>
                                                </div>
                                            </div>     
                                        </div>
                                        <div id="ControlHolder1" className="kt-form kt-form--label-right padding-10" role="form"> 
                                            <div className="form-group row">
                                                <label className="col-form-label col-sm-3 text-right pnlAdd">Add Time Slot</label>
                                                <div className="col-sm-4 pnlAdd">
                                                    <div className="input-group timepicker width-200  margin-r20">
                                                        <input
                                                            id="txtSlot"
                                                            name="txtSlot"
                                                            type="text"
                                                            className="form-control"
                                                            readOnly={true}
                                                            placeholder="Select time"
                                                            value={slot || ''}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text height-38">
                                                                <i className="la la-clock-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="btn-group">
                                                        {isEdit ? (
                                                            <button id="btnAdd" type="button" className="btn btn-success d-inline-block margin-0 " onClick={addSlotHandler}>Update</button>                                  
                                                        ) : (
                                                            <button id="btnAdd" type="button" className="btn btn-label-brand d-inline-block margin-0 " onClick={addSlotHandler}><i className="la la-plus"></i>Add</button>                                  
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table id="table-template" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th>Slot</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(slotList && slotList.length > 0) && (
                                                    slotList.map((s, i) => (
                                                        <tr key={i}>
                                                            <td className="template-slot">{s.Slot}</td>
                                                            <td style={{ width: '10%'}}>
                                                                <center>
                                                                    <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-template-edit mr-1" onClick={() => editSlotHandler(i)}><i className="flaticon2-edit"></i></button>
                                                                    <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-template-edit" onClick={() => deleteSlotHandler(i)}><i className="flaticon2-rubbish-bin-delete-button"></i></button>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>       
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={saveHandler}>Save</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
                        
        </>
    )
}

export default TournamentTee