import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { getViewTeeTimeBookUserByDate, sendNoShowEmail } from "../api/teeTimeAPI";

const TeeTimeNoShowReport = (props) => {

    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [TeeBoxOption,                    setTeeBoxOption]             = useState([{ value:  'All', label: 'All'},{ value:  '1', label: '1'}, { value:  '4', label: '4'}]);
    const [TypeOption,                      setTypeOption]               = useState([{ value:  'All', label: 'All'},{ value:  'N', label: 'No Show'}, { value:  'Y', label: 'Confirmed'}]);
    const [type,                            setType]                     = useState({'TeeBox': TeeBoxOption[0].label, 'Type': TypeOption[0].value});
    const [teeTimeNoShowTable,              setTeeTimeNoShowTable]       = useState(null);
    const [isInit,                          setIsInit]                   = useState(true);
    const [teeTimeNoShowReport,             setTeeTimeNoShowReport]      = useState([]);

    useEffect(() => {
        props.title('Tee Time Report ','no-show-report')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
    },[])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable(type);
        }
    }, [teeTimeNoShowReport]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate){
            fetchTeeTimeNoShowReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),)
        }
    },[selectedFromDate, selectedToDate])

    useEffect(() => {
        if(type && !isInit){
            $('#tblTeeTimeNoShowReport').KTDatatable().destroy(); 
            fillDataTable(type);
        }
    },[type])

    const fetchTeeTimeNoShowReport = async(fdate, tDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getViewTeeTimeBookUserByDate(fdate, tDate)
            isInit ? setIsInit(false) : $('#tblTeeTimeNoShowReport').KTDatatable().destroy(); 
            setTeeTimeNoShowReport(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    
    const sendTeeTimeNoShowEmail = async(users) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure',
            text                : users.length > 1 ? `You want to send no show email to all` :  `You want to send no show email to ${users[0]?.UserName}`,
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Confirm',
            preConfirm: async () => {
              try {
                let userList = []
                for (const m of users) {
                    if(m.IsPresent !== 'Y'){
                        userList.push({
                            UserName:        m.UserName,
                            Email:           m.Email || '',
                            BookDate:        m.BookDate,                 
                            Slot:            m.Slot

                        })
                    }
                }
                await sendNoShowEmail(userList)
              } catch (err) {
                swal.fire({
                  icon                : 'error',
                  titleText           : 'Error!',
                  text                : err.message,
                  buttonsStyling      : false,
                  confirmButtonClass  : 'btn btn-brand',
                });
                return;
              }
            },
        });
        if (!swalResult.value) return;

        swal.fire({
            icon              : 'success',
            titleText         : 'Email Send Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    function exportSheet(type) {
        teeTimeNoShowTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - No Show Report` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    }


    function fillDataTable(t) {

        var noShowReportList = [];
        if (t.TeeBox == 'All' && t.Type == 'All') {
            noShowReportList = teeTimeNoShowReport;
        } else if(t.TeeBox !== 'All' && t.Type == 'All'){
            noShowReportList = teeTimeNoShowReport.filter(i => i.TeeBox == t.TeeBox);
        }else if (t.TeeBox == 'All' && t.Type !== 'All'){
            noShowReportList = teeTimeNoShowReport.filter(i => i.IsPresent == t.Type);
        }else{
            noShowReportList = teeTimeNoShowReport.filter(i => i.IsPresent == t.Type && i.TeeBox == t.TeeBox);
        }

        const dataTable = $('#tblTeeTimeNoShowReport').KTDatatable({
            data: {
                saveState: false,
                source: noShowReportList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'TeeTimeBookID',
                    title: 'Book ID',
                    width: 35
                },
                {
                    field: 'TeeBox',
                    title: 'Tee Box',
                    width: 25   ,
                },
                {
                    field: 'BookDate',
                    title: 'Game Date',
                    width: 85,
                    template: function (row) {
                        return moment(row.BookDate).format("DD-MMM-YYYY");
                    }
                },
                {
                    field: 'Slot',
                    title: 'Slot/Hole',
                    width: 60

                },
                {
                    field: 'UserNameAdmin',
                    title: 'User Name',
                    width: 140,
                    template: function (row) {
                        if (row?.UserNameAdmin) {
                            return `<span class='kt-badge kt-badge--${row.UserType == "User" ? 'success' : (row.UserType == "Guest" || row.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.UserNameAdmin}`
                        }else {
                            return '';
                        }
                    }

                },
                {
                    field: 'MemberType',
                    title: 'Member Type',
                    width: 60,
                },
                {
                    field: 'MembershipNo',
                    title: 'Membership No',
                    width: 90,
                },
                {
                    field: 'IsOwner',
                    title: 'Booking Type',
                    width: 80,
                    template: function (row) {
                        if (row.IsPresent == 'Y') {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-success" style="padding-left: 9px">' + 'Confirmed' + '</span></div>';
                        } else {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-warning">' + 'No Show' + '</span></div>';
                        }
                    }
                },
                {
                    field: '',
                    title:   `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-emailAll" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="Send no show email to all"><i class="flaticon2-email"></i></button>`,
                    width: 25,
                    template: function (row) {

                        if (row.IsPresent == 'N') {
                            return `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-email" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="Send no show email"><i class="flaticon2-email"></i></button>`
                        } else {
                            return '';
                        }
                    }
                },
                
            ]
            ,
            rows: {
                autoHide: false,
            }
        });

        Config.Core.InitPopover();

        dataTable.on('click', '.btn-email', function (ss) {  // Email for individual
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                sendTeeTimeNoShowEmail([dataTable.dataSet[index]])
            } 
        });
        dataTable.on('click', '.btn-emailAll', function () { //Email for all
            sendTeeTimeNoShowEmail(dataTable.dataSet)
        });

        // export to excel
        const teeTimeNoShowTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - No Show Report`,
        });
        setTeeTimeNoShowTable(teeTimeNoShowTable);
        $('.tableexport-caption').addClass('hidden');
        
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    {/* <label className="col-form-label col-sm-1 text-right padding-lr0">From Date</label> */}
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <label className="col-form-label col-sm-1 text-right padding-lr0">To Date</label> */}
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div  className="col-sm-1">
                                    </div> */}
                                    <label className="col-form-label col-sm-1 text-right pnlSlot ">Tee Box </label>
                                    <div className="form-group col-sm-1 pnlSlot ">
                                        <div>
                                            <Select 
                                                id="ddlTeeBox" 
                                                name="ddlTeeBox "
                                                className="multi-custom "
                                                defaultValue={ TeeBoxOption.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => updateTypeDetails('TeeBox', event.value)}
                                                options={TeeBoxOption}
                                            />
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right pnlSlot ">Type </label>
                                    <div className="form-group col-sm-1 pnlSlot ">
                                        <div>
                                            <Select 
                                                id="ddlType" 
                                                name="ddlType "
                                                className="multi-custom "
                                                defaultValue={ TypeOption.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => updateTypeDetails('Type', event.value)}
                                                options={TypeOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Tee Time..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblTeeTimeNoShowReport" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeeTimeNoShowReport;