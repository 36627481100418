import React,{ useState, useEffect } from 'react'
import * as helper from "../global/helper";
import '../../assets/scripts/jquery-validation.init';
import swal from 'sweetalert2';
import { deleteOrderByID, verifyCode } from '../api/paymentAPI';

const VerificationCodeModal = (props) => {
 
    const  { onDismissModal, orderID, refechData, userName, timeStamp } = props;

    const [verificationCode,      setVerificationCode]      = useState();
    const [timer,                 setTimer]                 = useState(20)
    const [otpTimer,              setOtpTimer]              = useState(120)
    const [counter,               setCounter ]              = useState({m:2, s: 0})
    const [intervalId,            setIntervalId]            = useState({});
    const [serverTimeStamp,       setServerTimeStamp]       = useState();

    useEffect(() => {
        $('#verification-code-modal').modal({ backdrop: 'static' });
        $('#sendEmail-modal').addClass('modal-blur'); 

        $('#verification-code-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');
          $('#sendEmail-modal').removeClass('modal-blur');  
          onDismissModal(); 
        });

        $('#verification-code-modal').modal('toggle');
    }, []);
 
    useEffect(() => {
       if(timeStamp){
            setServerTimeStamp(timeStamp);
       }
    },[timeStamp])
 
    useEffect(() => {
        if(serverTimeStamp){
            const OTPExpirationSeconds = moment().diff(serverTimeStamp, 'seconds')
            showTimer(120 - OTPExpirationSeconds);
        }
    }, [serverTimeStamp])


    useEffect(()=>{
        let ignore = false;

        const updateCounter = () =>{   
            let d = otpTimer % (60 * 60 );
            let ds = Math.ceil(d % 60);
            let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
            setCounter(t)
        }

        if(!ignore) updateCounter();
        return () =>{
            ignore = true;
        }
    }, [otpTimer])

    const showTimer = (secondsToExpire) => {
        if(secondsToExpire < 0) {
            setOtpTimer(0);
            setTimer(0)
            return
        } 
        //verificationCode RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t =>{
                if(t > 0){
                    return (t-1)
                }else{
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);
        
        //verificationCode EXP TIMER
        let otpInterval;
        if(secondsToExpire <= 120 && secondsToExpire > 0){
            setOtpTimer(secondsToExpire);
            otpInterval = setInterval(() => {
                setOtpTimer(t =>{
                    if(t > 0){
                        return (t-1)
                    }else{
                        clearInterval(otpInterval)
                        return 0
                    }
                })
            }, 1000);
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval, otpInterval });        
    }

    const resetTime = () => {
        clearInterval(intervalId.resendInterval);
        clearInterval(intervalId.otpInterval);
        helper.StopProcessing($("#save-btn"));
    }

    const resendHandler = async () => {
        try {
            const response = await verifyCode(orderID, "Y", verificationCode);
            if(response){
                //Clear Inputs
                setVerificationCode('');
                resetTime();
                setServerTimeStamp(response.serverTimestamp);
            }
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }

    const deleteOrder = async() => {
        try {
            clearInterval(intervalId.resendInterval);
            clearInterval(intervalId.otpInterval);
            helper.StopProcessing($("#save-btn"));
            await deleteOrderByID(orderID);
            
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }
    
    const sendEmail = async (e) => {
        e.preventDefault();

        const form = $("#verification-code-form");
        form.validate({
            rules: {
                verificationCode: { required: true },
            },
        });

        if (!form.valid())  return;
    
        helper.StartProcessing($("#save-btn"));

        try {
           
            await verifyCode(orderID, "N", verificationCode);
            // $(from).modal("hide");
            helper.StopProcessing($("#save-btn"));
            resetTime();
            $("#verification-code-modal").modal("hide");
            refechData()
            swal.fire({
                icon: 'success',
                titleText: 'Payment registered successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
            helper.StopProcessing($("#save-btn"));    
        } 
    }

    return (
        <div className="modal fade" id="verification-code-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">  Validate Code </h5>
                        <button type="button" className="close" onClick={deleteOrder} data-dismiss="modal" aria-label="Close"> </button>
                    </div>
                    <div className="modal-body">
                        <div className="client-container">
                            <div className="row margin-b20">
                                <div className="col text-center">
                                    <div className="kt-login__desc"> 
                                        Verification Code sent to <b>{userName}'s Device</b>.
                                        <br/>
                                        <small>( In case you haven't received the verification code, please try reopening the app.)</small>
                                    </div>
                                    <h5 className="text-center margin-t10"> {(counter.m == 0 && counter.s == 0)?' Code Expired ':`${counter.m}:${counter.s}`} </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <form id="verification-code-form" onSubmit={(e) => {  e.preventDefault()}}>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    id="verificationCode"
                                                    value={verificationCode || ''}
                                                    name="verificationCode"
                                                    placeholder="Enter Code"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    minLength='6'
                                                    maxLength='6'
                                                    onChange={({ target: { value } }) => setVerificationCode(value)}
                                                />          
                                            </div>
                                        </div>
                                    </form>  
                                </div>
                            </div>
                            <div className="row margin-t20">
                                <div className="col text-center">                                                
                                    <span className="kt-login__account-msg">
                                        Didn't get it ?
                                    </span>
                                    &nbsp;&nbsp;
                                    {timer > 0 ? `Please wait for ${timer} seconds to Resend Code `
                                        :<span id="kt_login_otp_resend" className="kt-login__account-link clickable" onClick={resendHandler} > Resend Code </span>
                                    }
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal" 
                            onClick={() => {resetTime(), deleteOrder()}}
                        >   
                            Close
                        </button>
                        <button
                            id="save-btn"
                            type="button"
                            className="btn btn-brand margin-0"
                            onClick={sendEmail}
                        >   
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default VerificationCodeModal;
