import React, { useEffect, useState, useRef } from 'react'
import { deleteScorecard, postScoreCard } from '../../api/tournamentAPI';
import swal from 'sweetalert2';
import { getStrokeExtraShot } from '../tournamentHelper';

const StableFordGross = ({
    playerList,
    tournamentDetails,
    tournamentRound,
    format,
    selectedPlayer,
    setScorecardStatus,
    scorecardStatus,
    gameDetails,
    closeDrawer,
    fetchTournamentLeaderboard,
    pointData,
    setShowPointModal
}) => {

    const [scores,              setScores]              = useState({});
    const [totalScores,         setTotalScores]         = useState([]);
    const [footerTotalScore,    setFooterTotalScore]    = useState('-');
    const [comment,             setComment]             = useState("");
    const inputRefs = useRef([]);

    useEffect(() => {
        const initialScores = {};
        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            const oldScore = gameDetails.Card.find(s => s.ScoreCardMasterID === item.ID);
            initialScores[index] = oldScore ? (oldScore.Scratch === 'Y' ? 'X' : oldScore.Score) : 0;
        });
        setScores(initialScores);
        calculateScores(initialScores);
    }, [gameDetails]);

    useEffect(() => {
        if (selectedPlayer) {
            setScorecardStatus(selectedPlayer.IsVerified == 'Y' ? "verify" : selectedPlayer.IsVerified == "WD" ? 'withdraw' : selectedPlayer.IsVerified == 'DQ' ? 'disqualify' : '')
        }
    }, [selectedPlayer])

    const calculateScores = (updatedScores) => {
        const updatedTotalScores = [];
        let cumulativeTotalScore = 0;
        let allScoresZero = true;

        gameDetails.TeeBoxes.ScoreCard.forEach((item, index) => {
            let score = updatedScores[index] === 'X' ? 'X' : parseInt(updatedScores[index], 10) || 0;
            const par = item.Par;
            const extraShot = getStrokeExtraShot(item.Stroke, selectedPlayer.Handicap);
            let scoreValue = score === 'X' ? ((extraShot == 0 && selectedPlayer.Handicap < 0) ? par + 2 : par + 2 + extraShot) : score;
            let finalScore = score === 'X' ? scoreValue : (scoreValue - par);
            const result = getPointByScore(finalScore);
            if (score !== 0) {
                allScoresZero = false;
                cumulativeTotalScore += result.point;
            }

            updatedTotalScores[index] = {
                score: score == 0 ? '-' : (score == 'X' ? 'X' : (finalScore == 0 ? 'E' : (finalScore < 0 ? finalScore : "+" + finalScore))),
                totalScore: score == 0 ? '-' : ((cumulativeTotalScore == 0 ? 'E' : cumulativeTotalScore)),
                point: score == 0 ? '-' : result.point
            };
        });

        setTotalScores(updatedTotalScores);
        setFooterTotalScore(allScoresZero ? '-' : (cumulativeTotalScore === 0 ? 'E' : (cumulativeTotalScore < 0 ? cumulativeTotalScore : cumulativeTotalScore)));
    };


    const handleScoreChange = (index, value) => {
        if (!(/^[0-9X]*$/).test(value))  return;  // If the value matches the regex, update the state

        const updatedScores = { ...scores, [index]: value === '' ? 0 : value };
        setScores(updatedScores);
        calculateScores(updatedScores);

        // Move focus to the next input field if it exists
        if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
            inputRefs.current[index + 1].select();
        }
    };

    function onScratched(event, index) {
        const inputElement = event.target.closest('.input-group').querySelector('input');
        if (inputElement) {
            inputElement.value = 'X';
            handleScoreChange(index, 'X');
        }
    }

    const toggleStatus = (value) => {
        setScorecardStatus(prevStatus => (prevStatus == value ? '' : value));
    };


    function getPointByScore(score) {

        var min = { name: '', point: -1, score: 0 };
        var max = { name: '', point: -1, score: 0 };
        var result = { name: '', point: -1 };

        pointData.forEach(element => {

            if (score == element.Score) {
                result.name = element.Name;
                result.point = element.Point;
            }

            if (min.score > element.Score) {   
                min.name = element.Name;
                min.score = element.Score;
                min.point = element.Point;
            }
            if (max.score < element.Score) {
                max.name = element.Name;
                max.score = element.Score;
                max.point = element.Point;
            }
        });

        if (result.point == -1) {
            if (score < min.score) {
                result.name = min.name;
                result.point = min.point;
            }
            else {
                result.name = max.name;
                result.point = max.point;
            }
        }

        return result;
    }

    const deleteScoreCard = async () => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await deleteScorecard(selectedPlayer.DrawID);
            fetchTournamentLeaderboard();
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : 'Scorecard Updated Successfully!',
                timer               : 1500,
                animation           : false,
                showConfirmButton   : false
            });
            closeDrawer();
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    const saveScorecard = () => {
        let newScoreCard = [];
        let notComplete = false;

        let hole = 0;

        const rows = document.querySelectorAll('#table-scorecard > tbody > tr');
        rows.forEach((row, index) => {
            const masterID = row.getAttribute('data-masterid');

            const scoreCard = gameDetails.TeeBoxes.ScoreCard.find(i => i.ID == masterID);
            let par = scoreCard.Par;

            // const userControl = row.querySelector(`[id^=txtPlayer${index}Score]`);
            // const userInputVal = userControl ? userControl.value : '0';

            const userInputVal = scores[index]

            let grossScore = userInputVal;

            // const totalPointElement = row.querySelector('[id^=lblTotalScore]');
            // const extractedTotalPoint = totalPointElement.getAttribute('data-score')
            // const totalPoint = extractedTotalPoint == '-' ? 0 : parseInt(extractedTotalPoint, 10);

            const totalPoint = (totalScores[index].totalScore == '-' || totalScores[index].totalScore == 'E') ? 0 : parseInt(totalScores[index].totalScore, 10);

            // const pointElement = row.querySelector('[id^=lblScore]');
            // const extractedPoint = pointElement.getAttribute('data-score')
            // const point = extractedPoint == '-' ? 0 : parseInt(extractedPoint, 10);

            const point = totalScores[index].point == '-' ? 0 : parseInt(totalScores[index].point, 10);

            const extraShot = getStrokeExtraShot(scoreCard.Stroke, selectedPlayer.Handicap);
            let Scratch = 'N';
            if (grossScore == "X") {
                Scratch = 'Y';
                grossScore = (extraShot == 0 && selectedPlayer.Handicap < 0) ? (par + 2) : (par + 2 + extraShot);
            } else {
                grossScore = parseInt(grossScore, 10);
            }

            if ((isNaN(grossScore) || grossScore == 0) && scorecardStatus == 'verify') {
                notComplete = true;
                return;
            } else {
                newScoreCard.push({
                    ID                  : 0,
                    TournamentDrawID    : selectedPlayer.DrawID,
                    PlayerID            : selectedPlayer.PlayerID,
                    PlayerType          : selectedPlayer.Player1Type,
                    ScoreCardMasterID   : masterID,
                    Score               : grossScore,
                    HandicapScore       : grossScore,
                    DriveUserID         : 0,
                    Point               : point,
                    TotalPoint          : totalPoint,
                    Scratch
                });

                if (grossScore != 0) hole++;
            }
        });

        const result = {
            ID: 0,
            TournamentDrawID: selectedPlayer.DrawID,
            Hole: hole,
            Point: footerTotalScore == 'E' ? 0 : footerTotalScore,
            TotalPoint: footerTotalScore == 'E' ? 0 : footerTotalScore,
            IsVerified: scorecardStatus == 'verify' ? 'Y' : (scorecardStatus == 'disqualify' ? 'DQ' : (scorecardStatus == 'withdraw' ? 'WD' : 'N')),
            Comment: (scorecardStatus == 'disqualify' || scorecardStatus == 'withdraw') ? comment : ''
        }

        if (notComplete) {
            swal.fire({ 
                icon        : "warning", 
                titleText   : "Incomplete Scorecard", 
                text        : "Please complete the scorecard..!" 
            });
            return;
        }

        const dto = {
            tournamentId: tournamentDetails.ID,
            round: selectedPlayer.Round,
            userId: selectedPlayer.PlayerID,
            scoreCard: newScoreCard,
            result: result
        };
        postScorecard(dto)
    }

    const postScorecard = async (scoreCard) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            await postScoreCard(scoreCard);
            fetchTournamentLeaderboard();
            swal.close();
            swal.fire({
                icon                : 'success',
                titleText           : 'Scorecard Updated Successfully!',
                timer               : 1500,
                animation           : false,
                showConfirmButton   : false
            });
            closeDrawer();
        } catch(err){
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    }

    return (
        <>
            <div className="kt-portlet kt-portlet--mobile padding-lr20">
                <div className="kt-portlet__head kt-portlet__head--lg  d-block padding-lr0 border-0">
                    <div id="roundInfoPanel" className="kt-notification margin-t10 mb-3" style={{backgroundColor: "rgb(247, 248, 250)"}}>
                        <div className="kt-notification__item d-block">
                            <div className="row">
                                <div className="col-3">
                                    <div className="kt-user-card-v2 mb-2">
                                        <div className="kt-user-card-v2__pic">
                                            <img id="txtTournamentImg" src={tournamentDetails?.Icon} alt="tournament-logo" />
                                        </div>
                                        <div className="kt-user-card-v2__details ">
                                            <span id="txtTournament" className="kt-user-card-v2__name text-clip"> {tournamentDetails?.Name} </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtRound"> Round {selectedPlayer?.Round} </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2 ">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name ">Date </span>
                                            <span className="kt-user-card-v2__desc">
                                                <span id="txtStartDate">{moment(tournamentDetails?.StartDate).format('DD-MMM-YYYY')}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex-center">
                                    <div className="kt-user-card-v2">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name ">Format </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtFormat">{format?.Format}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {!!tournamentRound && tournamentRound?.length > 0 && (
                                    <div className="col-2 d-flex-center">
                                        <div className="kt-user-card-v2 mb-2">
                                            <div className="kt-user-card-v2__details ">
                                                <span className="kt-user-card-v2__name ">Tee Of Option </span>
                                                <span className="kt-user-card-v2__desc ">
                                                    <span id="txtTeeOption">{format?.TeeOffOption}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-1 d-flex-center">
                                    <div className="kt-user-card-v2 ">
                                        <div className="kt-user-card-v2__details ">
                                            <span className="kt-user-card-v2__name " id="lblStartTime">StartTime </span>
                                            <span className="kt-user-card-v2__desc ">
                                                <span id="txtStartTime">{selectedPlayer.Slot}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 d-flex-center justify-content-end">
                                    {!!pointData && pointData.length > 0 && (
                                        <button id="btnPoint" type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm m-0"
                                            onClick={()=> setShowPointModal(true)}
                                        > 
                                            <i className="la la-list"></i> Point 
                                        </button>
                                    )}
                                </div>
                            </div>
                            <hr />

                            <div className="row">		
                                <div className="col-9">
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--success mb-0">
                                        <input 
                                            value="verify"
                                            name='verify'
                                            checked={scorecardStatus == 'verify'}
                                            onChange={() => toggleStatus('verify')}
                                            type="checkbox" 
                                            className="form-verify"/> 
                                            Verify Scorecard <span></span>
                                    </label>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="kt-checkbox-inline">										
                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger mb-0 mr-2">
                                            <input 
                                                value="disqualify"
                                                name='disqualify' 
                                                checked={scorecardStatus == 'disqualify'}
                                                onChange={() => toggleStatus('disqualify')}
                                                type="checkbox" 
                                                className="form-disqualify" />
                                                Disqualify <span></span>
                                        </label>
                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--brand mb-0">
                                            <input 
                                                value="withdraw"
                                                checked={scorecardStatus == 'withdraw'}
                                                onChange={() => toggleStatus('withdraw')}
                                                name='withdraw' 
                                                type="checkbox" 
                                                className="form-withdraw" />
                                                Withdraw <span></span>
                                        </label>
                                    </div>														
                                </div>  
                            </div>
                            {(scorecardStatus == 'withdraw' || scorecardStatus == 'disqualify') && (
                                <div className="row justify-content-end mt-2" id="pnlComment">                                
                                    <div className="col-4 text-right">
                                        <textarea 
                                            name="txtComment" 
                                            id="txtComment" 
                                            className="form-control"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)} 
                                            cols="3" rows="2" placeholder="Comments" 
                                        ></textarea>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="table-register">

                        <table id="table-scorecard" className="table table-bordered table-striped table-card">
                            <thead>
                                <tr id="mainHeader">
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Hole   </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Par    </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Stroke </th>
                                    {selectedPlayer && (
                                        <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle'}}> 
                                            <span className="mr-2">{selectedPlayer.Player1} ({(selectedPlayer.Handicap < 0) ? "+" + Math.abs(selectedPlayer.Handicap) : selectedPlayer.Handicap})</span>
                                        </th>
                                    )}
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Score </th>
                                    <th style={{ textAlign: 'center', width: '3%', verticalAlign: 'middle' }}>Total Point</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gameDetails && gameDetails.TeeBoxes.ScoreCard.map((item, index) => {
                                    const score = scores[index] || 0;
                                    const totalScore = totalScores[index] || { score: '-', totalScore: '-' };
                                    return(
                                        <tr key={`row_${item.HoleNoValue}`} className="row-score" data-masterid={item.ID}>
                                            <td style={{ textAlign: 'center'}}>{item.HoleNoValue}</td>
                                            <td style={{ textAlign: 'center'}}>{item.Par}</td>
                                            <td style={{ textAlign: 'center'}}>{item.Stroke}</td>
                                            <td key={`cell_${item.HoleNoValue}_${selectedPlayer.PlayerID}`} className={`card-data-${index} player-td`} data-playerid={selectedPlayer.PlayerID} data-scoreid='0'>
                                                <div className="input-group">
                                                    <input
                                                        id={`txtPlayer${index}Score`}
                                                        type="text"
                                                        className="form-control input-sm input-numeric"
                                                        value={score}
                                                        ref={el => inputRefs.current[index] = el}
                                                        onChange={(e) => handleScoreChange(index, e.target.value)}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text clickable" onClick={(e) => onScratched(e, index)}>
                                                            <i className="la la-times"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td id={`lblScore${index + 1}`} className="card-score" style={{ textAlign: 'center' }} data-score={totalScore.point}>
                                                {totalScore.point}
                                            </td>
                                            <td id={`lblTotalScore${index + 1}`} className="card-total-score" style={{ textAlign: 'center' }} data-score={totalScore.totalScore}>
                                                {totalScore.totalScore}
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>   
                            <tfoot>
                                <tr className="bg-light font-weight500">
                                    <td style={{textAlign: 'center'}}>OUT</td>
                                    <td style={{textAlign: 'center'}} id="lblTotalPar"> {gameDetails.TeeBoxes.Par} </td>
                                    <td style={{textAlign: 'center'}}></td>
                                    <td style={{textAlign: 'center'}}></td>
                                    <td style={{textAlign: 'center'}}></td>
                                    <td style={{ textAlign: 'center' }}>
                                        {footerTotalScore}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                        <div className="row">
                            <div className="col-lg-12 ml-lg-auto text-center">
                                <button id="btnSave" type="button" className="btn btn-success d-inline-block mr-2" onClick={saveScorecard}> Save </button>
                                <button id="btnDelete" type="button" className="btn btn-danger d-inline-block" onClick={deleteScoreCard}> Delete </button>
                                <button id="btnBack" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Close </button>
                            </div>
                            {/* <button id="btnEmailCard" type="button" className="btn btn-message pull-right"><i className="fa fa-mail-forward bigger-110"></i> Email Card </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default StableFordGross