import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getGolfUsers, updateUserHandicap } from "../api/usersAPI";
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const UserHandicap = (props) => {

    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [userList,            setUserList]            = useState([]);
    const [user,                setUser]                = useState({});

    useEffect(() => {
        props.title("User Handicap", "user-handicap")
        fetchUsers();
    }, []);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [userList]);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setUser({});
    }, [rightSideDetails]);

    const updateUserDetails = (field, value) => {
        setUser(u => ({...u, [field]: value}))
    }
    const fillDataTable = () => {
        const dataTable = $('#tblUsers').KTDatatable({
            data: {
                saveState: false,
                source: userList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    width: 250,
                    title: 'User',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--${row.Category === 'Organization' ? 'success' : 'info'} ">  ${row.Name.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.MembershipNo} </span>
                                    </div>
                                </div>`
                    }
                },
                {
                    field: 'Handicap',
                    title: 'Handicap',
                    type: 'number',
                    textAlign: 'center',
                    template: function (row) {
                        return row.Handicap ? row.Handicap : '--'
                    }
                },
                {
                    field: 'HandicapIndex',
                    title: 'Handicap Index',
                    type: 'number',
                    textAlign: 'center',
                    template: function (row) {
                        return row.HandicapIndex ? row.HandicapIndex : '--'
                    }
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblUsers .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setUser(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    const fetchUsers = async () => {
        try {
            const users = await getGolfUsers('user');
            isInit ? setIsInit(false) : $('#tblUsers').KTDatatable().destroy(); 
            if (users && users.length > 0) {
                setUserList(users.map(u => ({
                    ...u,
                    Name : `${u.FirstName} ${u.LastName}`
                })))
            }
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const updateHandicap = async () => {

        const btn = $('#btnSave');
        var form = $('#update-handicap-form');
        
        form.validate({
            rules: {
                txtHandicap: {
                    required: true
                },
                txtHandicapIndex: {
                    required: true,
                }           
            },
            messages: {
                txtHandicap: {
                    required: "Please enter handicap"
                },
                txtHandicapIndex: {
                    required: "Please enter handicap index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await updateUserHandicap(user.MemberID, user.Handicap, user.HandicapIndex)
            helper.StopProcessing(btn);
            closeDrawer();
            fetchUsers();
            swal.fire({
                icon: 'success',
                titleText: 'Handicap updated successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Menu..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblUsers" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && 
                            <RightSideDetails title="User Handicap Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                                    <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body padding-t30 padding-b0">
                                                <form className="kt-form" id="update-handicap-form">
                                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                        <div className="form-group row">
                                                            <label className="col-form-label offset-lg-1 col-lg-2">User <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div className="input-group">
                                                                    <select 
                                                                        id="ddlUser" 
                                                                        title="Select User" 
                                                                        name="ddlUser" 
                                                                        disabled
                                                                        className="form-control kt-selectpicker show-tick" data-live-search="true">
                                                                        <option value={user.Name}>{user.Name}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label offset-lg-1 col-lg-2">Handicap <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input 
                                                                    id="txtHandicap" 
                                                                    name="txtHandicap" 
                                                                    type="text" 
                                                                    value={user.Handicap || ''}
                                                                    onChange={(e) => updateUserDetails('Handicap', e.target.value)}
                                                                    className="form-control" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-form-label offset-lg-1 col-lg-2">Handicap Index <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input 
                                                                    id="txtHandicapIndex" 
                                                                    name="txtHandicapIndex" 
                                                                    value={user.HandicapIndex || ''}
                                                                    onChange={(e) => updateUserDetails('HandicapIndex', e.target.value)}
                                                                    type="text" 
                                                                    className="form-control" />
                                                            </div>
                                                        </div>

                                                        <div className="kt-portlet__foot">
                                                            <div className="kt-form__actions">
                                                                <div className="row">
                                                                    <div className="col-lg-9 ml-lg-auto">
                                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={updateHandicap}>Save</button>
                                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserHandicap