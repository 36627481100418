const OnboardingTracker = (props) => {

    const { userDetails } = props;
 
    return(
        <>
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                </div>
            </div>
            <div className="kt-portlet__body padding-t50 tracker-onboarding">
                <div className="kt-notes">
                    <div className="kt-notes__items">
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.IsSubmit == "Y" ? 'success' : 'warning'} kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section" >
                                    <div className={`kt-notes__info  ${userDetails.IsSubmit == "Y" && 'padding-r30'}`}>
                                        <a href="#" className="kt-notes__title">
                                            Member
                                        </a>
                                        <span className={`kt-badge kt-badge--${userDetails.IsSubmit == "Y" ? 'success' : 'warning'} kt-badge--inline kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.IsSubmit == "Y" ? 'Submitted' : 'Form Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.IsSubmit == "Y" && userDetails.AccountSignatureStatus == "N" ? "warning": userDetails.AccountSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info padding-r30">
                                        <a href="#" className="kt-notes__title">
                                            Accounts
                                        </a>
                                        <span className={`kt-badge kt-badge--${userDetails.IsSubmit == "Y" && userDetails.AccountSignatureStatus == "N" ? "warning": userDetails.AccountSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--inline kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.IsSubmit == "Y" && userDetails.AccountSignatureStatus == "N" ? "Pending for approval": userDetails.AccountSignatureStatus == "Y" ? 'Approved' : 'Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.AccountSignatureStatus == "Y" && userDetails.MembershipSignatureStatus == "N"  ? 'warning' : userDetails.MembershipSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info padding-r30">
                                        <a href="#" className="kt-notes__title">
                                            Membership
                                        </a>
                                        <span className={`kt-badge kt-badge--${userDetails.AccountSignatureStatus == "Y" && userDetails.MembershipSignatureStatus == "N"  ? 'warning' : userDetails.MembershipSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.AccountSignatureStatus == "Y" && userDetails.MembershipSignatureStatus == "N"  ? 'Pending for approval' : userDetails.MembershipSignatureStatus == "Y" ? 'Approved' : 'Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.MembershipSignatureStatus == "Y" && userDetails.AdminSignatureStatus == "N"  ? 'warning' : userDetails.AdminSignatureStatus == "Y" ? 'success' : 'grey'}  kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info padding-r30">
                                        <a href="#" className="kt-notes__title">
                                            Admin
                                        </a>
                                        
                                        <span className={`kt-badge kt-badge--${userDetails.MembershipSignatureStatus == "Y" && userDetails.AdminSignatureStatus == "N"  ? 'warning' : userDetails.AdminSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.MembershipSignatureStatus == "Y" && userDetails.AdminSignatureStatus == "N"  ? 'Pending for approval' : userDetails.AdminSignatureStatus == "Y" ? 'Approved' : 'Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.AdminSignatureStatus == "Y" && userDetails.PresidentSignatureStatus == "N"  ? 'warning' : userDetails.PresidentSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info padding-r30">
                                        <a href="#" className="kt-notes__title">
                                            President
                                        </a>
                                        <span className={`kt-badge kt-badge--${userDetails.AdminSignatureStatus == "Y" && userDetails.PresidentSignatureStatus == "N"  ? 'warning' : userDetails.PresidentSignatureStatus == "Y" ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.AdminSignatureStatus == "Y" && userDetails.PresidentSignatureStatus == "N"  ? 'Pending for approval' : userDetails.PresidentSignatureStatus == "Y" ? 'Approved' : 'Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notes__item  ">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    {userDetails.AccountSignatureStatus == "Y" && userDetails.MembershipSignatureStatus == "Y" && userDetails.AdminSignatureStatus == "Y" && userDetails.PresidentSignatureStatus == "Y" ? <i className="flaticon2-check-mark" style={{color: '#0abb87'}}></i> : <i className="flaticon2-time" style={{color: '#e1e1ef'}}></i>}
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info">
                                        <a href="#" className="kt-notes__title">{userDetails.AccountSignatureStatus == "Y" && userDetails.MembershipSignatureStatus == "Y" && userDetails.AdminSignatureStatus == "Y" && userDetails.PresidentSignatureStatus == "Y" ? 'Completed' : 'Pending'}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};


export default OnboardingTracker;