import React, { useState, useEffect } from 'react';
import { postRangeRateMaster } from '../api/paymentAPI';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const RangeRateTable = (props) => {
    const { ID, expandedMenu, sportID, fetchRateMasterBySportID, rateMaster } = props;

    const rows = [
        { no: 1, UserType: 'Member', RangeType: 'Mat', Type: 'Weekday' },
        { no: 2, UserType: 'Member', RangeType: 'Mat', Type: 'Weekends' },
        { no: 3, UserType: 'Member', RangeType: 'Grass', Type: 'Weekday' },
        { no: 4, UserType: 'Member', RangeType: 'Grass', Type: 'Weekends' },
        { no: 5, UserType: 'Child', RangeType: 'Mat', Type: 'Weekday' },
        { no: 6, UserType: 'Child', RangeType: 'Mat', Type: 'Weekends' },
        { no: 7, UserType: 'Child', RangeType: 'Grass', Type: 'Weekday' },
        { no: 8, UserType: 'Child', RangeType: 'Grass', Type: 'Weekends' },
        { no: 9, UserType: 'Guest', RangeType: 'Mat', Type: 'Weekday' },
        { no: 10, UserType: 'Guest', RangeType: 'Mat', Type: 'Weekends' },
        { no: 11, UserType: 'Guest', RangeType: 'Grass', Type: 'Weekday' },
        { no: 12, UserType: 'Guest', RangeType: 'Grass', Type: 'Weekends' },
        { no: 13, UserType: 'Guest Child', RangeType: 'Mat', Type: 'Weekday' },
        { no: 14, UserType: 'Guest Child', RangeType: 'Mat', Type: 'Weekends' },
        { no: 15, UserType: 'Guest Child', RangeType: 'Grass', Type: 'Weekday' },
        { no: 16, UserType: 'Guest Child', RangeType: 'Grass', Type: 'Weekends' },
        { no: 17, UserType: 'Visitor', RangeType: 'Mat', Type: 'Weekday' },
        { no: 18, UserType: 'Visitor', RangeType: 'Mat', Type: 'Weekends' },
        { no: 19, UserType: 'Visitor', RangeType: 'Grass', Type: 'Weekday' },
        { no: 20, UserType: 'Visitor', RangeType: 'Grass', Type: 'Weekends' },
        { no: 21, UserType: 'Visitor Child', RangeType: 'Mat', Type: 'Weekday' },
        { no: 22, UserType: 'Visitor Child', RangeType: 'Mat', Type: 'Weekends' },
        { no: 23, UserType: 'Visitor Child', RangeType: 'Grass', Type: 'Weekday' },
        { no: 24, UserType: 'Visitor Child', RangeType: 'Grass', Type: 'Weekends' },
    ];
    
    const initialRateMasterDetails = rows.map(row => ({
        SportID: sportID,
        CalenderID: ID,
        UserType: row.UserType,
        RangeType: row.RangeType,
        Type: row.Type,
        GreenFee: 0,
        Bucket1: 0,
        Bucket2: 0,
        Tax: 0,
        Total: 0,
        Remark: '',
    }));
  
    const [rateMasterList, setRateMasterList] = useState(initialRateMasterDetails);
   
    useEffect(() => {
        if(expandedMenu === ID  && rateMaster.length > 0){
            setRateMasterList(rateMaster);
        }else{
            setRateMasterList(initialRateMasterDetails);
        }
    }, [rateMaster, expandedMenu])
  
    const updateRateMaster = (userType, rangeType, type, field, value) => {
        setRateMasterList((prevDetails) => {
            return prevDetails.map(detail => {
                if (detail.UserType === userType && detail.RangeType === rangeType && detail.Type === type) {
                    const updatedDetail = {
                        ...detail,
                        [field]: value,
                    };
    
                    const greenFee = parseFloat(updatedDetail.GreenFee) || 0;
                    const bucket1 = parseFloat(updatedDetail.Bucket1) || 0;
                    const bucket2 = parseFloat(updatedDetail.Bucket2) || 0;
    
                    const rate = greenFee + bucket1 + bucket2;
                    const tax = rate * 0.18;
                    const total = rate + tax;
    
                    updatedDetail.Tax = Math.round(tax);
                    updatedDetail.Total = Math.round(total);
    
                    return updatedDetail;
                }
                return detail;
            });
        });
    };
     
    const syncGrassWithMat = (list) => {
        return list.map((item) => {
            if (item.RangeType === 'Grass') {
                const matRecord = list.find(r => 
                    r.UserType === item.UserType && 
                    r.RangeType === 'Mat' && 
                    r.Type === item.Type
                );
                if (matRecord) {
                    return {
                        ...item,
                        GreenFee: matRecord.GreenFee,
                        Bucket1: matRecord.Bucket1,
                        Bucket2: matRecord.Bucket2,
                        Tax: matRecord.Tax,
                        Total: matRecord.Total,
                        Remark: matRecord.Remark,
                    };
                }
            }
            return item;
        });
    };
    
    const handleSave = async() => {
        const btn = $('#btnSave');
        helper.StartProcessing(btn);
  
        try { 
            const syncedRateMasterList = syncGrassWithMat(rateMasterList);
            await postRangeRateMaster(syncedRateMasterList);
            if (rateMasterList[0].ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Master updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Master added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
            fetchRateMasterBySportID();
            helper.StopProcessing(btn);
                    
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };
  
    return(
        <>
            <div>
                <div id="accordionExample4">
                    <div id={`collapse-${ID}`} className={`collapse ${expandedMenu === ID ? 'show' : ''}`} aria-labelledby={`menuHeading-${ID}`} data-parent="#accordionExample4">
                        <div className="card-body">
                            <div className="kt-widget4">
                                <div className="kt-widget4__item padding-tb5">
                                    <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                        <thead>
                                            <tr>
                                            <th style={{ width: '5%' }}>No</th>
                                            <th style={{ width: '15%' }}>Particular</th>
                                            <th style={{ width: '10%' }}>Type</th>
                                            <th style={{ width: '10%' }}>Days</th>
                                            <th style={{ width: '10%' }}>Green Fee</th>
                                            <th style={{ width: '10%' }}>Bucket 1</th>
                                            <th style={{ width: '10%' }}>Bucket 2</th>
                                            <th style={{ width: '10%' }}>Tax</th>
                                            <th style={{ width: '10%' }}>Total</th>
                                            <th style={{ width: '15%' }}>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rateMasterList.length > 0 && rateMasterList
                                                .filter(detail => detail.RangeType !== 'Grass')
                                                .map((detail, index) => (
                                                    <tr key={`${detail.UserType}-${detail.RangeType}-${detail.Type}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{detail.UserType}</td>
                                                        <td>{detail.RangeType}</td>
                                                        <td>{detail.Type}</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control input-sm"
                                                                value={detail.GreenFee || 0}
                                                                onChange={(e) => updateRateMaster(detail.UserType, detail.RangeType, detail.Type, 'GreenFee', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control input-sm"
                                                                value={detail.Bucket1 || 0}
                                                                onChange={(e) => updateRateMaster(detail.UserType, detail.RangeType, detail.Type, 'Bucket1', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control input-sm"
                                                                value={detail.Bucket2 || 0}
                                                                onChange={(e) => updateRateMaster(detail.UserType, detail.RangeType, detail.Type, 'Bucket2', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control input-sm"
                                                                value={Math.round(detail.Tax) || 0}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className="form-control input-sm"
                                                                value={Math.round(detail.Total) || 0}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <textarea
                                                                className="form-control input-sm"
                                                                style={{ width: '100%', height: '40px' }}
                                                                rows="3"
                                                                value={detail.Remark || ''}
                                                                onChange={(e) => updateRateMaster(detail.UserType, detail.RangeType, detail.Type, 'Remark', e.target.value)}
                                                            ></textarea>
                                                        </td>
                                                    </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="clearfix form-actions">
                                    <button type="button" id="btnSave" name="btnSave" className="btn btn-primary" onClick={handleSave}>
                                        <i className="fa fa-save"></i> Save
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RangeRateTable;